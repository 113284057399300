import { useApi } from '@hooks/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { Agreement } from '@root/globalTypes';

export const useDisableAgreement = ({ id, callback }: { id: string; callback: () => void }) => {
  const { query } = useApi();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<Agreement> => {
      return query({
        path: `v1/agreements/${id}/disable`,
        opt: {
          method: 'PUT',
        },
      });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: [...queryKeys.agreements, id] });
      callback();
    },
  });

  return {
    mutate,
    isPending,
  };
};
