import { DataTable } from '@components/dataTable';
import { TableDateCell } from '@components/table/dateCell';
import { useTableSorting } from '@hooks/useTableSorting';
import { AcquirerLink } from '@root/globalTypes';
import { ColumnDef, Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

type AcquirerLinkTableProps = {
  data: AcquirerLink[];
  visibleColumns?: string[];
  onRowSelect: (id: string) => void;
};

export function useAcquirerLinksTable({
  data,
  visibleColumns,
  onRowSelect,
}: AcquirerLinkTableProps) {
  const { t } = useTranslation('app');
  const { sorting, updateSorting } = useTableSorting();

  function handleRowSelect(row: Row<AcquirerLink>) {
    const { id } = row.original;

    onRowSelect(id);
  }

  const columns: ColumnDef<AcquirerLink>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
      enableSorting: false,
    },
    {
      id: 'acquirer',
      accessorKey: 'acquirer',
      header: t('table.acquirer'),
      enableSorting: false,
      cell: ({ row }) => row.original.acquirer.name,
    },
    {
      id: 'bin',
      accessorKey: 'bin',
      header: t('table.bin'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'link_type',
      accessorKey: 'linkType',
      header: t('table.linkType'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'schemes',
      accessorKey: 'schemes',
      header: t('table.schemes'),
      enableSorting: false,
      cell: ({ row }) => row.original.schemes.join(', '),
    },
    {
      id: 'priority',
      accessorKey: 'priority',
      header: t('table.priority'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
    {
      id: 'updated_at',
      accessorKey: 'updatedAt',
      header: t('table.updatedAt'),
      cell: ({ row }) => <TableDateCell date={row.original.updatedAt} />,
    },
  ];

  return {
    columns,
    Table: () => (
      <DataTable
        columns={
          visibleColumns
            ? columns.filter((col) => (col.id ? visibleColumns.includes(col.id) : false))
            : columns
        }
        sorting={sorting}
        onSortChange={updateSorting}
        data={data}
        selectionCallback={handleRowSelect}
      />
    ),
  };
}
