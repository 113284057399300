import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Acquirer, MutateObject } from '@root/globalTypes';
import { useTranslation } from 'react-i18next';

export const MAX_FILE_SIZE = 204800;
export const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

export function useAcquirerForm({
  acquirer,
  requestHandler,
}: {
  acquirer?: Acquirer;
  requestHandler: MutateObject<Acquirer, FormData>;
}) {
  const { t } = useTranslation('app');

  const schema = z.object({
    name: z
      .string()
      .trim()
      .min(1, {
        message: t('form.acquirerName.required'),
      }),
    timezone: z
      .string()
      .trim()
      .min(1, {
        message: t('form.timezone.required'),
      }),
    twoFactorRequired: z.boolean(),
    logo: z
      .instanceof(File)
      .nullable()
      .refine((file) => {
        if (!file) return true;
        return file.size <= MAX_FILE_SIZE;
      }, t('form.logo.maxSize'))
      .refine((file) => {
        if (!file) return true;
        return ACCEPTED_IMAGE_TYPES.includes(file.type);
      }, t('form.logo.invalidType')),
    logoUrl: z.string().nullable(),
  });

  const defaultValues = {
    name: acquirer?.name || '',
    timezone: acquirer?.timezone || '',
    twoFactorRequired: acquirer?.twoFactorRequired || false,
    logo: null,
    logoUrl: acquirer?.logoUrl || '',
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, error, isPending } = requestHandler;

  const onSubmit = async (values: z.infer<typeof schema>) => {
    const formData = new FormData();
    if (values.logo) formData.append('logo', values.logo);
    formData.append('name', values.name);
    formData.append('two_factor_required', values.twoFactorRequired.toString());
    formData.append('timezone', values.timezone);

    mutate(formData);
  };

  return {
    form,
    onSubmit,
    isPending,
    error: error?.message,
  };
}
