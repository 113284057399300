import { DataTable } from '@components/dataTable';
import { TableDateCell } from '@components/table/dateCell';
import { useAppContext } from '@hooks/useAppContext';
import { useTableSorting } from '@hooks/useTableSorting';
import { AuditLog } from '@services/global/auditLogs/types';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

type AuditLogsTableProps = {
  visibleColumns: string[];
  data: AuditLog[];
  onRowSelect: (id: string) => void;
};

export function useAuditLogsTable({ visibleColumns, data, onRowSelect }: AuditLogsTableProps) {
  const { t } = useTranslation('app');
  const { userType } = useAppContext();
  const { sorting, updateSorting } = useTableSorting();

  const optionalColumns: ColumnDef<AuditLog>[] = [
    {
      id: 'organization_id',
      accessorFn: (auditLog) => auditLog.organization?.id,
      header: t('table.organizationId'),
      enableSorting: false,
    },
    {
      id: 'organization_name',
      accessorFn: (auditLog) => auditLog.organization?.name,
      header: t('table.organizationName'),
      enableSorting: false,
    },
    {
      id: 'organization_type',
      accessorKey: 'organizationType',
      header: t('table.organizationType'),
      enableSorting: false,
    },
  ];

  const defaultColumns: ColumnDef<AuditLog>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
      enableSorting: false,
    },
    {
      id: 'performer_id',
      accessorKey: 'performer.id',
      header: t('table.performerId'),
      enableSorting: false,
    },
    {
      id: 'performer_email',
      accessorKey: 'performer.email',
      header: t('table.performerEmail'),
      enableSorting: false,
    },
    {
      id: 'performer_type',
      accessorKey: 'performerType',
      header: t('table.performerType'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'subject_id',
      accessorKey: 'subjectId',
      header: t('table.subjectId'),
      enableSorting: false,
    },
    {
      id: 'subject_type',
      accessorKey: 'subjectType',
      header: t('table.subjectType'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'action',
      accessorKey: 'action',
      header: t('table.action'),
      enableSorting: false,
    },
    {
      id: 'action_type',
      accessorKey: 'actionType',
      header: t('table.actionType'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: t('table.description'),
      enableSorting: false,
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
  ];

  const columns =
    userType === 'merchant'
      ? defaultColumns
      : [defaultColumns[0], ...optionalColumns, ...defaultColumns.slice(1)];

  return {
    columns,
    Table: () => (
      <DataTable
        columns={columns.filter(({ id }) =>
          typeof id === 'string' ? visibleColumns.includes(id) : false,
        )}
        data={data}
        sorting={sorting}
        onSortChange={updateSorting}
        selectionCallback={(row) => onRowSelect(row.original.id)}
      />
    ),
  };
}
