import { PaymentControlsTypes } from '@utils/consts';
import { Dispatch, SetStateAction, createContext } from 'react';

type FormOpen = {
  delete: boolean;
  edit: boolean;
  create: boolean;
};

type PaymentControlFormContextType = {
  formOpen: FormOpen;
  setFormOpen: Dispatch<SetStateAction<FormOpen>>;
  formType: PaymentControlsTypes;
  setFormType: Dispatch<SetStateAction<PaymentControlsTypes>>;
};

export const PaymentControlFormContext = createContext<PaymentControlFormContextType | null>(null);
