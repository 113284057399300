import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import ar from './translations/ar.json';
import { getLangFromPath } from '@utils/consts';

export const resources = {
  en: { app: en },
  ar: { app: ar },
} as const;

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(initReactI18next).init({
  resources,
  lng: getLangFromPath(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

/* eslint-disable immutable/no-mutation */
document.documentElement.lang = i18n.language;

export const handleLanguageChange = async (langParam = '') => {
  const lang = langParam || getLangFromPath();
  await i18n.changeLanguage(lang);
  document.documentElement.lang = lang;
};

export const isEn = () => {
  return i18n.language === 'en';
};

export default i18n;
