import { Loader } from '@components/loader';
import { RawPreview } from '@components/rawPreview';
import { Page404 } from '@pages/404';
import { useGetOrder } from '@services/global/orders/get';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { outletNavScroll } from '@utils/apiHelpers';
import { useEffect } from 'react';

const OrderApiResp = () => {
  const { id, localId } = useParams({
    from: '/u/$localId/_acquirer/acquirer/orders/$id/apiResponse',
  });
  const { data, loading } = useGetOrder(id);

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (loading) return <Loader />;
  if (!data) return <Page404 fallbackPath={`/u/${localId}/acquirer/orders`} />;

  return <RawPreview data={data} />;
};

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/orders/$id/apiResponse')({
  component: OrderApiResp,
});
