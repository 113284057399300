import { ColumnFilterPopover } from '@components/popover/columnsFilterPopover';
import { useMerchantsTable } from '@components/tables/merchants';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useToggleTableColumns } from '@hooks/useToggleTableColumns';
import { BaseLayout } from '@layouts/base';
import { Merchant } from '@root/globalTypes';
import { Plus } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersProvider } from '@ctx/filters';
import { Filters } from '@components/filters';
import { IconButton } from '@components/button/iconButton';
import { MobileFilters } from '@components/filters/mobileFilters';
import { FiltersConfig } from '@ctx/filters/context';

export const Merchants = ({
  data,
  newRedirect,
  onRowSelect,
  filtersConfig,
}: {
  data: Merchant[];
  newRedirect?: () => void;
  onRowSelect: (merchant: Merchant) => void;
  filtersConfig: FiltersConfig;
}) => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const predefinedCols = useMemo(
    () => [
      'id',
      'merchant_id',
      'name',
      'arabic_name',
      'trading_name',
      'mcc',
      'cr_number',
      'created_at',
      'updated_at',
    ],
    [],
  );
  const { visibleColumns, setColumns } = useToggleTableColumns({ predefinedCols });
  const { columns, Table: MerchantsTable } = useMerchantsTable({
    data,
    onRowSelect: onRowSelect,
    visibleColumns,
  });

  return (
    <FiltersProvider config={filtersConfig}>
      <BaseLayout
        title={t('pages.merchants.title')}
        actions={[
          <MobileFilters key="filters" />,
          <ColumnFilterPopover
            key="colFilter"
            activeCols={visibleColumns}
            setActiveCols={setColumns}
            columns={columns.map((col) => ({ id: col.id as string, label: col.header as string }))}
            defaultColumns={predefinedCols}
          />,
          newRedirect && verifyUserPermission('merchants_create') && (
            <IconButton
              key="new"
              onClick={newRedirect}
              icon={<Plus size={18} />}
              variant="secondary">
              {t('actions.addNew')}
            </IconButton>
          ),
        ]}>
        <Filters />
        <MerchantsTable />
      </BaseLayout>
    </FiltersProvider>
  );
};
