import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useApi } from '@hooks/useApi';
import { ApiError, OrderDetails } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

export const useGetOrder = (id: string) => {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();
  const { data, isPending } = useQuery<OrderDetails, ApiError>({
    queryKey: [...queryKeys.order, id],
    queryFn: () =>
      query({
        path: `v1/orders/${id}`,
      }),
    enabled: isLogged,
    staleTime: 60 * 1000,
  });

  return {
    data: data,
    loading: isPending,
  };
};
