import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { AcquirerUser } from '@root/globalTypes';

type GetUsersReturn = {
  data: AcquirerUser[];
  isPending: boolean;
};

export function useListAcquirerUsers(): GetUsersReturn {
  const { query } = useApi();

  const { data, isPending } = useQuery<AcquirerUser[]>({
    queryKey: [...queryKeys.acquirerUsers, window.location.search],
    queryFn: (): Promise<AcquirerUser[]> =>
      query({
        path: `v1/acquirer/users${window.location.search}`,
      }),
  });

  return {
    data: data || [],
    isPending,
  };
}
