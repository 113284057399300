import { FiltersConfig } from '@ctx/filters/context';
import { sourceTypeOptions, schemeOptions } from '@components/filters/selectOptions';
import { countries } from '@utils/countries';

export const config: FiltersConfig = {
  items: [
    { id: 'id', filterType: 'text' },
    { id: 'merchant_id', filterType: 'text' },
    { id: 'source_type', filterType: 'select', options: sourceTypeOptions },
    { id: 'terminal_id', filterType: 'text' },
    { id: 'forwarder_id', filterType: 'text' },
    { id: 'issuer_id', filterType: 'text' },
    { id: 'currency', filterType: 'currency' },
    { id: 'amount', filterType: 'amount' },
    { id: 'device_ip', filterType: 'text' },
    { id: 'agreement_id', filterType: 'text' },
    { id: 'sub_merchant_id', filterType: 'text' },
    { id: 'description', filterType: 'text' },
    { id: 'retrieval_reference', filterType: 'text', format: 'number' },
    { id: 'stan', filterType: 'text', format: 'number' },
    { id: 'auth_code', filterType: 'text', format: 'number' },
    { id: 'response_code', filterType: 'text' },
    { id: 'first_digits', filterType: 'text', format: 'number' },
    { id: 'last_digits', filterType: 'text', format: 'number' },
    { id: 'scheme', filterType: 'select', options: schemeOptions },
    { id: 'card_expiry', filterType: 'card_expiry' },
    { id: 'fingerprint', filterType: 'text' },
    { id: 'country', filterType: 'select', options: countries },
    { id: 'category', filterType: 'text' },
    { id: 'issuer', filterType: 'text' },
    { id: 'card_type', filterType: 'text' },
    { id: 'created_at', filterType: 'date', format: 'date' },
    { id: 'updated_at', filterType: 'date', format: 'date' },
  ],
  default: ['amount', 'scheme', 'source_type', 'last_digits', 'retrieval_reference', 'created_at'],
};
