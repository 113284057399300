import { Card, CardContent } from '@components/card';
import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { BaseLayout } from '@layouts/base';
import { AuditLog } from '@services/global/auditLogs/types';
import { cn } from '@utils/cn';
import { formatDate } from '@utils/dateHelpers';
import { Activity, CalendarPlus, FileText, Fingerprint, Workflow } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuditLogPerformer } from './tabs/performer';
import { AuditLogOrganization } from './tabs/organization';
import { AuditLogSubject } from './tabs/subject';
import { AuditLogMetadata } from './tabs/metaData';
import { TabsContainer } from '@components/tabLink';

type AuditLogPageProps = {
  auditLog: AuditLog;
};

export function AuditLogPage({ auditLog }: AuditLogPageProps) {
  const { t } = useTranslation('app');
  const tabs = useMemo(() => ['performer', 'organization', 'subject', 'metadata'], []);
  const visibleTabs = useMemo(() => {
    return tabs.filter((tab) => {
      if (tab == 'subject' && auditLog.subjectId) return true;
      return auditLog[tab as keyof AuditLog];
    });
  }, [tabs, auditLog]);

  const [activeTab, setActiveTab] = useState<string>('performer');

  function renderActiveTab() {
    switch (activeTab) {
      case 'performer':
        return <AuditLogPerformer auditLog={auditLog} />;
      case 'organization':
        return <AuditLogOrganization auditLog={auditLog} />;
      case 'subject':
        return <AuditLogSubject auditLog={auditLog} />;
      case 'metadata':
        return <AuditLogMetadata auditLog={auditLog} />;
      default:
        return null;
    }
  }

  return (
    <BaseLayout isChild title={t('pages.auditLog.title')}>
      <DetailsCard>
        <DetailsCardItem
          icon={<Fingerprint />}
          label={t('table.id')}
          data={auditLog.id}
          column={1}
        />
        <DetailsCardItem
          icon={<Activity />}
          label={t('table.action')}
          data={auditLog.action}
          column={1}
        />
        <DetailsCardItem
          icon={<Workflow />}
          label={t('table.actionType')}
          data={auditLog.actionType}
          column={1}
        />
        <DetailsCardItem
          icon={<FileText />}
          label={t('table.description')}
          data={auditLog.description}
          column={1}
        />
        <DetailsCardItem
          icon={<CalendarPlus size={26} />}
          data={formatDate(new Date(auditLog.createdAt))}
          label={t('table.createdAt')}
          column={2}
        />
      </DetailsCard>
      <Card>
        <CardContent className="py-3 pb-10">
          <TabsContainer>
            {visibleTabs.map((tab) => {
              return (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={cn(
                    'rounded bg-neutral-100 px-4 py-2 text-neutral-400 transition-colors hover:bg-primary/10',
                    tab === activeTab ? 'bg-primary/10 !text-primary' : '',
                  )}>
                  {t(`pages.auditLog.${tab}`)}
                </button>
              );
            })}
          </TabsContainer>
          {renderActiveTab()}
        </CardContent>
      </Card>
    </BaseLayout>
  );
}
