import { Button } from '@components/button';
import { buttonVariants } from '@components/button/variants';
import { Loader } from '@components/loader';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { useGetAcquirerUser } from '@services/acquirer/users/get';
import { useAcquirerUserResetPassword } from '@services/acquirer/users/reset';
import { Link, createFileRoute, redirect, useParams } from '@tanstack/react-router';
import { FileText, Landmark, Mail, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { Page404 } from '@pages/404';
import { useGetPermissionGroups } from '@services/acquirer/users/permissionGroups';
import { PermissionsAccordion } from '@components/permissionsAccordion';

const AcquirerUserPage = () => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const { id, localId } = useParams({ from: '/u/$localId/_acquirer/acquirer/users/$id/' });
  const { data, isPending } = useGetAcquirerUser(id);
  const { data: permissions } = useGetPermissionGroups();

  const { mutate, isPending: requested } = useAcquirerUserResetPassword({
    id,
    callback: () => {
      toast.success(t('toast.password.requestSuccessful'));
    },
  });

  if (isPending) return <Loader />;
  if (!data) return <Page404 />;
  return (
    <BaseLayout
      isChild
      title={data.name}
      actions={[
        verifyUserPermission(`acquirer_users_password_reset`) && (
          <Button
            key="reset"
            variant="outline"
            loading={requested}
            disabled={requested}
            onClick={() => mutate()}>
            {t('passwordReset')}
          </Button>
        ),
        verifyUserPermission('acquirer_users_update') && (
          <Link
            key="edit"
            to="/u/$localId/acquirer/users/$id/edit"
            params={{ id: data.id, localId }}
            className={buttonVariants({ variant: 'primary' })}>
            {t('actions.edit')}
          </Link>
        ),
      ]}>
      <DetailsCard>
        <DetailsCardItem
          icon={<Mail size={26} />}
          label={t('table.email')}
          data={data.email}
          column={1}
        />
        <DetailsCardItem
          icon={<User size={26} />}
          label={t('table.name')}
          data={data.name}
          column={2}
        />
        <DetailsCardItem
          icon=<Landmark size={26} />
          label={t('table.acquirer')}
          data={data.acquirer.name}
          column={1}
        />
        <DetailsCardItem
          icon={<FileText size={26} />}
          label={t('table.description')}
          data={data.description}
          column={2}
        />
      </DetailsCard>

      {permissions && (
        <PermissionsAccordion selectedPermissions={data.permissions} permissions={permissions} />
      )}
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/users/$id/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirer_users_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: AcquirerUserPage,
});
