import { Loader } from '@components/loader';
import { useGetApiKey } from '@services/staff/merchant/apiKeys/get';
import { createFileRoute, redirect, useParams } from '@tanstack/react-router';
import { ApiKeyShow } from '@pages/apiKeys/id';
import { useDisableApiKey } from '@services/staff/merchant/apiKeys/disable';
import { useGetPermissionGroups } from '@services/merchant/apiKeys/permissionsGroups';

const ShowApiKey = () => {
  const { id, merchantId } = useParams({
    from: '/u/$localId/_staff/staff/merchants/$merchantId/api-keys/$id/',
  });
  const { data, isPending } = useGetApiKey({ merchantId, id });
  const { mutate, isPending: disabling } = useDisableApiKey(merchantId);
  const { data: permissions } = useGetPermissionGroups();

  if (isPending) return <Loader />;

  return (
    <ApiKeyShow apiKey={data} onDisable={mutate} disabling={disabling} permissions={permissions} />
  );
};

export const Route = createFileRoute(
  '/u/$localId/_staff/staff/merchants/$merchantId/api-keys/$id/',
)({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['api_keys_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: ShowApiKey,
});
