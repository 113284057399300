import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  beforeLoad: ({ context }) => {
    if (context.isAuthenticated()) {
      throw redirect({ to: '/u' });
    } else {
      throw redirect({ to: `/${context.lastLoggedUserType()}/login`, replace: true });
    }
  },
  component: undefined,
});
