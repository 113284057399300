import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '@utils/cn';

interface ExtraProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  light?: boolean;
}

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, ExtraProps>(
  ({ className, light, ...props }, ref) => (
    <SwitchPrimitives.Root
      className={cn(
        'peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent bg-neutral-200/70 transition-colors focus:border-primary active:border-primary disabled:cursor-not-allowed disabled:border-transparent disabled:opacity-40',
        light
          ? 'focus:border-primary-300 active:border-primary-300'
          : 'focus:border-primary active:border-primary',
        className,
      )}
      {...props}
      ref={ref}>
      <SwitchPrimitives.Thumb
        className={cn(
          'pointer-events-none block h-5 w-5 rounded-full bg-white shadow-lg ring-0 transition-all data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0',
          light ? 'data-[state=checked]:bg-primary-300' : 'data-[state=checked]:bg-primary',
          // props.disabled && '!bg-neutral-300',
        )}
      />
    </SwitchPrimitives.Root>
  ),
);
// eslint-disable-next-line immutable/no-mutation
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
