import {
  Landmark,
  Users,
  Logs,
  ArrowRightLeft,
  BadgeCheck,
  Code,
  CreditCard,
  Download,
  FileText,
  LayoutDashboard,
  Link,
  Store,
} from 'lucide-react';
import { LinkProps } from './sideNavLink';

export const iconsClass = 'h-5 w-5';

export const staffLinks: LinkProps[] = [
  {
    icon: <LayoutDashboard className={iconsClass} />,
    to: `/u/$localId/staff/orders`,
    label: 'orders',
    permission: 'orders_read',
  },
  {
    icon: <ArrowRightLeft className={iconsClass} />,
    to: `/u/$localId/staff/transactions`,
    label: 'transactions',
    permission: 'orders_read',
  },
  {
    icon: <BadgeCheck className={iconsClass} />,
    to: `/u/$localId/staff/card-authentications`,
    label: 'cardAuths',
    permission: '3ds_read',
  },
  {
    icon: <FileText className={iconsClass} />,
    to: `/u/$localId/staff/agreements`,
    label: 'agreements',
    permission: 'agreements_read',
  },
  {
    icon: <Landmark className={iconsClass} />,
    to: `/u/$localId/staff/acquirers`,
    label: 'acquirers',
    permission: 'acquirers_read',
  },
  {
    icon: <Store className={iconsClass} />,
    to: `/u/$localId/staff/merchants`,
    label: 'merchants',
    permission: 'merchants_read',
  },
  {
    icon: <Users className={iconsClass} />,
    to: `/u/$localId/staff/users`,
    label: 'users',
    permission: 'staff_users_read',
  },
  {
    icon: <CreditCard className={iconsClass} />,
    to: '/u/$localId/staff/bins/import',
    label: 'bins',
    skipSearch: true,
    permission: 'bins_import',
  },
  {
    icon: <Logs className={iconsClass} />,
    to: `/u/$localId/staff/audit-logs`,
    label: 'auditLogs',
    permission: 'audit_logs_read',
  },
];

export const merchantLinks: LinkProps[] = [
  {
    icon: <LayoutDashboard className={iconsClass} />,
    to: `/u/$localId/merchant/orders`,
    label: 'orders',
    permission: 'orders_read',
  },
  {
    icon: <ArrowRightLeft className={iconsClass} />,
    to: `/u/$localId/merchant/transactions`,
    label: 'transactions',
    permission: 'orders_read',
  },
  {
    icon: <BadgeCheck className={iconsClass} />,
    to: `/u/$localId/merchant/card-authentications`,
    label: 'cardAuths',
    permission: '3ds_read',
  },
  {
    icon: <FileText className={iconsClass} />,
    to: `/u/$localId/merchant/agreements`,
    label: 'agreements',
    permission: 'agreements_read',
  },
  {
    icon: <Code className={iconsClass} />,
    to: `/u/$localId/merchant/api-keys`,
    label: 'apiKeys',
    permission: 'api_keys_read',
  },
  {
    icon: <CreditCard className={iconsClass} />,
    to: `/u/$localId/merchant/payment-controls`,
    label: 'paymentControls',
    permission: 'payment_controls_read',
  },
  {
    icon: <Link className={iconsClass} />,
    to: `/u/$localId/merchant/links`,
    label: 'links',
    permission: 'merchant_links_read',
  },
  {
    icon: <Users className={iconsClass} />,
    to: `/u/$localId/merchant/users`,
    label: 'users',
    permission: 'merchant_users_read',
  },
  {
    icon: <Logs className={iconsClass} />,
    to: `/u/$localId/merchant/audit-logs`,
    label: 'auditLogs',
    permission: 'audit_logs_read',
  },
];

export const acquirerLinks: LinkProps[] = [
  {
    icon: <LayoutDashboard className={iconsClass} />,
    to: `/u/$localId/acquirer/orders`,
    label: 'orders',
    permission: 'orders_read',
  },
  {
    icon: <ArrowRightLeft className={iconsClass} />,
    to: `/u/$localId/acquirer/transactions`,
    label: 'transactions',
    permission: 'orders_read',
  },
  {
    icon: <BadgeCheck className={iconsClass} />,
    to: `/u/$localId/acquirer/card-authentications`,
    label: 'cardAuths',
    permission: '3ds_read',
  },
  {
    icon: <FileText className={iconsClass} />,
    to: `/u/$localId/acquirer/agreements`,
    label: 'agreements',
    permission: 'agreements_read',
  },
  {
    icon: <Store className={iconsClass} />,
    to: `/u/$localId/acquirer/merchants`,
    label: 'merchants',
    permission: 'merchants_read',
  },
  {
    icon: <Code className={iconsClass} />,
    to: `/u/$localId/acquirer/api-keys`,
    label: 'apiKeys',
    permission: 'api_keys_read',
  },
  {
    icon: <CreditCard className={iconsClass} />,
    to: `/u/$localId/acquirer/payment-controls`,
    label: 'paymentControls',
    permission: 'payment_controls_read',
  },
  {
    icon: <Link className={iconsClass} />,
    to: `/u/$localId/acquirer/links`,
    label: 'links',
    permission: 'acquirer_links_read',
  },
  {
    icon: <Download className={iconsClass} />,
    to: `/u/$localId/acquirer/eod-statements`,
    label: 'eod',
    permission: 'eod_read',
  },
  {
    icon: <Users className={iconsClass} />,
    to: `/u/$localId/acquirer/users`,
    label: 'users',
    permission: 'acquirer_users_read',
  },
  {
    icon: <Logs className={iconsClass} />,
    to: `/u/$localId/acquirer/audit-logs`,
    label: 'auditLogs',
    permission: 'audit_logs_read',
  },
];
