import { Loader } from '@components/loader';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { AgreementOrders } from '@pages/agreements/orders';
import { useGetAgreement } from '@services/global/agreements/get';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { outletNavScroll } from '@utils/apiHelpers';
import { useEffect } from 'react';

function AgreementOrdersRoute() {
  const { verifyUserPermission } = useCheckUserPermissions();
  const navigate = useNavigate();
  const { id, localId } = useParams({
    from: '/u/$localId/_acquirer/acquirer/agreements/$id/orders',
  });
  const { data, isPending } = useGetAgreement({ id });

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (isPending) return <Loader />;
  if (!data) return null;

  return (
    <AgreementOrders
      orders={data.orders}
      onRowSelect={(orderId) => {
        if (!verifyUserPermission('orders_read')) return;

        navigate({ to: '/u/$localId/acquirer/orders/$id', params: { id: orderId, localId } }).catch(
          (err) => console.error(err),
        );
      }}
    />
  );
}

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/agreements/$id/orders')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['agreements_read'])) {
      throw redirect({ to: `/u/$localId/${context.userType}/forbidden` });
    }
  },
  component: AgreementOrdersRoute,
});
