import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useApi } from '@hooks/useApi';
import { ApiError } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { PaymentControlsTypes, queryKeys } from '@utils/consts';
import { PaymentControl } from '@root/globalTypes';
import { mapControlType } from '@utils/apiHelpers';

export type GetMerchantParams = {
  id: string;
  merchantId: string;
  type: PaymentControlsTypes;
};

export const useGetPaymentControl = ({ id, merchantId, type }: GetMerchantParams) => {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();

  const { data, isPending } = useQuery<PaymentControl, ApiError>({
    queryKey: [...queryKeys.paymentControls, merchantId, id],
    queryFn: () =>
      query({
        path: `v1/acquirer/merchants/${merchantId}/payment_controls/${mapControlType(type)}/${id}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged || Boolean(id),
  });

  return {
    data,
    loading: isPending,
  };
};
