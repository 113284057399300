import { Input } from '@components/form/input';
import { Label } from '@components/form/label';
import { useTranslation } from 'react-i18next';
import { validateKeyDown } from '@utils/textHelpers';
import { useFilters } from '@ctx/filters/context';
import { FilterItem } from './filterItem';
import { useMemo } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@components/tootlip';
import { Info } from 'lucide-react';
import { FilterProps } from '.';

export const TextFilter = ({ configItem, wrapped }: FilterProps) => {
  const { t } = useTranslation('app');
  const { activeFilters, setFilter } = useFilters();
  const field = (activeFilters[configItem.id] as string) || '';
  const isDisabled = configItem?.disabledBy && !activeFilters[configItem?.disabledBy];

  const formattedValue = useMemo(() => {
    return field.length ? `: ${field}` : '';
  }, [field]);

  function onChange(value: string | undefined) {
    setFilter(configItem.id, value || '');
  }

  const isNumeric = configItem.format === 'number';

  if (wrapped) {
    return (
      <div className="relative space-y-2">
        <Label className="absolute left-3 flex gap-2 bg-white px-2 text-xs" htmlFor={configItem.id}>
          {t(`filters.${configItem.id}.label`)}
          {isDisabled && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild className="self-start">
                  <Info size={14} className="text-neutral-500" />
                </TooltipTrigger>
                <TooltipContent className="bg-neutral-50">
                  {t('filters.disabled', {
                    item: t(`filters.${configItem.disabledBy}.label`),
                  })}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </Label>
        <Input
          name={`${configItem.id}_alt`}
          onChange={(ev) => onChange(ev.target.value)}
          defaultValue={field}
          onKeyDown={(e) => {
            if (isNumeric && !validateKeyDown(e, /[0-9]|,/)) e.preventDefault();
          }}
          disabled={isDisabled}
        />
      </div>
    );
  }

  return (
    <FilterItem displayedValue={formattedValue} configItem={configItem}>
      <div className="relative space-y-2">
        <Label className="absolute left-3 bg-white px-2 text-xs" htmlFor={configItem.id}>
          {t(`filters.${configItem.id}.label`)}
        </Label>
        <Input
          name={`${configItem.id}_alt`}
          onChange={(ev) => onChange(ev.target.value)}
          defaultValue={field}
          onKeyDown={(e) => {
            if (isNumeric && !validateKeyDown(e, /[0-9]|,/)) e.preventDefault();
          }}
        />
      </div>
    </FilterItem>
  );
};
