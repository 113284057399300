import { merchantLinks } from '@components/navigation/const';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

function Index() {
  const navigate = useNavigate();
  const { verifyUserPermission } = useCheckUserPermissions();

  useEffect(() => {
    const url =
      merchantLinks.filter(({ permission }) =>
        permission ? verifyUserPermission(permission) : true,
      )[0]?.to || '';
    const timer = setTimeout(() => {
      navigate({ to: url, replace: true }).catch((err) => {
        console.error(err);
      });
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, [navigate, verifyUserPermission]);

  return null;
}

export const Route = createFileRoute('/u/$localId/_merchant/merchant/')({
  component: Index,
});
