import { ScrollArea } from '@radix-ui/react-scroll-area';
import { Link } from '@tanstack/react-router';

export function TabsContainer({ children }: { children: React.ReactNode }) {
  return (
    <ScrollArea
      className="mb-6 w-full overflow-x-auto whitespace-nowrap border-b"
      type="scroll"
      id="tabs">
      <div className="flex flex-nowrap gap-4 px-2 py-6 before:flex-1 before:content-[''] after:flex-1 after:content-['']">
        {children}
      </div>
    </ScrollArea>
  );
}

export function TabLink({
  label,
  path,
  params = {},
  replace = true,
  hash,
}: {
  label: string;
  path: string;
  params?: Record<string, string>;
  replace?: boolean;
  hash?: string;
}) {
  return (
    <Link
      hash={hash || 'tabs'}
      className="flex items-center rounded bg-neutral-100 px-4 py-2 text-neutral-400 transition-colors hover:bg-primary/10"
      activeProps={{
        className: '!text-primary bg-primary/10',
      }}
      to={path}
      params={params}
      replace={replace}>
      {label}
    </Link>
  );
}
