import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { EodStatement } from './types';
import { MetaData } from '@root/globalTypes';

type EodStatementsListResp = {
  eodStatements: EodStatement[];
  meta: MetaData;
};

export function useEodStatementsList() {
  const { query } = useApi();

  const { data, isPending } = useQuery<EodStatementsListResp>({
    queryKey: [...queryKeys.eodStatements, window.location.search],
    queryFn: (): Promise<EodStatementsListResp> =>
      query({
        path: `v1/acquirer/eod_statements${window.location.search}`,
      }),
    placeholderData: (previousValue) => previousValue,
  });

  return {
    data: data?.eodStatements || [],
    meta: data?.meta,
    isPending,
  };
}
