import { useApi } from '@hooks/useApi';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { ApiError, AuthRespSuccess, StaffUser } from '@root/globalTypes';

type RecoveryData = {
  token: string;
  otpCode: string;
};

type RecoveryResponse = AuthRespSuccess<StaffUser> | ApiError;

export function isFailure(data: RecoveryResponse): data is ApiError {
  return (data as ApiError).message !== undefined;
}

export const useRecovery = ({
  handleSuccess,
}: {
  handleSuccess: (data: AuthRespSuccess<StaffUser>) => Promise<void>;
}) => {
  const { query } = useApi();
  const [apiError, setApiError] = useState<string>('');

  const { mutate, isPending } = useMutation({
    mutationFn: (data: RecoveryData): Promise<RecoveryResponse> => {
      return query({
        path: 'v1/staff/session/recovery',
        data: data,
        opt: {
          method: 'POST',
        },
      });
    },
    onSettled: async (data) => {
      if (!data) return;
      if (isFailure(data)) {
        setApiError(data.message);
      } else {
        await handleSuccess(data);
      }
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
};
