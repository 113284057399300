import { Button } from '@components/button';
import { Checkbox } from '@components/form/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '@components/form/select';
import { AcquirerLink } from '@root/globalTypes';
import { VALID_SCHEMES } from '@utils/consts';
import { humanizeText } from '@utils/textHelpers';
import { useTranslation } from 'react-i18next';
import cf from 'currency-format';
import { useMemo } from 'react';
import { ComboBoxItemType } from '@components/combobox';
import { MultiselectCombobox } from '@components/combobox/multiselect';
import { MerchantCategoriesCombobox } from '@components/comboboxes/merchantCategories';
import { useMerchantLinkForm } from '@hooks/forms/merchantLinks';

export function MerchantLinkForm({
  acquirerLinks,
  formObj,
  buttonLabel,
}: {
  acquirerLinks: AcquirerLink[];
  formObj: ReturnType<typeof useMerchantLinkForm>;
  buttonLabel: string;
}) {
  const { t } = useTranslation('app');
  const { form, error, onSubmit, isPending } = formObj;

  const currencies = useMemo(() => {
    const curr = [];

    for (const [k, v] of Object.entries(cf)) {
      curr.push({ label: `${k}, ${v.name}`, value: k });
    }

    return curr as ComboBoxItemType[];
  }, []);

  return (
    <Form {...form}>
      <form
        id="userForm"
        className="flex flex-col gap-4"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="acquirerLinkId"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('form.acquirerLinkId.label')}</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t('form.acquirerLinkId.placeholder')} />
                  </SelectTrigger>
                </FormControl>
                <FormMessage />
                <SelectContent>
                  {acquirerLinks.map((link) => (
                    <SelectItem key={link.id} value={link.id}>
                      {link.linkType} ({link.priority})
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="priority"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.priority.label')}</FormLabel>
              <FormControl>
                <Input {...field} type="number" min={0} max={40} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="merchantSchemeId"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('form.merchantSchemeId.label')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="terminalId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.terminalId.label')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mcc"
          render={({ field: { value, onChange } }) => (
            <FormItem>
              <FormLabel>{t('form.mcc.label')}</FormLabel>
              <FormControl>
                <MerchantCategoriesCombobox value={value} onSelect={onChange} className="w-full" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormLabel className="static">{t('form.schemes.label')}</FormLabel>
        <div className="flex flex-col gap-4 md:flex-row md:gap-6">
          {VALID_SCHEMES.map((scheme) => (
            <FormField
              key={scheme}
              control={form.control}
              name="schemes"
              render={({ field }) => {
                return (
                  <FormItem className="flex space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(scheme)}
                        onCheckedChange={(checked) => {
                          if (!field.value) return;
                          if (checked) form.setValue('schemes', [...field.value, scheme]);
                          if (!checked)
                            form.setValue('schemes', [
                              ...field.value.filter((el) => el !== scheme),
                            ]);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                    <div className="space-y-1 leading-none">
                      <FormLabel className="static">{humanizeText(scheme)}</FormLabel>
                    </div>
                  </FormItem>
                );
              }}
            />
          ))}
        </div>
        <FormField
          control={form.control}
          name="currencies"
          render={({ field: { value } }) => {
            return (
              <FormItem>
                <FormLabel>{t('form.currencies.label')}</FormLabel>
                <MultiselectCombobox
                  items={currencies}
                  defaultItems={currencies}
                  currentValues={value}
                  onSelect={(values: string[]) => form.setValue('currencies', values)}
                  noResultsMsg={t('form.currencies.noResultsMsg')}
                  placeholder={t('form.currencies.placeholder')}
                  searchPlaceholder={t('form.currencies.searchPlaceholder')}
                />
                <FormMessage />
              </FormItem>
            );
          }}
        />
        {error && <p className="text-red-500">{error}</p>}

        <Button type="submit" variant="primary" disabled={isPending} loading={isPending}>
          {buttonLabel}
        </Button>
      </form>
    </Form>
  );
}
