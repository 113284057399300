import { Loader } from '@components/loader';
import { Page404 } from '@pages/404';
import { OrderTransactions } from '@pages/orders/transactions';
import { useGetOrder } from '@services/global/orders/get';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { outletNavScroll } from '@utils/apiHelpers';
import { useEffect } from 'react';

const Transactions = () => {
  const { id, localId } = useParams({
    from: '/u/$localId/_merchant/merchant/orders/$id/transactions',
  });
  const { data, loading } = useGetOrder(id);

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (loading) return <Loader />;
  if (!data) return <Page404 fallbackPath={`/u/${localId}/merchant/orders`} />;

  return <OrderTransactions order={data} />;
};

export const Route = createFileRoute('/u/$localId/_merchant/merchant/orders/$id/transactions')({
  component: Transactions,
});
