import { useApi } from '@hooks/useApi';
import { ApiError, UserType } from '@root/globalTypes';
import { useMutation } from '@tanstack/react-query';

export type PasswordData = {
  password: string;
};

type PasswordUpdateResponse = {
  message: string;
};

export type UserPasswordResetParams = {
  userType: UserType;
  token: string;
  handleSuccess: () => void;
  handleFailure: (error: ApiError) => void;
};

export function isFailure(data: PasswordUpdateResponse | ApiError): data is ApiError {
  return (data as ApiError)?.errors !== undefined;
}

export function useResetPassword({
  userType,
  token,
  handleSuccess,
  handleFailure,
}: UserPasswordResetParams) {
  const { query } = useApi();

  const url = `v1/${userType}/users/password/reset?password_reset_token=${token}`;
  const { mutate, isPending } = useMutation({
    mutationFn: (data: PasswordData): Promise<PasswordUpdateResponse> => {
      return query({
        path: url,
        data,
        opt: {
          method: 'PUT',
        },
        useAuth: false,
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isFailure(data)) {
        handleFailure(data);
      } else {
        handleSuccess();
      }
    },
  });

  return {
    mutate,
    isPending,
  };
}
