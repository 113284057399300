import { DataTable } from '@components/dataTable';
import { Acquirer } from '@root/globalTypes';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Logo } from '@components/logo';
import { useTableSorting } from '@hooks/useTableSorting';
import { TableDateCell } from '@components/table/dateCell';

type AcquirersTableProps = {
  data: Acquirer[];
  visibleColumns?: string[];
  onRowSelect: (id: string) => void;
};

export function useStaffAcquirersTable({ data, visibleColumns, onRowSelect }: AcquirersTableProps) {
  const { t } = useTranslation('app');
  const { sorting, updateSorting } = useTableSorting();

  const columns: ColumnDef<Acquirer>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: t('table.name'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <Logo imgSrc={row.original.logoUrl || ''} name={row.original.name} />
          {row.original.name}
        </div>
      ),
    },
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
      enableSorting: false,
    },
    {
      id: 'timezone',
      accessorKey: 'timezone',
      header: t('table.timezone'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'two_factor_required',
      accessorKey: 'twoFactorRequired',
      header: t('table.twoFactorRequired'),
      enableSorting: false,
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
    {
      id: 'updated_at',
      accessorKey: 'updatedAt',
      header: t('table.updatedAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.updatedAt} />,
    },
  ];

  return {
    columns,
    Table: () => {
      return (
        <DataTable
          columns={
            visibleColumns
              ? columns.filter(({ id }) =>
                  typeof id === 'string' ? visibleColumns.includes(id) : false,
                )
              : columns
          }
          data={data}
          selectionCallback={(row) => onRowSelect(row.original.id)}
          sorting={sorting}
          onSortChange={updateSorting}
        />
      );
    },
  };
}
