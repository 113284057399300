import { memo } from 'react';
import logo from '@assets/logo-landscape.png';
import { useAppContext } from '@hooks/useAppContext';
import { Link, useParams } from '@tanstack/react-router';
import { SwitchUser } from '@components/navigation/switchUser';
import { MobileNav } from './mobileNav';
import { useNavigation } from '@ctx/navigation/context';
import { AcquirerUser, MerchantUser, StaffUser } from '@root/globalTypes';
import { AcquirerUserInfo } from '@components/navigation/acquirer/userInfo';
import { MerchantUserInfo } from '@components/navigation/merchant/userInfo';
import { StaffUserInfo } from '@components/navigation/staff/userInfo';

export const NavTopBar = memo(() => {
  const { user, userType } = useAppContext();
  const { localId } = useParams({ strict: false });
  const { links } = useNavigation();

  function renderUserInfo() {
    switch (userType) {
      case 'acquirer':
        return <AcquirerUserInfo user={user as AcquirerUser} />;
      case 'merchant':
        return <MerchantUserInfo user={user as MerchantUser} />;
      case 'staff':
        return <StaffUserInfo user={user as StaffUser} />;
    }
  }

  return (
    <nav id="topBar" className="sticky top-0 z-50 !flex w-full bg-white shadow">
      <div className="shadow-2 flex flex-grow items-center justify-between px-4 py-4 md:px-6 2xl:px-11">
        <MobileNav links={links} />
        <ul className="flex items-center gap-4">
          <li>
            <Link to={`/u/$localId/${userType}`} params={{ localId: localId || '0' }}>
              <img alt="" src={logo} className="-my-2 h-12" />
            </Link>
          </li>
        </ul>
        <div className="ms-auto flex items-center gap-4">
          {renderUserInfo()}
          <SwitchUser />
        </div>
      </div>
    </nav>
  );
});
