import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePostOrderCapture } from '@services/global/orders/postCapture';

export const useOrderCapture = (id: string, max: number, callback?: () => void) => {
  const { t } = useTranslation('app');
  const schema = z.object({
    amount: z.coerce
      .number()
      .nonnegative()
      .lte(max, {
        message: t('form.amount.upperLimit', { limit: max }),
      }),
    final: z.boolean(),
  });

  const defaultValues = {
    amount: max,
    final: false,
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const onSuccess = () => {
    if (callback) callback();
    form.reset();
  };

  const { mutate, isPending, error } = usePostOrderCapture({
    id: id,
    callback: onSuccess,
    setError: form.setError,
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    isPending,
    error,
  };
};
