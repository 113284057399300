import { Avatar, AvatarImage, AvatarFallback } from '@components/avatar';

type LogoProps = {
  imgSrc: string;
  name: string;
  fallback?: React.ReactNode;
};

export function Logo({ imgSrc, name, fallback }: LogoProps) {
  return (
    <Avatar>
      <AvatarImage src={imgSrc} alt={name} />
      <AvatarFallback>{fallback}</AvatarFallback>
    </Avatar>
  );
}
