import { useApi } from '@hooks/useApi';
import { ApiError } from '@root/globalTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { queryKeys } from '@utils/consts';
import { Merchant } from '@root/globalTypes';
import { isFailure } from '@utils/apiHelpers';
import { MerchantData } from '@services/types';

type UpdateMerchantParams = {
  id: string;
  successHandler: (merchant: Merchant) => void;
};

export function useUpdateMerchant({ id, successHandler }: UpdateMerchantParams) {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { mutate, isPending } = useMutation({
    mutationFn: ({ ...data }: MerchantData): Promise<Merchant | ApiError> => {
      return query({
        path: `v1/staff/merchants/${id}`,
        data: data,
        opt: {
          method: 'PUT',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isFailure(data)) {
        setApiError(data);
      } else {
        successHandler(data);
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.merchants });
      await queryClient.refetchQueries({ queryKey: queryKeys.acquirerMerchants });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
}
