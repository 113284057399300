import { Button } from '@components/button';
import { Form, FormField, FormItem, FormLabel, FormMessage } from '@components/form/form';
import { FileInput } from '@components/form/fileInput';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImportBinsForm, IMPORT_TYPES, ACCEPTED_FILE_TYPES } from '@hooks/forms/bins/import';
import { humanizeText } from '@utils/textHelpers';

export function ImportBinsForm({ formObj }: { formObj: ReturnType<typeof useImportBinsForm> }) {
  const { t } = useTranslation('app');
  const [files, setFiles] = useState<Record<string, File | null>>(
    Object.fromEntries(IMPORT_TYPES.map((type) => [type, null])),
  );

  const { form, onSubmit, error, isPending } = formObj;

  return (
    <Form {...form}>
      <form
        id="userForm"
        className="flex flex-col gap-6"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}>
        {IMPORT_TYPES.map((type) => (
          <FormField
            key={type}
            control={form.control}
            name={type}
            render={({ field: { onChange } }) => (
              <FormItem className="h-full">
                <FormLabel required>{humanizeText(type)}</FormLabel>
                <FileInput
                  file={files[type]}
                  title={humanizeText(type)}
                  acceptedTypes={ACCEPTED_FILE_TYPES[type]}
                  setFile={(f) => {
                    onChange(f);
                    setFiles((prev) => ({ ...prev, [type]: f as File | null }));
                    return f;
                  }}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        ))}

        {error && <p className="text-red-500">{error}</p>}

        <Button type="submit" variant="primary" disabled={isPending} loading={isPending}>
          {t('actions.import')}
        </Button>
      </form>
    </Form>
  );
}
