import { AcquirerUser, MerchantUser, StaffUser } from '@root/globalTypes';
import { Dispatch, SetStateAction, createContext } from 'react';

type TwoFactorSetupContextType = {
  user: MerchantUser | AcquirerUser | StaffUser;
  showConfirmDialog: boolean;
  setShowConfirmDialog: Dispatch<SetStateAction<boolean>>;
  showBackupCodesDialog: boolean;
  setShowBackupCodesDialog: Dispatch<SetStateAction<boolean>>;
  backupCodes: string[];
  setBackupCodes: Dispatch<SetStateAction<string[]>>;
  qrCodeUri: string;
  setQrCodeUri: Dispatch<SetStateAction<string>>;
};
export const TwoFactorSetupContext = createContext<TwoFactorSetupContextType | null>(null);
