import { Button } from '@components/button';
import { Sheet, SheetContent, SheetTrigger } from '@components/sheet';
import { useFilters } from '@ctx/filters/context';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { useResetTable } from '@hooks/useResetTable';
import { SlidersVertical } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { FiltersPopover } from './filtersPopover';
import { Card, CardContent, CardFooter } from '@components/card';
import { Filter } from './types';
import { IconButton } from '@components/button/iconButton';
import { useState } from 'react';

export function MobileFilters() {
  const { t } = useTranslation('app');
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const { configItems, visibleFilters, applyFilters } = useFilters();
  const { reset } = useResetTable();

  function handleApply() {
    applyFilters();

    setOpen(false);
  }

  function handleReset() {
    reset();

    setOpen(false);
  }

  if (isDesktop) return;

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <IconButton variant="primary-alt" icon={<SlidersVertical size={18} />}>
          {t('filters.title')}
        </IconButton>
      </SheetTrigger>
      <SheetContent side="right" className="w-full overflow-auto lg:hidden">
        <div className="mt-8 flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <h3 className="grow text-xl font-medium text-black">{t('filters.title')}</h3>
            <FiltersPopover filters={configItems} />
          </div>
          <Card>
            <CardContent className="flex flex-wrap gap-4 p-2">
              {visibleFilters.map((af) => {
                const configItem = configItems.find((item) => item.id == af);
                return configItem ? <Filter key={af} configItem={configItem} /> : null;
              })}
            </CardContent>
            <CardFooter className="p-2">
              <div className="flex w-full items-center justify-end gap-2">
                <Button variant="secondary" type="button" onClick={handleApply}>
                  {t('filters.apply')}
                </Button>
                <Button variant="ghost" type="button" onClick={handleReset}>
                  {t('filters.reset')}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </div>
      </SheetContent>
    </Sheet>
  );
}
