import { IconButton } from '@components/button/iconButton';
import { Loader } from '@components/loader';
import { useAcquirerLinksTable } from '@components/tables/acquirerLinks';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { useListAcquirerLinks } from '@services/staff/acquirer/links/list';
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const AcquirerLinks = () => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const { acquirerId, localId } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId/links',
  });
  const navigate = useNavigate();
  const { data, isPending } = useListAcquirerLinks({ acquirerId });
  const { Table: AcquirerLinksTable } = useAcquirerLinksTable({
    data,
    onRowSelect: (id: string) => {
      navigate({
        to: '/u/$localId/staff/acquirers/$acquirerId/links/$id',
        params: { acquirerId, id, localId },
      }).catch((err) => console.error(err));
    },
  });
  if (isPending) return <Loader />;

  return (
    <BaseLayout
      title={t('pages.links.title')}
      actions={[
        verifyUserPermission('acquirer_links_create') && (
          <IconButton
            key="new"
            variant="secondary"
            icon={<Plus size={18} />}
            onClick={() => {
              navigate({
                to: `/u/$localId/staff/acquirers/$acquirerId/links/new`,
                params: { acquirerId, localId },
              }).catch((err) => console.error(err));
            }}>
            {t('actions.addNew')}
          </IconButton>
        ),
      ]}>
      <AcquirerLinksTable />
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/$acquirerId/links')({
  component: AcquirerLinks,
});
