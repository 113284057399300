import { Card, CardHeader, CardContent } from '@components/card';
import { ObjectContent } from '@components/objectContent';
import { BaseLayout } from '@layouts/base';
import { MutateObject, PaymentControl } from '@root/globalTypes';
import { useTranslation } from 'react-i18next';
import { EditDialog } from './editDialog';
import { DeleteDialog } from './deleteDialog';
import { PaymentControlApiParams } from '@hooks/forms/paymentControls';

export function PaymentControlShow({
  paymentControl,
  editRequestHandler,
  deleteRequestHandler,
}: {
  paymentControl: PaymentControl;
  editRequestHandler: MutateObject<PaymentControl, PaymentControlApiParams>;
  deleteRequestHandler: {
    onDelete: () => void;
    isPending: boolean;
  };
}) {
  const { t } = useTranslation('app');

  const { id, activity, identifierType, ...rest } = paymentControl;
  const transformedControl = {
    ...rest,
    action: activity,
    identifierType: t(`pages.paymentControls.${identifierType}`),
  };

  return (
    <BaseLayout
      isChild
      actions={[
        <EditDialog
          key="editDialog"
          paymentControl={paymentControl}
          requestHandler={editRequestHandler}
        />,
        <DeleteDialog
          key="deleteDialog"
          paymentControl={paymentControl}
          requestHandler={deleteRequestHandler}
        />,
      ]}>
      <Card>
        <CardHeader>
          <strong>
            {t(`pages.paymentControls.${paymentControl.identifierType}`)}: {id}
          </strong>
        </CardHeader>
        <CardContent>
          <ObjectContent data={transformedControl} translationKey="table" filterKey={['id']} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}
