import { Loader } from '@components/loader';
import { MerchantLinkPage } from '@pages/merchantLinks/id';
import { useGetMerchantLink } from '@services/merchant/links/get';
import { createFileRoute, redirect, useParams } from '@tanstack/react-router';

function MerchantLink() {
  const { id } = useParams({
    from: '/u/$localId/_merchant/merchant/links/$id',
  });
  const { data: merchantLink, isPending } = useGetMerchantLink({ id });

  if (isPending) return <Loader />;
  if (!merchantLink) return null;

  return <MerchantLinkPage merchantLink={merchantLink} />;
}

export const Route = createFileRoute('/u/$localId/_merchant/merchant/links/$id')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_links_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantLink,
});
