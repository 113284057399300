import { cva } from 'class-variance-authority';

export const buttonVariants = cva(
  'inline-flex gap-2 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:scale-[102%] transition-transform origin-center',
  {
    variants: {
      variant: {
        default: 'bg-neutral-400 text-neutral-50 hover:bg-neutral-500/90',
        primary: 'bg-primary text-neutral-50 hover:bg-opacity-90',
        'primary-alt': 'bg-primary-400 text-neutral-50 hover:bg-opacity-90',
        secondary: 'bg-secondary text-white hover:bg-opacity-90',
        tertiary: 'bg-filter-900 text-white hover:bg-opacity-10 hover:text-black',
        destructive: 'bg-error text-neutral-50 hover:bg-opacity-90',
        'destructive-outline':
          'border border-error bg-white text-error hover:bg-error hover:text-white',
        outline: 'border border-primary bg-white text-primary hover:bg-primary hover:text-white',
        'outline-dark': 'border border-black bg-white text-black hover:bg-black hover:text-white',
        ghost:
          'hover:bg-neutral-100 hover:text-neutral-900 dark:hover:bg-neutral-800 dark:hover:text-neutral-50',
        link: 'text-primary underline-offset-4 hover:underline dark:text-neutral-50',
        pagination: 'hover:bg-accent hover:text-white rounded-full',
        'pagination-active': 'bg-accent rounded-full text-white',
        select:
          'font-normal w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter flex items-center justify-between text-md [&>span]:line-clamp-1 hover:!scale-[100%]',
        accent: 'border border-accent/70 text-accent hover:bg-accent/70 hover:text-white',
        warning: 'bg-yellow-400/20 text-yellow-600 hover:bg-yellow-400/30',
      },
      size: {
        default: 'h-10 px-3 md:px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 md:h-12 text-md rounded px-6 md:px-8',
        icon: 'h-10 w-10',
        auto: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);
