import { useTranslation } from 'react-i18next';
import { TablePagination } from '@components/pagination/tablePagination';
import { ColumnFilterPopover } from '@components/popover/columnsFilterPopover';
import { useToggleTableColumns } from '@hooks/useToggleTableColumns';
import { BaseLayout } from '@layouts/base';
import { Order } from '@root/globalTypes';
import { useOrdersTable } from '@components/tables/orders';
import { MetaData } from '@root/globalTypes';
import { useMemo } from 'react';
import { ExportDialog } from '@components/dialog/exportDialog';
import { FiltersProvider } from '@ctx/filters';
import { Filters } from '@components/filters';
import { FiltersConfig } from '@ctx/filters/context';
import { MobileFilters } from '@components/filters/mobileFilters';

type OrdersProps = {
  meta?: MetaData;
  orders: Order[];
  filterConfig: FiltersConfig;
  onRowSelect: (id: string) => void;
};

export function Orders({ orders, onRowSelect, meta, filterConfig }: OrdersProps) {
  const { t } = useTranslation('app');
  const predefinedCols = useMemo(
    () => [
      'id',
      'amount',
      'currency',
      'sourceType',
      'scheme',
      'source',
      'created_at',
      'updated_at',
    ],
    [],
  );

  const { visibleColumns, setColumns } = useToggleTableColumns({ predefinedCols });
  const { columns, Table: OrdersTable } = useOrdersTable({
    data: orders,
    onRowSelect,
    visibleColumns,
  });

  return (
    <FiltersProvider config={filterConfig}>
      <BaseLayout
        title={t('pages.orders.title')}
        actions={[
          <ExportDialog key="export" type="orders" />,
          <MobileFilters key="filters" />,
          <ColumnFilterPopover
            key="colFilter"
            activeCols={visibleColumns}
            setActiveCols={setColumns}
            columns={columns.map((col) => ({ id: col.id as string, label: col.header as string }))}
            defaultColumns={predefinedCols}
          />,
        ]}>
        <Filters />
        <OrdersTable />
        {meta?.totalPages && (
          <TablePagination pages={meta.totalPages} total={meta.totalCount} items={orders.length} />
        )}
      </BaseLayout>
    </FiltersProvider>
  );
}
