import { useMatch, useSearch } from '@tanstack/react-router';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationPrevious,
  PaginationLink,
  PaginationNext,
} from '.';
import { useTranslation } from 'react-i18next';
import { RowsPerPageSelect } from './rowsPerPageSelect';
import { useAppContext } from '@hooks/useAppContext';
import { useMemo, useState } from 'react';
import { getCacheKeyFromRoute } from '@utils/apiHelpers';
import { DEFAULT_ROWS_PER_PAGE } from '@utils/consts';

export const TablePagination = ({
  pages,
  items,
  total,
}: {
  pages: number;
  total: number;
  items: number;
}) => {
  const { t } = useTranslation('app', { keyPrefix: 'pagination' });
  const search = useSearch({ from: '__root__' });
  const match = useMatch({ strict: false });

  const { rowsPerPageCache } = useAppContext();
  const key = useMemo(() => getCacheKeyFromRoute(match.routeId), [match]);
  const [rowsPerPage, setRowsPerPage] = useState(
    search.items || rowsPerPageCache?.[key] || DEFAULT_ROWS_PER_PAGE,
  );

  const page = Number(search.page) || 1;
  const allPages = new Array(pages).fill('').map((_el, i) => i + 1);
  const visiblePages = () => {
    if (pages <= 6) return allPages;
    if (page > 2 && page < allPages.length - 4)
      return [
        allPages.slice(0, 1),
        ['...'],
        allPages.slice(page - 2, page + 1),
        '...',
        allPages.slice(-2),
      ].flat();
    if (page > allPages.length - 4)
      return [allPages.slice(0, 1), ['...'], allPages.slice(page - 2)].flat();
    return [allPages.slice(0, page + 1), ['...'], allPages.slice(-3)].flat();
  };

  const min = Number(rowsPerPage) * (page - 1) + 1;
  const max = Number(rowsPerPage) * page;

  if (!items) return null;

  return (
    <div className="-mt-8 mb-4 flex w-full flex-col items-center justify-between gap-2 rounded-b bg-white px-5 py-6 md:flex-row md:gap-0">
      <div className="mx-auto flex flex-col items-center gap-2 md:flex-row md:gap-4">
        {pages && pages > 1 && (
          <Pagination className="w-auto">
            <PaginationContent>
              {page > 1 && (
                <PaginationItem>
                  <PaginationPrevious
                    to="./"
                    search={{
                      ...search,
                      page: page - 1 < 1 ? 1 : page - 1,
                    }}
                    aria-disabled={page === 1}
                  />
                </PaginationItem>
              )}
              {visiblePages().map((el, i) => (
                <PaginationItem key={el + '_' + i}>
                  {el === '...' ? (
                    el
                  ) : (
                    <PaginationLink
                      isActive={page === +el}
                      to="./"
                      search={{
                        ...search,
                        page: +el,
                      }}>
                      {el}
                    </PaginationLink>
                  )}
                </PaginationItem>
              ))}
              {page !== pages && (
                <PaginationItem>
                  <PaginationNext
                    to="./"
                    aria-disabled={page === pages}
                    search={{
                      ...search,
                      page: page + 1,
                    }}
                  />
                </PaginationItem>
              )}
            </PaginationContent>
          </Pagination>
        )}
        <span>
          {t('items', { items: `${min} - ${max > total ? total : max}`, results: total })}
        </span>
      </div>

      <div className="flex items-center gap-2">
        <span>{t('rowsPerPage')}</span>
        <RowsPerPageSelect rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
      </div>
    </div>
  );
};
