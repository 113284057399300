import { useMediaQuery } from '@hooks/useMediaQuery';
import { Button, ButtonProps } from '.';
import { forwardRef } from 'react';

interface IconButtonProps extends ButtonProps {
  icon: React.ReactNode;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, children, ...props }, ref) => {
    const showLabel = useMediaQuery('(min-width: 768px)');

    return (
      <Button {...props} ref={ref}>
        {showLabel && children}
        {icon}
      </Button>
    );
  },
);
