import { WithoutNullableKeys } from '@root/globalTypes';
import { KeyboardEvent } from 'react';
import cf from 'currency-format';

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const transformSnakeToCamelCase = (text: string) =>
  text.includes('_')
    ? text
        .split('_')
        .map((part: string, i: number) => (i === 0 ? part : capitalizeFirstLetter(part)))
        .join('')
    : text;

export const transformCamelCaseToSnakeCase = (text: string) =>
  text
    .split('')
    .map((letter: string, idx) => {
      if (letter === '_') return letter;
      if (!isNaN(parseInt(letter))) {
        return !isNaN(parseInt(text[idx - 1])) ? letter : `_${letter}`;
      }
      return letter === letter.toUpperCase() ? `_${letter.toLowerCase()}` : letter;
    })
    .join('');

export const humanizeText = (text: string) =>
  capitalizeFirstLetter(transformCamelCaseToSnakeCase(text).split('_').join(' '));

export const customJsonParser = <T>(data: T, keyFormatter: (arg0: string) => string): T => {
  if (typeof keyFormatter !== 'function') return data;
  if (typeof data !== 'object') return data;
  const raw = JSON.stringify(data);
  const parsed = JSON.parse(raw, function (key, value: unknown) {
    const reg = /^\d+$/;
    if (reg.test(key)) return value;
    const newKey = keyFormatter(key);
    if (key !== newKey) {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line immutable/no-mutation, immutable/no-this, @typescript-eslint/no-unsafe-member-access
      this[newKey] = value;
      return;
    }
    return value;
  }) as T;
  return parsed;
};

// simplify usage by single export methods
export const formatBody = <T>(body: T): T => {
  if (!body) return body;
  if (typeof body !== 'object') return body;
  return customJsonParser(body, transformCamelCaseToSnakeCase);
};

export const formatResponse = <T>(body: T): T => {
  if (!body) return body;
  if (typeof body !== 'object') return body;
  return customJsonParser(body, transformSnakeToCamelCase);
};

const maskCardNumber = (card: string) =>
  [...card]
    .map((e, i) => {
      if (i !== 0 && i % 4 === 0) return `-${e}`;
      return e;
    })
    .join('');

export const formatCardNumber = (number: string | number, lastDigits?: string | number) => {
  const n = number.toString();
  if (!lastDigits) return maskCardNumber(n);
  const l = lastDigits.toString();
  const filerCount = 16 - n.length - l.length;
  const s = `${n}${new Array(filerCount).fill('X').join('')}${l}`;
  return maskCardNumber(s);
};

export const getCurrencyFractionSize = (currency?: string) => {
  if (!currency) return 2;
  return cf[currency as keyof typeof cf].fractionSize;
};

export const convertStringToNumber = (text: string, fractionSize?: number) => {
  const tmp = parseFloat(text);
  if (fractionSize === 0) return tmp;
  if (fractionSize === 1) return tmp * 10;
  return tmp * 100;
};

export const formatAmount = (amount: number | string, currency?: string) => {
  const fractionSize = getCurrencyFractionSize(currency);
  const tmpAmount =
    typeof amount === 'string' ? convertStringToNumber(amount, fractionSize) : amount;
  if (!currency) return (tmpAmount / 100).toFixed(2);
  if (fractionSize === 0) return tmpAmount.toFixed(0);
  if (fractionSize === 1) return (tmpAmount / 10).toFixed(1);
  return (tmpAmount / 100).toFixed(2);
};

export const stringifyNulls = <T>(obj: T): WithoutNullableKeys<T> => {
  return JSON.parse(
    JSON.stringify(obj, (_k, v: unknown) => (v === null ? '' : v)),
  ) as WithoutNullableKeys<T>;
};

export function maskApiKey(key: string) {
  return key.replace(/.(?=.{5})/g, '*');
}

export function handleCopy(text: string) {
  void navigator.clipboard.writeText(text);
}

export function transformUid(id?: string) {
  if (!id) return '';
  return id.slice(0, 4) + '-' + id.slice(-8);
}

export const validateKeyDown = (e: KeyboardEvent<HTMLInputElement>, pattern: RegExp) => {
  const match = e.key.match(pattern);

  return (
    e.metaKey ||
    e.key.includes('Arrow') ||
    e.key === 'Backspace' ||
    (match && match['0'] === match.input)
  );
};

export function humanizeBytes(bytes: number) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  let i = 0;

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  return parseFloat(bytes.toFixed()) + ' ' + units[i];
}

export function humanizeAcceptedFileTypes(types: string[]) {
  return types.map((type) => type.replace('image/', '')).join(', ');
}
