import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { MetaData, Agreement } from '@root/globalTypes';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';

interface AgreementsResponse {
  agreements: Agreement[];
  meta: MetaData | undefined;
}

export function useGetAgreements() {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();

  const { data, isPending } = useQuery<AgreementsResponse>({
    queryKey: [...queryKeys.agreements, window.location.search],
    queryFn: (): Promise<AgreementsResponse> =>
      query({
        path: `v1/agreements${window.location.search}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged,
  });

  return {
    data: data?.agreements || [],
    meta: data?.meta || undefined,
    isPending,
  };
}
