import {
  Dispatch,
  InputHTMLAttributes,
  SetStateAction,
  SyntheticEvent,
  useRef,
  DragEvent,
  useState,
} from 'react';
import { cn } from '@utils/cn';
import { Download, Trash2 } from 'lucide-react';
import { baseInputStyle } from './inputStyle';
import { humanizeAcceptedFileTypes, humanizeBytes } from '@utils/textHelpers';
import { useTranslation } from 'react-i18next';

interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  file: File | null;
  preview?: React.ReactNode;
  className?: string;
  maxSize?: number;
  resolution?: { width: number; height: number };
  acceptedTypes: string[];
  setFile: Dispatch<SetStateAction<File | null>>;
  showTooltip?: boolean;
  additionalInfo?: React.ReactNode;
}

function DefaultPreview({ file, onRemove }: { file: File; onRemove: () => void }) {
  return (
    <div className="my-2 flex w-full items-center justify-between rounded-md bg-neutral-100 px-4 py-2">
      <span>{file.name}</span>
      <span
        className="rounded-md p-2 hover:bg-neutral-200"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onRemove();
        }}>
        <Trash2 size={14} />
      </span>
    </div>
  );
}

export function FileInput({
  file,
  setFile,
  preview,
  className,
  maxSize,
  resolution,
  acceptedTypes,
  additionalInfo,
  showTooltip = true,
}: FileInputProps) {
  const { t } = useTranslation('app');
  const ref = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  function handleDrop(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDragging(false);
    setFile(e.dataTransfer.files[0]);
  }

  function renderPreview() {
    if (preview) return preview;

    return (
      file && (
        <DefaultPreview
          file={file}
          onRemove={() => {
            setFile(null);
          }}
        />
      )
    );
  }

  return (
    <div className="min-h-30 flex flex-col items-stretch gap-4 lg:flex-row">
      <div
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={() => setIsDragging(false)}
        onDragEnter={() => setIsDragging(true)}
        onClick={() => ref?.current?.click()}
        className={cn([
          baseInputStyle,
          'flex basis-1/2 flex-col items-center justify-center gap-2 py-4 font-semibold text-neutral-500 hover:cursor-pointer',
          isDragging && 'border-primary',
          className,
        ])}>
        {renderPreview() || <Download size={40} className={cn(isDragging && 'text-primary')} />}

        <div>
          <span
            onClick={(e) => {
              e.stopPropagation();
              ref?.current?.click();
            }}
            className="text-neutral-700 hover:cursor-pointer hover:text-primary">
            {t('form.file.chooseFile')}&nbsp;
          </span>
          {t('form.file.drag')}
        </div>
        <input
          ref={ref}
          className="hidden"
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            if (!e.currentTarget.files?.length) return;

            setFile(e.currentTarget.files[0]);
          }}
          type="file"
          accept={acceptedTypes.join(',')}
        />
      </div>
      {showTooltip && (
        <div className="basis-1/2 rounded bg-[#DADDDD29] p-4 text-sm leading-normal">
          <h3 className="mb-2 text-gray-400">{t('form.file.recomendations')}</h3>
          <ul className="mx-4 list-disc">
            {maxSize && (
              <li>
                {t('form.file.maxSize')}: {humanizeBytes(maxSize)}
              </li>
            )}
            {resolution && (
              <li>
                {t('form.file.resolution')}: {resolution.width}x{resolution.height} px
              </li>
            )}
            <li>
              {t('form.file.acceptedFormats')}: {humanizeAcceptedFileTypes(acceptedTypes)}
            </li>
            {additionalInfo && <li>{additionalInfo}</li>}
          </ul>
        </div>
      )}
    </div>
  );
}
