import { Button } from '@components/button';
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '@components/dialog';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function DeleteMerchantLinkDialog({ onDelete }: { onDelete: () => void }) {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const [open, setOpen] = useState(false);

  if (!verifyUserPermission('merchant_links_delete')) return null;

  function handleDelete() {
    onDelete();
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive">{t('pages.merchantLink.remove.title')}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle className="flex items-center gap-2 whitespace-nowrap">
          {t('pages.merchantLink.remove.title')}
        </DialogTitle>
        <p>{t('pages.merchantLink.remove.info')}</p>
        <Button onClick={handleDelete} variant="destructive">
          {t('actions.delete')}
        </Button>
        <Button onClick={() => setOpen(false)} variant="ghost">
          {t('actions.cancel')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
