import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from '@utils/cn';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@components/command';
import { Popover, PopoverContent, PopoverTrigger } from '@components/popover';
import { ScrollArea } from '@components/scrollArea';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect, useState } from 'react';

export type ComboBoxItemType = {
  value: string;
  label: string;
};

type ComboboxProps = {
  value: string;
  items: ComboBoxItemType[];
  defaultItems: ComboBoxItemType[];
  searchPlaceholder: string;
  noResultsMsg: string;
  placeholder?: string;
  className?: string;
  onSelect: (value: string | undefined) => void;
  onSearch?: (input: string) => void;
  defaultOpen?: boolean;
  id?: string;
};

export function Combobox({
  value,
  items,
  defaultItems,
  searchPlaceholder,
  noResultsMsg,
  placeholder,
  className,
  onSelect,
  onSearch,
  defaultOpen = false,
}: ComboboxProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const showSearch = items.length >= 10;

  const handleSearch = useDebouncedCallback((input: string) => {
    if (onSearch) onSearch(input);
  }, 300);

  function handleOpenClose(open: boolean) {
    handleSearch('');
    return setIsOpen(open);
  }

  useEffect(() => {
    if (defaultOpen) setIsOpen(defaultOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popover open={isOpen} onOpenChange={handleOpenClose} modal={true}>
      <PopoverTrigger asChild>
        <button
          role="combobox"
          aria-expanded={isOpen}
          className={cn(
            'text-md border-stroke disabled:bg-whiter flex w-full items-center justify-between rounded border-[1.5px] bg-transparent px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default [&>span]:line-clamp-1',
            className,
          )}>
          <span className="flex-1 truncate text-left">
            {value ? defaultItems.find((item) => item.value === value)?.label : placeholder}
          </span>
          <ChevronsUpDown className="ml-2 ms-auto h-4 w-4 shrink-0 opacity-50" />
        </button>
      </PopoverTrigger>
      <PopoverContent
        style={{ width: 'var(--radix-popover-trigger-width)' }}
        className="popover-content-width-same-as-its-trigger p-0">
        <Command shouldFilter={onSearch === undefined}>
          {showSearch && (
            <CommandInput placeholder={searchPlaceholder} onValueChange={handleSearch} />
          )}
          <ScrollArea className="max-h-[220px] overflow-auto">
            <CommandEmpty>{noResultsMsg}</CommandEmpty>
            <CommandGroup>
              {items.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  className="gap-2"
                  onSelect={(currentValue) => {
                    onSelect(currentValue === item.value.toLowerCase() ? item.value : '');
                    handleOpenClose(false);
                  }}>
                  <Check
                    className={cn(
                      'h-4 w-4 shrink-0',
                      value === item.value ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  <span>{item.label}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
