import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAcceptInvitation } from '@services/global/invitations/accept';
import { PASSWORD_REGEX } from '@utils/passwordsHelpers';
import { UserType } from '@root/globalTypes';

export const useAcceptInvitationForm = ({
  token,
  userType,
  successHandler,
}: {
  token: string;
  userType: UserType;
  successHandler: () => void;
}) => {
  const { t } = useTranslation('app');
  const schema = z.object({
    password: z
      .string()
      .trim()
      .min(1, {
        message: t('form.password.required'),
      })
      .regex(PASSWORD_REGEX, { message: t('form.password.complexity') }),
    confirmPassword: z.custom<string>((val) => {
      const { value } = document.querySelector('[name="password"]') as HTMLInputElement;
      return val === value;
    }, t('form.passwordConfirmation.required')),
  });

  const defaultValues = {
    password: '',
    confirmPassword: '',
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, isPending } = useAcceptInvitation({
    callback: successHandler,
    token,
    userType,
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate({ password: values.password });
  };

  return {
    form,
    onSubmit,
    isPending,
  };
};
