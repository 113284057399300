import { createContext } from 'react';
import { Dispatch, SetStateAction } from 'react';
import {
  UserType,
  AcquirerUser,
  MerchantUser,
  AuthRespSuccess,
  StaffUser,
  BCMessage,
} from '@root/globalTypes';
import { FiltersSearch } from './filters/context';
import { SortingState } from '@tanstack/react-table';

export type FiltersCache = Record<string, FiltersSearch>;
export type SortingCache = Record<string, SortingState>;
export type RowsPerPageCache = Record<string, string>;
export type SessionData = {
  token?: string;
  expiresAt?: string;
  user?: AcquirerUser | MerchantUser | StaffUser;
  isMerchant?: boolean;
  userType?: UserType;
  uid?: string;
  twoFactorRequired?: boolean;
  bc?: BroadcastChannel | null;
  sendBcPost?: (x: BCMessage) => void;
  [key: string]: unknown;
};

export type AppContextData = SessionData & {
  frontSession?: string;
  setData: Dispatch<SetStateAction<SessionData>>;
  clearData: () => void;
  isAuthenticated: () => boolean;
  isValidUserType: (type: UserType) => boolean;
  handleLogin: ({
    loginData,
    userType,
  }: {
    loginData: AuthRespSuccess<MerchantUser | AcquirerUser | StaffUser>;
    userType: UserType;
  }) => void;
  userHasPermissions: (permissions: string[]) => boolean;
  updateSessionTimer: () => void;
  lastLoggedUserType: () => UserType;
  partialAccess: boolean;
  filtersCache: FiltersCache;
  updateFiltersCache: (key: string, sorting: FiltersSearch) => void;
  sortingCache: SortingCache;
  updateSortingCache: (key: string, sorting: SortingState) => void;
  rowsPerPageCache: RowsPerPageCache;
  updateRowsPerPageCache: (key: string, sorting: string) => void;
};

export const AppContext = createContext<AppContextData | null>(null);
