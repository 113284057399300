import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useApi } from '@hooks/useApi';
import { ApiError, PaymentControl } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { PaymentControlsTypes, queryKeys } from '@utils/consts';
import { mapControlType } from '@utils/apiHelpers';

export const useListControls = (type: PaymentControlsTypes) => {
  const { query } = useApi();

  const { isLogged } = useCheckUserPermissions();

  const { data, isPending } = useQuery<PaymentControl[], ApiError>({
    queryKey: [...queryKeys.paymentControls, type, window.location.search],
    queryFn: () =>
      query({
        path: `v1/payment_controls/${mapControlType(type)}${window.location.search}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged,
  });

  return {
    data: data || [],
    loading: isPending,
  };
};
