import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useApi } from '@hooks/useApi';
import { ApiError, MetaData } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { Order } from '@root/globalTypes';

interface OrdersRespSuccess {
  orders: Order[];
  meta: MetaData | undefined;
}

interface OrdersReturn extends OrdersRespSuccess {
  loading: boolean;
}

export const useListOrders = (): OrdersReturn => {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();
  const { data, isPending } = useQuery<OrdersRespSuccess, ApiError>({
    queryKey: [...queryKeys.orders, window.location.search],
    queryFn: () =>
      query({
        path: `v1/orders${window.location.search}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged,
  });

  return {
    orders: data?.orders || [],
    meta: data?.meta || undefined,
    loading: isPending,
  };
};
