import { useAppContext } from '@hooks/useAppContext';
import { MustResetPasswordAlert } from './mustResetPassword';
import { ResetPasswordAlert } from './resetPassword';

export function PasswordExpirationAlert() {
  const { user } = useAppContext();

  if (!user || !user.passwordExpiresAt || !user.passwordResetAt || !user.mustResetPasswordAt) {
    return null;
  }

  const resetAt = new Date(user.passwordResetAt);
  const mustResetAt = new Date(user.mustResetPasswordAt);
  const expiresAt = new Date(user.passwordExpiresAt);

  const daysToExpire = Math.round((expiresAt.getTime() - Date.now()) / (1000 * 3600 * 24));

  if (mustResetAt.getTime() < Date.now()) {
    return <MustResetPasswordAlert daysToExpire={daysToExpire} />;
  } else if (resetAt.getTime() < Date.now()) {
    return <ResetPasswordAlert daysToExpire={daysToExpire} />;
  } else {
    return null;
  }
}
