import { Loader } from '@components/loader';
import { useGetMerchantUser } from '@services/acquirer/merchant/users/get';
import { createFileRoute, redirect, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { useMerchantUserResetPassword } from '@services/acquirer/users/resetMerchant';
import { MerchantUserShow } from '@pages/merchantUsers/id';
import { Page404 } from '@pages/404';

const AcquirerMerchantUser = () => {
  const { t } = useTranslation('app');
  const { merchantId, id } = useParams({
    from: '/u/$localId/_acquirer/acquirer/merchants/$merchantId/users/$id/',
  });
  const { data, isPending } = useGetMerchantUser(merchantId, id);

  const { mutate: resetPassword, isPending: requestPending } = useMerchantUserResetPassword({
    id,
    merchantId,
    callback: () => {
      toast.success(t('toast.password.requestSuccessful'));
    },
  });

  if (isPending) return <Loader />;
  if (!data) return <Page404 />;

  return (
    <MerchantUserShow
      user={data}
      onResetPassword={() => {
        resetPassword();
      }}
      resetPending={requestPending}
    />
  );
};

export const Route = createFileRoute(
  '/u/$localId/_acquirer/acquirer/merchants/$merchantId/users/$id/',
)({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_users_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: AcquirerMerchantUser,
});
