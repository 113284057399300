import { useTranslation } from 'react-i18next';
import { useAcceptInvitationForm } from '@hooks/forms/invitations/accept';
import { Button } from '@components/button';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';

export function AcceptInvitationForm({
  formObj,
  loginFields,
}: {
  loginFields: React.ReactNode;
  formObj: ReturnType<typeof useAcceptInvitationForm>;
}) {
  const { t } = useTranslation('app');
  const { form, onSubmit, isPending } = formObj;
  const [inputType, setInputType] = useState({
    password: 'password',
    confirmPassword: 'password',
  });

  const toggleInputType = (field: 'password' | 'confirmPassword') => {
    setInputType((ps) =>
      ps[field] == 'password' ? { ...ps, [field]: 'text' } : { ...ps, [field]: 'password' },
    );
  };

  return (
    <Form {...form}>
      <form
        className="flex w-full flex-col gap-2"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}>
        {loginFields}
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.password.label')}</FormLabel>
              <FormControl>
                <Input type={inputType.password} {...field}>
                  <button
                    type="button"
                    onClick={() => toggleInputType('password')}
                    className="text-black/30 transition-colors hover:text-black">
                    {inputType.password === 'text' ? (
                      <EyeOff className="w-4" />
                    ) : (
                      <Eye className="w-4" />
                    )}
                  </button>
                </Input>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.passwordConfirmation.label')}</FormLabel>
              <FormControl>
                <Input type={inputType.confirmPassword} {...field}>
                  <button
                    type="button"
                    onClick={() => toggleInputType('confirmPassword')}
                    className="text-black/30 transition-colors hover:text-black">
                    {inputType.confirmPassword === 'text' ? (
                      <EyeOff className="w-4" />
                    ) : (
                      <Eye className="w-4" />
                    )}
                  </button>
                </Input>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          variant="primary"
          size="lg"
          className="mt-8"
          disabled={isPending}
          loading={isPending}>
          {t('form.submit.confirm')}
        </Button>
      </form>
    </Form>
  );
}
