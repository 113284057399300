import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { Loader } from '@components/loader';
import { PermissionsAccordion } from '@components/permissionsAccordion';
import { TwoFactorSetupContextProvider } from '@ctx/twoFactor/provider';
import { TwoFactorSection } from '@pages/settings/twoFactorSection';
import { UpdatePasswordSection } from '@pages/settings/updatePaswordSection';
import { MerchantUser } from '@root/globalTypes';
import { useSessionUser } from '@services/global/session/user';
import { useGetPermissionGroups } from '@services/staff/users/permissionGroups';
import { createFileRoute } from '@tanstack/react-router';
import { Mail, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';

function StaffSettings() {
  const { t } = useTranslation('app');
  const { data, isPending } = useSessionUser<MerchantUser>();
  const { data: permissions } = useGetPermissionGroups();

  if (isPending) return <Loader />;
  if (!data) return null;

  return (
    <div className="flex flex-col gap-4">
      <DetailsCard title={t('pages.settings.userDetails.title')}>
        <DetailsCardItem
          icon={<Mail size={26} />}
          data={data.email}
          label={t('table.email')}
          column={1}
        />
        <DetailsCardItem
          icon={<User size={26} />}
          data={data.name}
          label={t('table.name')}
          column={2}
        />
      </DetailsCard>
      {permissions && (
        <PermissionsAccordion permissions={permissions} selectedPermissions={data.permissions} />
      )}
      <TwoFactorSetupContextProvider user={data}>
        <TwoFactorSection />
      </TwoFactorSetupContextProvider>
      <UpdatePasswordSection userType="staff" />
    </div>
  );
}

export const Route = createFileRoute('/u/$localId/_staff/staff/settings/')({
  component: StaffSettings,
});
