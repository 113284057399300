import { Button } from '@components/button';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { ApiKeyType } from '@root/globalTypes';
import { useTranslation } from 'react-i18next';

export const DisableApiKeyButton = ({
  apiKey,
  onDisable,
  disabling,
}: {
  apiKey: ApiKeyType;
  onDisable: (id: string) => void;
  disabling: boolean;
}) => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();

  const handleDisable = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('pages.apiKeys.confirmDisable', { key: apiKey.description }))) {
      onDisable(apiKey.id);
    }
  };

  if (!verifyUserPermission('api_keys_disable') || !apiKey.active) return null;

  return (
    <Button variant="destructive" onClick={handleDisable} disabled={disabling} loading={disabling}>
      {t('pages.apiKeys.disable')}
    </Button>
  );
};
