import { useApi } from '@hooks/useApi';
import { ApiError } from '@root/globalTypes';
import { MerchantLink } from '@root/globalTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { queryKeys } from '@utils/consts';
import { isFailure } from '@utils/apiHelpers';
import { MerchantLinkData } from '@services/types';

type CreateMerchantLinkParams = {
  merchantId: string;
  successHandler: (data: MerchantLink) => Promise<void>;
};

export function useCreateMerchantLink({ merchantId, successHandler }: CreateMerchantLinkParams) {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { mutate, isPending } = useMutation({
    mutationFn: (merchantLinkData: MerchantLinkData): Promise<MerchantLink | ApiError> => {
      return query({
        path: `v1/acquirer/merchants/${merchantId}/links`,
        data: merchantLinkData,
        opt: {
          method: 'POST',
        },
      });
    },
    onSettled: async (data) => {
      if (!data) return;
      if (isFailure(data)) {
        setApiError(data);
      } else {
        await successHandler(data);
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: [...queryKeys.merchantLinks, merchantId] });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
}
