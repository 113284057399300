import { formatDate } from '@utils/dateHelpers';

export function TableDateCell({ date }: { date?: string | null }) {
  if (!date) return '-';

  const [d, h, a] = formatDate(new Date(date)).split(' ');

  return (
    <div className="flex flex-col">
      {d}
      <span className="text-xs text-neutral-500">
        {h} {a}
      </span>
    </div>
  );
}
