import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { MerchantLinkData } from '@services/types';
import { MerchantLink, MutateObject } from '@root/globalTypes';
import { useTranslation } from 'react-i18next';
import { VALID_SCHEMES } from '@utils/consts';
import { useEffect } from 'react';

export function useMerchantLinkForm({
  merchantLink,
  requestHandler,
}: {
  merchantLink?: MerchantLink;
  requestHandler: MutateObject<MerchantLink, MerchantLinkData>;
}) {
  const { t } = useTranslation('app');
  const schema = z.object({
    acquirerLinkId: z
      .string()
      .trim()
      .min(1, { message: t('form.acquirerLinkId.required') }),
    merchantSchemeId: z
      .string()
      .trim()
      .min(1, { message: t('form.merchantSchemeId.required') }),
    terminalId: z.string().trim(),
    priority: z.preprocess(
      (v) => (typeof v === 'string' ? parseInt(v, 10) : v),
      z
        .number()
        .int()
        .min(0, { message: t('form.priority.min') })
        .max(40, { message: t('form.priority.max') }),
    ),
    mcc: z.string().trim(),
    schemes: z.array(z.enum(VALID_SCHEMES)),
    currencies: z.array(z.string()),
  });

  const defaultValues = {
    acquirerLinkId: merchantLink?.acquirerLink?.id || '',
    merchantSchemeId: merchantLink?.merchantSchemeId || '',
    terminalId: merchantLink?.terminalId || '',
    mcc: merchantLink?.mcc || '',
    priority: merchantLink?.priority || 0,
    schemes: merchantLink?.schemes || [],
    currencies: merchantLink?.currencies || [],
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, error, isPending } = requestHandler;

  useEffect(() => {
    if (!error || !error.errors) return;

    Object.entries(error.errors).forEach(([key, value]) => {
      const k = key as keyof z.infer<typeof schema>;

      form.setError(k, { type: 'manual', message: value[0] });
    });
  }, [error, form]);

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    isPending,
    error: error?.message,
  };
}
