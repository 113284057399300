import { Card, CardContent } from '@components/card';
import { CreateStaffUserForm } from '@components/forms/staff/users/create';
import { BaseLayout } from '@layouts/base';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export function NewStaffUser() {
  const { t } = useTranslation('app');

  return (
    <BaseLayout isChild title={t('pages.newStaffUser.title')}>
      <Card>
        <CardContent className="p-6">
          <CreateStaffUserForm />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}

export const Route = createFileRoute('/u/$localId/_staff/staff/users/new')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['staff_users_create'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: NewStaffUser,
});
