import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { OrderDetails } from '@root/globalTypes';
import { formatCardNumber } from '@utils/textHelpers';
import { Blocks, CalendarClock, CreditCard, Earth, HandCoins, WalletCards } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function OrderSource({ order }: { order: OrderDetails }) {
  const { t } = useTranslation('app');

  return (
    <DetailsCard>
      <DetailsCardItem
        icon={<WalletCards size={26} />}
        data={order.source.type}
        label={t('table.sourceType')}
        column={1}
      />
      <DetailsCardItem
        icon={<CreditCard size={26} />}
        data={formatCardNumber(order.source.firstDigits, order.source.lastDigits)}
        label={t('table.sourceNumber')}
        column={2}
      />
      <DetailsCardItem
        icon={<CalendarClock size={26} />}
        data={`${order.source.month}/${order.source.year}`}
        label={t('table.sourceExpiry')}
        column={1}
      />
      {order.source.issuer && (
        <DetailsCardItem
          icon={<HandCoins size={26} />}
          data={order.source.issuer}
          label={t('table.issuer')}
          column={1}
        />
      )}
      {order.source.cardType && (
        <DetailsCardItem
          icon={<CreditCard size={26} />}
          data={order.source.cardType}
          label={t('table.cardType')}
          column={1}
        />
      )}
      {order.source.category && (
        <DetailsCardItem
          icon={<Blocks size={26} />}
          data={order.source.category}
          label={t('table.category')}
          column={2}
        />
      )}
      {order.source.countryAlpha2 && (
        <DetailsCardItem
          icon={<Earth size={26} />}
          data={order.source.countryAlpha2}
          label={t('table.country')}
          column={2}
        />
      )}
    </DetailsCard>
  );
}
