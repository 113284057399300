import { Sheet, SheetContent, SheetTrigger } from '@components/sheet';
import { useNavigation } from '@ctx/navigation/context';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { Menu } from 'lucide-react';

export function MobileNav({ links }: { links: React.ReactNode }) {
  const { navOpen, setNavOpen } = useNavigation();
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  if (isDesktop) return;

  return (
    <Sheet open={navOpen} onOpenChange={setNavOpen}>
      <SheetTrigger asChild className="lg:hidden">
        <Menu className="mr-4" />
      </SheetTrigger>
      <SheetContent side="left" className="w-full overflow-auto lg:hidden">
        <div className="flex h-full flex-col items-center justify-center pt-8">{links}</div>
      </SheetContent>
    </Sheet>
  );
}
