import { Timer } from '@components/timer';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export const SessionInfo = memo(
  ({ expiresAt, callback }: { expiresAt: string; callback: () => void }) => {
    const { t } = useTranslation('app');
    const handleLogout = (c: number) => {
      if (c > 0) return;
      if (c === 0 && typeof callback === 'function') {
        callback();
      }
    };

    return (
      <div className="flex min-w-36 items-end gap-1">
        <strong className="text-sm font-medium tracking-tighter">{t('sessionEndsIn')}</strong>
        <Timer countTo={expiresAt} callback={handleLogout} />
      </div>
    );
  },
);
