import { Loader } from '@components/loader';
import { CardAuthShow } from '@pages/cardAuths/id';
import { useGetCardAuth } from '@services/global/cardAuths/get';
import { createFileRoute, redirect, useParams } from '@tanstack/react-router';

const MerchantCardAuth = () => {
  const { id } = useParams({ from: '/u/$localId/_merchant/merchant/card-authentications/$id' });
  const { data, isPending } = useGetCardAuth(id);

  if (isPending) return <Loader />;
  if (!data) return null;

  return <CardAuthShow cardAuth={data} />;
};

export const Route = createFileRoute('/u/$localId/_merchant/merchant/card-authentications/$id')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['3ds_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantCardAuth,
});
