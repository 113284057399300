import { Loader } from '@components/loader';
import { Page404 } from '@pages/404';
import { MerchantBriefDetails } from '@pages/merchants/brief';
import { useGetOrder } from '@services/global/orders/get';
import { createFileRoute, useParams } from '@tanstack/react-router';

const OrderMerchant = () => {
  const { id, localId } = useParams({
    from: '/u/$localId/_merchant/merchant/orders/$id/merchant',
  });
  const { data, loading } = useGetOrder(id);

  if (loading) return <Loader />;
  if (!data) return <Page404 fallbackPath={`/u/${localId}/merchant/orders`} />;

  return <MerchantBriefDetails merchant={data.merchant} />;
};

export const Route = createFileRoute('/u/$localId/_merchant/merchant/orders/$id/merchant')({
  component: OrderMerchant,
});
