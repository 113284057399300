import { Loader } from '@components/loader';
import { MerchantUsers } from '@pages/merchantUsers';
import { useDisableMerchantUser } from '@services/staff/merchant/users/disable';
import { useEnableMerchantUser } from '@services/staff/merchant/users/enable';
import { useGetMerchantUsers } from '@services/staff/merchant/users/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { outletNavScroll } from '@utils/apiHelpers';
import { useEffect } from 'react';

const AcqurierMerchantUsers = () => {
  const { merchantId, localId } = useParams({
    from: '/u/$localId/_staff/staff/merchants/$merchantId/users',
  });
  const navigate = useNavigate();
  const { data, isPending } = useGetMerchantUsers(merchantId);
  const { mutate: disableUser } = useDisableMerchantUser(merchantId);
  const { mutate: enableUser } = useEnableMerchantUser(merchantId);

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (isPending) return <Loader />;

  function handleDisable(id: string) {
    disableUser(id);
  }

  function handleEnable(id: string) {
    enableUser(id);
  }

  function handleSelect(id: string) {
    navigate({
      to: '/u/$localId/staff/merchants/$merchantId/users/$id',
      params: { merchantId, id, localId },
    }).catch((err) => console.error(err));
  }

  function newRedirect() {
    navigate({
      to: '/u/$localId/staff/merchants/$merchantId/users/new',
      params: { merchantId, localId },
    }).catch((err) => console.error(err));
  }

  return (
    <MerchantUsers
      users={data}
      onRowSelect={handleSelect}
      onDisable={handleDisable}
      onEnable={handleEnable}
      newRedirect={newRedirect}
    />
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/merchants/$merchantId/users')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_users_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: AcqurierMerchantUsers,
});
