import { IconButton } from '@components/button/iconButton';
import { useAcquirerUsersTable } from '@components/tables/acquirerUsers';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { useDisableUser } from '@services/staff/acquirer/users/disable';
import { useEnableUser } from '@services/staff/acquirer/users/enable';
import { useListAcquirerUsers } from '@services/staff/acquirer/users/list';
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router';
import { Loader, Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const AcquirerUsers = () => {
  const { t } = useTranslation('app');
  const { acquirerId, localId } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId/users',
  });
  const { verifyUserPermission } = useCheckUserPermissions();
  const navigate = useNavigate();
  const { data, isPending } = useListAcquirerUsers({ acquirerId });
  const { mutate: disableUser } = useDisableUser({ acquirerId });
  const { mutate: enableUser } = useEnableUser({ acquirerId });

  function handleSelect(id: string) {
    navigate({
      to: '/u/$localId/staff/acquirers/$acquirerId/users/$id',
      params: { acquirerId, id, localId },
    }).catch((err) => console.error(err));
  }

  function newRedirect() {
    navigate({
      to: '/u/$localId/staff/acquirers/$acquirerId/users/new',
      params: { acquirerId, localId },
    }).catch((err) => console.error(err));
  }

  const { Table: UsersTable } = useAcquirerUsersTable({
    data,
    onRowSelect: handleSelect,
    onDisable: disableUser,
    onEnable: enableUser,
  });

  if (isPending) return <Loader />;

  return (
    <BaseLayout
      title={t('pages.users.acquirer.title')}
      actions={[
        verifyUserPermission('acquirer_users_create') && (
          <IconButton icon={<Plus size={18} />} key="new" variant="secondary" onClick={newRedirect}>
            {t('actions.addNew')}
          </IconButton>
        ),
      ]}>
      <UsersTable />
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/$acquirerId/users')({
  component: AcquirerUsers,
});
