import { ColumnFilterPopover } from '@components/popover/columnsFilterPopover';
import { useOrderTransactionsTable } from '@components/tables/orderTransactions';
import { useToggleTableColumns } from '@hooks/useToggleTableColumns';
import { BaseLayout } from '@layouts/base';
import { OrderDetails } from '@root/globalTypes';
import { useMemo } from 'react';

export function OrderTransactions({ order }: { order: OrderDetails }) {
  const predefinedCols = useMemo(
    () => [
      'id',
      'status',
      'type',
      'amount',
      'refunded_amount',
      'response_code',
      'message',
      'created_at',
    ],
    [],
  );
  const { visibleColumns, setColumns } = useToggleTableColumns({ predefinedCols });
  const { columns, Table: OrderTransactionsTable } = useOrderTransactionsTable({
    order,
    visibleColumns,
  });

  return (
    <BaseLayout
      actions={[
        <ColumnFilterPopover
          key="colFilter"
          activeCols={visibleColumns}
          setActiveCols={setColumns}
          columns={columns.map((col) => ({ id: col.id as string, label: col.header as string }))}
          defaultColumns={predefinedCols}
        />,
      ]}>
      <OrderTransactionsTable />
    </BaseLayout>
  );
}
