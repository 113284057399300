import {
  OrderAirline,
  OrderSubMerchant,
  Merchant,
  MerchantAddress,
  MerchantMastercardSettings,
  MerchantVisaSettings,
} from '@root/globalTypes';
import { useTranslation } from 'react-i18next';
import { ApiIpWhitelistItem } from '@root/globalTypes';
import { Auth } from '@services/global/cardAuths/types';

type ObjectData =
  | OrderAirline
  | OrderSubMerchant
  | MerchantAddress
  | MerchantVisaSettings
  | MerchantMastercardSettings
  | Pick<Merchant, 'id' | 'name' | 'arabicName' | 'merchantId'>
  | Auth
  | ApiIpWhitelistItem;

export const ObjectContent = ({
  data,
  translationKey,
  translationKeySuffix,
  filterKey = [],
}: {
  data?: ObjectData;
  translationKey: string;
  translationKeySuffix?: string;
  filterKey?: string[];
}) => {
  const { t } = useTranslation('app');

  const checkValueType = (v: unknown) => {
    if (typeof v === 'string' || typeof v === 'number') return v;
    return JSON.stringify(v);
  };

  const transKey = (k: string) =>
    `${translationKey}.${k}${translationKeySuffix ? `.${translationKeySuffix}` : ''}`;

  if (!data) return null;
  return (
    <div className="space-y-2">
      {Object.entries(data)
        .filter(([k]) => !filterKey.includes(k))
        .map(([k, v]) => (
          <div key={k}>
            <strong>{t(transKey(k))}</strong>: {checkValueType(v)}
          </div>
        ))}
    </div>
  );
};
