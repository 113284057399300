import { useGetOrder } from '@services/global/orders/get';
import { createFileRoute, redirect, useParams } from '@tanstack/react-router';
import { Loader } from '@components/loader';
import { Order } from '@pages/orders/id';
import { Page404 } from '@pages/404';

const StaffOrder = () => {
  const { id, localId } = useParams({ from: '/u/$localId/_staff/staff/orders/$id' });
  const { data, loading } = useGetOrder(id);

  if (loading) return <Loader />;
  if (!data) return <Page404 fallbackPath={`/u/${localId}/staff/orders`} />;

  return <Order order={data} />;
};

export const Route = createFileRoute('/u/$localId/_staff/staff/orders/$id')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['orders_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: StaffOrder,
});
