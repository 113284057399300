import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePostOrderExtend } from '@services/global/orders/postExtend';

export const useOrderExtend = (id: string, callback?: () => void) => {
  const schema = z.object({
    amount: z.coerce.number().nonnegative(),
  });

  const defaultValues = {
    amount: 0,
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const onSuccess = () => {
    if (callback) callback();
    form.reset();
  };

  const { mutate, isPending, error } = usePostOrderExtend({
    id: id,
    callback: onSuccess,
    setError: form.setError,
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    isPending,
    error,
  };
};
