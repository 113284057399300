import { Button } from '@components/button';
import { Dialog, DialogTrigger, DialogContent, DialogTitle } from '@components/dialog';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { usePaymentControlFormContext } from '@hooks/usePaymentControlsContext';
import { PaymentControl } from '@root/globalTypes';
import { useTranslation } from 'react-i18next';

export const DeleteDialog = ({
  paymentControl,
  requestHandler,
}: {
  paymentControl: PaymentControl;
  requestHandler: {
    onDelete: () => void;
    isPending: boolean;
  };
}) => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const { formOpen, setFormOpen } = usePaymentControlFormContext();
  const { identifierType: type } = paymentControl;
  const tdsDisplayName = type === 'tds_status' ? '3ds_status' : type;
  const { onDelete, isPending } = requestHandler;

  if (!verifyUserPermission('payment_controls_delete')) return null;

  return (
    <Dialog
      open={formOpen.delete}
      onOpenChange={(open) => setFormOpen({ ...formOpen, delete: open })}>
      <DialogTrigger asChild>
        <Button variant="destructive">
          {t('pages.paymentControls.remove', {
            type: t(`pages.paymentControls.${tdsDisplayName}`),
          })}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle className="flex items-center gap-2 whitespace-nowrap">
          {t('pages.paymentControls.delete.title')}
        </DialogTitle>
        <p>{t('pages.paymentControls.delete.info')}</p>
        <Button disabled={isPending} onClick={() => onDelete()} variant="destructive">
          {t('actions.delete')}
        </Button>
        <Button
          disabled={isPending}
          onClick={() => setFormOpen({ ...formOpen, delete: false })}
          variant="ghost">
          {t('actions.cancel')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
