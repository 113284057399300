import { ComboBoxItemType, Combobox } from '@components/combobox';
import { Label } from '@components/form/label';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import cf from 'currency-format';
import { useFilters } from '@ctx/filters/context';
import { FilterItem } from './filterItem';
import { FilterProps } from '.';

export const CurrencyFilter = ({ configItem, wrapped = false }: FilterProps) => {
  const { t } = useTranslation('app');
  const { activeFilters, setFilter } = useFilters();
  const options: ComboBoxItemType[] = Object.entries(cf).map(([k, v]) => ({
    value: k,
    label: `${k}: ${v.name}`,
  }));
  const currencyField = activeFilters.currency;
  const [filteredOptions, setFilteredOptions] = useState<ComboBoxItemType[]>(options || []);
  const formattedValue = useMemo(
    () => (currencyField ? `: ${currencyField}` : ''),
    [currencyField],
  );

  function handleSearch(input: string) {
    if (!options) return;
    if (input.trim().length) {
      const byCode = options.filter((opt) => opt.value.toLowerCase() === input.toLowerCase());

      const byName = options.filter((opt) => opt.label.toLowerCase().includes(input.toLowerCase()));

      setFilteredOptions(Array.from(new Set([...byCode, ...byName])));
    } else {
      setFilteredOptions(options);
    }
  }

  function onSelectChange(value: string | undefined) {
    setFilter('currency', value || '');
  }

  if (wrapped)
    return (
      <div className="relative space-y-4">
        <Label className="absolute left-3 top-[-6px] bg-white px-2 text-xs" htmlFor="currency-alt">
          {t(`filters.currency.label`)}
        </Label>
        <Combobox
          value={currencyField || ''}
          onSelect={onSelectChange}
          items={filteredOptions}
          defaultItems={filteredOptions}
          noResultsMsg={t(`filters.currency.noResultsMsg`)}
          placeholder={t(`filters.currency.placeholder`)}
          searchPlaceholder={t(`filters.currency.searchPlaceholder`)}
          className="w-full"
          defaultOpen={false}
          onSearch={handleSearch}
        />
      </div>
    );

  return (
    <FilterItem displayedValue={formattedValue} configItem={configItem}>
      <div className="relative space-y-4">
        <Label className="absolute left-3 top-[-6px] bg-white px-2 text-xs" htmlFor="currency-alt">
          {t(`filters.currency.label`)}
        </Label>
        <Combobox
          value={currencyField || ''}
          onSelect={onSelectChange}
          items={filteredOptions}
          defaultItems={filteredOptions}
          noResultsMsg={t(`filters.currency.noResultsMsg`)}
          placeholder={t(`filters.currency.placeholder`)}
          searchPlaceholder={t(`filters.currency.searchPlaceholder`)}
          className="w-full"
          defaultOpen={false}
          onSearch={handleSearch}
        />
      </div>
    </FilterItem>
  );
};
