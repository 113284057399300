import { createFileRoute, redirect } from '@tanstack/react-router';
import { paymentControlsParams } from '@utils/consts';

export const Route = createFileRoute(
  '/u/$localId/_staff/staff/merchants/$merchantId/payment-controls/',
)({
  beforeLoad: ({ params }) => {
    if (Object.keys(params).length === 2) {
      throw redirect({
        to: `/u/$localId/staff/merchants/$merchantId/payment-controls/$type`,
        params: {
          type: paymentControlsParams[0],
          localId: params.localId,
          merchantId: params.merchantId,
        },
      });
    }
  },
  component: () => null,
});
