import { useState } from 'react';
import { PaymentControlFormContext } from './context';
import { PaymentControlsTypes } from '@utils/consts';

export function PaymentControlFormContextProvider({
  type,
  children,
}: {
  type: PaymentControlsTypes;
  children: React.ReactNode;
}) {
  const [formType, setFormType] = useState<PaymentControlsTypes>(type);
  const [formOpen, setFormOpen] = useState({ delete: false, edit: false, create: false });

  return (
    <PaymentControlFormContext.Provider
      value={{
        formOpen,
        setFormOpen,
        formType,
        setFormType,
      }}>
      {children}
    </PaymentControlFormContext.Provider>
  );
}
