import { Button } from '@components/button';
import { buttonVariants } from '@components/button/variants';
import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { PermissionsAccordion } from '@components/permissionsAccordion';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { MerchantUser } from '@root/globalTypes';
import { useGetPermissionGroups } from '@services/merchant/users/permissionGroups';
import { Link, useLocation } from '@tanstack/react-router';
import { FileText, Landmark, Mail, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type MerchantUserShowProps = {
  user: MerchantUser;
  onResetPassword: () => void;
  resetPending: boolean;
};

export function MerchantUserShow({ user, onResetPassword, resetPending }: MerchantUserShowProps) {
  const { t } = useTranslation('app');
  const location = useLocation();
  const { verifyUserPermission } = useCheckUserPermissions();
  const { data: permissions } = useGetPermissionGroups();

  return (
    <BaseLayout
      isChild
      title={user.name}
      actions={[
        verifyUserPermission('merchant_users_password_reset') && (
          <Button
            key="reset"
            variant="outline"
            loading={resetPending}
            disabled={resetPending}
            onClick={onResetPassword}>
            {t('passwordReset')}
          </Button>
        ),
        verifyUserPermission('merchant_users_update') && (
          <Link
            key="edit"
            to={`${location.pathname}/edit`}
            replace
            className={buttonVariants({ variant: 'primary' })}>
            {t('actions.edit')}
          </Link>
        ),
      ]}>
      <DetailsCard>
        <DetailsCardItem
          icon={<Mail size={26} />}
          label={t('table.email')}
          data={user.email}
          column={1}
        />
        <DetailsCardItem
          icon={<User size={26} />}
          label={t('table.name')}
          data={user.name}
          column={2}
        />
        <DetailsCardItem
          icon={<Landmark size={26} />}
          label={t('table.acquirer')}
          data={user.acquirer.name}
          column={1}
        />
        <DetailsCardItem
          icon={<FileText size={26} />}
          label={t('table.description')}
          data={user.description}
          column={2}
        />
      </DetailsCard>
      {permissions && (
        <PermissionsAccordion selectedPermissions={user.permissions} permissions={permissions} />
      )}
    </BaseLayout>
  );
}
