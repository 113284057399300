import { useMediaQuery } from '@hooks/useMediaQuery';
import { useState } from 'react';
import { NavigationContext } from './context';

export function NavigationProvider({
  children,
  logout,
  logoutPending,
  links,
}: {
  children: React.ReactNode;
  logout: () => void;
  logoutPending: boolean;
  links: React.ReactNode;
}) {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const [navOpen, setNavOpen] = useState(isDesktop);

  return (
    <NavigationContext.Provider value={{ navOpen, setNavOpen, logout, links, logoutPending }}>
      {children}
    </NavigationContext.Provider>
  );
}
