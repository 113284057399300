import { ReactNode } from 'react';
import hand from '@assets/login/hand.svg';
import decor from '@assets/login/decor.svg';
import { LoginContextProvider } from '@ctx/login/provider';
import { useTranslation } from 'react-i18next';
import moyasarLogo from '@assets/logo.png';
import mpgLogo from '@assets/logo-landscape.png';
import { Card, CardContent, CardHeader } from '@components/card';

export const LoginLayout = ({ title, children }: { title: string; children: ReactNode }) => {
  const { t } = useTranslation('app');

  return (
    <div className="relative h-screen md:grid md:grid-cols-2">
      <div className="relative hidden bg-accent md:block">
        <img
          alt=""
          src={decor}
          className="absolute left-0 top-0 h-full w-full object-cover mix-blend-multiply"
        />
        <img alt="" src={hand} className="absolute bottom-0 left-0 w-1/2" />
      </div>

      <div className="relative flex h-full w-full items-center justify-center bg-accent md:bg-transparent">
        <img
          alt=""
          src={decor}
          className="absolute left-0 top-0 z-10 h-full w-full object-cover mix-blend-multiply md:hidden"
        />
        <img alt="" src={hand} className="absolute bottom-0 left-0 w-1/3 sm:w-1/4 md:hidden" />

        <div className="flex h-full w-full flex-col">
          <div className="mx-4 flex flex-grow flex-col items-center justify-center">
            <LoginContextProvider>
              <Card className="z-20 w-full max-w-lg rounded-lg pb-6 md:pt-4">
                <CardHeader className="flex flex-col items-center">
                  <img alt="" src={mpgLogo} className="h-16 md:mb-4 md:h-28" />
                  <h1 className="self-start text-3xl font-semibold">{title}</h1>
                </CardHeader>
                <CardContent className="pb-0">
                  <div className="flex flex-col items-center gap-4">
                    {children}
                    <span className="flex">
                      {t('poweredBy')}
                      <img src={moyasarLogo} className="mx-1 h-6" />
                      <a
                        href="https://moyasar.com/"
                        target="_blank"
                        className="inline-flex items-center gap-1 hover:text-blue-600 hover:underline">
                        {t('moyasar')}
                      </a>
                    </span>
                  </div>
                </CardContent>
              </Card>
            </LoginContextProvider>
          </div>
        </div>
      </div>
    </div>
  );
};
