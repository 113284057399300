import { useApi } from '@hooks/useApi';
import { PaymentControlsTypes } from '@utils/consts';
import { isError, mapControlType } from '@utils/apiHelpers';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

export const useDeletePaymentControl = ({
  type,
  id,
  merchantId,
  callback,
}: {
  type: PaymentControlsTypes;
  id: string;
  merchantId: string;
  callback: () => void;
}) => {
  const { query } = useApi();
  const [apiError, setApiError] = useState<string>('');

  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      return query({
        path: `v1/acquirer/merchants/${merchantId}/payment_controls/${mapControlType(type)}/${id}`,
        opt: {
          method: 'DELETE',
        },
      });
    },
    onSuccess: async (data) => {
      if (isError(data)) {
        setApiError(data.message);
      } else {
        callback();
      }
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
};
