import { Card, CardContent } from '@components/card';
import { MerchantLinkForm } from '@components/forms/merchantLinks';
import { Loader } from '@components/loader';
import { useMerchantLinkForm } from '@hooks/forms/merchantLinks';
import { BaseLayout } from '@layouts/base';
import { useListAcquirerLinks } from '@services/acquirer/links/list';
import { useCreateMerchantLink } from '@services/acquirer/merchant/links/create';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function NewMerchantLink() {
  const { t } = useTranslation('app');
  const { merchantId, localId } = useParams({
    from: '/u/$localId/_acquirer/acquirer/merchants/$merchantId/links/new',
  });
  const navigate = useNavigate();

  const { data, isPending } = useListAcquirerLinks();
  const requestHandler = useCreateMerchantLink({
    merchantId,
    successHandler: async () => {
      await navigate({
        to: '/u/$localId/acquirer/merchants/$merchantId/links',
        params: { merchantId, localId: localId || '0' },
        replace: true,
      });
      toast.success(t('toast.merchantLink.createSuccess'));
    },
  });

  const form = useMerchantLinkForm({ requestHandler });

  if (isPending) return <Loader />;

  return (
    <BaseLayout isChild title={t('pages.newMerchantLink.title')}>
      <Card>
        <CardContent className="p-6">
          <MerchantLinkForm
            acquirerLinks={data}
            formObj={form}
            buttonLabel={t('form.submit.createMerchantLink')}
          />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}
export const Route = createFileRoute(
  '/u/$localId/_acquirer/acquirer/merchants/$merchantId/links/new',
)({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_links_create'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: NewMerchantLink,
});
