import { useApi } from '@hooks/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { ApiError } from '@root/globalTypes';

type DisableTwoFactorResp = undefined | ApiError;

export const useDisableTwoFactor = () => {
  const { query } = useApi();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<DisableTwoFactorResp> => {
      return query({
        path: 'v1/2fa',
        opt: {
          method: 'DELETE',
        },
      });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.loggedUser });
    },
  });

  return {
    mutate,
    isPending,
  };
};
