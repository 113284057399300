import { Logo } from '@components/logo';
import { useAppContext } from '@hooks/useAppContext';
import { AcquirerUser } from '@root/globalTypes';
import { User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from '@tanstack/react-router';
import { SessionInfo } from '../sessionInfo';
import { useNavigation } from '@ctx/navigation/context';

export function AcquirerUserInfo({ user }: { user: AcquirerUser }) {
  const { t } = useTranslation('app');
  const { localId } = useParams({ strict: false });
  const { frontSession } = useAppContext();
  const { logout } = useNavigation();

  return (
    <Link
      to={`/u/$localId/acquirer/settings`}
      params={{ localId: localId || '0' }}
      className="flex items-center gap-4">
      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-neutral-300">
        <Logo
          imgSrc={user.acquirer.logoUrl || ''}
          name={user.acquirer.name}
          fallback={<User size={28} className="fill-white text-white" />}
        />
      </div>
      <div className="flex flex-col">
        <span>
          {t('acquirer')}: {user.acquirer.name}
        </span>
        {frontSession && <SessionInfo callback={logout} expiresAt={frontSession} />}
      </div>
    </Link>
  );
}
