import { createContext, Dispatch, SetStateAction, useContext } from 'react';

type NavigationCtx = {
  navOpen: boolean;
  setNavOpen: Dispatch<SetStateAction<boolean>>;
  logout: () => void;
  logoutPending: boolean;
  links: React.ReactNode;
};

export const NavigationContext = createContext<NavigationCtx | null>(null);

export function useNavigation() {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error('useNavigation must be used within NavigationProvider');
  }

  return context;
}
