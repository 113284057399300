import { Card, CardContent } from '@components/card';
import { Loader } from '@components/loader';
import { BaseLayout } from '@layouts/base';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useGet } from '@services/staff/acquirers/get';
import { Page404 } from '@pages/404';
import { useAcquirerForm } from '@hooks/forms/acquirers';
import { useUpdate } from '@services/staff/acquirers/update';
import { toast } from 'sonner';
import { AcquirerForm } from '@components/forms/acquirers';

const EditStaffUser = () => {
  const { t } = useTranslation('app');
  const { acquirerId, localId } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId/edit',
  });
  const navigate = useNavigate();
  const { data, isPending: loading } = useGet(acquirerId);

  const requestHandler = useUpdate({
    id: acquirerId,
    callback: () => {
      toast.success(t('toast.acquirers.updateSuccess'));
      navigate({
        to: '/u/$localId/staff/acquirers/$acquirerId',
        replace: true,
        params: { localId: localId || '0', acquirerId },
      }).catch((err) => {
        console.error(err);
      });
    },
  });

  const formObj = useAcquirerForm({ acquirer: data, requestHandler });

  if (loading) return <Loader />;
  if (!data) return <Page404 />;

  return (
    <BaseLayout isChild title={t('pages.editAcquirer.title')}>
      <Card>
        <CardContent className="p-6">
          <AcquirerForm formObj={formObj} submitText={t('form.submit.acquirerUpdate')} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/$acquirerId/edit')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirers_read', 'acquirers_update'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: EditStaffUser,
});
