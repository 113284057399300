import { Card, CardContent } from '@components/card';
import { AcquirerLinkForm } from '@components/forms/acquirerLinks';
import { useAcquirerLinkForm } from '@hooks/forms/acquirerLinks';
import { BaseLayout } from '@layouts/base';
import { useCreateAcquirerLink } from '@services/staff/acquirer/links/create';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function NewAcquirerLink() {
  const { t } = useTranslation('app');
  const { acquirerId, localId } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId/links/new',
  });
  const navigate = useNavigate();

  const requestHandler = useCreateAcquirerLink({
    acquirerId,
    successHandler: async () => {
      await navigate({
        to: '/u/$localId/staff/acquirers/$acquirerId/links',
        params: { acquirerId, localId },
        replace: true,
      });
      toast.success(t('toast.acquirerLink.createSuccess'));
    },
  });
  const formObj = useAcquirerLinkForm({ requestHandler });

  return (
    <BaseLayout isChild title={t('pages.newAcquirerLink.title')}>
      <Card>
        <CardContent className="p-6">
          <AcquirerLinkForm formObj={formObj} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}
export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/$acquirerId/links/new')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirer_links_create'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: NewAcquirerLink,
});
