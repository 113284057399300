import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { CreateAcquirerUserData } from '@services/types';
import { AcquirerUser, MutateObject } from '@root/globalTypes';
import { useEffect } from 'react';

export function useCreateAcquirerUserForm({
  requestHandler,
}: {
  requestHandler: MutateObject<AcquirerUser, CreateAcquirerUserData>;
}) {
  const { t } = useTranslation('app');

  const schema = z.object({
    name: z
      .string()
      .trim()
      .min(1, {
        message: t('form.name.required'),
      }),
    email: z
      .string()
      .trim()
      .min(1, {
        message: t('form.email.required'),
      }),
    description: z.string(),
    permissions: z.string().array(),
  });

  const defaultValues: CreateAcquirerUserData = {
    name: '',
    email: '',
    description: '',
    permissions: [],
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, error, isPending } = requestHandler;

  useEffect(() => {
    if (!error || !error.errors) return;

    Object.entries(error.errors).forEach(([key, value]) => {
      const k = key as keyof z.infer<typeof schema>;

      form.setError(k, { type: 'manual', message: value[0] });
    });
  }, [form, error]);
  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    isPending,
    error: error?.message,
  };
}
