import { Card, CardContent } from '@components/card';
import { Loader } from '@components/loader';
import { BaseLayout } from '@layouts/base';
import { EditStaffUserForm } from '@components/forms/staff/users/edit';
import { useGet } from '@services/staff/users/get';
import { createFileRoute, redirect, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { Page404 } from '@pages/404';

const EditStaffUser = () => {
  const { t } = useTranslation('app');
  const { id } = useParams({ from: '/u/$localId/_staff/staff/users/$id/edit' });
  const { data: user, isPending: loading } = useGet(id);

  if (loading) return <Loader />;
  if (!user) return <Page404 />;

  return (
    <BaseLayout isChild title={t('pages.editStaffUser.title')}>
      <Card>
        <CardContent className="p-6">
          <EditStaffUserForm user={user} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/users/$id/edit')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['staff_users_read', 'staff_users_update'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: EditStaffUser,
});
