import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { ApiKeyType } from '@root/globalTypes';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';

export const useGetApiKey = (id: string) => {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();

  const { data, isPending } = useQuery<ApiKeyType, unknown>({
    queryKey: [...queryKeys.merchantApiKeys, id],
    queryFn: (): Promise<ApiKeyType> =>
      query({
        path: `v1/merchant/api_keys/${id}`,
      }),
    enabled: Boolean(id) && isLogged,
  });

  return {
    data: data || ({} as ApiKeyType),
    isPending,
  };
};
