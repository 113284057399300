import { FormItem, FormLabel, FormControl } from '@components/form/form';
import { Input } from '@components/form/input';
import { ResetPasswordForm } from '@components/forms/global/passwords/reset';
import { Loader } from '@components/loader';
import { useResetPasswordForm } from '@hooks/forms/passwords/reset';
import { LoginLayout } from '@layouts/login';
import { Page404 } from '@pages/404';
import { useGetUserByToken } from '@services/acquirer/users/getByToken';
import { createFileRoute, useSearch, useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function InvalidToken() {
  const { t } = useTranslation('app');

  return <Page404 message={t('pages.resetPassword.invalidToken')} fallbackPath="/acquirer/login" />;
}
function ResetPassword() {
  const { t } = useTranslation('app');
  const search = useSearch({ from: '/acquirer/reset-password' });
  const navigate = useNavigate();
  const token = search.password_reset_token || '';

  const { data, isPending } = useGetUserByToken({ type: 'password', token });

  const form = useResetPasswordForm({
    token: token,
    userType: 'acquirer',
    successHandler: () => {
      navigate({ to: '/acquirer/login' }).catch((err) => console.error(err));
      toast.success(t('toast.password.updateSuccess'));
    },
  });

  if (!token) return <InvalidToken />;
  if (isPending) return <Loader />;
  if (!data) return <InvalidToken />;

  return (
    <LoginLayout title={t('pages.resetPassword.title')}>
      <ResetPasswordForm
        formObj={form}
        loginFields={
          <>
            <FormItem>
              <FormLabel>{t('form.acquirerName.label')}</FormLabel>
              <FormControl>
                <Input value={data.acquirer.name} disabled />
              </FormControl>
            </FormItem>
            <FormItem>
              <FormLabel>{t('form.email.label')}</FormLabel>
              <FormControl>
                <Input value={data.email} disabled />
              </FormControl>
            </FormItem>
          </>
        }
      />
    </LoginLayout>
  );
}

export const Route = createFileRoute('/acquirer/reset-password')({
  component: ResetPassword,
});
