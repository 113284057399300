import { Button } from '@components/button';
import { ColumnFilterPopover } from '@components/popover/columnsFilterPopover';
import { useTransactionsTable } from '@components/tables/transactions';
import { useToggleTableColumns } from '@hooks/useToggleTableColumns';
import { BaseLayout } from '@layouts/base';
import { Transaction } from '@root/globalTypes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const TransactionsPreview = ({
  transactions,
  onSelect,
  onViewMore,
}: {
  transactions: Transaction[];
  onSelect: (transaction: Transaction) => void;
  onViewMore: () => void;
}) => {
  const { t } = useTranslation('app');
  const truncatedTransactions = transactions.slice(0, 5);

  const predefinedCols = useMemo(
    () => [
      'id',
      'order_id',
      'merchant_id',
      'status',
      'type',
      'message',
      'source',
      'source_type',
      'created_at',
    ],
    [],
  );
  const { visibleColumns, setColumns } = useToggleTableColumns({ predefinedCols });
  const { columns, Table: TransactionsTable } = useTransactionsTable({
    visibleColumns,
    onRowSelect: onSelect,
    data: truncatedTransactions,
  });

  return (
    <BaseLayout
      title={t('pages.transactions.title')}
      actions={[
        <ColumnFilterPopover
          key="colFilter"
          activeCols={visibleColumns}
          setActiveCols={setColumns}
          columns={columns.map((col) => ({ id: col.id as string, label: col.header as string }))}
          defaultColumns={predefinedCols}
        />,
      ]}>
      <TransactionsTable />
      {truncatedTransactions.length < transactions.length && (
        <Button className="mx-4 mt-[-1em]" size="lg" variant="accent" onClick={onViewMore}>
          {t('showMore')}
        </Button>
      )}
    </BaseLayout>
  );
};
