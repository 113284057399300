import { Loader } from '@components/loader';
import { useMerchantLinkList } from '@services/acquirer/merchant/links/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';
import { outletNavScroll } from '@utils/apiHelpers';
import { MerchantLinksList } from '@pages/merchantLinks';

function MerchantLinks() {
  const navigate = useNavigate();
  const { merchantId, localId } = useParams({
    from: '/u/$localId/_acquirer/acquirer/merchants/$merchantId/links',
  });
  const { data, isPending } = useMerchantLinkList({ merchantId });

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (isPending) return <Loader />;

  return (
    <MerchantLinksList
      data={data}
      onRowSelect={(id) => {
        navigate({
          to: `/u/$localId/acquirer/merchants/$merchantId/links/$id`,
          params: { merchantId, id, localId },
        }).catch((err) => console.error(err));
      }}
      newRedirect={() => {
        navigate({
          to: `/u/$localId/acquirer/merchants/$merchantId/links/new`,
          params: { merchantId, localId },
        }).catch((err) => console.error(err));
      }}
    />
  );
}

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/merchants/$merchantId/links')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_links_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantLinks,
});
