import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { CardAuth } from '@services/global/cardAuths/types';
import { useListCardAuths } from '@services/global/cardAuths/list';
import { Loader } from '@components/loader';
import { Row } from '@tanstack/react-table';
import { CardAuths } from '@pages/cardAuths';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { config } from './-filterConfig';

const AcquirerCardAuth = () => {
  const navigate = useNavigate();
  const { localId } = useParams({ from: '/u/$localId/_staff/staff/card-authentications/' });
  const { verifyUserPermission } = useCheckUserPermissions();
  const { meta, data, isPending } = useListCardAuths();

  const handleSelect = (row: Row<CardAuth>) => {
    const id = row.getValue('id');
    if (typeof id !== 'string') return;
    if (!verifyUserPermission('3ds_read')) return null;
    navigate({
      to: '/u/$localId/staff/card-authentications/$id/merchant',
      params: { id, localId },
    }).catch((err) => {
      console.error(err);
    });
  };

  if (isPending) return <Loader />;

  return (
    <CardAuths cardAuths={data} meta={meta} onRowSelect={handleSelect} filterConfig={config} />
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/card-authentications/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['3ds_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: AcquirerCardAuth,
});
