import { Card, CardContent } from '@components/card';
import { EditAcquirerUserForm } from '@components/forms/acquirerUsers/edit';
import { Loader } from '@components/loader';
import { useEditAcquirerUserForm } from '@hooks/forms/acquirerUsers/editForm';
import { BaseLayout } from '@layouts/base';
import { useUpdateAcquirerUser } from '@services/staff/acquirer/users/update';
import { useGetAcquirerUser } from '@services/staff/acquirer/users/get';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const UserEdit = () => {
  const { t } = useTranslation('app');
  const { acquirerId, id, localId } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId/users/$id/edit',
  });
  const navigate = useNavigate();
  const { data, isPending } = useGetAcquirerUser({ acquirerId, id });

  const requestHandler = useUpdateAcquirerUser({
    id,
    acquirerId,
    callback: () => {
      toast.success(t('toast.users.updateSuccess'));
      navigate({
        to: '/u/$localId/staff/acquirers/$acquirerId/users/$id',
        params: { acquirerId, id, localId },
        replace: true,
      }).catch((err) => {
        console.error(err);
      });
    },
  });
  const formObj = useEditAcquirerUserForm({ user: data, requestHandler });

  if (isPending) return <Loader />;
  if (!data) return null;

  return (
    <BaseLayout isChild title={t('pages.editAcquirerUser.title')}>
      <Card>
        <CardContent className="p-6">
          <EditAcquirerUserForm formObj={formObj} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
};

export const Route = createFileRoute(
  '/u/$localId/_staff/staff/acquirers/$acquirerId/users/$id/edit',
)({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirer_users_update'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: UserEdit,
});
