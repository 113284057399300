import { useTranslation } from 'react-i18next';
import { TablePagination } from '@components/pagination/tablePagination';
import { ColumnFilterPopover } from '@components/popover/columnsFilterPopover';
import { useToggleTableColumns } from '@hooks/useToggleTableColumns';
import { BaseLayout } from '@layouts/base';
import { MetaData, Transaction } from '@root/globalTypes';
import { useTransactionsTable } from '@components/tables/transactions';
import { useMemo } from 'react';
import { ExportDialog } from '@components/dialog/exportDialog';
import { FiltersProvider } from '@ctx/filters';
import { Filters } from '@components/filters';
import { FiltersConfig } from '@ctx/filters/context';
import { MobileFilters } from '@components/filters/mobileFilters';

type TransactionsProps = {
  meta?: MetaData;
  transactions: Transaction[];
  onRowSelect: (transaction: Transaction) => void;
  filterConfig: FiltersConfig;
};

export function Transactions({ transactions, onRowSelect, meta, filterConfig }: TransactionsProps) {
  const { t } = useTranslation('app');
  const predefinedCols = useMemo(
    () => [
      'id',
      'order_id',
      'merchant_id',
      'status',
      'type',
      'message',
      'source',
      'source_type',
      'created_at',
    ],
    [],
  );
  const { visibleColumns, setColumns } = useToggleTableColumns({ predefinedCols });
  const { columns, Table: TransactionsTable } = useTransactionsTable({
    visibleColumns,
    onRowSelect,
    data: transactions,
  });

  return (
    <FiltersProvider config={filterConfig}>
      <BaseLayout
        title={t('pages.transactions.title')}
        actions={[
          <ExportDialog key="export" type="transactions" />,
          <MobileFilters key="filters" />,
          <ColumnFilterPopover
            key="colFilter"
            activeCols={visibleColumns}
            setActiveCols={setColumns}
            columns={columns.map((col) => ({ id: col.id as string, label: col.header as string }))}
            defaultColumns={predefinedCols}
          />,
        ]}>
        <Filters />
        <TransactionsTable />
        {meta?.totalPages && (
          <TablePagination
            pages={meta.totalPages}
            total={meta.totalCount}
            items={transactions.length}
          />
        )}
      </BaseLayout>
    </FiltersProvider>
  );
}
