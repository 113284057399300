import { useApi } from '@hooks/useApi';
import { MerchantUser } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

export function useGetMerchantUsers(merchantId: string) {
  const { query } = useApi();

  const { data, isPending } = useQuery<MerchantUser[]>({
    queryKey: [...queryKeys.merchantUsers, merchantId, window.location.search],
    queryFn: (): Promise<MerchantUser[]> =>
      query({
        path: `v1/acquirer/merchants/${merchantId}/users${window.location.search}`,
      }),
  });

  return {
    data: data || [],
    isPending,
  };
}
