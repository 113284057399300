import { Loader } from '@components/loader';
import { TabLink } from '@components/tabLink';
import { PaymentControlFormContextProvider } from '@ctx/paymentControlForm/provider';
import { usePaymentControlFormContext } from '@hooks/usePaymentControlsContext';
import { PaymentControls } from '@pages/paymentControls';
import { PaymentControl } from '@root/globalTypes';
import { useCreatePaymentControl } from '@services/staff/merchant/paymentControls/create';
import { useListControls } from '@services/staff/merchant/paymentControls/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Row } from '@tanstack/react-table';
import { outletNavScroll } from '@utils/apiHelpers';
import { PaymentControlsTypes, paymentControlsParams } from '@utils/consts';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const PaymentControlList = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { type, localId, merchantId } = useParams({
    from: '/u/$localId/_staff/staff/merchants/$merchantId/payment-controls/$type/',
  });
  const { data, loading } = useListControls({
    merchantId,
    type: type as PaymentControlsTypes,
  });
  const { formType, setFormOpen } = usePaymentControlFormContext();

  const createHandler = useCreatePaymentControl({
    type: formType,
    merchantId,
    callback: () => {
      setFormOpen((ps) => ({ ...ps, create: false }));
      toast.success(t('toast.paymentControls.createSuccess'));
    },
  });

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (loading) return <Loader />;
  if (!type) return null;

  const handleSelect = (row: Row<PaymentControl>) => {
    const id = row.original.id;

    navigate({
      to: '/u/$localId/staff/merchants/$merchantId/payment-controls/$type/$id',
      params: { id, type, localId, merchantId },
    }).catch((err) => {
      console.error(err);
    });
  };

  return (
    <PaymentControls
      type={type as PaymentControlsTypes}
      onRowSelect={handleSelect}
      paymentControls={data}
      createHandler={createHandler}
      tabs={paymentControlsParams.map((paramType) => (
        <TabLink
          key={paramType}
          path={`/u/$localId/staff/merchants/$merchantId/payment-controls/$type`}
          label={t(`pages.paymentControls.${paramType}`)}
          params={{ localId, merchantId, type: paramType }}
          hash="outletNav"
        />
      ))}
    />
  );
};

function PaymentControlsWithFormProvider() {
  const { type } = useParams({
    from: '/u/$localId/_staff/staff/merchants/$merchantId/payment-controls/$type/',
  });

  return (
    <PaymentControlFormContextProvider type={type as PaymentControlsTypes}>
      <PaymentControlList />,
    </PaymentControlFormContextProvider>
  );
}

export const Route = createFileRoute(
  '/u/$localId/_staff/staff/merchants/$merchantId/payment-controls/$type/',
)({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['payment_controls_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: PaymentControlsWithFormProvider,
});
