import { acquirerLinks, merchantLinks, staffLinks } from '@components/navigation/const';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useAppContext } from '@hooks/useAppContext';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';
import { useEffect } from 'react';

export const Page404 = ({ message, fallbackPath }: { message?: string; fallbackPath?: string }) => {
  const navigate = useNavigate();
  const { verifyUserPermission } = useCheckUserPermissions();
  const { userType, lastLoggedUserType, isAuthenticated } = useAppContext();

  const mapUserTypeLinks = () => {
    if (userType === 'staff') return staffLinks;
    if (userType === 'acquirer') return acquirerLinks;
    if (userType === 'merchant') return merchantLinks;
    return [];
  };

  const navUrl =
    mapUserTypeLinks().filter(({ permission }) =>
      permission ? verifyUserPermission(permission) : true,
    )[0]?.to || '/';
  const redirectUrl = fallbackPath || isAuthenticated() ? navUrl : `/${lastLoggedUserType()}/login`;

  useEffect(() => {
    if (message) {
      toast.error(message, { id: message });
    }
    navigate({ to: redirectUrl }).catch((err) => console.error(err));
  }, [message, navigate, redirectUrl]);

  return null;
};
