import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { Merchant } from '@root/globalTypes';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';

export function useListMerchants({ acquirerId }: { acquirerId: string }) {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();

  const { data, isPending } = useQuery<Merchant[]>({
    queryKey: [...queryKeys.acquirerMerchants, acquirerId, window.location.search],
    queryFn: (): Promise<Merchant[]> =>
      query({
        path: `v1/staff/acquirers/${acquirerId}/merchants${window.location.search}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged,
  });

  return {
    data: data || [],
    isPending,
  };
}
