import { Button } from '@components/button';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import { PermissionGroups } from '@components/permissionGroups';
import { useCreateStaffUserForm } from '@hooks/staff/users/createForm';
import { useGetPermissionGroups } from '@services/staff/users/permissionGroups';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export function CreateStaffUserForm() {
  const { t } = useTranslation('app');
  const { localId } = useParams({ strict: false });
  const navigate = useNavigate();

  const { data: permissions } = useGetPermissionGroups();
  const { form, onSubmit, error, isPending } = useCreateStaffUserForm({
    successHandler: () => {
      form.reset();
      toast.success(t('toast.users.createSuccess'));
      navigate({
        to: '/u/$localId/staff/users',
        params: { localId: localId || '0' },
        replace: true,
      }).catch((err) => {
        console.error(err);
      });
    },
  });

  if (!permissions) return null;

  return (
    <Form {...form}>
      <form
        id="userForm"
        className="flex flex-col gap-4"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('form.name.label')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('form.email.label')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormLabel className="static ml-2 mt-3">{t('form.permissions.label')}</FormLabel>
        <FormField
          control={form.control}
          name="permissions"
          render={({ field }) => (
            <PermissionGroups
              permissions={permissions}
              selectedValues={field.value}
              onSelect={(perms: string[]) => form.setValue('permissions', perms)}
            />
          )}
        />
        {error && <p className="text-red">{error}</p>}

        <Button type="submit" variant="primary" disabled={isPending} loading={isPending}>
          {t('form.submit.userCreate')}
        </Button>
      </form>
    </Form>
  );
}
