import { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogHeader,
} from '@components/dialog';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  InputOTPSeparator,
} from '@components/form/inputOtp';
import { Button } from '@components/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@components/form/form';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from '@hooks/useLoginContext';
import { useRecoveryForm } from '@hooks/merchant/login/recovery';
import { useAppContext } from '@hooks/useAppContext';
import { toast } from 'sonner';
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { getUserSlot } from '@utils/apiHelpers';

export function Recovery() {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const router = useRouter();
  const { handleLogin, sendBcPost } = useAppContext();
  const { token, showRecovery, setShowRecovery, setShowSecondStep } = useLoginContext();

  const { form, onSubmit, isPending, error } = useRecoveryForm({
    handleSuccess: async (data) => {
      handleLogin({ loginData: data, userType: 'merchant' });
      await router.invalidate();
      await navigate({
        to: '/u/$localId/merchant',
        params: { localId: getUserSlot(data.user.id) },
      });
      if (sendBcPost) sendBcPost({ type: 'session', sender: getUserSlot(data.user.id) });
      toast.dismiss();
      toast.success(t('toast.session.success'));
    },
  });

  useEffect(() => {
    form.setValue('token', token);
  }, [form, token]);

  if (!token) return null;

  return (
    <Dialog open={showRecovery} onOpenChange={setShowRecovery}>
      <DialogContent onInteractOutside={(e) => e.preventDefault()}>
        <DialogHeader>
          <DialogTitle className="text-center text-2xl font-medium">
            {t('pages.login.recovery.title')}
          </DialogTitle>
          <DialogDescription>{t('pages.login.recovery.description')}</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            id="recoveryForm"
            className="flex flex-col items-center gap-6"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="otpCode"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputOTP maxLength={6} pattern={REGEXP_ONLY_DIGITS_AND_CHARS} {...field}>
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={3} />
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {error && <p className="text-red">{error}</p>}
            <div className="flex gap-4">
              <Button
                className="w-full"
                type="button"
                onClick={() => setShowRecovery(false)}
                variant="outline"
                disabled={isPending}
                loading={isPending}>
                {t('form.submit.cancel')}
              </Button>
              <Button
                className="w-full"
                type="submit"
                variant="primary"
                disabled={isPending}
                loading={isPending}>
                {t('form.submit.confirm')}
              </Button>
            </div>
          </form>
        </Form>
        <ul className="ml-4 mt-5 text-center text-primary">
          <li
            className="cursor-pointer hover:underline"
            onClick={() => {
              setShowRecovery(false);
              setShowSecondStep(true);
            }}>
            {t('pages.login.recovery.otp')}
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  );
}
