import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/u/$localId/')({
  beforeLoad: ({ context, params }) => {
    if (context.isAuthenticated()) {
      throw redirect({
        to: `/u/$localId/${context.userType}`,
        params: { localId: params.localId },
        replace: true,
      });
    } else {
      throw redirect({
        to: `/${context.lastLoggedUserType()}/login`,
        replace: true,
      });
    }
  },
  component: () => null,
});
