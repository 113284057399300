import { Button } from '@components/button';
import { Dialog, DialogTrigger, DialogContent, DialogTitle } from '@components/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import { Switch } from '@components/switch';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useOrderCapture } from '@hooks/forms/order/capture';
import { Order } from '@root/globalTypes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OrderCapture = ({ order }: { order: Order }) => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const [open, setOpen] = useState(false);
  const { capturedAmount, voidedAmount, amount } = order;
  const disabled = capturedAmount + voidedAmount >= amount;
  const limit = amount - capturedAmount - voidedAmount;

  const onSuccess = () => {
    setOpen(false);
  };

  const { form, onSubmit, isPending, error } = useOrderCapture(order.id, limit, onSuccess);

  if (!verifyUserPermission('orders_capture')) return null;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button disabled={disabled} variant="primary-alt" className="w-24">
          {t('pages.order.capture')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{t('pages.order.captureOrder', { id: order.id })}</DialogTitle>
        <Form {...form}>
          <form
            id="acquirerForm"
            className="flex w-full flex-col gap-2"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('table.amount')}</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="final"
              render={({ field }) => (
                <FormItem className="flex items-center gap-2 space-y-0">
                  <FormControl>
                    <Switch checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel className="static">{t('form.final.label')}</FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            {error && <p className="text-red">{error}</p>}
            <Button
              variant="primary"
              size="lg"
              type="submit"
              className="mt-8"
              disabled={isPending}
              loading={isPending}>
              {t('form.submit.capture')}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
