import { FiltersConfig } from '@ctx/filters/context';
import { schemeOptions, cardAuthStatusOpions } from '@components/filters/selectOptions';

export const config: FiltersConfig = {
  items: [
    { id: 'id', filterType: 'text' },
    { id: 'merchant_id', filterType: 'text' },
    { id: 'scheme', filterType: 'select', options: schemeOptions },
    { id: 'currency', filterType: 'currency' },
    { id: 'status', filterType: 'select', options: cardAuthStatusOpions },
    { id: 'amount', filterType: 'amount' },
    { id: 'fingerprint', filterType: 'text' },
    { id: 'card_expiry', filterType: 'card_expiry' },
    { id: 'tds_ref', filterType: 'text' },
    { id: 'ds_ref', filterType: 'text' },
    { id: 'acs_ref', filterType: 'text' },
    { id: 'tds_id', filterType: 'text' },
    { id: 'ds_id', filterType: 'text' },
    { id: 'acs_id', filterType: 'text' },
    { id: 'created_at', filterType: 'date', format: 'date' },
    { id: 'updated_at', filterType: 'date', format: 'date' },
  ],
  default: ['id', 'status', 'scheme', 'amount', 'created_at'],
};
