import { useApi } from '@hooks/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { queryKeys } from '@utils/consts';
import { Acquirer, ApiError } from '@root/globalTypes';
import { isError } from '@utils/apiHelpers';

export function useUpdate({ id, callback }: { id: string; callback: (data: Acquirer) => void }) {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { mutate, isPending } = useMutation({
    mutationFn: (data: FormData): Promise<Acquirer> => {
      return query({
        path: `v1/staff/acquirers/${id}`,
        formData: true,
        opt: {
          method: 'PUT',
          body: data,
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isError(data)) {
        setApiError(data);
      } else {
        callback(data);
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.staffAcquirers });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
}
