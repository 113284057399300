import { Button } from '@components/button';
import { DataTable } from '@components/dataTable';
import { ApiKeyType } from '@root/globalTypes';
import { ColumnDef, Row } from '@tanstack/react-table';
import { maskApiKey, handleCopy } from '@utils/textHelpers';
import { Eye, EyeOff, Copy } from 'lucide-react';
import { Badge } from '@components/badge';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisableApiKeyButton } from './disableButton';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useTableSorting } from '@hooks/useTableSorting';
import { TableDateCell } from '@components/table/dateCell';

type ApiKeysTableProps = {
  data: ApiKeyType[];
  visibleColumns?: string[];
  onDisable: (id: string) => void;
  disabling: boolean;
  onRowSelect: (row: Row<ApiKeyType>) => void;
};

export function useApiKeysTable({
  data,
  visibleColumns,
  onDisable,
  disabling,
  onRowSelect,
}: ApiKeysTableProps) {
  const { verifyUserPermission } = useCheckUserPermissions();
  const [show, setShow] = useState<string | null>(null);
  const { sorting, updateSorting } = useTableSorting();
  const { t } = useTranslation('app');

  const columns: ColumnDef<ApiKeyType>[] = [
    {
      id: 'key',
      accessorKey: 'key',
      header: t('table.key'),
      enableSorting: false,
      cell: ({ row }) => {
        const { key, id } = row.original;
        return show === id ? key : maskApiKey(key);
      },
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: t('table.description'),
      enableSorting: false,
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: t('table.status'),
      size: 150,
      enableSorting: false,
      cell: ({ row }) => {
        const { active } = row.original;
        return (
          <Badge variant={active ? 'default' : 'secondary'}>
            {active ? t('status.active') : t('status.inactive')}
          </Badge>
        );
      },
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
    {
      id: 'updated_at',
      accessorKey: 'updatedAt',
      header: t('table.updatedAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.updatedAt} />,
    },
    {
      id: 'actions',
      accessorKey: 'actions',
      header: t('table.actions'),
      enableSorting: false,
      cell: ({ row }) => {
        const { key, id } = row.original;
        return (
          <div className="flex gap-2">
            <Button
              className="w-14"
              variant={show === id ? 'outline' : 'tertiary'}
              size="icon"
              onClick={() => setShow((ps) => (ps === id ? null : id))}>
              {show !== id ? <Eye size={16} /> : <EyeOff size={16} />}
            </Button>
            <Button
              className="w-14"
              variant="outline-dark"
              onClick={() => handleCopy(key)}
              size="icon">
              <Copy size={16} />
            </Button>
            {verifyUserPermission('api_keys_disable') && (
              <DisableApiKeyButton
                apiKey={row.original}
                onDisable={onDisable}
                disabling={disabling}
              />
            )}
          </div>
        );
      },
    },
  ];

  return {
    columns,
    Table: () => {
      return (
        <DataTable
          columns={
            visibleColumns
              ? columns.filter(({ id }) =>
                  typeof id === 'string' ? visibleColumns.includes(id) : false,
                )
              : columns
          }
          data={data}
          sorting={sorting}
          onSortChange={updateSorting}
          selectionCallback={onRowSelect}
        />
      );
    },
  };
}
