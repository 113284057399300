import { PaymentControlFormContextProvider } from '@ctx/paymentControlForm/provider';
import { usePaymentControlFormContext } from '@hooks/usePaymentControlsContext';
import { PaymentControlShow } from '@pages/paymentControls/id';
import { useDeleteControl } from '@services/global/paymentControl/delete';
import { useGetControl } from '@services/global/paymentControl/get';
import { useUpdateControl } from '@services/global/paymentControl/update';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { PaymentControlsTypes } from '@utils/consts';
import { Loader } from '@components/loader';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function AcquirerPaymentControl() {
  const { t } = useTranslation('app');
  const { localId, type, id } = useParams({
    from: '/u/$localId/_acquirer/acquirer/payment-controls/$type/$id',
  });
  const navigate = useNavigate();
  const { data, loading } = useGetControl(type as PaymentControlsTypes, id);
  const { formOpen, setFormOpen, formType } = usePaymentControlFormContext();
  const editRequestHandler = useUpdateControl({
    id: id,
    type: formType,
    callback: () => {
      setFormOpen({ ...formOpen, edit: false });
      toast.success(t('toast.paymentControls.updateSuccess'));
    },
  });

  const { mutate, isPending } = useDeleteControl({
    type: type as PaymentControlsTypes,
    id,
    callback: () => {
      navigate({
        to: '/u/$localId/acquirer/payment-controls/$type',
        params: { type, localId },
      }).catch((err) => {
        console.error(err);
      });
      toast.success(t('toast.paymentControls.deleteSuccess'));
    },
  });

  if (loading) return <Loader />;
  if (!data) return null;

  return (
    <PaymentControlShow
      paymentControl={data}
      editRequestHandler={editRequestHandler}
      deleteRequestHandler={{ onDelete: mutate, isPending }}
    />
  );
}

function PaymentControlWithFormProvider() {
  const { type } = useParams({
    from: '/u/$localId/_acquirer/acquirer/payment-controls/$type/$id',
  });

  return (
    <PaymentControlFormContextProvider type={type as PaymentControlsTypes}>
      <AcquirerPaymentControl />,
    </PaymentControlFormContextProvider>
  );
}

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/payment-controls/$type/$id')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['payment_controls_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: PaymentControlWithFormProvider,
});
