import { getTime } from 'date-fns';

type LocalData = {
  ttl: number;
  data: string | Record<string, unknown>;
};

export const isLocalObj = (object: unknown): object is LocalData => {
  if (object !== null && typeof object === 'object') {
    return 'ttl' in object;
  }
  return false;
};

export const localStore = {
  setItem(key: string | null, value: string | Record<string, unknown>, tOffset?: number) {
    if (!key) return;
    const timeOffset = tOffset && tOffset > 0 ? tOffset : 1000 * 3600;
    const data = JSON.stringify({
      ttl: new Date().getTime() + timeOffset,
      data: value,
    });
    localStorage.setItem(key, data);
  },
  getItem(key: string | null) {
    if (!key) return;
    const rawData = localStorage.getItem(key);
    if (!rawData) return null;
    try {
      const keyData: unknown = JSON.parse(rawData);
      if (isLocalObj(keyData)) {
        if (keyData.ttl < new Date().getTime()) {
          localStorage.removeItem(key);
          return null;
        }
        if (!keyData.data) return null;
        return keyData.data;
      }
    } catch (error) {
      console.error(error);
      localStorage.clear();
    }
  },
  getItemValue(key?: string, param?: string) {
    if (!key) return null;
    const raw = localStore.getItem(key);
    let data = raw;
    if (raw && typeof raw === 'string') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data = JSON.parse(raw);
    }
    if (typeof param === 'string' && data && typeof data !== 'string') return data[param];
    return data;
  },
  updateItem(key: string, value: object, tOffset?: number) {
    const timeOffset = tOffset && tOffset > 0 ? tOffset : 1000 * 3600 * 24 * 7;
    const oldValue = localStore.getItem(key);
    let newValue = {};
    if (oldValue && typeof oldValue === 'string') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const json = JSON.parse(oldValue);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      newValue = {
        ...json,
        ...value,
      };
    } else {
      newValue = value;
    }
    const data = JSON.stringify({
      ttl: new Date().getTime() + timeOffset,
      data: JSON.stringify(newValue),
    });
    localStorage.setItem(key, data);
  },
  removeItem(key: string | null) {
    if (!key) return;
    localStorage.removeItem(key);
  },
  getAvailableUserSlot() {
    const slots = new Array(10).fill('').map((_e, i) => localStore.getItem(`app-cache-${i}`));
    return slots.findIndex((el) => el === null).toString();
  },
};

export const calcTimeOffset = (date?: string) => {
  if (!date) return 1000;
  const now = getTime(new Date());
  const from = getTime(date);

  return from - now;
};
