import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { MERCHANT_RULE_PRECEDENCES, MERCHANT_VAULT_NAMES } from '@utils/consts';

export function useMerchantSchema() {
  const { t } = useTranslation('app');
  const MARKET_INDICATOR_VALUES = ['A', 'B', 'E', 'H', 'J', 'M', 'N', 'T'];

  return z.object({
    name: z
      .string()
      .trim()
      .min(1, { message: t('form.merchantName.required') }),
    arabicName: z
      .string()
      .trim()
      .min(1, { message: t('form.arabicName.required') }),
    merchantId: z
      .string()
      .trim()
      .min(1, { message: t('form.merchantId.required') }),
    mcc: z
      .string()
      .trim()
      .min(1, { message: t('form.mcc.required') }),
    tradingName: z
      .string()
      .trim()
      .min(1, { message: t('form.tradingName.required') }),
    crNumber: z
      .string()
      .trim()
      .min(1, { message: t('form.crNumber.required') }),
    unifiedNumber: z
      .string()
      .trim()
      .min(1, { message: t('form.unifiedNumber.required') }),
    goodsDescription: z
      .string()
      .trim()
      .min(1, { message: t('form.goodsDescription.required') }),
    website: z.string().trim().url().or(z.literal('')),
    timezone: z
      .string()
      .trim()
      .min(1, { message: t('form.timezone.required') }),
    madaTerminalId: z
      .string()
      .trim()
      .min(1, { message: t('form.madaTerminalId.required') })
      .length(8, { message: t('form.madaTerminalId.length') }),
    visaTerminalId: z
      .string()
      .trim()
      .min(1, { message: t('form.visaTerminalId.required') })
      .length(8, { message: t('form.visaTerminalId.length') }),
    masterTerminalId: z
      .string()
      .trim()
      .min(1, { message: t('form.masterTerminalId.required') })
      .length(8, { message: t('form.masterTerminalId.length') }),
    endeavourMerchantId: z.string().trim().optional(),
    address: z.object({
      country: z
        .string()
        .trim()
        .min(1, { message: t('form.country.required') }),
      city: z
        .string()
        .trim()
        .min(1, { message: t('form.city.required') }),
      state: z
        .string()
        .trim()
        .min(1, { message: t('form.state.required') }),
      zip: z
        .string()
        .trim()
        .min(1, { message: t('form.zip.required') }),
      company: z
        .string()
        .trim()
        .min(1, { message: t('form.company.required') }),
      line1: z
        .string()
        .trim()
        .min(1, { message: t('form.line1.required') }),
      line2: z
        .string()
        .trim()
        .min(1, { message: t('form.line2.required') }),
    }),
    visaSettings: z.object({
      identifier: z.string().trim().length(8).or(z.literal('')),
      verificationValue: z.string().trim().length(10).or(z.literal('')),
      marketIndicator: z.custom<string>((v) => {
        if (typeof v !== 'string') return false;
        if (v.trim() === '') return true;
        return typeof v === 'string' ? MARKET_INDICATOR_VALUES.includes(v) : false;
      }, t('form.marketIndicator.invalid')),
    }),
    mastercardSettings: z.object({
      assignedId: z.string().trim().length(6).or(z.literal('')),
    }),
    rulePrecedence: z.enum(MERCHANT_RULE_PRECEDENCES).optional(),
    vaultName: z.enum(MERCHANT_VAULT_NAMES).optional(),
  });
}
