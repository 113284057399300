import { Loader } from '@components/loader';
import { TablePagination } from '@components/pagination/tablePagination';
import { useEodStatementsTable } from '@components/tables/eodStatements';
import { BaseLayout } from '@layouts/base';
import { useEodStatementsList } from '@services/acquirer/eodStatements/list';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

function EodStatements() {
  const { t } = useTranslation('app');

  const { data, meta, isPending } = useEodStatementsList();
  const { Table: EodStatementsTable } = useEodStatementsTable({ data });

  if (isPending) return <Loader />;

  return (
    <BaseLayout title={t('pages.eodStatements.title')}>
      <EodStatementsTable />
      {meta?.totalPages && (
        <TablePagination pages={meta.totalPages} total={meta.totalCount} items={data.length} />
      )}
    </BaseLayout>
  );
}

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/eod-statements/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['eod_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: EodStatements,
});
