import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { OrderDetails } from '@root/globalTypes';
import { Fingerprint, Mails, User, Building2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function OrderSubMerchant({ order }: { order: OrderDetails }) {
  const { t } = useTranslation('app');

  if (!order.subMerchant) return null;

  return (
    <DetailsCard>
      <DetailsCardItem
        alt
        icon={<Fingerprint size={28} />}
        data={order.subMerchant.id}
        label={t('subMerchant.id')}
        column={1}
      />
      <DetailsCardItem
        alt
        icon={<Mails size={28} />}
        data={order.subMerchant.email}
        label={t('subMerchant.email')}
        column={1}
      />
      <DetailsCardItem
        alt
        icon={<User size={28} />}
        data={order.subMerchant.registeredName}
        label={t('subMerchant.registeredName')}
        column={2}
      />
      <DetailsCardItem
        alt
        icon={<Building2 size={28} />}
        data={order.subMerchant.tradingName}
        label={t('subMerchant.tradingName')}
        column={2}
      />
    </DetailsCard>
  );
}
