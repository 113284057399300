import { Button } from '@components/button';
import { FormLabel, FormMessage } from '@components/form/form';
import { Input } from '@components/form/input';
import { ApiIpWhitelistItem } from '@root/globalTypes';
import { Plus, Trash } from 'lucide-react';
import { useEffect, useState, Fragment } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ZodError, z } from 'zod';

const IPV4_REGEX =
  /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\/(3[0-2]|[1-2]?[0-9]))?$/;

const IPV6_REGEX =
  /^(([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|(([0-9a-fA-F]{1,4}:){1,7}:)|(::([0-9a-fA-F]{1,4}:){0,6}[0-9a-fA-F]{1,4})|(([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4})|([0-9a-fA-F]{1,4}:){1,4}::([0-9a-fA-F]{1,4}:){1,4})?(\/(12[0-8]|1[01][0-9]|[1-9]?[0-9]))?$/;

export const IpWhitelist = ({
  setValue,
  value,
}: {
  value: ApiIpWhitelistItem[];
  setValue: UseFormSetValue<{
    permissions: string[];
    ipWhitelist: ApiIpWhitelistItem[];
    description?: string | undefined;
  }>;
}) => {
  const { t } = useTranslation('app');
  const [ips, setIps] = useState<ApiIpWhitelistItem[]>(value || []);
  const [formValues, setFormValues] = useState({ ip: '', description: '' });
  const [errors, setErrors] = useState<{ ip: string | null; description: string | null }>({
    ip: null,
    description: null,
  });

  const IpWhiteListItemSchema = z.object({
    ip: z.union([
      z.string().regex(IPV4_REGEX, { message: t('form.ip.invalid') }),
      z.string().regex(IPV6_REGEX, { message: t('form.ip.invalid') }),
    ]),
    description: z
      .string()
      .trim()
      .min(1, { message: t('form.description.required') }),
  });

  function validateIpItem(ipItem: ApiIpWhitelistItem) {
    try {
      IpWhiteListItemSchema.parse(ipItem);
      setErrors({ ip: null, description: null });
      return true;
    } catch (e) {
      if (e instanceof ZodError) {
        setErrors({ ip: null, description: null });
        e.errors.forEach((err) => {
          if (err.path[0] === 'ip') setErrors((pv) => ({ ...pv, ip: err.message }));
          if (err.path[0] === 'description')
            setErrors((pv) => ({ ...pv, description: err.message }));
        });
      }
      return false;
    }
  }

  const handleAdd = () => {
    if (validateIpItem(formValues)) {
      setIps((ps) => [...ps, formValues]);
      setFormValues({ ip: '', description: '' });
      setErrors({ ip: null, description: null });
    }
  };

  const handleRemove = (ip: string) => {
    setIps((ps) => ps.filter((el) => el.ip !== ip));
  };

  useEffect(() => {
    setValue('ipWhitelist', ips);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ips]);

  if (!value) return null;

  return (
    <div>
      {value?.length > 0 && (
        <div className="my-4 grid max-h-44 grid-cols-[1fr,2fr,50px] gap-x-4 gap-y-1 overflow-y-auto">
          {value.map((v, i) => (
            <Fragment key={v.ip}>
              <div key={v.ip} data-key={v.ip} className="contents">
                <strong>{v.ip}</strong>
                <span>{v.description}</span>
                <Button
                  type="button"
                  size="sm"
                  variant="destructive"
                  onClick={() => handleRemove(v.ip)}>
                  <Trash size={16} />
                </Button>
              </div>
              {i < value.length - 1 && <hr className="col-span-full text-neutral-200" />}
            </Fragment>
          ))}
        </div>
      )}
      <div className="mb-4 flex flex-col items-start gap-2 md:flex-row md:gap-4">
        <div className="relative w-full space-y-2 bg-white">
          <FormLabel>{t('form.ip.label')}</FormLabel>
          <Input
            type="text"
            name="ip"
            value={formValues.ip}
            onChange={(e) => {
              setFormValues((pv) => ({ ...pv, ip: e.target.value }));
              if (errors.ip) {
                validateIpItem(formValues);
              }
            }}
          />
          {errors.ip && <FormMessage>{errors.ip}</FormMessage>}
        </div>
        <div className="relative w-full space-y-2 bg-white">
          <FormLabel>{t('form.description.label')}</FormLabel>
          <Input
            type="text"
            name="description"
            value={formValues.description}
            onChange={(e) => {
              setFormValues((pv) => ({ ...pv, description: e.target.value }));
              if (errors.description) {
                validateIpItem(formValues);
              }
            }}
          />
          {errors.description && <FormMessage>{errors.description}</FormMessage>}
        </div>
        <Button
          type="button"
          variant="secondary"
          className="mt-2 h-[44px] w-full md:h-[50px] md:w-32"
          onClick={handleAdd}>
          {t('actions.add')}
          <Plus size={18} />
        </Button>
      </div>
    </div>
  );
};
