import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { AcquirerLink } from '@root/globalTypes';

export function useListAcquirerLinks({ acquirerId }: { acquirerId: string }) {
  const { isLogged, isMerchant } = useCheckUserPermissions();
  const { query } = useApi();

  const { data, isPending } = useQuery<AcquirerLink[]>({
    queryKey: [...queryKeys.acquirerLinks, acquirerId, window.location.search],
    queryFn: (): Promise<AcquirerLink[]> =>
      query({
        path: `v1/staff/acquirers/${acquirerId}/links${window.location.search}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged && !isMerchant && !!acquirerId,
  });

  return {
    data: data || [],
    isPending,
  };
}
