import { useApi } from '@hooks/useApi';
import { PermissionGroupsType } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

export const useGetPermissionGroups = () => {
  const { query } = useApi();

  const { data, isPending } = useQuery<PermissionGroupsType, unknown>({
    queryKey: queryKeys.staffUserPermissions,
    queryFn: (): Promise<PermissionGroupsType> =>
      query({
        path: `v1/staff/users/permissions`,
        raw: true,
      }),
    staleTime: 1000 * 60 * 10,
  });

  return {
    data,
    isPending,
  };
};
