import { Loader } from '@components/loader';
import { useGetMerchant } from '@services/acquirer/merchants/get';
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router';
import { redirect } from '@tanstack/react-router';
import { Page404 } from '@pages/404';
import { MerchantPage } from '@pages/merchants/id';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { TabLink } from '@components/tabLink';
import { useTranslation } from 'react-i18next';

const MerchantDetails = () => {
  const { verifyUserPermission } = useCheckUserPermissions();
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { merchantId, localId } = useParams({
    from: '/u/$localId/_acquirer/acquirer/merchants/$merchantId',
  });

  const { data, isPending } = useGetMerchant(merchantId);

  const handleEditRedirect = () => {
    navigate({
      to: '/u/$localId/acquirer/merchants/$merchantId/edit',
      params: { merchantId, localId },
    }).catch((err) => {
      console.error(err);
    });
  };
  const basePath = '/u/$localId/acquirer/merchants/$merchantId';

  if (isPending) return <Loader />;
  if (!data) return <Page404 />;

  return (
    <MerchantPage
      data={data}
      tabs={[
        verifyUserPermission('orders_read') && (
          <TabLink
            key="orders"
            label={t('nav.orders')}
            path={`${basePath}/orders`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
        verifyUserPermission('orders_read') && (
          <TabLink
            key="transactions"
            label={t('nav.transactions')}
            path={`${basePath}/transactions`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
        verifyUserPermission('3ds_read') && (
          <TabLink
            key="cardAuths"
            label={t('nav.cardAuths')}
            path={`${basePath}/card-authentications`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
        verifyUserPermission('merchant_users_read') && (
          <TabLink
            key="users"
            label={t('nav.users')}
            path={`${basePath}/users`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
        verifyUserPermission('payment_controls_read') && (
          <TabLink
            key="paymentControls"
            label={t('nav.paymentControls')}
            path={`${basePath}/payment-controls/bin_range`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
        verifyUserPermission('merchant_links_read') && (
          <TabLink
            key="links"
            label={t('nav.merchantLinks')}
            path={`${basePath}/links`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
      ]}
      handleEditRedirect={handleEditRedirect}
    />
  );
};

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/merchants/$merchantId')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchants_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantDetails,
});
