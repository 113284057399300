import { useState } from 'react';
import { timezones } from '@utils/timezones';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@components/combobox';

export function TimezonesCombobox({
  value,
  onSelect,
  className,
}: {
  value: string;
  onSelect: (value: string | undefined) => void;
  className?: string;
}) {
  const { t } = useTranslation('app');
  const [filteredTimezones, setFilteredTimezones] = useState(timezones);

  function handleSearch(input: string) {
    if (input.trim().length) {
      const filtered = timezones.filter((timezone) => {
        return timezone.label.toLowerCase().includes(input.toLowerCase());
      });

      setFilteredTimezones(filtered);
    } else {
      setFilteredTimezones(timezones);
    }
  }

  return (
    <Combobox
      items={filteredTimezones}
      defaultItems={timezones}
      value={value}
      onSelect={onSelect}
      className={className}
      onSearch={handleSearch}
      noResultsMsg={t('form.timezone.noResultsMsg')}
      placeholder={t('form.timezone.placeholder')}
      searchPlaceholder={t('form.timezone.searchPlaceholder')}
    />
  );
}
