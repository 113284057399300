import { FilterConfigItem } from '@ctx/filters/context';
import { CurrencyFilter } from './currency';
import { TextFilter } from './text';
import { SelectFilter } from './select';
import { CardExpiryFilter } from './cardExpiry';
import { RangeFilter } from './range';
import { AmountFilter } from './amount';
import { DateFilter } from './date';

export type FilterProps = {
  configItem: FilterConfigItem;
  wrapped?: boolean;
};

export function Filter({ configItem, wrapped = false }: FilterProps) {
  function renderFilterContent() {
    switch (configItem.filterType) {
      case 'amount':
        return <AmountFilter configItem={configItem} />;
      case 'currency':
        return <CurrencyFilter configItem={configItem} />;
      case 'text':
        return <TextFilter configItem={configItem} wrapped={wrapped} />;
      case 'select':
        return <SelectFilter configItem={configItem} wrapped={wrapped} />;
      case 'card_expiry':
        return <CardExpiryFilter configItem={configItem} />;
      case 'range':
        return <RangeFilter configItem={configItem} />;
      case 'date':
        return <DateFilter configItem={configItem} />;
      default:
        throw new Error('Unhandled filter type');
    }
  }

  return renderFilterContent();
}
