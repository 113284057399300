import { Loader } from '@components/loader';
import { MerchantUserShow } from '@pages/merchantUsers/id';
import { useGetMerchantUser } from '@services/merchant/users/get';
import { useUserResetPassword } from '@services/merchant/users/reset';
import { createFileRoute, redirect, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export function MerchantUser() {
  const { t } = useTranslation('app');
  const { id } = useParams({ from: '/u/$localId/_merchant/merchant/users/$id/' });
  const { data, isPending } = useGetMerchantUser(id);
  const { mutate: resetPassword, isPending: requestPending } = useUserResetPassword({
    id,
    callback: () => {
      toast.success(t('toast.password.requestSuccessful'));
    },
  });

  if (isPending) return <Loader />;
  if (!data) return null;

  return (
    <MerchantUserShow
      user={data}
      onResetPassword={() => {
        resetPassword();
      }}
      resetPending={requestPending}
    />
  );
}

export const Route = createFileRoute('/u/$localId/_merchant/merchant/users/$id/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_users_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantUser,
});
