/* eslint-disable immutable/no-mutation */
type SearchValue =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | Record<string, string | number>;
export type SearchObject = Record<string, SearchValue>;

export const toQueryString = (search: SearchObject): string => {
  function parseObject(searchObj: SearchObject, prefix?: string): string {
    return Object.keys(searchObj)
      .map((key) => {
        const value = searchObj[key];
        const prefixedKey = encodeURIComponent(prefix ? `${prefix}[${key}]` : key);

        if (typeof value === 'object' && !Array.isArray(value)) {
          return parseObject(value, prefixedKey);
        } else if (Array.isArray(value)) {
          return value.map((arrayValue) => `${prefixedKey}[]=${String(arrayValue)}`).join('&');
        } else {
          return `${prefixedKey}=${value}`;
        }
      })
      .join('&');
  }
  const queryString = parseObject(search);

  return queryString ? `?${queryString}` : '';
};

export function fromQueryString(queryString: string): SearchObject {
  const queryObject: SearchObject = {};
  const pairs = queryString.startsWith('?')
    ? queryString.slice(1).split('&')
    : queryString.split('&');

  pairs.forEach((pair) => {
    const [rawKey, value] = pair.split('=');
    const decodedValue = decodeURIComponent(value || '');
    const decodedKey = decodeURIComponent(rawKey || '');

    const keys = decodedKey.split(/[[\]]+/).filter(Boolean);

    let currentLevel = queryObject;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (i === keys.length - 1) {
        if (key.endsWith('[]')) {
          const arrayKey = key.slice(0, -2);
          if (!Array.isArray(currentLevel[arrayKey])) {
            currentLevel[arrayKey] = [];
          }
          (currentLevel[arrayKey] as string[]).push(String(decodedValue));
        } else {
          currentLevel[key] = decodedValue;
        }
      } else {
        if (!currentLevel[key]) {
          currentLevel[key] = {};
        }
        currentLevel = currentLevel[key] as SearchObject;
      }
    }
  });

  return queryObject;
}
