import { Input } from '@components/form/input';
import { Label } from '@components/form/label';
import { useTranslation } from 'react-i18next';
import { validateKeyDown } from '@utils/textHelpers';
import { useFilters, isRangeFilter } from '@ctx/filters/context';
import { FilterItem } from './filterItem';
import { useMemo } from 'react';
import { FilterProps } from '.';

export const RangeFilter = ({ configItem }: FilterProps) => {
  const { t } = useTranslation('app');
  const { activeFilters, setFilter } = useFilters();
  const filter = activeFilters[configItem.id];

  const formattedValue = useMemo(() => {
    if (!isRangeFilter(filter)) return '';
    const gte = filter.gte;
    const lte = filter.lte;

    if (gte && lte) return `: ${gte} - ${lte}`;
    if (gte && !lte) return ` > ${gte}`;
    if (!gte && lte) return ` < ${lte}`;
    return '';
  }, [filter]);

  if (!isRangeFilter(filter)) return;
  const isNumeric = configItem.format === 'number';

  return (
    <FilterItem displayedValue={formattedValue} configItem={configItem}>
      <div className="space-y-2">
        <Label htmlFor={`${configItem.id}[gte]_alt`}>{t(`filters.${configItem.id}.label`)}</Label>
        <div className="relative space-y-2">
          <Label
            className="absolute left-3 bg-white px-2 text-xs"
            htmlFor={`${configItem.id}[gte]_alt`}>
            {t(`filters.${configItem.id}.from`)}
          </Label>
          <Input
            name={`${configItem.id}[gte]_alt`}
            type={configItem.format || 'text'}
            onChange={(ev) => setFilter(configItem.id, { ...filter, gte: ev.target.value })}
            defaultValue={filter.gte}
            onKeyDown={(e) => {
              if (isNumeric && !validateKeyDown(e, /[0-9]|,/)) e.preventDefault();
            }}
          />
        </div>
        <div className="relative space-y-2">
          <Label
            className="absolute left-3 bg-white px-2 text-xs"
            htmlFor={`${configItem.id}[lte]_alt`}>
            {t(`filters.${configItem.id}.to`)}
          </Label>
          <Input
            name={`${configItem.id}[lte]_alt`}
            type={configItem.format || 'text'}
            onChange={(ev) => setFilter(configItem.id, { ...filter, lte: ev.target.value })}
            defaultValue={filter.lte}
            onKeyDown={(e) => {
              if (isNumeric && !validateKeyDown(e, /[0-9]|,/)) e.preventDefault();
            }}
          />
        </div>
      </div>
    </FilterItem>
  );
};
