import { Button } from '@components/button';
import { ObjectContent } from '@components/objectContent';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@components/accordion';
import { ApiKeyType, PermissionGroupsType } from '@root/globalTypes';
import { maskApiKey, handleCopy } from '@utils/textHelpers';
import { PencilIcon, Eye, EyeOff, Copy } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from '@tanstack/react-router';
import { DisableApiKeyButton } from '@components/tables/apiKeys/disableButton';
import { UseMutateFunction } from '@tanstack/react-query';
import { PermissionsAccordion } from '@components/permissionsAccordion';

type ApiKeyShowProps = {
  apiKey: ApiKeyType;
  onDisable: UseMutateFunction<ApiKeyType, Error, string, unknown>;
  disabling: boolean;
  permissions?: PermissionGroupsType;
};

export function ApiKeyShow({ apiKey, onDisable, disabling, permissions }: ApiKeyShowProps) {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState<boolean>(false);

  return (
    <BaseLayout
      isChild
      title={show ? apiKey.key : maskApiKey(apiKey.key)}
      actions={[
        verifyUserPermission('api_keys_update') && (
          <Button
            key="edit"
            variant="primary"
            size="icon"
            className="w-14"
            onClick={() => {
              navigate({ to: `${location.pathname}/edit`, params: { id: apiKey.id } }).catch(
                (err) => {
                  console.error(err);
                },
              );
            }}>
            <PencilIcon className="h-5" />
          </Button>
        ),
        <Button
          key="show"
          className="w-14"
          variant={show ? 'outline' : 'tertiary'}
          size="icon"
          onClick={() => setShow((ps) => !ps)}>
          {show ? <Eye size={16} /> : <EyeOff size={16} />}
        </Button>,
        <Button
          key="copy"
          className="w-14"
          variant="outline-dark"
          onClick={() => handleCopy(apiKey.key)}
          size="icon">
          <Copy size={16} />
        </Button>,
        apiKey.active && verifyUserPermission('api_keys_disable') && (
          <DisableApiKeyButton
            key="disable"
            apiKey={apiKey}
            onDisable={onDisable}
            disabling={disabling}
          />
        ),
      ]}>
      {permissions && (
        <PermissionsAccordion permissions={permissions} selectedPermissions={apiKey.permissions} />
      )}
      <Accordion type="multiple">
        <AccordionItem value="whitelist">
          <AccordionTrigger className="border-none" variant="default">
            <h3>{t('pages.apiKeys.whitelist')}</h3>
          </AccordionTrigger>
          <AccordionContent>
            <div className="space-y-6">
              {apiKey.ipWhitelist.length
                ? apiKey.ipWhitelist.map((wl) => (
                    <ObjectContent key={wl.ip} data={wl} translationKey="table" />
                  ))
                : t('noData')}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </BaseLayout>
  );
}
