import { cn } from '@utils/cn';
import { useCallback, useEffect, useRef, useState } from 'react';

export const Timer = ({
  countTo,
  className = '',
  callback,
}: {
  countTo?: string;
  className?: string;
  callback?: (c: number) => void;
}) => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const calcTimeLeft = useCallback(() => {
    const t0 = countTo ? new Date(countTo).getTime() - new Date().getTime() : 0;
    const t1 = t0 < 0 ? 0 : t0;
    return t1;
  }, [countTo]);
  const [time, setTime] = useState(calcTimeLeft());

  useEffect(() => {
    // eslint-disable-next-line immutable/no-mutation
    timer.current = setInterval(() => {
      setTime(calcTimeLeft());
    }, 500);

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [calcTimeLeft]);

  useEffect(() => {
    if (time === 0) {
      if (timer.current) clearInterval(timer.current);
      if (typeof callback === 'function') callback(time);
    }
  }, [callback, time]);

  const formatTime = () => {
    const hours = Math.floor(time / 1000 / 60 / 60);
    const minutes = Math.floor((time / 1000 / 60 / 60 - hours) * 60);
    const seconds = Math.floor(((time / 1000 / 60 / 60 - hours) * 60 - minutes) * 60);

    return (
      <span
        className={cn(
          hours === 0 && minutes <= 5 && 'text-yellow-500',
          hours === 0 && minutes <= 2 && 'text-error',
        )}>
        {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </span>
    );
  };

  if (!countTo || !time) return null;
  return <div className={cn('inline-flex gap-2 text-sm', className)}>{formatTime()}</div>;
};
