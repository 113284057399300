import { Button } from '@components/button';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import { useEditMerchantUserForm } from '@hooks/forms/merchantUsers/editForm';
import { useGetPermissionGroups } from '@services/merchant/users/permissionGroups';
import { useTranslation } from 'react-i18next';
import { PermissionGroups } from '@components/permissionGroups';

export function EditMerchantUserForm({
  formObj,
}: {
  formObj: ReturnType<typeof useEditMerchantUserForm>;
}) {
  const { t } = useTranslation('app');
  const { data: permissions } = useGetPermissionGroups();
  const { form, onSubmit, error, isPending } = formObj;

  if (!permissions) return null;

  return (
    <Form {...form}>
      <form
        id="userForm"
        className="flex flex-col gap-4"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('form.name.label')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.description.label')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormLabel className="static ml-3 mt-2">{t('form.permissions.label')}</FormLabel>
        <FormField
          control={form.control}
          name="permissions"
          render={({ field }) => (
            <PermissionGroups
              permissions={permissions}
              selectedValues={field.value}
              onSelect={(perms: string[]) => form.setValue('permissions', perms)}
            />
          )}
        />
        {error && <p className="text-red">{error}</p>}

        <Button type="submit" variant="primary" disabled={isPending} loading={isPending}>
          {t('form.submit.userUpdate')}
        </Button>
      </form>
    </Form>
  );
}
