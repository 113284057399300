import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useAppContext } from '@hooks/useAppContext';
import { AcquirerUser, MerchantUser, StaffUser } from '@root/globalTypes';

export function useGetLoggedUser() {
  const { query } = useApi();
  const { token } = useAppContext();

  const { data, isPending, refetch } = useQuery<MerchantUser | AcquirerUser | StaffUser>({
    queryKey: queryKeys.loggedUser,
    queryFn: () => {
      return query({ path: 'v1/session' });
    },
    enabled: Boolean(token),
  });

  return {
    data: data,
    isPending,
    refetch,
  };
}
