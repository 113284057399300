import { useApi } from '@hooks/useApi';
import { ApiError } from '@root/globalTypes';
import { useMutation } from '@tanstack/react-query';

export const useMerchantLogout = ({ successHandler }: { successHandler: () => Promise<void> }) => {
  const { query } = useApi();

  type LogoutSuccess = {
    message: string;
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<LogoutSuccess | ApiError> => {
      return query({
        path: 'v1/merchant/session/logout',
        opt: {
          method: 'DELETE',
        },
      });
    },
    onSuccess: async () => {
      await successHandler();
    },
  });

  return {
    mutate,
    isPending,
  };
};
