import { IconButton } from '@components/button/iconButton';
import { Loader } from '@components/loader';
import { useStaffUsersTable } from '@components/tables/staffUsers';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { useDisable } from '@services/staff/users/disable';
import { useEnable } from '@services/staff/users/enable';
import { useList } from '@services/staff/users/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

function StaffUsers() {
  const { t } = useTranslation('app');
  const { localId } = useParams({ from: '/u/$localId/_staff/staff/users/' });
  const { verifyUserPermission } = useCheckUserPermissions();
  const navigate = useNavigate();
  const { data, isPending } = useList();
  const { mutate: disableUser } = useDisable();
  const { mutate: enableUser } = useEnable();

  function handleSelect(id: string) {
    navigate({ to: '/u/$localId/staff/users/$id', params: { localId, id } }).catch((err) =>
      console.error(err),
    );
  }

  function newRedirect() {
    navigate({ to: '/u/$localId/staff/users/new', params: { localId } }).catch((err) =>
      console.error(err),
    );
  }

  const { Table: UsersTable } = useStaffUsersTable({
    data,
    onRowSelect: handleSelect,
    onDisable: disableUser,
    onEnable: enableUser,
  });

  if (isPending) return <Loader />;

  return (
    <BaseLayout
      title={t('pages.users.staff.title')}
      actions={[
        verifyUserPermission('staff_users_create') && (
          <IconButton key="new" icon={<Plus size={18} />} variant="secondary" onClick={newRedirect}>
            {t('actions.addNew')}
          </IconButton>
        ),
      ]}>
      <UsersTable />
    </BaseLayout>
  );
}

export const Route = createFileRoute('/u/$localId/_staff/staff/users/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['staff_users_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: StaffUsers,
});
