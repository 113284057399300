/* eslint-disable immutable/no-mutation */
import { useMemo, useState } from 'react';
import {
  AvailableFilters,
  FiltersConfig,
  FiltersContext,
  FiltersSearch,
  FiltersValues,
  getDefaultFilterValue,
  isFilterKey,
  removeEmptyKeys,
} from './context';
import { useAppContext } from '@hooks/useAppContext';
import { useNavigate, useSearch, useMatch } from '@tanstack/react-router';
import { getCacheKeyFromRoute } from '@utils/apiHelpers';

export function FiltersProvider({
  config,
  children,
}: {
  config: FiltersConfig;
  children: React.ReactNode;
}) {
  const { filtersCache, updateFiltersCache } = useAppContext();
  const search = useSearch({ from: '__root__' });
  const match = useMatch({ strict: false });
  const key = useMemo(() => getCacheKeyFromRoute(match.routeId), [match]);
  const savedFilters = filtersCache[key];
  const navigate = useNavigate();

  const searchWithoutFilters = useMemo(() => {
    const searchCopy: Record<string, unknown> = {};

    Object.keys(search).forEach((k) => {
      if (!isFilterKey(k)) {
        searchCopy[k] = search[k as keyof typeof search];
      }
    });

    return searchCopy as typeof search;
  }, [search]);

  function getActiveFiltersFromSearch() {
    const searchCopy: Record<string, unknown> = {};

    Object.keys(search).forEach((k) => {
      if (isFilterKey(k)) {
        searchCopy[k] = search[k as keyof typeof search];
      }
    });

    return searchCopy as FiltersSearch;
  }

  const defaultFilters = useMemo(() => {
    return config.default.reduce(
      (acc, k) => {
        acc[k] = getDefaultFilterValue(k);
        return acc;
      },
      {} as Record<string, unknown>,
    ) as FiltersSearch;
  }, [config.default]);

  function initializeFilters(): FiltersSearch {
    const searchFilters = getActiveFiltersFromSearch();
    const searchObj = { ...savedFilters, ...searchFilters };

    return Object.keys(searchObj).length ? searchObj : defaultFilters;
  }

  const [activeFilters, setActiveFilters] = useState<FiltersSearch>(initializeFilters());

  const visibleFilters = useMemo(() => {
    return Object.keys(activeFilters) as AvailableFilters[];
  }, [activeFilters]);

  function setFilter(filter: AvailableFilters, value: FiltersValues) {
    setActiveFilters((pv) => ({ ...pv, [filter]: value }));
  }

  function addFilter(filter: AvailableFilters) {
    setFilter(filter, getDefaultFilterValue(filter));
  }

  function addFilters(filters: AvailableFilters[]) {
    const active: Record<string, unknown> = {};

    filters.forEach((f) => {
      active[f] = getDefaultFilterValue(f);
    });

    setActiveFilters((pv) => ({ ...pv, ...active }));
  }

  function removeFilter(filters: AvailableFilters[]) {
    const activeFiltersCopy = { ...activeFilters };
    filters.forEach((filter) => {
      delete activeFiltersCopy[filter];
    });
    setActiveFilters(activeFiltersCopy);
  }

  const applyFilters = () => {
    updateFiltersCache(key, activeFilters);
    navigate({
      to: '.',
      search: { ...removeEmptyKeys(activeFilters), ...searchWithoutFilters },
    }).catch((err) => {
      console.error(err);
    });
  };

  return (
    <FiltersContext.Provider
      value={{
        activeFilters,
        setActiveFilters,
        visibleFilters,
        setFilter,
        removeFilter,
        applyFilters,
        addFilter,
        addFilters,
        configItems: config.items,
        defaultFilters,
      }}>
      {children}
    </FiltersContext.Provider>
  );
}
