import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from '@components/dataTable';
import { Transaction } from '@root/globalTypes';
import { mapSourceToAsset } from '@utils/fileHelpers';
import { formatCardNumber } from '@utils/textHelpers';
import { useTranslation } from 'react-i18next';
import { CreditCard } from 'lucide-react';
import { useTableSorting } from '@hooks/useTableSorting';
import { TableDateCell } from '@components/table/dateCell';
import { useAppContext } from '@hooks/useAppContext';

type TransactionsTableProps = {
  visibleColumns: string[];
  onRowSelect: (transaction: Transaction) => void;
  data: Transaction[];
};

export function useTransactionsTable({
  visibleColumns,
  onRowSelect,
  data,
}: TransactionsTableProps) {
  const { t } = useTranslation('app');
  const { userType } = useAppContext();
  const { sorting, updateSorting } = useTableSorting();

  const optionalColumns: ColumnDef<Transaction>[] = [
    {
      id: 'merchant_id',
      accessorKey: 'merchant.merchantId',
      header: t('table.merchantId'),
      enableSorting: false,
    },
    {
      id: 'merchant_name',
      accessorKey: 'merchant.name',
      header: t('table.merchantName'),
      enableSorting: false,
    },
  ];

  const defaultColumns: ColumnDef<Transaction>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
      enableSorting: false,
    },
    {
      id: 'order_id',
      accessorKey: 'orderId',
      header: t('table.orderId'),
      enableSorting: false,
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: t('table.status'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'type',
      accessorKey: 'type',
      header: t('table.type'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'message',
      accessorKey: 'message',
      header: t('table.message'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'stan',
      accessorKey: 'stan',
      header: t('table.stan'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'source',
      accessorKey: 'source',
      header: t('table.source'),
      enableSorting: false,
      cell: ({ row }) => {
        const s = row.original.source;
        return formatCardNumber(s.firstDigits, s.lastDigits);
      },
    },
    {
      id: 'source_type',
      accessorKey: 'sourceType',
      header: t('table.sourceType'),
      enableSorting: false,
      cell: ({ row }) => {
        const s = row.original.source;
        if (s.type === 'card')
          return (
            <div className="flex w-12 justify-center">
              <CreditCard size={24} />
            </div>
          );
        return (
          <img
            alt={s.type}
            src={mapSourceToAsset(s.type)}
            className="h-6 w-12 object-contain object-center"
          />
        );
      },
    },
    {
      id: 'reverse_reason',
      accessorKey: 'reverseReason',
      header: t('table.reverseReason'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'response_code',
      accessorKey: 'responseCode',
      header: t('table.responseCode'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'retrieval_reference',
      accessorKey: 'retrievalReference',
      header: t('table.retrievalReference'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
  ];

  const columns =
    userType === 'merchant'
      ? defaultColumns
      : [defaultColumns[0], ...optionalColumns, ...defaultColumns.slice(1)];

  return {
    columns,
    Table: () => (
      <DataTable
        columns={columns.filter(({ id }) =>
          typeof id === 'string' ? visibleColumns.includes(id) : false,
        )}
        sorting={sorting}
        onSortChange={updateSorting}
        data={data}
        selectionCallback={(row) => {
          onRowSelect(row.original);
        }}
      />
    ),
  };
}
