import { useCallback, useLayoutEffect, useState } from 'react';

export function useMediaQuery(query: string) {
  const getMatches = (queryStr: string): boolean => {
    return window.matchMedia(queryStr).matches;
  };
  const [matches, setMatches] = useState(getMatches(query));

  const handleChange = useCallback(() => {
    setMatches(getMatches(query));
  }, [query]);

  useLayoutEffect(() => {
    const matchMedia = window.matchMedia(query);

    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  }, [query, handleChange]);

  return matches;
}
