import { cva } from 'class-variance-authority';

export const badgeVariants = cva(
  'inline-flex rounded-md min-w-20 justify-center py-1 px-3 text-sm font-medium text-white hover:bg-opacity-90',
  {
    variants: {
      variant: {
        default: 'bg-accent-50 text-accent-400',
        dark: 'bg-[#212B36]',
        primary: 'bg-primary',
        secondary: 'bg-secondary',
        destructive: 'bg-error',
        outline: 'text-neutral-950 border border-neutral-950',
        danger: 'bg-[#DC3545]',
        warning: 'bg-[#F9C107]',
        success: 'bg-[#3CA745]',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
