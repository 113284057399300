import { IconButton } from '@components/button/iconButton';
import { useMerchantUsersTable } from '@components/tables/merchantUsers';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { ApiError, MerchantUser } from '@root/globalTypes';
import { UseMutateFunction } from '@tanstack/react-query';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type MerchantUsersProps = {
  users: MerchantUser[];
  onRowSelect: (id: string) => void;
  onEnable: UseMutateFunction<MerchantUser | ApiError, Error, string, unknown>;
  onDisable: UseMutateFunction<MerchantUser | ApiError, Error, string, unknown>;
  newRedirect: () => void;
};

export function MerchantUsers({
  users,
  onRowSelect,
  onEnable,
  onDisable,
  newRedirect,
}: MerchantUsersProps) {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const { Table: UsersTable } = useMerchantUsersTable({
    data: users,
    onRowSelect,
    onDisable,
    onEnable,
  });

  return (
    <BaseLayout
      title={t('pages.users.merchant.title')}
      actions={[
        verifyUserPermission('merchant_users_create') && (
          <IconButton key="new" icon={<Plus size={18} />} variant="secondary" onClick={newRedirect}>
            {t('actions.addNew')}
          </IconButton>
        ),
      ]}>
      <UsersTable />
    </BaseLayout>
  );
}
