import { Button } from '@components/button';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { MerchantUser } from '@root/globalTypes';
import { UseMutateFunction } from '@tanstack/react-query';
import { UserPlus } from 'lucide-react';

type EnableUserBtnProps = {
  user: MerchantUser;
  onEnable: UseMutateFunction<MerchantUser, Error, string, unknown>;
};

export function EnableMerchantUserButton({ user, onEnable }: EnableUserBtnProps) {
  const { verifyUserPermission } = useCheckUserPermissions();

  if (!verifyUserPermission(`merchant_users_enable`) || user.active) {
    return null;
  }

  return (
    <Button
      size="sm"
      className="w-14"
      variant="outline"
      type="button"
      onClick={() => onEnable(user.id)}>
      <UserPlus className="h-5" />
    </Button>
  );
}
