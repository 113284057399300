import { ApiError } from '@root/globalTypes';
import { PaymentControlsTypes } from './consts';
import { localStore } from './localStore';
import { SessionData } from '@ctx/appCtx';

export function isError(data: unknown): data is ApiError {
  return (data as ApiError).errors !== undefined;
}

export const listURLParams = () => {
  const f: Record<string, string> = {};
  const search = window.location.search
    .slice(1)
    .split('&')
    .map((el) => el.split('='));
  search.forEach(([k, v]) => {
    // eslint-disable-next-line immutable/no-mutation
    if (v) f[k] = v;
  });

  return {
    search: Object.entries(f)
      .map(([k, v]) => (v ? `${k}=${v}` : ''))
      .filter((el) => el)
      .join('&'),
    params: f,
  };
};

export const mapControlType = (type: PaymentControlsTypes) =>
  type === '3ds_status' ? 'tds_status' : type;

export const outletNavScroll = () => {
  setTimeout(() => {
    document.getElementById('outletNav')?.scrollIntoView({
      behavior: 'smooth',
    });
  }, 20);
};

export const getSlots = () =>
  new Array(10).fill('').map((_e, i) => localStore.getItem(`app-cache-${i}`));

export function getUserSlot(id?: string): string {
  const slots = getSlots();
  const objSlots = slots.map((el) =>
    typeof el === 'string' ? (JSON.parse(el) as SessionData) : null,
  );
  const firstFree = slots.findIndex((el) => el === null).toString();
  if (id) {
    const idx = objSlots.findIndex((el) => el?.sessionId === id);
    // console.log('idx', idx);
    return idx < 0 ? '0' : idx.toString();
  }
  const { pathname, search } = location;
  if (pathname.endsWith('/login') && !document.querySelector('[role="dialog"]')) return firstFree;
  if (pathname.startsWith('/u/')) {
    const cut = pathname.replace('/u/', '');
    return parseFloat(cut.slice(0, cut.indexOf('/'))).toString();
  }
  if (decodeURIComponent(search).includes('/u/')) {
    const raw = decodeURIComponent(search);
    const cut = raw.slice(raw.indexOf('/u/') + 3);
    const idx = +cut.slice(0, cut.indexOf('/'));
    // console.log('redirect idx', idx, idx + 1);
    return (idx + 1).toString();
  }

  const firstTakenSlot = slots.findIndex((el) => el !== null);
  return (firstTakenSlot > 0 ? firstTakenSlot : 0).toString();
}

export function isFailure<T>(data: T | ApiError): data is ApiError {
  return (data as ApiError)?.message !== undefined;
}

export function getCacheKeyFromRoute(route: string) {
  return route
    .split('/')
    .slice(-3)
    .filter((item) => item.length && !item.includes('$'))
    .join('-');
}
