import { Loader } from '@components/loader';
import { ApiKeys } from '@pages/apiKeys';
import { ApiKeyType } from '@root/globalTypes';
import { useDisableApiKey } from '@services/merchant/apiKeys/disable';
import { useListApiKeys } from '@services/merchant/apiKeys/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Row } from '@tanstack/react-table';

function MerchantApiKeys() {
  const navigate = useNavigate();
  const { localId } = useParams({ from: '/u/$localId/_merchant/merchant/api-keys/' });
  const { data, isPending } = useListApiKeys();
  const { mutate: disableApiKey, isPending: disabling } = useDisableApiKey();

  function handleSelect(row: Row<ApiKeyType>) {
    const id: string = row.original.id;

    navigate({ to: '/u/$localId/merchant/api-keys/$id', params: { id, localId } }).catch((err) =>
      console.error(err),
    );
  }

  function newRedirect() {
    navigate({ to: '/u/$localId/merchant/api-keys/new', params: { localId } }).catch((err) =>
      console.error(err),
    );
  }

  if (isPending) return <Loader />;

  return (
    <ApiKeys
      data={data}
      onDisable={disableApiKey}
      disabling={disabling}
      newRedirect={newRedirect}
      onRowSelect={handleSelect}
    />
  );
}

export const Route = createFileRoute('/u/$localId/_merchant/merchant/api-keys/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['api_keys_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantApiKeys,
});
