import { Loader } from '@components/loader';
import { AuditLogsList } from '@pages/auditLogs';
import { useAuditLogsList } from '@services/global/auditLogs/list';
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router';

function StaffAuditLogs() {
  const navigate = useNavigate();
  const { localId } = useParams({ from: '/u/$localId/_staff/staff/audit-logs/' });
  const { data, isPending, meta } = useAuditLogsList();

  if (isPending) return <Loader />;

  return (
    <AuditLogsList
      data={data}
      meta={meta}
      onRowSelect={(id) => {
        navigate({ to: '/u/$localId/staff/audit-logs/$id', params: { localId, id } }).catch((err) =>
          console.error(err),
        );
      }}
    />
  );
}

export const Route = createFileRoute('/u/$localId/_staff/staff/audit-logs/')({
  component: StaffAuditLogs,
});
