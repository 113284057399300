import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { ApiKeyType } from '@root/globalTypes';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';

interface UseGetApiKeysReturn {
  data: ApiKeyType;
  isPending: boolean;
}

export const useGetApiKey = ({
  id,
  acquirerId,
}: {
  id: string;
  acquirerId: string;
}): UseGetApiKeysReturn => {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();

  const { data, isPending } = useQuery<ApiKeyType, unknown>({
    queryKey: [...queryKeys.acquirerApiKeys, id],
    queryFn: (): Promise<ApiKeyType> =>
      query({
        path: `v1/staff/acquirers/${acquirerId}/api_keys/${id}`,
      }),
    enabled: Boolean(id) && isLogged,
  });

  return {
    data: data || ({} as ApiKeyType),
    isPending,
  };
};
