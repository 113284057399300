import { useApi } from '@hooks/useApi';
import { ApiError, MerchantUser, AuthRespSuccess } from '@root/globalTypes';
import { useMutation } from '@tanstack/react-query';
import { isError } from '@utils/apiHelpers';
import { useState } from 'react';

export type AuthData = {
  acquirer: string;
  merchantId: string;
  email: string;
  password: string;
};

export type AuthResp = AuthRespSuccess<MerchantUser> | ApiError | undefined;

export const useMerchanLogin = ({
  successHandler,
}: {
  successHandler: (data: AuthRespSuccess<MerchantUser>) => Promise<void>;
}) => {
  const { query } = useApi();
  const [apiError, setApiError] = useState<string>('');

  const { mutate, isPending } = useMutation({
    mutationFn: (authData: AuthData): Promise<AuthResp> => {
      return query({
        path: 'v1/merchant/session/login',
        data: authData,
        opt: {
          method: 'POST',
        },
        useAuth: false,
      });
    },
    onSettled: async (data) => {
      if (!data) return;
      if (isError(data)) {
        setApiError(data.message);
      } else {
        await successHandler(data);
      }
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
};
