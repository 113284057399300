import { Loader } from '@components/loader';
import { MerchantUsers } from '@pages/merchantUsers';
import { useDisableUser } from '@services/merchant/users/disable';
import { useEnableUser } from '@services/merchant/users/enable';
import { useListUsers } from '@services/merchant/users/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';

function Users() {
  const navigate = useNavigate();
  const { localId } = useParams({ from: '/u/$localId/_merchant/merchant/users/' });
  const { data, isPending } = useListUsers();
  const { mutate: disableUser } = useDisableUser();
  const { mutate: enableUser } = useEnableUser();

  if (isPending) return <Loader />;

  function handleSelect(id: string) {
    navigate({ to: '/u/$localId/merchant/users/$id', params: { id, localId } }).catch((err) =>
      console.error(err),
    );
  }

  function newRedirect() {
    navigate({ to: '/u/$localId/merchant/users/new', params: { localId } }).catch((err) =>
      console.error(err),
    );
  }

  return (
    <MerchantUsers
      users={data}
      onRowSelect={handleSelect}
      onDisable={disableUser}
      onEnable={enableUser}
      newRedirect={newRedirect}
    />
  );
}
export const Route = createFileRoute('/u/$localId/_merchant/merchant/users/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_users_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: Users,
});
