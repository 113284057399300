import { Card, CardContent } from '@components/card';
import { MerchantLinkForm } from '@components/forms/merchantLinks';
import { useMerchantLinkForm } from '@hooks/forms/merchantLinks';
import { BaseLayout } from '@layouts/base';
import { Page404 } from '@pages/404';
import { useListAcquirerLinks } from '@services/staff/acquirer/links/list';
import { useCreateMerchantLink } from '@services/staff/merchant/links/create';
import { useGetMerchant } from '@services/staff/merchants/get';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Loader } from '@components/loader';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export function NewMerchantLink() {
  const { t } = useTranslation('app');
  const { merchantId, localId } = useParams({
    from: '/u/$localId/_staff/staff/merchants/$merchantId/links/new',
  });
  const navigate = useNavigate();
  const { data: merchant } = useGetMerchant(merchantId);
  const { data: acquirerLinks, isPending } = useListAcquirerLinks({
    acquirerId: merchant?.acquirer?.id || '',
  });

  const requestHandler = useCreateMerchantLink({
    merchantId,
    successHandler: async () => {
      await navigate({
        to: '/u/$localId/staff/merchants/$merchantId/links',
        params: { merchantId, localId: localId || '0' },
        replace: true,
      });
      toast.success(t('toast.merchantLink.createSuccess'));
    },
  });

  const form = useMerchantLinkForm({ requestHandler });

  if (isPending) return <Loader />;
  if (!merchant) return <Page404 />;

  return (
    <BaseLayout isChild title={t('pages.newMerchantLink.title')}>
      <Card>
        <CardContent className="p-6">
          <MerchantLinkForm
            acquirerLinks={acquirerLinks}
            formObj={form}
            buttonLabel={t('form.submit.createMerchantLink')}
          />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}

export const Route = createFileRoute('/u/$localId/_staff/staff/merchants/$merchantId/links/new')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_links_create'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: NewMerchantLink,
});
