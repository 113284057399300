import { FormLabel } from '@components/form/form';
import { Switch } from '@components/switch';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@components/tootlip';
import { PermissionGroupsType } from '@root/globalTypes';
import { humanizeText } from '@utils/textHelpers';
import { CircleHelp } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function PermissionGroups({
  permissions,
  disabled = false,
  selectedValues,
  onSelect,
}: {
  permissions: PermissionGroupsType;
  disabled?: boolean;
  selectedValues: string[];
  onSelect?: (permissions: string[]) => void;
}) {
  const { t } = useTranslation('app');

  const columns: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          Object.values(permissions)
            .map((actions) => actions)
            .flat(),
        ),
      ),
    [permissions],
  );

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (!permissions || !onSelect) return;

      const allPermissions = Object.entries(permissions)
        .map(([group, actions]) => {
          return actions.map((action) => [group, action].join('_'));
        })
        .flat();
      onSelect(checked ? allPermissions : []);
    },
    [permissions, onSelect],
  );

  return (
    <div className="mx-1 flex flex-col gap-2 overflow-auto">
      {!disabled && (
        <div className="flex items-center gap-2">
          <Switch
            light
            id="allPermissions"
            checked={selectedValues?.length === Object.values(permissions).flat().length}
            onCheckedChange={handleSelectAll}
          />
          <FormLabel htmlFor="allColumns" className="static text-neutral-400">
            {t('checkAll')}
          </FormLabel>
        </div>
      )}
      <table className="table-auto">
        <tbody>
          {Object.entries(permissions).map(([group, actions]) => (
            <tr key={group} className="even:bg-neutral-50">
              <td className="max-w-24 p-2">
                <h3>{t(`permissionGroups.groups.${group}`)}</h3>
              </td>
              {columns.map((column) => {
                const perm = [group, column].join('_');
                return (
                  <td key={column} className="p-2">
                    {actions.includes(column) ? (
                      <div className="flex gap-2">
                        <div className="flex flex-col items-center">
                          <h3>{humanizeText(column)}</h3>
                          <Switch
                            light
                            disabled={disabled}
                            onCheckedChange={(checked) => {
                              if (!onSelect) return;
                              if (checked) {
                                onSelect([...selectedValues, perm]);
                              } else {
                                onSelect(selectedValues.filter((el) => el !== perm));
                              }
                            }}
                            checked={selectedValues.includes(perm)}
                          />
                        </div>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild className="self-start">
                              <CircleHelp size={14} className="text-neutral-500" />
                            </TooltipTrigger>
                            <TooltipContent className="bg-neutral-50">
                              {t(`permissionGroups.actions.${column}`, {
                                group: t(`permissionGroups.groups.${group}`),
                              })}
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    ) : null}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
