import { StaffUser } from '@root/globalTypes';
import { SessionInfo } from '../sessionInfo';
import { Link, useParams } from '@tanstack/react-router';
import { useAppContext } from '@hooks/useAppContext';
import { useNavigation } from '@ctx/navigation/context';

export function StaffUserInfo({ user }: { user: StaffUser }) {
  const { localId } = useParams({ strict: false });
  const { frontSession } = useAppContext();
  const { logout } = useNavigation();

  return (
    <Link
      to={`/u/$localId/staff/settings`}
      params={{ localId: localId || '0' }}
      className="flex gap-4">
      <div className="flex flex-col">
        <span>{user?.name}</span>
        {frontSession && <SessionInfo callback={logout} expiresAt={frontSession} />}
      </div>
    </Link>
  );
}
