export const schemeOptions = [
  {
    label: 'Visa',
    value: 'visa',
  },
  {
    label: 'MasterCard',
    value: 'mastercard',
  },
  {
    label: 'Mada',
    value: 'mada',
  },
  {
    label: 'AMEX',
    value: 'amex',
  },
];

export const transactionTypeOptions = [
  {
    label: 'Verify',
    value: 'verify',
  },
  {
    label: 'Purchase',
    value: 'purchase',
  },
  {
    label: 'Authorize',
    value: 'authorize',
  },
  {
    label: 'Capture',
    value: 'capture',
  },
  {
    label: 'Void',
    value: 'void',
  },
  {
    label: 'Refund',
    value: 'refund',
  },
  {
    label: 'Extension',
    value: 'extension',
  },
  {
    label: 'Reversal',
    value: 'reversal',
  },
];

export const sourceTypeOptions = [
  {
    label: 'Card',
    value: 'Card',
  },
  {
    label: 'Apple Pay',
    value: 'ApplePayToken',
  },
  {
    label: 'Samsung Pay',
    value: 'SamsungPayToken',
  },
  {
    label: 'Google Pay',
    value: 'GooglePayToken',
  },
];

export const organizationTypeOptions = [
  {
    label: 'Merchant',
    value: 'Merchant',
  },
  {
    label: 'Acquirer',
    value: 'Acquirer',
  },
];

export const actionTypeOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Passive',
    value: 'passive',
  },
];

export const cardAuthStatusOpions = [
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Available',
    value: 'available',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
  },
  {
    label: 'Authenticated',
    value: 'authenticated',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
];

export const transactionStatusOptions = [
  {
    label: 'Initiated',
    value: 'initiated',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
  {
    label: 'Timeout',
    value: 'timeout',
  },
  {
    label: 'System Error',
    value: 'system_error',
  },
];
