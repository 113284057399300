import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { ApiKeyType } from '@root/globalTypes';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';

export const useListApiKeys = () => {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();

  const { data, isPending } = useQuery<ApiKeyType[], unknown>({
    queryKey: queryKeys.merchantApiKeys,
    queryFn: (): Promise<ApiKeyType[]> =>
      query({
        path: `v1/merchant/api_keys`,
      }),
    enabled: isLogged,
  });

  return {
    data: data || [],
    isPending,
  };
};
