import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { MerchantData } from '@services/types';
import { useMerchantSchema } from '@hooks/useMerchantSchema';
import { Merchant, MutateObject } from '@root/globalTypes';
import { useEffect } from 'react';
import { stringifyNulls } from '@utils/textHelpers';

export function useMerchantForm({
  merchant,
  requestHandler,
}: {
  merchant?: Merchant;
  requestHandler: MutateObject<Merchant, MerchantData>;
}) {
  const schema = useMerchantSchema();

  const defaultValues = {
    name: '',
    arabicName: '',
    merchantId: '',
    mcc: '',
    tradingName: '',
    crNumber: '',
    unifiedNumber: '',
    goodsDescription: '',
    website: '',
    timezone: 'Asia/Riyadh',
    madaTerminalId: '',
    visaTerminalId: '',
    masterTerminalId: '',
    address: {
      country: 'SA',
      city: '',
      state: '',
      zip: '',
      company: '',
      line1: '',
      line2: '',
    },
    visaSettings: {
      identifier: '',
      verificationValue: '',
      marketIndicator: '',
    },
    rulePrecedence: undefined,
    vaultName: undefined,
    mastercardSettings: {
      assignedId: '',
    },
    endeavourMerchantId: '',
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (!merchant) return;

    form.reset(stringifyNulls(merchant));
  }, [merchant, form]);

  const { mutate, isPending, error } = requestHandler;

  useEffect(() => {
    if (!error || !error.errors) return;

    Object.entries(error.errors).forEach(([key, value]) => {
      const k = key as keyof z.infer<typeof schema>;

      form.setError(k, { type: 'manual', message: value[0] });
    });
  }, [form, error]);

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    isPending,
    error: error?.message,
  };
}
