import { Card, CardContent } from '@components/card';
import { ImportBinsForm } from '@components/forms/bins/import';
import { useImportBinsForm } from '@hooks/forms/bins/import';
import { BaseLayout } from '@layouts/base';
import { useImportBins } from '@services/staff/bins/import';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function ImportBins() {
  const { t } = useTranslation('app');

  const requestHandler = useImportBins({
    callback: () => {
      toast.success(t('toast.bins.importSuccess'));
    },
  });
  const formObj = useImportBinsForm({ requestHandler });

  return (
    <BaseLayout isChild title={t('pages.bins.import.title')}>
      <Card>
        <CardContent className="p-6">
          <ImportBinsForm formObj={formObj} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}

export const Route = createFileRoute('/u/$localId/_staff/staff/bins/import')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['bins_import'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: ImportBins,
});
