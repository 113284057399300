import { TransactionsPreview } from '@pages/transactions/preview';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { outletNavScroll } from '@utils/apiHelpers';
import { Loader } from '@components/loader';
import { useEffect } from 'react';
import { useListAcquirerTransactions } from '@services/staff/acquirer/transactions';
import { Transaction } from '@root/globalTypes';

const AcquirerTransactions = () => {
  const navigate = useNavigate();
  const { localId, acquirerId } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId/transactions',
  });
  const { transactions, loading } = useListAcquirerTransactions(acquirerId);

  const handleSelect = (transaction: Transaction) => {
    navigate({
      to: '/u/$localId/staff/orders/$id/transactions',
      params: { id: transaction.orderId, localId },
    }).catch((err) => {
      console.error(err);
    });
  };

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (loading) return <Loader />;

  return (
    <TransactionsPreview
      onSelect={handleSelect}
      transactions={transactions}
      onViewMore={() => {
        navigate({
          to: '/u/$localId/staff/transactions',
          params: { localId },
          /* eslint-disable camelcase */
          search: { acquirer_id: acquirerId },
        }).catch((err) => console.error(err));
      }}
    />
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/$acquirerId/transactions')(
  {
    beforeLoad: ({ context }) => {
      if (!context.userHasPermissions(['orders_read'])) {
        throw redirect({
          to: `/u/$localId/${context.userType}/forbidden`,
        });
      }
    },
    component: AcquirerTransactions,
  },
);
