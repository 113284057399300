import { useApi } from '@hooks/useApi';
import { StaffUser, ApiError } from '@root/globalTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { queryKeys } from '@utils/consts';

export type UserData = {
  email: string;
  name: string;
  permissions: string[];
};

export type UserResponse = StaffUser | ApiError;

export function isFailure(data: UserResponse): data is ApiError {
  return (data as ApiError)?.message !== undefined;
}

type CreateUserParams = {
  callback: (data: StaffUser) => void;
};

export function useCreate({ callback }: CreateUserParams) {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string>('');

  const { mutate, isPending } = useMutation({
    mutationFn: (userData: UserData): Promise<StaffUser> => {
      return query({
        path: 'v1/staff/users',
        data: userData,
        opt: {
          method: 'POST',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isFailure(data)) {
        setApiError(data.message);
      } else {
        callback(data);
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.staffUsers });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
}
