import { OrdersPreview } from '@pages/orders/preview';
import { useListAcquirerOrders } from '@services/staff/acquirer/orders/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { outletNavScroll } from '@utils/apiHelpers';
import { Loader } from '@components/loader';
import { useEffect } from 'react';

const AcquirerOrders = () => {
  const navigate = useNavigate();
  const { acquirerId, localId } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId/orders',
  });
  const { orders, loading } = useListAcquirerOrders(acquirerId);
  const handleSelect = (id: string) => {
    navigate({
      to: '/u/$localId/staff/orders/$id',
      params: { id, localId },
    }).catch((err) => console.error(err));
  };

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (loading) return <Loader />;

  return (
    <OrdersPreview
      onSelect={handleSelect}
      orders={orders}
      onViewMore={() => {
        navigate({
          to: '/u/$localId/staff/orders',
          params: { localId },
          /* eslint-disable camelcase */
          search: { acquirer_id: acquirerId },
        }).catch((err) => console.error(err));
      }}
    />
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/$acquirerId/orders')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['orders_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: AcquirerOrders,
});
