import { Button } from '@components/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { useAppContext } from '@hooks/useAppContext';
import { useLoginContext } from '@hooks/useLoginContext';
import { SecondStep } from './secondStep';
import { Recovery } from './recovery';
import { useAcquirerLoginForm } from '@hooks/acquirer/login';
import { useState } from 'react';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { Eye, EyeOff, Mail, User } from 'lucide-react';
import { getUserSlot } from '@utils/apiHelpers';

export const AcquirerLoginForm = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const router = useRouter();
  const { setShowSecondStep, setToken } = useLoginContext();
  const { handleLogin, sendBcPost } = useAppContext();

  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  function toggleInputType() {
    setInputType((ps) => (ps === 'password' ? 'text' : 'password'));
  }

  const { form, onSubmit, isPending, error } = useAcquirerLoginForm({
    successHandler: async (data) => {
      if (data.confirmed) {
        handleLogin({ loginData: data, userType: 'acquirer' });
        await router.invalidate();
        await navigate({
          to: '/u/$localId/acquirer',
          params: { localId: getUserSlot(data.user.id) },
        });
        if (sendBcPost) sendBcPost({ type: 'session', sender: getUserSlot(data.user.id) });
        toast.dismiss();
        toast.success(t('toast.session.success'));
      } else {
        setShowSecondStep(true);
        setToken(data.token);
      }
    },
  });

  return (
    <>
      <SecondStep />
      <Recovery />
      <Form {...form}>
        <form
          id="acquirerForm"
          className="flex w-full flex-col gap-4"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="acquirer"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.acquirerName.label')}</FormLabel>
                <FormControl>
                  <Input {...field} placeholder={t('form.acquirerName.label')}>
                    <User className="w-4 text-black/30" />
                  </Input>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.email.label')}</FormLabel>
                <FormControl>
                  <Input {...field} placeholder={t('form.email.label')}>
                    <Mail className="w-4 text-black/30" />
                  </Input>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.password.label')}</FormLabel>
                <FormControl>
                  <Input type={inputType} {...field} placeholder={t('form.password.label')}>
                    <button
                      type="button"
                      onClick={toggleInputType}
                      className="text-black/30 transition-colors hover:text-black">
                      {inputType === 'text' ? <EyeOff className="w-4" /> : <Eye className="w-4" />}
                    </button>
                  </Input>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {error && <p className="text-red">{error}</p>}
          <Button
            type="submit"
            variant="primary"
            size="lg"
            className="mt-8"
            disabled={isPending}
            loading={isPending}>
            {t('form.submit.login')}
          </Button>
        </form>
      </Form>
    </>
  );
};
