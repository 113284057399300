/* eslint-disable  */
export const pubSub = (() => {
  const topics: Record<PropertyKey, any> = {};
  const hOP = topics.hasOwnProperty;

  return {
    subscribe: (topic: PropertyKey, listener: any) => {
      // Create the topic's object if not yet created
      if (!hOP.call(topics, topic)) topics[topic] = [];

      // Add the listener to queue
      const index = topics[topic].push(listener) - 1;

      // Provide handle back for removal of topic
      return {
        remove: () => delete topics[topic][index],
      };
    },
    publish: (topic: PropertyKey, info?: any) => {
      // Leave if the topic doesn't exist, or there's no listeners in queue
      if (!hOP.call(topics, topic)) return;

      // Cycle through topics queue, fire!
      topics[topic].forEach((item: (arg0: any) => void) => {
        item(info !== undefined ? info : {});
      });
    },
  };
})();
