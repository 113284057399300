import { Loader } from '@components/loader';
import { TabLink } from '@components/tabLink';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { Page404 } from '@pages/404';
import { MerchantPage } from '@pages/merchants/id';
import { useGetMerchant } from '@services/staff/merchants/get';
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export function MerchantDetails() {
  const { verifyUserPermission } = useCheckUserPermissions();
  const { t } = useTranslation('app');
  const { localId, merchantId } = useParams({
    from: '/u/$localId/_staff/staff/merchants/$merchantId',
  });
  const navigate = useNavigate();
  const { data, isPending } = useGetMerchant(merchantId);

  const basePath = '/u/$localId/staff/merchants/$merchantId';

  if (isPending) return <Loader />;
  if (!data) return <Page404 />;

  return (
    <MerchantPage
      data={data}
      handleEditRedirect={() => {
        navigate({
          to: '/u/$localId/staff/merchants/$merchantId/edit',
          params: { localId, merchantId },
        }).catch((err) => console.error(err));
      }}
      tabs={[
        verifyUserPermission('orders_read') && (
          <TabLink
            key="orders"
            label={t('nav.orders')}
            path={`${basePath}/orders`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
        verifyUserPermission('orders_read') && (
          <TabLink
            key="transactions"
            label={t('nav.transactions')}
            path={`${basePath}/transactions`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
        verifyUserPermission('api_keys_read') && (
          <TabLink
            key="apiKeys"
            label={t('nav.apiKeys')}
            path={`${basePath}/api-keys`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
        verifyUserPermission('payment_controls_read') && (
          <TabLink
            key="paymentControls"
            label={t('nav.paymentControls')}
            path={`${basePath}/payment-controls/bin_range`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
        verifyUserPermission('merchant_users_read') && (
          <TabLink
            key="users"
            label={t('nav.users')}
            path={`${basePath}/users`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
        verifyUserPermission('merchant_links_read') && (
          <TabLink
            key="links"
            label={t('nav.links')}
            path={`${basePath}/links`}
            params={{ merchantId: data.merchantId, localId: localId || '' }}
          />
        ),
      ]}
    />
  );
}
export const Route = createFileRoute('/u/$localId/_staff/staff/merchants/$merchantId')({
  component: MerchantDetails,
});
