import { Loader } from '@components/loader';
import { AcquirerLinkPage } from '@pages/acquirerLinks/id';
import { useGetAcquirerLink } from '@services/staff/acquirer/links/get';
import { createFileRoute, redirect, useParams } from '@tanstack/react-router';

function AcquirerLink() {
  const { acquirerId, id } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId/links/$id/',
  });
  const { data, isPending } = useGetAcquirerLink({ acquirerId, id });

  if (isPending) return <Loader />;
  if (!data) return null;

  return <AcquirerLinkPage acquirerLink={data} acquirerId={acquirerId} />;
}

export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/$acquirerId/links/$id/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirer_links_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: AcquirerLink,
});
