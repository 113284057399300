import { useState } from 'react';
import { countries } from '@utils/countries';
import { Combobox } from '@components/combobox';
import { useTranslation } from 'react-i18next';

export function CountriesCombobox({
  value,
  onSelect,
  className,
}: {
  value: string;
  onSelect: (value: string | undefined) => void;
  className?: string;
}) {
  const { t } = useTranslation('app');
  const [filteredCountries, setFilteredCountries] = useState(countries);

  function handleSearch(input: string) {
    if (input.trim().length) {
      const byIsoCode = countries.filter(
        (country) => country.value.toLowerCase() === input.toLowerCase(),
      );

      const byName = countries.filter((country) =>
        country.label.toLowerCase().includes(input.toLowerCase()),
      );

      setFilteredCountries(Array.from(new Set([...byIsoCode, ...byName])));
    } else {
      setFilteredCountries(countries);
    }
  }

  return (
    <Combobox
      items={filteredCountries}
      defaultItems={countries}
      value={value}
      onSelect={onSelect}
      className={className}
      onSearch={handleSearch}
      noResultsMsg={t('form.country.noResultsMsg')}
      placeholder={t('form.country.placeholder')}
      searchPlaceholder={t('form.country.searchPlaceholder')}
    />
  );
}
