import { ChevronsUpDown } from 'lucide-react';
import { cn } from '@utils/cn';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@components/command';
import { Popover, PopoverContent, PopoverTrigger } from '@components/popover';
import { ScrollArea } from '@components/scrollArea';
import { useEffect, useMemo, useState } from 'react';
import { ComboBoxItemType } from '.';
import { Badge } from '@components/badge';
import { X } from 'lucide-react';

type ComboboxProps = {
  currentValues: string[];
  items: ComboBoxItemType[];
  defaultItems: ComboBoxItemType[];
  searchPlaceholder: string;
  noResultsMsg: string;
  placeholder?: string;
  className?: string;
  onSelect: (value: string[]) => void;
  onSearch?: (input: string) => void;
  defaultOpen?: boolean;
  id?: string;
};

export function MultiselectCombobox({
  currentValues,
  items,
  searchPlaceholder,
  noResultsMsg,
  placeholder,
  className,
  onSelect,
  onSearch,
  defaultOpen = false,
}: ComboboxProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const handleSearch = (input: string) => {
    setSearch(input);
    if (onSearch) {
      onSearch(input);
    }
  };

  function handleOpenClose(open: boolean) {
    handleSearch('');
    return setIsOpen(open);
  }

  function removeItem(item: string) {
    onSelect(currentValues.filter((v) => v !== item));
  }

  useEffect(() => {
    if (defaultOpen) setIsOpen(defaultOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedItems = useMemo(() => {
    return items.filter((item) => currentValues.includes(item.value));
  }, [items, currentValues]);

  const availableItems = useMemo(() => {
    return items.filter((item) => !currentValues.includes(item.value));
  }, [items, currentValues]);

  return (
    <Popover open={isOpen} onOpenChange={handleOpenClose} modal={true}>
      <PopoverTrigger asChild>
        <button
          role="combobox"
          aria-expanded={isOpen}
          className={cn(
            'text-md border-stroke disabled:bg-whiter flex w-full items-center justify-between rounded border-[1.5px] bg-transparent px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default [&>span]:line-clamp-1',
            className,
          )}>
          <div className="flex flex-row flex-wrap">
            {selectedItems.length
              ? selectedItems.map((item) => (
                  <Badge
                    className="m-1 flex items-center bg-gray-400 text-white"
                    key={item.value}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeItem(item.value);
                    }}>
                    {item.label}
                    <X size={16} className="ml-2" />
                  </Badge>
                ))
              : placeholder}
          </div>
          <ChevronsUpDown className="ml-2 ms-auto h-4 w-4 shrink-0 opacity-50" />
        </button>
      </PopoverTrigger>
      <PopoverContent
        style={{ width: 'var(--radix-popover-trigger-width)' }}
        className="popover-content-width-same-as-its-trigger p-0">
        <Command shouldFilter={onSearch === undefined}>
          <CommandInput
            value={search}
            placeholder={searchPlaceholder}
            onValueChange={handleSearch}
          />
          <ScrollArea className="max-h-[220px] overflow-auto">
            <CommandEmpty>{noResultsMsg}</CommandEmpty>
            <CommandGroup>
              {availableItems.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={() => {
                    handleSearch('');
                    onSelect([...currentValues, item.value]);
                  }}>
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
