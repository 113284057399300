import { Card, CardContent } from '@components/card';
import { BaseLayout } from '@layouts/base';
import { MerchantForm } from '@components/forms/merchants';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { useCreateMerchant } from '@services/acquirer/merchants/create';
import { useMerchantForm } from '@hooks/forms/merchants';

const NewAcquirerMerchant = () => {
  const { t } = useTranslation('app');
  const { localId } = useParams({ from: '/u/$localId/_acquirer/acquirer/merchants/new' });
  const navigate = useNavigate();

  const requestHandler = useCreateMerchant({
    successHandler: () => {
      toast.success(t('toast.merchants.createSuccess'));
      navigate({ to: '/u/$localId/acquirer/merchants', params: { localId }, replace: true }).catch(
        (err) => {
          console.error(err);
        },
      );
    },
  });

  const form = useMerchantForm({ requestHandler });

  return (
    <BaseLayout isChild title={t('pages.merchants.create')}>
      <Card>
        <CardContent className="p-6">
          <MerchantForm formObj={form} submitText={t('form.submit.merchantCreate')} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/merchants/new')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchants_create'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: NewAcquirerMerchant,
});
