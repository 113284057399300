import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { UpdateMerchantUserData } from '@services/types';
import { MerchantUser, MutateObject } from '@root/globalTypes';
import { useEffect } from 'react';

export function useEditMerchantUserForm({
  user,
  requestHandler,
}: {
  user?: MerchantUser;
  requestHandler: MutateObject<MerchantUser, UpdateMerchantUserData>;
}) {
  const { t } = useTranslation('app');

  const schema = z.object({
    name: z
      .string()
      .trim()
      .min(1, {
        message: t('form.name.required'),
      }),
    description: z.string(),
    permissions: z.string().array(),
  });

  const defaultValues = {
    name: user?.name || '',
    description: user?.description || '',
    permissions: user?.permissions || [],
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, error, isPending } = requestHandler;

  useEffect(() => {
    if (!error || !error.errors) return;

    Object.entries(error.errors).forEach(([key, value]) => {
      const k = key as keyof z.infer<typeof schema>;

      form.setError(k, { type: 'manual', message: value[0] });
    });
  }, [form, error]);

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    isPending,
    error: error?.message,
  };
}
