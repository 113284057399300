import { ExportVariants } from '@components/dialog/exportDialog';
import { useAppContext } from '@hooks/useAppContext';
import { useState } from 'react';

export type ExportParameters = {
  count?: number | '';
  columns?: string[];
};

export const useCreateExportFile = ({
  callback,
  merchantId,
  type,
}: {
  callback: () => void;
  merchantId?: string;
  type: ExportVariants;
}) => {
  const { token } = useAppContext();
  const acquirerPart = merchantId ? `acquirer/merchants/${merchantId}/` : '';
  const url = `${import.meta.env.VITE_API_URL}/v1/${acquirerPart}${type}_exports`;

  const [isPending, setIsPending] = useState(false);

  const mutate = async (data: ExportParameters) => {
    setIsPending(true);

    const filtersSearch = window.location.search;
    const urlSearch = Object.entries(data)
      .map(([k, v]) => {
        if (Array.isArray(v)) return v.map((vEl) => `${k}[]=${vEl}`).join('&');
        if (v) return `${k}=${v}`;
        return '';
      })
      .filter((el) => el)
      .join('&');

    const reqUrl = `${url}${filtersSearch}${filtersSearch ? '&' : '?'}${urlSearch}`;

    let filename = '';
    return fetch(reqUrl, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
        Accept: 'text/csv',
      },
      method: 'GET',
    })
      .then((response) => {
        const rawFilename = response.headers.get('Content-Disposition')?.split('"') || '';
        filename = rawFilename[1];
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        // eslint-disable-next-line immutable/no-mutation
        link.href = blobUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      })
      .finally(() => {
        if (typeof callback === 'function') callback();
        setIsPending(false);
      });
  };

  return {
    mutate,
    isPending,
  };
};
