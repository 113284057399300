export const timezones = [
  {
    value: 'Etc/GMT+12',
    label: 'International Date Line West',
    offset: '-12:00',
  },
  {
    value: 'Pacific/Pago_Pago',
    label: 'American Samoa',
    offset: '-11:00',
  },
  {
    value: 'Pacific/Midway',
    label: 'Midway Island',
    offset: '-11:00',
  },
  {
    value: 'Pacific/Honolulu',
    label: 'Hawaii',
    offset: '-10:00',
  },
  {
    value: 'America/Juneau',
    label: 'Alaska',
    offset: '-09:00',
  },
  {
    value: 'America/Los_Angeles',
    label: 'Pacific Time (US & Canada)',
    offset: '-08:00',
  },
  {
    value: 'America/Tijuana',
    label: 'Tijuana',
    offset: '-08:00',
  },
  {
    value: 'America/Phoenix',
    label: 'Arizona',
    offset: '-07:00',
  },
  {
    value: 'America/Mazatlan',
    label: 'Mazatlan',
    offset: '-07:00',
  },
  {
    value: 'America/Denver',
    label: 'Mountain Time (US & Canada)',
    offset: '-07:00',
  },
  {
    value: 'America/Guatemala',
    label: 'Central America',
    offset: '-06:00',
  },
  {
    value: 'America/Chicago',
    label: 'Central Time (US & Canada)',
    offset: '-06:00',
  },
  {
    value: 'America/Chihuahua',
    label: 'Chihuahua',
    offset: '-06:00',
  },
  {
    value: 'America/Mexico_City',
    label: 'Guadalajara, Mexico City',
    offset: '-06:00',
  },
  {
    value: 'America/Monterrey',
    label: 'Monterrey',
    offset: '-06:00',
  },
  {
    value: 'America/Regina',
    label: 'Saskatchewan',
    offset: '-06:00',
  },
  {
    value: 'America/Bogota',
    label: 'Bogota',
    offset: '-05:00',
  },
  {
    value: 'America/New_York',
    label: 'Eastern Time (US & Canada)',
    offset: '-05:00',
  },
  {
    value: 'America/Indiana/Indianapolis',
    label: 'Indiana (East)',
    offset: '-05:00',
  },
  {
    value: 'America/Lima',
    label: 'Lima, Quito',
    offset: '-05:00',
  },
  {
    value: 'America/Halifax',
    label: 'Atlantic Time (Canada)',
    offset: '-04:00',
  },
  {
    value: 'America/Caracas',
    label: 'Caracas',
    offset: '-04:00',
  },
  {
    value: 'America/Guyana',
    label: 'Georgetown',
    offset: '-04:00',
  },
  {
    value: 'America/La_Paz',
    label: 'La Paz',
    offset: '-04:00',
  },
  {
    value: 'America/Puerto_Rico',
    label: 'Puerto Rico',
    offset: '-04:00',
  },
  {
    value: 'America/Santiago',
    label: 'Santiago',
    offset: '-03:00',
  },
  {
    value: 'America/St_Johns',
    label: 'Newfoundland',
    offset: '-03:30',
  },
  {
    value: 'America/Sao_Paulo',
    label: 'Brasilia',
    offset: '-03:00',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'Buenos Aires',
    offset: '-03:00',
  },
  {
    value: 'America/Montevideo',
    label: 'Montevideo',
    offset: '-03:00',
  },
  {
    value: 'America/Godthab',
    label: 'Greenland',
    offset: '-02:00',
  },
  {
    value: 'Atlantic/South_Georgia',
    label: 'Mid-Atlantic',
    offset: '-02:00',
  },
  {
    value: 'Atlantic/Azores',
    label: 'Azores',
    offset: '-01:00',
  },
  {
    value: 'Atlantic/Cape_Verde',
    label: 'Cape Verde Is.',
    offset: '-01:00',
  },
  {
    value: 'Africa/Casablanca',
    label: 'Casablanca',
    offset: '+01:00',
  },
  {
    value: 'Europe/Dublin',
    label: 'Dublin',
    offset: '+00:00',
  },
  {
    value: 'Europe/London',
    label: 'Edinburgh, London',
    offset: '+00:00',
  },
  {
    value: 'Europe/Lisbon',
    label: 'Lisbon',
    offset: '+00:00',
  },
  {
    value: 'Africa/Monrovia',
    label: 'Monrovia',
    offset: '+00:00',
  },
  {
    value: 'Etc/UTC',
    label: 'UTC',
    offset: '+00:00',
  },
  {
    value: 'Europe/Amsterdam',
    label: 'Amsterdam',
    offset: '+01:00',
  },
  {
    value: 'Europe/Belgrade',
    label: 'Belgrade',
    offset: '+01:00',
  },
  {
    value: 'Europe/Berlin',
    label: 'Berlin',
    offset: '+01:00',
  },
  {
    value: 'Europe/Zurich',
    label: 'Bern, Zurich',
    offset: '+01:00',
  },
  {
    value: 'Europe/Bratislava',
    label: 'Bratislava',
    offset: '+01:00',
  },
  {
    value: 'Europe/Brussels',
    label: 'Brussels',
    offset: '+01:00',
  },
  {
    value: 'Europe/Budapest',
    label: 'Budapest',
    offset: '+01:00',
  },
  {
    value: 'Europe/Copenhagen',
    label: 'Copenhagen',
    offset: '+01:00',
  },
  {
    value: 'Europe/Ljubljana',
    label: 'Ljubljana',
    offset: '+01:00',
  },
  {
    value: 'Europe/Madrid',
    label: 'Madrid',
    offset: '+01:00',
  },
  {
    value: 'Europe/Paris',
    label: 'Paris',
    offset: '+01:00',
  },
  {
    value: 'Europe/Prague',
    label: 'Prague',
    offset: '+01:00',
  },
  {
    value: 'Europe/Rome',
    label: 'Rome',
    offset: '+01:00',
  },
  {
    value: 'Europe/Sarajevo',
    label: 'Sarajevo',
    offset: '+01:00',
  },
  {
    value: 'Europe/Skopje',
    label: 'Skopje',
    offset: '+01:00',
  },
  {
    value: 'Europe/Stockholm',
    label: 'Stockholm',
    offset: '+01:00',
  },
  {
    value: 'Europe/Vienna',
    label: 'Vienna',
    offset: '+01:00',
  },
  {
    value: 'Europe/Warsaw',
    label: 'Warsaw',
    offset: '+01:00',
  },
  {
    value: 'Africa/Algiers',
    label: 'West Central Africa',
    offset: '+01:00',
  },
  {
    value: 'Europe/Zagreb',
    label: 'Zagreb',
    offset: '+01:00',
  },
  {
    value: 'Europe/Athens',
    label: 'Athens',
    offset: '+02:00',
  },
  {
    value: 'Europe/Bucharest',
    label: 'Bucharest',
    offset: '+02:00',
  },
  {
    value: 'Africa/Cairo',
    label: 'Cairo',
    offset: '+02:00',
  },
  {
    value: 'Africa/Harare',
    label: 'Harare',
    offset: '+02:00',
  },
  {
    value: 'Europe/Helsinki',
    label: 'Helsinki',
    offset: '+02:00',
  },
  {
    value: 'Asia/Jerusalem',
    label: 'Jerusalem',
    offset: '+02:00',
  },
  {
    value: 'Europe/Kaliningrad',
    label: 'Kaliningrad',
    offset: '+02:00',
  },
  {
    value: 'Europe/Kiev',
    label: 'Kyiv',
    offset: '+02:00',
  },
  {
    value: 'Africa/Johannesburg',
    label: 'Pretoria',
    offset: '+02:00',
  },
  {
    value: 'Europe/Riga',
    label: 'Riga',
    offset: '+02:00',
  },
  {
    value: 'Europe/Sofia',
    label: 'Sofia',
    offset: '+02:00',
  },
  {
    value: 'Europe/Tallinn',
    label: 'Tallinn',
    offset: '+02:00',
  },
  {
    value: 'Europe/Vilnius',
    label: 'Vilnius',
    offset: '+02:00',
  },
  {
    value: 'Asia/Baghdad',
    label: 'Baghdad',
    offset: '+03:00',
  },
  {
    value: 'Europe/Istanbul',
    label: 'Istanbul',
    offset: '+03:00',
  },
  {
    value: 'Asia/Kuwait',
    label: 'Kuwait',
    offset: '+03:00',
  },
  {
    value: 'Europe/Minsk',
    label: 'Minsk',
    offset: '+03:00',
  },
  {
    value: 'Europe/Moscow',
    label: 'Moscow, St. Petersburg',
    offset: '+03:00',
  },
  {
    value: 'Africa/Nairobi',
    label: 'Nairobi',
    offset: '+03:00',
  },
  {
    value: 'Asia/Riyadh',
    label: 'Riyadh',
    offset: '+03:00',
  },
  {
    value: 'Europe/Volgograd',
    label: 'Volgograd',
    offset: '+03:00',
  },
  {
    value: 'Asia/Tehran',
    label: 'Tehran',
    offset: '+03:30',
  },
  {
    value: 'Asia/Muscat',
    label: 'Abu Dhabi, Muscat',
    offset: '+04:00',
  },
  {
    value: 'Asia/Baku',
    label: 'Baku',
    offset: '+04:00',
  },
  {
    value: 'Europe/Samara',
    label: 'Samara',
    offset: '+04:00',
  },
  {
    value: 'Asia/Tbilisi',
    label: 'Tbilisi',
    offset: '+04:00',
  },
  {
    value: 'Asia/Yerevan',
    label: 'Yerevan',
    offset: '+04:00',
  },
  {
    value: 'Asia/Kabul',
    label: 'Kabul',
    offset: '+04:30',
  },
  {
    value: 'Asia/Yekaterinburg',
    label: 'Ekaterinburg',
    offset: '+05:00',
  },
  {
    value: 'Asia/Karachi',
    label: 'Islamabad, Karachi',
    offset: '+05:00',
  },
  {
    value: 'Asia/Tashkent',
    label: 'Tashkent',
    offset: '+05:00',
  },
  {
    value: 'Asia/Kolkata',
    label: 'Chennai, Kolkata, Mumbai, New Delhi',
    offset: '+05:30',
  },
  {
    value: 'Asia/Colombo',
    label: 'Sri Jayawardenepura',
    offset: '+05:30',
  },
  {
    value: 'Asia/Kathmandu',
    label: 'Kathmandu',
    offset: '+05:45',
  },
  {
    value: 'Asia/Almaty',
    label: 'Almaty',
    offset: '+06:00',
  },
  {
    value: 'Asia/Dhaka',
    label: 'Astana, Dhaka',
    offset: '+06:00',
  },
  {
    value: 'Asia/Urumqi',
    label: 'Urumqi',
    offset: '+06:00',
  },
  {
    value: 'Asia/Rangoon',
    label: 'Rangoon',
    offset: '+06:30',
  },
  {
    value: 'Asia/Bangkok',
    label: 'Bangkok, Hanoi',
    offset: '+07:00',
  },
  {
    value: 'Asia/Jakarta',
    label: 'Jakarta',
    offset: '+07:00',
  },
  {
    value: 'Asia/Krasnoyarsk',
    label: 'Krasnoyarsk',
    offset: '+07:00',
  },
  {
    value: 'Asia/Novosibirsk',
    label: 'Novosibirsk',
    offset: '+07:00',
  },
  {
    value: 'Asia/Shanghai',
    label: 'Beijing',
    offset: '+08:00',
  },
  {
    value: 'Asia/Chongqing',
    label: 'Chongqing',
    offset: '+08:00',
  },
  {
    value: 'Asia/Hong_Kong',
    label: 'Hong Kong',
    offset: '+08:00',
  },
  {
    value: 'Asia/Irkutsk',
    label: 'Irkutsk',
    offset: '+08:00',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    label: 'Kuala Lumpur',
    offset: '+08:00',
  },
  {
    value: 'Australia/Perth',
    label: 'Perth',
    offset: '+08:00',
  },
  {
    value: 'Asia/Singapore',
    label: 'Singapore',
    offset: '+08:00',
  },
  {
    value: 'Asia/Taipei',
    label: 'Taipei',
    offset: '+08:00',
  },
  {
    value: 'Asia/Ulaanbaatar',
    label: 'Ulaanbaatar',
    offset: '+08:00',
  },
  {
    value: 'Asia/Tokyo',
    label: 'Osaka, Sapporo, Tokyo',
    offset: '+09:00',
  },
  {
    value: 'Asia/Seoul',
    label: 'Seoul',
    offset: '+09:00',
  },
  {
    value: 'Asia/Yakutsk',
    label: 'Yakutsk',
    offset: '+09:00',
  },
  {
    value: 'Australia/Adelaide',
    label: 'Adelaide',
    offset: '+10:30',
  },
  {
    value: 'Australia/Darwin',
    label: 'Darwin',
    offset: '+09:30',
  },
  {
    value: 'Australia/Brisbane',
    label: 'Brisbane',
    offset: '+10:00',
  },
  {
    value: 'Australia/Melbourne',
    label: 'Canberra, Melbourne',
    offset: '+11:00',
  },
  {
    value: 'Pacific/Guam',
    label: 'Guam',
    offset: '+10:00',
  },
  {
    value: 'Australia/Hobart',
    label: 'Hobart',
    offset: '+11:00',
  },
  {
    value: 'Pacific/Port_Moresby',
    label: 'Port Moresby',
    offset: '+10:00',
  },
  {
    value: 'Australia/Sydney',
    label: 'Sydney',
    offset: '+11:00',
  },
  {
    value: 'Asia/Vladivostok',
    label: 'Vladivostok',
    offset: '+10:00',
  },
  {
    value: 'Asia/Magadan',
    label: 'Magadan',
    offset: '+11:00',
  },
  {
    value: 'Pacific/Noumea',
    label: 'New Caledonia',
    offset: '+11:00',
  },
  {
    value: 'Pacific/Guadalcanal',
    label: 'Solomon Is.',
    offset: '+11:00',
  },
  {
    value: 'Asia/Srednekolymsk',
    label: 'Srednekolymsk',
    offset: '+11:00',
  },
  {
    value: 'Pacific/Auckland',
    label: 'Auckland, Wellington',
    offset: '+13:00',
  },
  {
    value: 'Pacific/Fiji',
    label: 'Fiji',
    offset: '+12:00',
  },
  {
    value: 'Asia/Kamchatka',
    label: 'Kamchatka',
    offset: '+12:00',
  },
  {
    value: 'Pacific/Majuro',
    label: 'Marshall Is.',
    offset: '+12:00',
  },
  {
    value: 'Pacific/Chatham',
    label: 'Chatham Is.',
    offset: '+13:45',
  },
  {
    value: 'Pacific/Tongatapu',
    label: "Nuku'alofa",
    offset: '+13:00',
  },
  {
    value: 'Pacific/Apia',
    label: 'Samoa',
    offset: '+13:00',
  },
  {
    value: 'Pacific/Fakaofo',
    label: 'Tokelau Is.',
    offset: '+13:00',
  },
];
