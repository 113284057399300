import { Button } from '@components/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import { useTranslation } from 'react-i18next';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@components/form/select';
import { BinRange } from './bin';
import { PCSelect } from './select';
import { PaymentControlsTypes } from '@utils/consts';
import { validateKeyDown } from '@utils/textHelpers';
import { usePaymentControlForm } from '@hooks/forms/paymentControls';

export const PaymentControlForm = ({
  type,
  formObj,
}: {
  type: PaymentControlsTypes;
  formObj: ReturnType<typeof usePaymentControlForm>;
}) => {
  const { t } = useTranslation('app');
  const textVariants = ['pan', 'fingerprint', 'ip'];

  const { form, onSubmit, error, isPending } = formObj;

  return (
    <Form {...form}>
      <form
        id="paymentControlsForm"
        className="flex w-full flex-col gap-4"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="identifier"
          render={({ field }) => (
            <FormItem>
              <FormLabel required className="static">
                {t('pages.paymentControls.identifier', {
                  type: t(`pages.paymentControls.${type}`),
                })}
              </FormLabel>
              {type === 'bin_range' && <BinRange form={form} field={field} />}
              {textVariants.includes(type) && (
                <FormControl>
                  <Input
                    type="text"
                    onKeyDown={(e) => {
                      if (type === 'bin_range' && !validateKeyDown(e, /[0-9]|,/))
                        e.preventDefault();
                    }}
                    {...field}
                  />
                </FormControl>
              )}
              <PCSelect field={field} internalType={type} />
              <p className="whitespace-pre-line font-mono text-sm text-neutral-400 empty:hidden">
                {t(`pages.paymentControls.tooltip.${type}`)}
              </p>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="activity"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('table.action')}</FormLabel>
              <FormControl>
                <Select value={field.value} defaultValue="allow" onValueChange={field.onChange}>
                  <SelectTrigger className="w-full">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="allow">{t('allow')}</SelectItem>
                    <SelectItem value="deny">{t('deny')}</SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {error && <p className="text-red">{error}</p>}
        <Button
          variant="primary"
          size="lg"
          type="submit"
          className="mt-8"
          disabled={isPending}
          loading={isPending}>
          {t('form.submit.confirm')}
        </Button>
      </form>
    </Form>
  );
};
