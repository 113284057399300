import { useApi } from '@hooks/useApi';
import { PaymentControlsTypes, queryKeys } from '@utils/consts';
import { isError, mapControlType } from '@utils/apiHelpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { ApiError, PaymentControl } from '@root/globalTypes';

export type PaymentControlApiParams = {
  identifier: string;
  activity: 'allow' | 'deny';
};

export const useCreatePaymentControl = ({
  type,
  acquirerId,
  callback,
}: {
  type: PaymentControlsTypes;
  acquirerId: string;
  callback: () => void;
}) => {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { mutate, isPending } = useMutation({
    mutationFn: (controlData: PaymentControlApiParams): Promise<PaymentControl | ApiError> => {
      return query({
        path: `v1/staff/acquirers/${acquirerId}/payment_controls/${mapControlType(type)}`,
        data: controlData,
        opt: {
          method: 'POST',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isError(data)) {
        setApiError(data);
      } else {
        callback();
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [...queryKeys.paymentControls, acquirerId, type],
      });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
};
