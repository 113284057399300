import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { Acquirer } from '@root/globalTypes';

export function useGet(id: string) {
  const { query } = useApi();

  const { data, isPending } = useQuery<Acquirer>({
    queryKey: [...queryKeys.staffAcquirers, id],
    queryFn: (): Promise<Acquirer> =>
      query({
        path: `v1/staff/acquirers/${id}`,
      }),
    enabled: Boolean(id),
  });

  return {
    data: data,
    isPending,
  };
}
