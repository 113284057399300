import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogHeader,
} from '@components/dialog';
import QRCode from 'react-qr-code';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  InputOTPSeparator,
} from '@components/form/inputOtp';
import { Button } from '@components/button';
import { useConfirmTwoFactorForm } from '@hooks/forms/twoFactor/confirm';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@components/form/form';
import { useTranslation } from 'react-i18next';
import { useTwoFactorSetupContext } from '@hooks/useTwoFactorSetupContext';

export function ConfirmDialog() {
  const { t } = useTranslation('app');
  const {
    qrCodeUri,
    setShowConfirmDialog,
    showConfirmDialog,
    setBackupCodes,
    setShowBackupCodesDialog,
  } = useTwoFactorSetupContext();
  const { form, onSubmit, error, isPending } = useConfirmTwoFactorForm({
    handleSuccess: (data) => {
      setBackupCodes(data.backupCodes);
      setShowConfirmDialog(false);
      setShowBackupCodesDialog(true);
    },
  });

  if (!qrCodeUri.length) return null;

  return (
    <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
      <DialogContent className="max-w-sm">
        <DialogHeader>
          <DialogTitle>{t('pages.settings.twoFactor.confirm.title')}</DialogTitle>
          <DialogDescription>{t('pages.settings.twoFactor.confirm.description')}</DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <QRCode className="h-75 w-75 self-center" value={qrCodeUri} />
          <Form {...form}>
            <form
              id="confirmTwoFactorForm"
              className="flex flex-col items-center gap-2"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="otpCode"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputOTP maxLength={6} {...field}>
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                        </InputOTPGroup>
                        <InputOTPSeparator />
                        <InputOTPGroup>
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {error && <p className="text-red">{error}</p>}
              <Button
                className="w-75"
                type="submit"
                variant="primary"
                disabled={isPending}
                loading={isPending}>
                {t('form.submit.confirm')}
              </Button>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
