import { Dispatch, SetStateAction, createContext } from 'react';

type LoginContextType = {
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
  showSecondStep: boolean;
  setShowSecondStep: Dispatch<SetStateAction<boolean>>;
  showRecovery: boolean;
  setShowRecovery: Dispatch<SetStateAction<boolean>>;
};

export const LoginContext = createContext<LoginContextType | null>(null);
