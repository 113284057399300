export const MERCHANT_CATEGORIES = [
  {
    value: '1520',
    label: '1520, General Contractors-Residential and Commercial',
  },
  {
    value: '1711',
    label:
      '1711, Air Conditioning Contractors – Sales and Installation, Heating Contractors – Sales, Service, Installation',
  },
  {
    value: '1731',
    label: '1731, Electrical Contractors',
  },
  {
    value: '1740',
    label:
      '1740, Insulation – Contractors, Masonry, Stonework Contractors, Plastering Contractors, Stonework and Masonry Contractors, Tile Settings Contractors',
  },
  {
    value: '1750',
    label: '1750, Carpentry Contractors',
  },
  {
    value: '1761',
    label: '1761, Roofing – Contractors, Sheet Metal Work – Contractors, Siding – Contractors',
  },
  {
    value: '1771',
    label: '1771, Contractors – Concrete Work',
  },
  {
    value: '1799',
    label: '1799, Contractors – Special Trade, Not Elsewhere Classified',
  },
  {
    value: '2741',
    label: '2741, Miscellaneous Publishing and Printing',
  },
  {
    value: '2791',
    label: '2791, Typesetting, Plate Making, & Related Services',
  },
  {
    value: '2842',
    label: '2842, Specialty Cleaning, Polishing, and Sanitation Preparations',
  },
  {
    value: '3000',
    label: '3000, UNITED AIRLINES',
  },
  {
    value: '3001',
    label: '3001, AMERICAN AIRLINES',
  },
  {
    value: '3002',
    label: '3002, PAN AMERICAN',
  },
  {
    value: '3003',
    label: '3003, Airlines',
  },
  {
    value: '3004',
    label: '3004, TRANS WORLD AIRLINES',
  },
  {
    value: '3005',
    label: '3005, BRITISH AIRWAYS',
  },
  {
    value: '3006',
    label: '3006, JAPAN AIRLINES',
  },
  {
    value: '3007',
    label: '3007, AIR FRANCE',
  },
  {
    value: '3008',
    label: '3008, LUFTHANSA',
  },
  {
    value: '3009',
    label: '3009, AIR CANADA',
  },
  {
    value: '3010',
    label: '3010, KLM (ROYAL DUTCH AIRLINES)',
  },
  {
    value: '3011',
    label: '3011, AEORFLOT',
  },
  {
    value: '3012',
    label: '3012, QANTAS',
  },
  {
    value: '3013',
    label: '3013, ALITALIA',
  },
  {
    value: '3014',
    label: '3014, SAUDIA ARABIAN AIRLINES',
  },
  {
    value: '3015',
    label: '3015, SWISSAIR',
  },
  {
    value: '3016',
    label: '3016, SAS',
  },
  {
    value: '3017',
    label: '3017, SOUTH AFRICAN AIRWAYS',
  },
  {
    value: '3018',
    label: '3018, VARIG (BRAZIL)',
  },
  {
    value: '3019',
    label: '3019, Airlines',
  },
  {
    value: '3020',
    label: '3020, AIR-INDIA',
  },
  {
    value: '3021',
    label: '3021, AIR ALGERIE',
  },
  {
    value: '3022',
    label: '3022, PHILIPPINE AIRLINES',
  },
  {
    value: '3023',
    label: '3023, MEXICANA',
  },
  {
    value: '3024',
    label: '3024, PAKISTAN INTERNATIONAL',
  },
  {
    value: '3025',
    label: '3025, AIR NEW ZEALAND',
  },
  {
    value: '3026',
    label: '3026, Airlines',
  },
  {
    value: '3027',
    label: '3027, UTA/INTERAIR',
  },
  {
    value: '3028',
    label: '3028, AIR MALTA',
  },
  {
    value: '3029',
    label: '3029, SABENA',
  },
  {
    value: '3030',
    label: '3030, AEROLINEAS ARGENTINAS',
  },
  {
    value: '3031',
    label: '3031, OLYMPIC AIRWAYS',
  },
  {
    value: '3032',
    label: '3032, EL AL',
  },
  {
    value: '3033',
    label: '3033, ANSETT AIRLINES',
  },
  {
    value: '3034',
    label: '3034, AUSTRAINLIAN AIRLINES',
  },
  {
    value: '3035',
    label: '3035, TAP (PORTUGAL)',
  },
  {
    value: '3036',
    label: '3036, VASP (BRAZIL)',
  },
  {
    value: '3037',
    label: '3037, EGYPTAIR',
  },
  {
    value: '3038',
    label: '3038, KUWAIT AIRLINES',
  },
  {
    value: '3039',
    label: '3039, AVIANCA',
  },
  {
    value: '3040',
    label: '3040, GULF AIR (BAHRAIN)',
  },
  {
    value: '3041',
    label: '3041, BALKAN-BULGARIAN AIRLINES',
  },
  {
    value: '3042',
    label: '3042, FINNAIR',
  },
  {
    value: '3043',
    label: '3043, AER LINGUS',
  },
  {
    value: '3044',
    label: '3044, AIR LANKA',
  },
  {
    value: '3045',
    label: '3045, NIGERIA AIRWAYS',
  },
  {
    value: '3046',
    label: '3046, CRUZEIRO DO SUL (BRAZIJ)',
  },
  {
    value: '3047',
    label: '3047, THY (TURKEY)',
  },
  {
    value: '3048',
    label: '3048, ROYAL AIR MAROC',
  },
  {
    value: '3049',
    label: '3049, TUNIS AIR',
  },
  {
    value: '3050',
    label: '3050, ICELANDAIR',
  },
  {
    value: '3051',
    label: '3051, AUSTRIAN AIRLINES',
  },
  {
    value: '3052',
    label: '3052, LANCHILE',
  },
  {
    value: '3053',
    label: '3053, AVIACO (SPAIN)',
  },
  {
    value: '3054',
    label: '3054, LADECO (CHILE)',
  },
  {
    value: '3055',
    label: '3055, LAB (BOLIVIA)',
  },
  {
    value: '3056',
    label: '3056, QUEBECAIRE',
  },
  {
    value: '3057',
    label: '3057, EASTWEST AIRLINES (AUSTRALIA)',
  },
  {
    value: '3058',
    label: '3058, DELTA',
  },
  {
    value: '3059',
    label: '3059, Airlines',
  },
  {
    value: '3060',
    label: '3060, NORTHWEST',
  },
  {
    value: '3061',
    label: '3061, CONTINENTAL',
  },
  {
    value: '3062',
    label: '3062, WESTERN',
  },
  {
    value: '3063',
    label: '3063, US AIR',
  },
  {
    value: '3064',
    label: '3064, Airlines',
  },
  {
    value: '3065',
    label: '3065, AIRINTER',
  },
  {
    value: '3066',
    label: '3066, SOUTHWEST',
  },
  {
    value: '3067',
    label: '3067, Airlines',
  },
  {
    value: '3068',
    label: '3068, Airlines',
  },
  {
    value: '3069',
    label: '3069, SUN COUNTRY AIRLINES',
  },
  {
    value: '3070',
    label: '3070, Airlines',
  },
  {
    value: '3071',
    label: '3071, AIR BRITISH COLUBIA',
  },
  {
    value: '3072',
    label: '3072, Airlines',
  },
  {
    value: '3073',
    label: '3073, Airlines',
  },
  {
    value: '3074',
    label: '3074, Airlines',
  },
  {
    value: '3075',
    label: '3075, SINGAPORE AIRLINES',
  },
  {
    value: '3076',
    label: '3076, AEROMEXICO',
  },
  {
    value: '3077',
    label: '3077, THAI AIRWAYS',
  },
  {
    value: '3078',
    label: '3078, CHINA AIRLINES',
  },
  {
    value: '3079',
    label: '3079, Airlines',
  },
  {
    value: '3080',
    label: '3080, Airlines',
  },
  {
    value: '3081',
    label: '3081, NORDAIR',
  },
  {
    value: '3082',
    label: '3082, KOREAN AIRLINES',
  },
  {
    value: '3083',
    label: '3083, AIR AFRIGUE',
  },
  {
    value: '3084',
    label: '3084, EVA AIRLINES',
  },
  {
    value: '3085',
    label: '3085, MIDWEST EXPRESS AIRLINES, INC.',
  },
  {
    value: '3086',
    label: '3086, Airlines',
  },
  {
    value: '3087',
    label: '3087, METRO AIRLINES',
  },
  {
    value: '3088',
    label: '3088, CROATIA AIRLINES',
  },
  {
    value: '3089',
    label: '3089, TRANSAERO',
  },
  {
    value: '3090',
    label: '3090, Airlines',
  },
  {
    value: '3091',
    label: '3091, Airlines',
  },
  {
    value: '3092',
    label: '3092, Airlines',
  },
  {
    value: '3093',
    label: '3093, Airlines',
  },
  {
    value: '3094',
    label: '3094, ZAMBIA AIRWAYS',
  },
  {
    value: '3095',
    label: '3095, Airlines',
  },
  {
    value: '3096',
    label: '3096, AIR ZIMBABWE',
  },
  {
    value: '3097',
    label: '3097, Airlines',
  },
  {
    value: '3098',
    label: '3098, Airlines',
  },
  {
    value: '3099',
    label: '3099, CATHAY PACIFIC',
  },
  {
    value: '3100',
    label: '3100, MALAYSIAN AIRLINE SYSTEM',
  },
  {
    value: '3101',
    label: '3101, Airlines',
  },
  {
    value: '3102',
    label: '3102, IBERIA',
  },
  {
    value: '3103',
    label: '3103, GARUDA (INDONESIA)',
  },
  {
    value: '3104',
    label: '3104, Airlines',
  },
  {
    value: '3105',
    label: '3105, Airlines',
  },
  {
    value: '3106',
    label: '3106, BRAATHENS S.A.F.E. (NORWAY)',
  },
  {
    value: '3107',
    label: '3107, Airlines',
  },
  {
    value: '3108',
    label: '3108, Airlines',
  },
  {
    value: '3109',
    label: '3109, Airlines',
  },
  {
    value: '3110',
    label: '3110, WINGS AIRWAYS',
  },
  {
    value: '3111',
    label: '3111, BRITISH MIDLAND',
  },
  {
    value: '3112',
    label: '3112, WINDWARD ISLAND',
  },
  {
    value: '3113',
    label: '3113, Airlines',
  },
  {
    value: '3114',
    label: '3114, Airlines',
  },
  {
    value: '3115',
    label: '3115, Airlines',
  },
  {
    value: '3116',
    label: '3116, Airlines',
  },
  {
    value: '3117',
    label: '3117, VIASA',
  },
  {
    value: '3118',
    label: '3118, VALLEY AIRLINES',
  },
  {
    value: '3119',
    label: '3119, Airlines',
  },
  {
    value: '3120',
    label: '3120, Airlines',
  },
  {
    value: '3121',
    label: '3121, Airlines',
  },
  {
    value: '3122',
    label: '3122, Airlines',
  },
  {
    value: '3123',
    label: '3123, Airlines',
  },
  {
    value: '3124',
    label: '3124, Airlines',
  },
  {
    value: '3125',
    label: '3125, TAN',
  },
  {
    value: '3126',
    label: '3126, TALAIR',
  },
  {
    value: '3127',
    label: '3127, TACA INTERNATIONAL',
  },
  {
    value: '3128',
    label: '3128, Airlines',
  },
  {
    value: '3129',
    label: '3129, SURINAM AIRWAYS',
  },
  {
    value: '3130',
    label: '3130, SUN WORLD INTERNATIONAL',
  },
  {
    value: '3131',
    label: '3131, Airlines',
  },
  {
    value: '3132',
    label: '3132, Airlines',
  },
  {
    value: '3133',
    label: '3133, SUNBELT AIRLINES',
  },
  {
    value: '3134',
    label: '3134, Airlines',
  },
  {
    value: '3135',
    label: '3135, SUDAN AIRWAYS',
  },
  {
    value: '3136',
    label: '3136, Airlines',
  },
  {
    value: '3137',
    label: '3137, SINGLETON',
  },
  {
    value: '3138',
    label: '3138, SIMMONS AIRLINES',
  },
  {
    value: '3139',
    label: '3139, Airlines',
  },
  {
    value: '3140',
    label: '3140, Airlines',
  },
  {
    value: '3141',
    label: '3141, Airlines',
  },
  {
    value: '3142',
    label: '3142, Airlines',
  },
  {
    value: '3143',
    label: '3143, SCENIC AIRLINES',
  },
  {
    value: '3144',
    label: '3144, VIRGIN ATLANTIC',
  },
  {
    value: '3145',
    label: '3145, SAN JUAN AIRLINES',
  },
  {
    value: '3146',
    label: '3146, LUXAIR',
  },
  {
    value: '3147',
    label: '3147, Airlines',
  },
  {
    value: '3148',
    label: '3148, Airlines',
  },
  {
    value: '3149',
    label: '3149, Airlines',
  },
  {
    value: '3150',
    label: '3150, Airlines',
  },
  {
    value: '3151',
    label: '3151, AIR ZAIRE',
  },
  {
    value: '3152',
    label: '3152, Airlines',
  },
  {
    value: '3153',
    label: '3153, Airlines',
  },
  {
    value: '3154',
    label: '3154, PRINCEVILLE',
  },
  {
    value: '3155',
    label: '3155, Airlines',
  },
  {
    value: '3156',
    label: '3156, Airlines',
  },
  {
    value: '3157',
    label: '3157, Airlines',
  },
  {
    value: '3158',
    label: '3158, Airlines',
  },
  {
    value: '3159',
    label: '3159, PBA',
  },
  {
    value: '3160',
    label: '3160, Airlines',
  },
  {
    value: '3161',
    label: '3161, ALL NIPPON AIRWAYS',
  },
  {
    value: '3162',
    label: '3162, Airlines',
  },
  {
    value: '3163',
    label: '3163, Airlines',
  },
  {
    value: '3164',
    label: '3164, NORONTAIR',
  },
  {
    value: '3165',
    label: '3165, NEW YORK HELICOPTER',
  },
  {
    value: '3166',
    label: '3166, Airlines',
  },
  {
    value: '3167',
    label: '3167, Airlines',
  },
  {
    value: '3168',
    label: '3168, Airlines',
  },
  {
    value: '3169',
    label: '3169, Airlines',
  },
  {
    value: '3170',
    label: '3170, NOUNT COOK',
  },
  {
    value: '3171',
    label: '3171, CANADIAN AIRLINES INTERNATIONAL',
  },
  {
    value: '3172',
    label: '3172, NATIONAIR',
  },
  {
    value: '3173',
    label: '3173, Airlines',
  },
  {
    value: '3174',
    label: '3174, Airlines',
  },
  {
    value: '3175',
    label: '3175, Airlines',
  },
  {
    value: '3176',
    label: '3176, METROFLIGHT AIRLINES',
  },
  {
    value: '3177',
    label: '3177, Airlines',
  },
  {
    value: '3178',
    label: '3178, MESA AIR',
  },
  {
    value: '3179',
    label: '3179, Airlines',
  },
  {
    value: '3180',
    label: '3180, Airlines',
  },
  {
    value: '3181',
    label: '3181, MALEV',
  },
  {
    value: '3182',
    label: '3182, LOT (POLAND)',
  },
  {
    value: '3183',
    label: '3183, Airlines',
  },
  {
    value: '3184',
    label: '3184, LIAT',
  },
  {
    value: '3185',
    label: '3185, LAV (VENEZUELA)',
  },
  {
    value: '3186',
    label: '3186, LAP (PARAGUAY)',
  },
  {
    value: '3187',
    label: '3187, LACSA (COSTA RICA)',
  },
  {
    value: '3188',
    label: '3188, Airlines',
  },
  {
    value: '3189',
    label: '3189, Airlines',
  },
  {
    value: '3190',
    label: '3190, JUGOSLAV AIR',
  },
  {
    value: '3191',
    label: '3191, ISLAND AIRLINES',
  },
  {
    value: '3192',
    label: '3192, IRAN AIR',
  },
  {
    value: '3193',
    label: '3193, INDIAN AIRLINES',
  },
  {
    value: '3194',
    label: '3194, Airlines',
  },
  {
    value: '3195',
    label: '3195, Airlines',
  },
  {
    value: '3196',
    label: '3196, HAWAIIAN AIR',
  },
  {
    value: '3197',
    label: '3197, HAVASU AIRLINES',
  },
  {
    value: '3198',
    label: '3198, Airlines',
  },
  {
    value: '3199',
    label: '3199, Airlines',
  },
  {
    value: '3200',
    label: '3200, FUYANA AIRWAYS',
  },
  {
    value: '3201',
    label: '3201, Airlines',
  },
  {
    value: '3202',
    label: '3202, Airlines',
  },
  {
    value: '3203',
    label: '3203, GOLDEN PACIFIC AIR',
  },
  {
    value: '3204',
    label: '3204, FREEDOM AIR',
  },
  {
    value: '3205',
    label: '3205, Airlines',
  },
  {
    value: '3206',
    label: '3206, Airlines',
  },
  {
    value: '3207',
    label: '3207, Airlines',
  },
  {
    value: '3208',
    label: '3208, Airlines',
  },
  {
    value: '3209',
    label: '3209, Airlines',
  },
  {
    value: '3210',
    label: '3210, Airlines',
  },
  {
    value: '3211',
    label: '3211, Airlines',
  },
  {
    value: '3212',
    label: '3212, DOMINICANA',
  },
  {
    value: '3213',
    label: '3213, Airlines',
  },
  {
    value: '3214',
    label: '3214, Airlines',
  },
  {
    value: '3215',
    label: '3215, DAN AIR SERVICES',
  },
  {
    value: '3216',
    label: '3216, CUMBERLAND AIRLINES',
  },
  {
    value: '3217',
    label: '3217, CSA',
  },
  {
    value: '3218',
    label: '3218, CROWN AIR',
  },
  {
    value: '3219',
    label: '3219, COPA',
  },
  {
    value: '3220',
    label: '3220, COMPANIA FAUCETT',
  },
  {
    value: '3221',
    label: '3221, TRANSPORTES AEROS MILITARES ECCUATORANOS',
  },
  {
    value: '3222',
    label: '3222, COMMAND AIRWAYS',
  },
  {
    value: '3223',
    label: '3223, COMAIR',
  },
  {
    value: '3224',
    label: '3224, Airlines',
  },
  {
    value: '3225',
    label: '3225, Airlines',
  },
  {
    value: '3226',
    label: '3226, Airlines',
  },
  {
    value: '3227',
    label: '3227, Airlines',
  },
  {
    value: '3228',
    label: '3228, CAYMAN AIRWAYS',
  },
  {
    value: '3229',
    label: '3229, SAETA SOCIAEDAD ECUATORIANOS DE TRANSPORTES AEREOS',
  },
  {
    value: '3230',
    label: '3230, Airlines',
  },
  {
    value: '3231',
    label: '3231, SASHA SERVICIO AERO DE HONDURAS',
  },
  {
    value: '3232',
    label: '3232, Airlines',
  },
  {
    value: '3233',
    label: '3233, CAPITOL AIR',
  },
  {
    value: '3234',
    label: '3234, BWIA',
  },
  {
    value: '3235',
    label: '3235, BROKWAY AIR',
  },
  {
    value: '3236',
    label: '3236, Airlines',
  },
  {
    value: '3237',
    label: '3237, Airlines',
  },
  {
    value: '3238',
    label: '3238, BEMIDJI AIRLINES',
  },
  {
    value: '3239',
    label: '3239, BAR HARBOR AIRLINES',
  },
  {
    value: '3240',
    label: '3240, BAHAMASAIR',
  },
  {
    value: '3241',
    label: '3241, AVIATECA (GUATEMALA)',
  },
  {
    value: '3242',
    label: '3242, AVENSA',
  },
  {
    value: '3243',
    label: '3243, AUSTRIAN AIR SERVICE',
  },
  {
    value: '3244',
    label: '3244, Airlines',
  },
  {
    value: '3245',
    label: '3245, Airlines',
  },
  {
    value: '3246',
    label: '3246, Airlines',
  },
  {
    value: '3247',
    label: '3247, Airlines',
  },
  {
    value: '3248',
    label: '3248, Airlines',
  },
  {
    value: '3249',
    label: '3249, Airlines',
  },
  {
    value: '3250',
    label: '3250, Airlines',
  },
  {
    value: '3251',
    label: '3251, ALOHA AIRLINES',
  },
  {
    value: '3252',
    label: '3252, ALM',
  },
  {
    value: '3253',
    label: '3253, AMERICA WEST',
  },
  {
    value: '3254',
    label: '3254, TRUMP AIRLINE',
  },
  {
    value: '3255',
    label: '3255, Airlines',
  },
  {
    value: '3256',
    label: '3256, ALASKA AIRLINES',
  },
  {
    value: '3257',
    label: '3257, Airlines',
  },
  {
    value: '3258',
    label: '3258, Airlines',
  },
  {
    value: '3259',
    label: '3259, AMERICAN TRANS AIR',
  },
  {
    value: '3260',
    label: '3260, Airlines',
  },
  {
    value: '3261',
    label: '3261, AIR CHINA',
  },
  {
    value: '3262',
    label: '3262, RENO AIR, INC.',
  },
  {
    value: '3263',
    label: '3263, Airlines',
  },
  {
    value: '3264',
    label: '3264, Airlines',
  },
  {
    value: '3265',
    label: '3265, Airlines',
  },
  {
    value: '3266',
    label: '3266, AIR SEYCHELLES',
  },
  {
    value: '3267',
    label: '3267, AIR PANAMA',
  },
  {
    value: '3268',
    label: '3268, Airlines',
  },
  {
    value: '3269',
    label: '3269, Airlines',
  },
  {
    value: '3270',
    label: '3270, Airlines',
  },
  {
    value: '3271',
    label: '3271, Airlines',
  },
  {
    value: '3272',
    label: '3272, Airlines',
  },
  {
    value: '3273',
    label: '3273, Airlines',
  },
  {
    value: '3274',
    label: '3274, Airlines',
  },
  {
    value: '3275',
    label: '3275, Airlines',
  },
  {
    value: '3276',
    label: '3276, Airlines',
  },
  {
    value: '3277',
    label: '3277, Airlines',
  },
  {
    value: '3278',
    label: '3278, Airlines',
  },
  {
    value: '3279',
    label: '3279, Airlines',
  },
  {
    value: '3280',
    label: '3280, AIR JAMAICA',
  },
  {
    value: '3281',
    label: '3281, Airlines',
  },
  {
    value: '3282',
    label: '3282, AIR DJIBOUTI',
  },
  {
    value: '3283',
    label: '3283, Airlines',
  },
  {
    value: '3284',
    label: '3284, AERO VIRGIN ISLANDS',
  },
  {
    value: '3285',
    label: '3285, AERO PERU',
  },
  {
    value: '3286',
    label: '3286, AEROLINEAS NICARAGUENSIS',
  },
  {
    value: '3287',
    label: '3287, AERO COACH AVAIATION',
  },
  {
    value: '3288',
    label: '3288, Airlines',
  },
  {
    value: '3289',
    label: '3289, Airlines',
  },
  {
    value: '3290',
    label: '3290, Airlines',
  },
  {
    value: '3291',
    label: '3291, ARIANA AFGHAN',
  },
  {
    value: '3292',
    label: '3292, CYPRUS AIRWAYS',
  },
  {
    value: '3293',
    label: '3293, ECUATORIANA',
  },
  {
    value: '3294',
    label: '3294, ETHIOPIAN AIRLINES',
  },
  {
    value: '3295',
    label: '3295, KENYA AIRLINES',
  },
  {
    value: '3296',
    label: '3296, Airlines',
  },
  {
    value: '3297',
    label: '3297, Airlines',
  },
  {
    value: '3298',
    label: '3298, AIR MAURITIUS',
  },
  {
    value: '3299',
    label: '3299, WIDERO’S FLYVESELSKAP',
  },
  {
    value: '3351',
    label: '3351, AFFILIATED AUTO RENTAL',
  },
  {
    value: '3352',
    label: '3352, AMERICAN INTL RENT-A-CAR',
  },
  {
    value: '3353',
    label: '3353, BROOKS RENT-A-CAR',
  },
  {
    value: '3354',
    label: '3354, ACTION AUTO RENTAL',
  },
  {
    value: '3355',
    label: '3355, Car Rental',
  },
  {
    value: '3356',
    label: '3356, Car Rental',
  },
  {
    value: '3357',
    label: '3357, HERTZ RENT-A-CAR',
  },
  {
    value: '3358',
    label: '3358, Car Rental',
  },
  {
    value: '3359',
    label: '3359, PAYLESS CAR RENTAL',
  },
  {
    value: '3360',
    label: '3360, SNAPPY CAR RENTAL',
  },
  {
    value: '3361',
    label: '3361, AIRWAYS RENT-A-CAR',
  },
  {
    value: '3362',
    label: '3362, ALTRA AUTO RENTAL',
  },
  {
    value: '3363',
    label: '3363, Car Rental',
  },
  {
    value: '3364',
    label: '3364, AGENCY RENT-A-CAR',
  },
  {
    value: '3365',
    label: '3365, Car Rental',
  },
  {
    value: '3366',
    label: '3366, BUDGET RENT-A-CAR',
  },
  {
    value: '3367',
    label: '3367, Car Rental',
  },
  {
    value: '3368',
    label: '3368, HOLIDAY RENT-A-WRECK',
  },
  {
    value: '3369',
    label: '3369, Car Rental',
  },
  {
    value: '3370',
    label: '3370, RENT-A-WRECK',
  },
  {
    value: '3371',
    label: '3371, Car Rental',
  },
  {
    value: '3372',
    label: '3372, Car Rental',
  },
  {
    value: '3373',
    label: '3373, Car Rental',
  },
  {
    value: '3374',
    label: '3374, Car Rental',
  },
  {
    value: '3375',
    label: '3375, Car Rental',
  },
  {
    value: '3376',
    label: '3376, AJAX RENT-A-CAR',
  },
  {
    value: '3377',
    label: '3377, Car Rental',
  },
  {
    value: '3378',
    label: '3378, Car Rental',
  },
  {
    value: '3379',
    label: '3379, Car Rental',
  },
  {
    value: '3380',
    label: '3380, Car Rental',
  },
  {
    value: '3381',
    label: '3381, EUROP CAR',
  },
  {
    value: '3382',
    label: '3382, Car Rental',
  },
  {
    value: '3383',
    label: '3383, Car Rental',
  },
  {
    value: '3384',
    label: '3384, Car Rental',
  },
  {
    value: '3385',
    label: '3385, TROPICAL RENT-A-CAR',
  },
  {
    value: '3386',
    label: '3386, SHOWCASE RENTAL CARS',
  },
  {
    value: '3387',
    label: '3387, ALAMO RENT-A-CAR',
  },
  {
    value: '3388',
    label: '3388, Car Rental',
  },
  {
    value: '3389',
    label: '3389, AVIS RENT-A-CAR',
  },
  {
    value: '3390',
    label: '3390, DOLLAR RENT-A-CAR',
  },
  {
    value: '3391',
    label: '3391, EUROPE BY CAR',
  },
  {
    value: '3392',
    label: '3392, Car Rental',
  },
  {
    value: '3393',
    label: '3393, NATIONAL CAR RENTAL',
  },
  {
    value: '3394',
    label: '3394, KEMWELL GROUP RENT-A-CAR',
  },
  {
    value: '3395',
    label: '3395, THRIFTY RENT-A-CAR',
  },
  {
    value: '3396',
    label: '3396, TILDEN TENT-A-CAR',
  },
  {
    value: '3397',
    label: '3397, Car Rental',
  },
  {
    value: '3398',
    label: '3398, ECONO-CAR RENT-A-CAR',
  },
  {
    value: '3399',
    label: '3399, Car Rental',
  },
  {
    value: '3400',
    label: '3400, AUTO HOST COST CAR RENTALS',
  },
  {
    value: '3401',
    label: '3401, Car Rental',
  },
  {
    value: '3402',
    label: '3402, Car Rental',
  },
  {
    value: '3403',
    label: '3403, Car Rental',
  },
  {
    value: '3404',
    label: '3404, Car Rental',
  },
  {
    value: '3405',
    label: '3405, ENTERPRISE RENT-A-CAR',
  },
  {
    value: '3406',
    label: '3406, Car Rental',
  },
  {
    value: '3407',
    label: '3407, Car Rental',
  },
  {
    value: '3408',
    label: '3408, Car Rental',
  },
  {
    value: '3409',
    label: '3409, GENERAL RENT-A-CAR',
  },
  {
    value: '3410',
    label: '3410, Car Rental',
  },
  {
    value: '3411',
    label: '3411, Car Rental',
  },
  {
    value: '3412',
    label: '3412, A-1 RENT-A-CAR',
  },
  {
    value: '3413',
    label: '3413, Car Rental',
  },
  {
    value: '3414',
    label: '3414, GODFREY NATL RENT-A-CAR',
  },
  {
    value: '3415',
    label: '3415, Car Rental',
  },
  {
    value: '3416',
    label: '3416, Car Rental',
  },
  {
    value: '3417',
    label: '3417, Car Rental',
  },
  {
    value: '3418',
    label: '3418, Car Rental',
  },
  {
    value: '3419',
    label: '3419, ALPHA RENT-A-CAR',
  },
  {
    value: '3420',
    label: '3420, ANSA INTL RENT-A-CAR',
  },
  {
    value: '3421',
    label: '3421, ALLSTAE RENT-A-CAR',
  },
  {
    value: '3422',
    label: '3422, Car Rental',
  },
  {
    value: '3423',
    label: '3423, AVCAR RENT-A-CAR',
  },
  {
    value: '3424',
    label: '3424, Car Rental',
  },
  {
    value: '3425',
    label: '3425, AUTOMATE RENT-A-CAR',
  },
  {
    value: '3426',
    label: '3426, Car Rental',
  },
  {
    value: '3427',
    label: '3427, AVON RENT-A-CAR',
  },
  {
    value: '3428',
    label: '3428, CAREY RENT-A-CAR',
  },
  {
    value: '3429',
    label: '3429, INSURANCE RENT-A-CAR',
  },
  {
    value: '3430',
    label: '3430, MAJOR RENT-A-CAR',
  },
  {
    value: '3431',
    label: '3431, REPLACEMENT RENT-A-CAR',
  },
  {
    value: '3432',
    label: '3432, RESERVE RENT-A-CAR',
  },
  {
    value: '3433',
    label: '3433, UGLY DUCKLING RENT-A-CAR',
  },
  {
    value: '3434',
    label: '3434, USA RENT-A-CAR',
  },
  {
    value: '3435',
    label: '3435, VALUE RENT-A-CAR',
  },
  {
    value: '3436',
    label: '3436, AUTOHANSA RENT-A-CAR',
  },
  {
    value: '3437',
    label: '3437, CITE RENT-A-CAR',
  },
  {
    value: '3438',
    label: '3438, INTERENT RENT-A-CAR',
  },
  {
    value: '3439',
    label: '3439, MILLEVILLE RENT-A-CAR',
  },
  {
    value: '3440',
    label: '3440, VIA ROUTE RENT-A-CAR',
  },
  {
    value: '3441',
    label: '3441, Car Rental',
  },
  {
    value: '3501',
    label: '3501, HOLIDAY INNS, HOLIDAY INN EXPRESS',
  },
  {
    value: '3502',
    label: '3502, BEST WESTERN HOTELS',
  },
  {
    value: '3503',
    label: '3503, SHERATON HOTELS',
  },
  {
    value: '3504',
    label: '3504, HILTON HOTELS',
  },
  {
    value: '3505',
    label: '3505, FORTE HOTELS',
  },
  {
    value: '3506',
    label: '3506, GOLDEN TULIP HOTELS',
  },
  {
    value: '3507',
    label: '3507, FRIENDSHIP INNS',
  },
  {
    value: '3508',
    label: '3508, QUALITY INNS, QUALITY SUITES',
  },
  {
    value: '3509',
    label: '3509, MARRIOTT HOTELS',
  },
  {
    value: '3510',
    label: '3510, DAYS INN, DAYSTOP',
  },
  {
    value: '3511',
    label: '3511, ARABELLA HOTELS',
  },
  {
    value: '3512',
    label: '3512, INTER-CONTINENTAL HOTELS',
  },
  {
    value: '3513',
    label: '3513, WESTIN HOTELS',
  },
  {
    value: '3514',
    label: '3514, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3515',
    label: '3515, RODEWAY INNS',
  },
  {
    value: '3516',
    label: '3516, LA QUINTA MOTOR INNS',
  },
  {
    value: '3517',
    label: '3517, AMERICANA HOTELS',
  },
  {
    value: '3518',
    label: '3518, SOL HOTELS',
  },
  {
    value: '3519',
    label: '3519, PULLMAN INTERNATIONAL HOTELS',
  },
  {
    value: '3520',
    label: '3520, MERIDIEN HOTELS',
  },
  {
    value: '3521',
    label: '3521, CREST HOTELS (see FORTE HOTELS)',
  },
  {
    value: '3522',
    label: '3522, TOKYO HOTEL',
  },
  {
    value: '3523',
    label: '3523, PENNSULA HOTEL',
  },
  {
    value: '3524',
    label: '3524, WELCOMGROUP HOTELS',
  },
  {
    value: '3525',
    label: '3525, DUNFEY HOTELS',
  },
  {
    value: '3526',
    label: '3526, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3527',
    label: '3527, DOWNTOWNER-PASSPORT HOTEL',
  },
  {
    value: '3528',
    label: '3528, RED LION HOTELS, RED LION INNS',
  },
  {
    value: '3529',
    label: '3529, CP HOTELS',
  },
  {
    value: '3530',
    label: '3530, RENAISSANCE HOTELS, STOUFFER HOTELS',
  },
  {
    value: '3531',
    label: '3531, ASTIR HOTELS',
  },
  {
    value: '3532',
    label: '3532, SUN ROUTE HOTELS',
  },
  {
    value: '3533',
    label: '3533, HOTEL IBIS',
  },
  {
    value: '3534',
    label: '3534, SOUTHERN PACIFIC HOTELS',
  },
  {
    value: '3535',
    label: '3535, HILTON INTERNATIONAL',
  },
  {
    value: '3536',
    label: '3536, AMFAC HOTELS',
  },
  {
    value: '3537',
    label: '3537, ANA HOTEL',
  },
  {
    value: '3538',
    label: '3538, CONCORDE HOTELS',
  },
  {
    value: '3539',
    label: '3539, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3540',
    label: '3540, IBEROTEL HOTELS',
  },
  {
    value: '3541',
    label: '3541, HOTEL OKURA',
  },
  {
    value: '3542',
    label: '3542, ROYAL HOTELS',
  },
  {
    value: '3543',
    label: '3543, FOUR SEASONS HOTELS',
  },
  {
    value: '3544',
    label: '3544, CIGA HOTELS',
  },
  {
    value: '3545',
    label: '3545, SHANGRI-LA INTERNATIONAL',
  },
  {
    value: '3546',
    label: '3546, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3547',
    label: '3547, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3548',
    label: '3548, HOTELES MELIA',
  },
  {
    value: '3549',
    label: '3549, AUBERGE DES GOVERNEURS',
  },
  {
    value: '3550',
    label: '3550, REGAL 8 INNS',
  },
  {
    value: '3551',
    label: '3551, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3552',
    label: '3552, COAST HOTELS',
  },
  {
    value: '3553',
    label: '3553, PARK INNS INTERNATIONAL',
  },
  {
    value: '3554',
    label: '3554, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3555',
    label: '3555, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3556',
    label: '3556, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3557',
    label: '3557, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3558',
    label: '3558, JOLLY HOTELS',
  },
  {
    value: '3559',
    label: '3559, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3560',
    label: '3560, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3561',
    label: '3561, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3562',
    label: '3562, COMFORT INNS',
  },
  {
    value: '3563',
    label: '3563, JOURNEY’S END MOTLS',
  },
  {
    value: '3564',
    label: '3564, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3565',
    label: '3565, RELAX INNS',
  },
  {
    value: '3566',
    label: '3566, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3567',
    label: '3567, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3568',
    label: '3568, LADBROKE HOTELS',
  },
  {
    value: '3569',
    label: '3569, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3570',
    label: '3570, FORUM HOTELS',
  },
  {
    value: '3571',
    label: '3571, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3572',
    label: '3572, MIYAKO HOTELS',
  },
  {
    value: '3573',
    label: '3573, SANDMAN HOTELS',
  },
  {
    value: '3574',
    label: '3574, VENTURE INNS',
  },
  {
    value: '3575',
    label: '3575, VAGABOND HOTELS',
  },
  {
    value: '3576',
    label: '3576, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3577',
    label: '3577, MANDARIN ORIENTAL HOTEL',
  },
  {
    value: '3578',
    label: '3578, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3579',
    label: '3579, HOTEL MERCURE',
  },
  {
    value: '3580',
    label: '3580, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3581',
    label: '3581, DELTA HOTEL',
  },
  {
    value: '3582',
    label: '3582, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3583',
    label: '3583, SAS HOTELS',
  },
  {
    value: '3584',
    label: '3584, PRINCESS HOTELS INTERNATIONAL',
  },
  {
    value: '3585',
    label: '3585, HUNGAR HOTELS',
  },
  {
    value: '3586',
    label: '3586, SOKOS HOTELS',
  },
  {
    value: '3587',
    label: '3587, DORAL HOTELS',
  },
  {
    value: '3588',
    label: '3588, HELMSLEY HOTELS',
  },
  {
    value: '3589',
    label: '3589, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3590',
    label: '3590, FAIRMONT HOTELS',
  },
  {
    value: '3591',
    label: '3591, SONESTA HOTELS',
  },
  {
    value: '3592',
    label: '3592, OMNI HOTELS',
  },
  {
    value: '3593',
    label: '3593, CUNARD HOTELS',
  },
  {
    value: '3594',
    label: '3594, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3595',
    label: '3595, HOSPITALITY INTERNATIONAL',
  },
  {
    value: '3596',
    label: '3596, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3597',
    label: '3597, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3598',
    label: '3598, REGENT INTERNATIONAL HOTELS',
  },
  {
    value: '3599',
    label: '3599, PANNONIA HOTELS',
  },
  {
    value: '3600',
    label: '3600, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3601',
    label: '3601, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3602',
    label: '3602, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3603',
    label: '3603, NOAH’S HOTELS',
  },
  {
    value: '3604',
    label: '3604, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3605',
    label: '3605, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3606',
    label: '3606, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3607',
    label: '3607, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3608',
    label: '3608, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3609',
    label: '3609, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3610',
    label: '3610, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3611',
    label: '3611, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3612',
    label: '3612, MOVENPICK HOTELS',
  },
  {
    value: '3613',
    label: '3613, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3614',
    label: '3614, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3615',
    label: '3615, TRAVELODGE',
  },
  {
    value: '3616',
    label: '3616, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3617',
    label: '3617, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3618',
    label: '3618, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3619',
    label: '3619, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3620',
    label: '3620, TELFORD INTERNATIONAL',
  },
  {
    value: '3621',
    label: '3621, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3622',
    label: '3622, MERLIN HOTELS',
  },
  {
    value: '3623',
    label: '3623, DORINT HOTELS',
  },
  {
    value: '3624',
    label: '3624, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3625',
    label: '3625, HOTLE UNIVERSALE',
  },
  {
    value: '3626',
    label: '3626, PRINCE HOTELS',
  },
  {
    value: '3627',
    label: '3627, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3628',
    label: '3628, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3629',
    label: '3629, DAN HOTELS',
  },
  {
    value: '3630',
    label: '3630, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3631',
    label: '3631, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3632',
    label: '3632, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3633',
    label: '3633, RANK HOTELS',
  },
  {
    value: '3634',
    label: '3634, SWISSOTEL',
  },
  {
    value: '3635',
    label: '3635, RESO HOTELS',
  },
  {
    value: '3636',
    label: '3636, SAROVA HOTELS',
  },
  {
    value: '3637',
    label: '3637, RAMADA INNS, RAMADA LIMITED',
  },
  {
    value: '3638',
    label: '3638, HO JO INN, HOWARD JOHNSON',
  },
  {
    value: '3639',
    label: '3639, MOUNT CHARLOTTE THISTLE',
  },
  {
    value: '3640',
    label: '3640, HYATT HOTEL',
  },
  {
    value: '3641',
    label: '3641, SOFITEL HOTELS',
  },
  {
    value: '3642',
    label: '3642, NOVOTEL HOTELS',
  },
  {
    value: '3643',
    label: '3643, STEIGENBERGER HOTELS',
  },
  {
    value: '3644',
    label: '3644, ECONO LODGES',
  },
  {
    value: '3645',
    label: '3645, QUEENS MOAT HOUSES',
  },
  {
    value: '3646',
    label: '3646, SWALLOW HOTELS',
  },
  {
    value: '3647',
    label: '3647, HUSA HOTELS',
  },
  {
    value: '3648',
    label: '3648, DE VERE HOTELS',
  },
  {
    value: '3649',
    label: '3649, RADISSON HOTELS',
  },
  {
    value: '3650',
    label: '3650, RED ROOK INNS',
  },
  {
    value: '3651',
    label: '3651, IMPERIAL LONDON HOTEL',
  },
  {
    value: '3652',
    label: '3652, EMBASSY HOTELS',
  },
  {
    value: '3653',
    label: '3653, PENTA HOTELS',
  },
  {
    value: '3654',
    label: '3654, LOEWS HOTELS',
  },
  {
    value: '3655',
    label: '3655, SCANDIC HOTELS',
  },
  {
    value: '3656',
    label: '3656, SARA HOTELS',
  },
  {
    value: '3657',
    label: '3657, OBEROI HOTELS',
  },
  {
    value: '3658',
    label: '3658, OTANI HOTELS',
  },
  {
    value: '3659',
    label: '3659, TAJ HOTELS INTERNATIONAL',
  },
  {
    value: '3660',
    label: '3660, KNIGHTS INNS',
  },
  {
    value: '3661',
    label: '3661, METROPOLE HOTELS',
  },
  {
    value: '3662',
    label: '3662, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3663',
    label: '3663, HOTELES EL PRESIDENTS',
  },
  {
    value: '3664',
    label: '3664, FLAG INN',
  },
  {
    value: '3665',
    label: '3665, HAMPTON INNS',
  },
  {
    value: '3666',
    label: '3666, STAKIS HOTELS',
  },
  {
    value: '3667',
    label: '3667, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3668',
    label: '3668, MARITIM HOTELS',
  },
  {
    value: '3669',
    label: '3669, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3670',
    label: '3670, ARCARD HOTELS',
  },
  {
    value: '3671',
    label: '3671, ARCTIA HOTELS',
  },
  {
    value: '3672',
    label: '3672, CAMPANIEL HOTELS',
  },
  {
    value: '3673',
    label: '3673, IBUSZ HOTELS',
  },
  {
    value: '3674',
    label: '3674, RANTASIPI HOTELS',
  },
  {
    value: '3675',
    label: '3675, INTERHOTEL CEDOK',
  },
  {
    value: '3676',
    label: '3676, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3677',
    label: '3677, CLIMAT DE FRANCE HOTELS',
  },
  {
    value: '3678',
    label: '3678, CUMULUS HOTELS',
  },
  {
    value: '3679',
    label: '3679, DANUBIUS HOTEL',
  },
  {
    value: '3680',
    label: '3680, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3681',
    label: '3681, ADAMS MARK HOTELS',
  },
  {
    value: '3682',
    label: '3682, ALLSTAR INNS',
  },
  {
    value: '3683',
    label: '3683, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3684',
    label: '3684, BUDGET HOST INNS',
  },
  {
    value: '3685',
    label: '3685, BUDGETEL HOTELS',
  },
  {
    value: '3686',
    label: '3686, SUISSE CHALETS',
  },
  {
    value: '3687',
    label: '3687, CLARION HOTELS',
  },
  {
    value: '3688',
    label: '3688, COMPRI HOTELS',
  },
  {
    value: '3689',
    label: '3689, CONSORT HOTELS',
  },
  {
    value: '3690',
    label: '3690, COURTYARD BY MARRIOTT',
  },
  {
    value: '3691',
    label: '3691, DILLION INNS',
  },
  {
    value: '3692',
    label: '3692, DOUBLETREE HOTELS',
  },
  {
    value: '3693',
    label: '3693, DRURY INNS',
  },
  {
    value: '3694',
    label: '3694, ECONOMY INNS OF AMERICA',
  },
  {
    value: '3695',
    label: '3695, EMBASSY SUITES',
  },
  {
    value: '3696',
    label: '3696, EXEL INNS',
  },
  {
    value: '3697',
    label: '3697, FARFIELD HOTELS',
  },
  {
    value: '3698',
    label: '3698, HARLEY HOTELS',
  },
  {
    value: '3699',
    label: '3699, MIDWAY MOTOR LODGE',
  },
  {
    value: '3700',
    label: '3700, MOTEL 6',
  },
  {
    value: '3701',
    label: '3701, GUEST QUARTERS (Formally PICKETT SUITE HOTELS)',
  },
  {
    value: '3702',
    label: '3702, THE REGISTRY HOTELS',
  },
  {
    value: '3703',
    label: '3703, RESIDENCE INNS',
  },
  {
    value: '3704',
    label: '3704, ROYCE HOTELS',
  },
  {
    value: '3705',
    label: '3705, SANDMAN INNS',
  },
  {
    value: '3706',
    label: '3706, SHILO INNS',
  },
  {
    value: '3707',
    label: '3707, SHONEY’S INNS',
  },
  {
    value: '3708',
    label: '3708, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3709',
    label: '3709, SUPER8 MOTELS',
  },
  {
    value: '3710',
    label: '3710, THE RITZ CARLTON HOTELS',
  },
  {
    value: '3711',
    label: '3711, FLAG INNS (AUSRALIA)',
  },
  {
    value: '3712',
    label: '3712, GOLDEN CHAIN HOTEL',
  },
  {
    value: '3713',
    label: '3713, QUALITY PACIFIC HOTEL',
  },
  {
    value: '3714',
    label: '3714, FOUR SEASONS HOTEL (AUSTRALIA)',
  },
  {
    value: '3715',
    label: '3715, FARIFIELD INN',
  },
  {
    value: '3716',
    label: '3716, CARLTON HOTELS',
  },
  {
    value: '3717',
    label: '3717, CITY LODGE HOTELS',
  },
  {
    value: '3718',
    label: '3718, KAROS HOTELS',
  },
  {
    value: '3719',
    label: '3719, PROTEA HOTELS',
  },
  {
    value: '3720',
    label: '3720, SOUTHERN SUN HOTELS',
  },
  {
    value: '3721',
    label: '3721, HILTON CONRAD',
  },
  {
    value: '3722',
    label: '3722, WYNDHAM HOTEL AND RESORTS',
  },
  {
    value: '3723',
    label: '3723, RICA HOTELS',
  },
  {
    value: '3724',
    label: '3724, INER NOR HOTELS',
  },
  {
    value: '3725',
    label: '3725, SEAINES PLANATION',
  },
  {
    value: '3726',
    label: '3726, RIO SUITES',
  },
  {
    value: '3727',
    label: '3727, BROADMOOR HOTEL',
  },
  {
    value: '3728',
    label: '3728, BALLY’S HOTEL AND CASINO',
  },
  {
    value: '3729',
    label: '3729, JOHN ASCUAGA’S NUGGET',
  },
  {
    value: '3730',
    label: '3730, MGM GRAND HOTEL',
  },
  {
    value: '3731',
    label: '3731, HARRAH’S HOTELS AND CASINOS',
  },
  {
    value: '3732',
    label: '3732, OPRYLAND HOTEL',
  },
  {
    value: '3733',
    label: '3733, BOCA RATON RESORT',
  },
  {
    value: '3734',
    label: '3734, HARVEY/BRISTOL HOTELS',
  },
  {
    value: '3735',
    label: '3735, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3736',
    label: '3736, COLORADO BELLE/EDGEWATER RESORT',
  },
  {
    value: '3737',
    label: '3737, RIVIERA HOTEL AND CASINO',
  },
  {
    value: '3738',
    label: '3738, TROPICANA RESORT AND CASINO',
  },
  {
    value: '3739',
    label: '3739, WOODSIDE HOTELS AND RESORTS',
  },
  {
    value: '3740',
    label: '3740, TOWNPLACE SUITES',
  },
  {
    value: '3741',
    label: '3741, MILLENIUM BROADWAY HOTEL',
  },
  {
    value: '3742',
    label: '3742, CLUB MED',
  },
  {
    value: '3743',
    label: '3743, BILTMORE HOTEL AND SUITES',
  },
  {
    value: '3744',
    label: '3744, CAREFREE RESORTS',
  },
  {
    value: '3745',
    label: '3745, ST. REGIS HOTEL',
  },
  {
    value: '3746',
    label: '3746, THE ELIOT HOTEL',
  },
  {
    value: '3747',
    label: '3747, CLUBCORP/CLUB RESORTS',
  },
  {
    value: '3748',
    label: '3748, WELESLEY INNS',
  },
  {
    value: '3749',
    label: '3749, THE BEVERLY HILLS HOTEL',
  },
  {
    value: '3750',
    label: '3750, CROWNE PLAZA HOTELS',
  },
  {
    value: '3751',
    label: '3751, HOMEWOOD SUITES',
  },
  {
    value: '3752',
    label: '3752, PEABODY HOTELS',
  },
  {
    value: '3753',
    label: '3753, GREENBRIAH RESORTS',
  },
  {
    value: '3754',
    label: '3754, AMELIA ISLAND PLANATION',
  },
  {
    value: '3755',
    label: '3755, THE HOMESTEAD',
  },
  {
    value: '3756',
    label: '3756, SOUTH SEAS RESORTS',
  },
  {
    value: '3757',
    label: '3757, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3758',
    label: '3758, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3759',
    label: '3759, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3760',
    label: '3760, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3761',
    label: '3761, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3762',
    label: '3762, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3763',
    label: '3763, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3764',
    label: '3764, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3765',
    label: '3765, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3766',
    label: '3766, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3767',
    label: '3767, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3768',
    label: '3768, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3769',
    label: '3769, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3770',
    label: '3770, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3771',
    label: '3771, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3772',
    label: '3772, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3773',
    label: '3773, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3774',
    label: '3774, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3775',
    label: '3775, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3776',
    label: '3776, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3777',
    label: '3777, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3778',
    label: '3778, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3779',
    label: '3779, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3780',
    label: '3780, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3781',
    label: '3781, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3782',
    label: '3782, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3783',
    label: '3783, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3784',
    label: '3784, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3785',
    label: '3785, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3786',
    label: '3786, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3787',
    label: '3787, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3788',
    label: '3788, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3789',
    label: '3789, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3790',
    label: '3790, Hotels/Motels/Inns/Resorts',
  },
  {
    value: '3816',
    label: '3816, Home2Suites',
  },
  {
    value: '3835',
    label: '3835, * MASTERS ECONOMY INNS',
  },
  {
    value: '4011',
    label: '4011, Railroads',
  },
  {
    value: '4111',
    label:
      '4111, Local/Suburban Commuter Passenger Transportation – Railroads, Feries, Local Water Transportation.',
  },
  {
    value: '4112',
    label: '4112, Passenger Railways',
  },
  {
    value: '4119',
    label: '4119, Ambulance Services',
  },
  {
    value: '4121',
    label: '4121, Taxicabs and Limousines',
  },
  {
    value: '4131',
    label: '4131, Bus Lines, Including Charters, Tour Buses',
  },
  {
    value: '4214',
    label:
      '4214, Motor Freight Carriers, Moving and Storage Companies, Trucking – Local/Long Distance, Delivery Services – Local',
  },
  {
    value: '4215',
    label: '4215, Courier Services – Air or Ground, Freight forwarders',
  },
  {
    value: '4225',
    label: '4225, Public warehousing, Storage',
  },
  {
    value: '4411',
    label: '4411, Cruise and Steamship Lines',
  },
  {
    value: '4457',
    label: '4457, Boat Rentals and Leases',
  },
  {
    value: '4468',
    label: '4468, Marinas, Marine Service, and Supplies',
  },
  {
    value: '4511',
    label: '4511, Airlines, Air Carriers (not listed elsewhere)',
  },
  {
    value: '4582',
    label: '4582, Airports, Airport Terminals, Flying Fields',
  },
  {
    value: '4722',
    label: '4722, Travel Agencies and Tour Operations',
  },
  {
    value: '4723',
    label: '4723, Package Tour Operators (For use in Germany only)',
  },
  {
    value: '4784',
    label: '4784, Toll and Bridge Fees',
  },
  {
    value: '4789',
    label: '4789, Transportation Services, Not elsewhere classified)',
  },
  {
    value: '4812',
    label: '4812, Telecommunications Equipment including telephone sales',
  },
  {
    value: '4814',
    label: '4814, Fax services, Telecommunication Services',
  },
  {
    value: '4815',
    label: '4815, VisaPhone',
  },
  {
    value: '4816',
    label: '4816, Computer Network Services',
  },
  {
    value: '4821',
    label: '4821, Telegraph services',
  },
  {
    value: '4829',
    label: '4829, Money Orders – Wire Transfer',
  },
  {
    value: '4899',
    label: '4899, Cable and other pay television (previously Cable Services)',
  },
  {
    value: '4900',
    label: '4900, Electric, Gas, Sanitary and Water Utilities',
  },
  {
    value: '5013',
    label: '5013, Motor vehicle supplies and new parts',
  },
  {
    value: '5021',
    label: '5021, Office and Commercial Furniture',
  },
  {
    value: '5039',
    label: '5039, Construction Materials, Not Elsewhere Classified',
  },
  {
    value: '5044',
    label: '5044, Office, Photographic, Photocopy, and Microfilm Equipment',
  },
  {
    value: '5045',
    label: '5045, Computers, Computer Peripheral Equipment, Software',
  },
  {
    value: '5046',
    label: '5046, Commercial Equipment, Not Elsewhere Classified',
  },
  {
    value: '5047',
    label: '5047, Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
  },
  {
    value: '5051',
    label: '5051, Metal Service Centers and Offices',
  },
  {
    value: '5065',
    label: '5065, Electrical Parts and Equipment',
  },
  {
    value: '5072',
    label: '5072, Hardware Equipment and Supplies',
  },
  {
    value: '5074',
    label: '5074, Plumbing and Heating Equipment and Supplies',
  },
  {
    value: '5085',
    label: '5085, Industrial Supplies, Not Elsewhere Classified',
  },
  {
    value: '5094',
    label: '5094, Precious Stones and Metals, Watches and Jewelry',
  },
  {
    value: '5099',
    label: '5099, Durable Goods, Not Elsewhere Classified',
  },
  {
    value: '5111',
    label: '5111, Stationery, Office Supplies, Printing, and Writing Paper',
  },
  {
    value: '5122',
    label: '5122, Drugs, Drug Proprietors, and Druggist’s Sundries',
  },
  {
    value: '5131',
    label: '5131, Piece Goods, Notions, and Other Dry Goods',
  },
  {
    value: '5137',
    label: '5137, Men’s Women’s and Children’s Uniforms and Commercial Clothing',
  },
  {
    value: '5139',
    label: '5139, Commercial Footwear',
  },
  {
    value: '5169',
    label: '5169, Chemicals and Allied Products, Not Elsewhere Classified',
  },
  {
    value: '5172',
    label: '5172, Petroleum and Petroleum Products',
  },
  {
    value: '5192',
    label: '5192, Books, Periodicals, and Newspapers',
  },
  {
    value: '5193',
    label: '5193, Florists’ Supplies, Nursery Stock and Flowers',
  },
  {
    value: '5198',
    label: '5198, Paints, Varnishes, and Supplies',
  },
  {
    value: '5199',
    label: '5199, Non-durable Goods, Not Elsewhere Classified',
  },
  {
    value: '5200',
    label: '5200, Home Supply Warehouse Stores',
  },
  {
    value: '5211',
    label: '5211, Lumber and Building Materials Stores',
  },
  {
    value: '5231',
    label: '5231, Glass, Paint, and Wallpaper Stores',
  },
  {
    value: '5251',
    label: '5251, Hardware Stores',
  },
  {
    value: '5261',
    label: '5261, Nurseries – Lawn and Garden Supply Store',
  },
  {
    value: '5271',
    label: '5271, Mobile Home Dealers',
  },
  {
    value: '5300',
    label: '5300, Wholesale Clubs',
  },
  {
    value: '5309',
    label: '5309, Duty Free Store',
  },
  {
    value: '5310',
    label: '5310, Discount Stores',
  },
  {
    value: '5311',
    label: '5311, Department Stores',
  },
  {
    value: '5331',
    label: '5331, Variety Stores',
  },
  {
    value: '5399',
    label: '5399, Misc. General Merchandise',
  },
  {
    value: '5411',
    label: '5411, Grocery Stores, Supermarkets',
  },
  {
    value: '5422',
    label: '5422, Meat Provisioners – Freezer and Locker',
  },
  {
    value: '5441',
    label: '5441, Candy, Nut, and Confectionery Stores',
  },
  {
    value: '5451',
    label: '5451, Dairy Products Stores',
  },
  {
    value: '5462',
    label: '5462, Bakeries',
  },
  {
    value: '5499',
    label: '5499, Misc. Food Stores – Convenience Stores and Specialty Markets',
  },
  {
    value: '5511',
    label: '5511, Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
  },
  {
    value: '5521',
    label: '5521, Automobile and Truck Dealers (Used Only)',
  },
  {
    value: '5531',
    label: '5531, Automobile Supply Stores',
  },
  {
    value: '5532',
    label: '5532, Automotive Tire Stores',
  },
  {
    value: '5533',
    label: '5533, Automotive Parts, Accessories Stores',
  },
  {
    value: '5541',
    label: '5541, Service Stations (with or without ancillary services)',
  },
  {
    value: '5542',
    label: '5542, Automated Fuel Dispensers',
  },
  {
    value: '5551',
    label: '5551, Boat Dealers',
  },
  {
    value: '5561',
    label: '5561, Recreational and Utility Trailers, Camp Dealers',
  },
  {
    value: '5571',
    label: '5571, Motorcycle Dealers',
  },
  {
    value: '5592',
    label: '5592, Motor Home Dealers',
  },
  {
    value: '5598',
    label: '5598, Snowmobile Dealers',
  },
  {
    value: '5599',
    label: '5599, Miscellaneous Auto Dealers ',
  },
  {
    value: '5611',
    label: '5611, Men’s and Boy’s Clothing and Accessories Stores',
  },
  {
    value: '5621',
    label: '5621, Women’s Ready-to-Wear Stores',
  },
  {
    value: '5631',
    label: '5631, Women’s Accessory and Specialty Shops',
  },
  {
    value: '5641',
    label: '5641, Children’s and Infant’s Wear Stores',
  },
  {
    value: '5651',
    label: '5651, Family Clothing Stores',
  },
  {
    value: '5655',
    label: '5655, Sports Apparel, Riding Apparel Stores',
  },
  {
    value: '5661',
    label: '5661, Shoe Stores',
  },
  {
    value: '5681',
    label: '5681, Furriers and Fur Shops',
  },
  {
    value: '5691',
    label: '5691, Men’s and Women’s Clothing Stores',
  },
  {
    value: '5697',
    label: '5697, Tailors, Seamstress, Mending, and Alterations',
  },
  {
    value: '5698',
    label: '5698, Wig and Toupee Stores',
  },
  {
    value: '5699',
    label: '5699, Miscellaneous Apparel and Accessory Shops',
  },
  {
    value: '5712',
    label: '5712, Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
  },
  {
    value: '5713',
    label: '5713, Floor Covering Stores',
  },
  {
    value: '5714',
    label: '5714, Drapery, Window Covering and Upholstery Stores',
  },
  {
    value: '5718',
    label: '5718, Fireplace, Fireplace Screens, and Accessories Stores',
  },
  {
    value: '5719',
    label: '5719, Miscellaneous Home Furnishing Specialty Stores',
  },
  {
    value: '5722',
    label: '5722, Household Appliance Stores',
  },
  {
    value: '5732',
    label: '5732, Electronic Sales',
  },
  {
    value: '5733',
    label: '5733, Music Stores, Musical Instruments, Piano Sheet Music',
  },
  {
    value: '5734',
    label: '5734, Computer Software Stores',
  },
  {
    value: '5735',
    label: '5735, Record Shops',
  },
  {
    value: '5811',
    label: '5811, Caterers',
  },
  {
    value: '5812',
    label: '5812, Eating places and Restaurants',
  },
  {
    value: '5813',
    label:
      '5813, Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
  },
  {
    value: '5814',
    label: '5814, Fast Food Restaurants',
  },
  {
    value: '5815',
    label: '5815, Digital Goods: Media, Books, Movies, Music',
  },
  {
    value: '5816',
    label: '5816, Digital Goods: Games',
  },
  {
    value: '5817',
    label: '5817, Digital Goods: Applications (Excludes Games)',
  },
  {
    value: '5818',
    label: '5818, Digital Goods: Large Digital Goods Merchant',
  },
  {
    value: '5832',
    label: '5832, Antique Shops – Sales, Repairs, and Restoration Services',
  },
  {
    value: '5912',
    label: '5912, Drug Stores and Pharmacies',
  },
  {
    value: '5921',
    label: '5921, Package Stores – Beer, Wine, and Liquor',
  },
  {
    value: '5931',
    label: '5931, Used Merchandise and Secondhand Stores',
  },
  {
    value: '5932',
    label: '5932, Antique Shops',
  },
  {
    value: '5933',
    label: '5933, Pawn Shops and Salvage Yards',
  },
  {
    value: '5935',
    label: '5935, Wrecking and Salvage Yards',
  },
  {
    value: '5937',
    label: '5937, Antique Reproductions',
  },
  {
    value: '5940',
    label: '5940, Bicycle Shops – Sales and Service',
  },
  {
    value: '5941',
    label: '5941, Sporting Goods Stores',
  },
  {
    value: '5942',
    label: '5942, Book Stores',
  },
  {
    value: '5943',
    label: '5943, Stationery Stores, Office and School Supply Stores',
  },
  {
    value: '5944',
    label: '5944, Watch, Clock, Jewelry, and Silverware Stores',
  },
  {
    value: '5945',
    label: '5945, Hobby, Toy, and Game Shops',
  },
  {
    value: '5946',
    label: '5946, Camera and Photographic Supply Stores',
  },
  {
    value: '5947',
    label: '5947, Card Shops, Gift, Novelty, and Souvenir Shops',
  },
  {
    value: '5948',
    label: '5948, Leather Goods Stores',
  },
  {
    value: '5949',
    label: '5949, Sewing, Needle, Fabric, and Price Goods Stores',
  },
  {
    value: '5950',
    label: '5950, Glassware/Crystal Stores',
  },
  {
    value: '5960',
    label: '5960, Direct Marketing- Insurance Service',
  },
  {
    value: '5961',
    label:
      '5961, Mail Order Houses Including Catalog Order Stores, Book/Record Clubs (No longer permitted for U.S. original presentments)',
  },
  {
    value: '5962',
    label: '5962, Direct Marketing – Travel Related Arrangements Services',
  },
  {
    value: '5963',
    label: '5963, Door-to-Door Sales',
  },
  {
    value: '5964',
    label: '5964, Direct Marketing – Catalog Merchant',
  },
  {
    value: '5965',
    label: '5965, Direct Marketing – Catalog and Catalog and Retail Merchant',
  },
  {
    value: '5966',
    label: '5966, Direct Marketing- Outbound Telemarketing Merchant',
  },
  {
    value: '5967',
    label: '5967, Direct Marketing – Inbound Teleservices Merchant',
  },
  {
    value: '5968',
    label: '5968, Direct Marketing – Continuity/Subscription Merchant',
  },
  {
    value: '5969',
    label: '5969, Direct Marketing – Not Elsewhere Classified',
  },
  {
    value: '5970',
    label: '5970, Artist’s Supply and Craft Shops',
  },
  {
    value: '5971',
    label: '5971, Art Dealers and Galleries',
  },
  {
    value: '5972',
    label: '5972, Stamp and Coin Stores – Philatelic and Numismatic Supplies',
  },
  {
    value: '5973',
    label: '5973, Religious Goods Stores',
  },
  {
    value: '5975',
    label: '5975, Hearing Aids – Sales, Service, and Supply Stores',
  },
  {
    value: '5976',
    label: '5976, Orthopedic Goods Prosthetic Devices',
  },
  {
    value: '5977',
    label: '5977, Cosmetic Stores',
  },
  {
    value: '5978',
    label: '5978, Typewriter Stores – Sales, Rental, Service',
  },
  {
    value: '5983',
    label: '5983, Fuel – Fuel Oil, Wood, Coal, Liquefied Petroleum',
  },
  {
    value: '5992',
    label: '5992, Florists',
  },
  {
    value: '5993',
    label: '5993, Cigar Stores and Stands',
  },
  {
    value: '5994',
    label: '5994, News Dealers and Newsstands',
  },
  {
    value: '5995',
    label: '5995, Pet Shops, Pet Foods, and Supplies Stores',
  },
  {
    value: '5996',
    label: '5996, Swimming Pools – Sales, Service, and Supplies',
  },
  {
    value: '5997',
    label: '5997, Electric Razor Stores – Sales and Service',
  },
  {
    value: '5998',
    label: '5998, Tent and Awning Shops',
  },
  {
    value: '5999',
    label: '5999, Miscellaneous and Specialty Retail Stores',
  },
  {
    value: '6010',
    label: '6010, Financial Institutions – Manual Cash Disbursements',
  },
  {
    value: '6011',
    label: '6011, Financial Institutions – Manual Cash Disbursements',
  },
  {
    value: '6012',
    label: '6012, Financial Institutions – Merchandise and Services',
  },
  {
    value: '6051',
    label:
      '6051, Non-Financial Institutions – Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques',
  },
  {
    value: '6211',
    label: '6211, Security Brokers/Dealers',
  },
  {
    value: '6300',
    label: '6300, Insurance Sales, Underwriting, and Premiums',
  },
  {
    value: '6381',
    label: '6381, Insurance Premiums, (no longer valid for first presentment work)',
  },
  {
    value: '6399',
    label: '6399, Insurance, Not Elsewhere Classified (no longer valid forfirst presentment work)',
  },
  {
    value: '6513',
    label: '6513, Real Estate Agents and Managers - Rentals',
  },
  {
    value: '7011',
    label:
      '7011, Lodging – Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)',
  },
  {
    value: '7012',
    label: '7012, Timeshares',
  },
  {
    value: '7032',
    label: '7032, Sporting and Recreational Camps',
  },
  {
    value: '7033',
    label: '7033, Trailer Parks and Camp Grounds',
  },
  {
    value: '7210',
    label: '7210, Laundry, Cleaning, and Garment Services',
  },
  {
    value: '7211',
    label: '7211, Laundry – Family and Commercial',
  },
  {
    value: '7216',
    label: '7216, Dry Cleaners',
  },
  {
    value: '7217',
    label: '7217, Carpet and Upholstery Cleaning',
  },
  {
    value: '7221',
    label: '7221, Photographic Studios',
  },
  {
    value: '7230',
    label: '7230, Barber and Beauty Shops',
  },
  {
    value: '7251',
    label: '7251, Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
  },
  {
    value: '7261',
    label: '7261, Funeral Service and Crematories',
  },
  {
    value: '7273',
    label: '7273, Dating and Escort Services',
  },
  {
    value: '7276',
    label: '7276, Tax Preparation Service',
  },
  {
    value: '7277',
    label: '7277, Counseling Service – Debt, Marriage, Personal',
  },
  {
    value: '7278',
    label: '7278, Buying/Shopping Services, Clubs',
  },
  {
    value: '7296',
    label: '7296, Clothing Rental – Costumes, Formal Wear, Uniforms',
  },
  {
    value: '7297',
    label: '7297, Massage Parlors',
  },
  {
    value: '7298',
    label: '7298, Health and Beauty Shops',
  },
  {
    value: '7299',
    label: '7299, Miscellaneous Personal Services (not elsewhere classifies)',
  },
  {
    value: '7311',
    label: '7311, Advertising Services',
  },
  {
    value: '7321',
    label: '7321, Consumer Credit Reporting Agencies',
  },
  {
    value: '7332',
    label: '7332, Blueprinting and Photocopying Services',
  },
  {
    value: '7333',
    label: '7333, Commercial Photography, Art and Graphics',
  },
  {
    value: '7338',
    label: '7338, Quick Copy, Reproduction and Blueprinting Services',
  },
  {
    value: '7339',
    label: '7339, Stenographic and Secretarial Support Services',
  },
  {
    value: '7342',
    label: '7342, Exterminating and Disinfecting Services',
  },
  {
    value: '7349',
    label: '7349, Cleaning and Maintenance, Janitorial Services',
  },
  {
    value: '7361',
    label: '7361, Employment Agencies, Temporary Help Services',
  },
  {
    value: '7372',
    label: '7372, Computer Programming, Integrated Systems Design and Data Processing Services',
  },
  {
    value: '7375',
    label: '7375, Information Retrieval Services',
  },
  {
    value: '7379',
    label: '7379, Computer Maintenance and Repair Services, Not Elsewhere Classified',
  },
  {
    value: '7392',
    label: '7392, Management, Consulting, and Public Relations Services',
  },
  {
    value: '7393',
    label: '7393, Protective and Security Services – Including Armored Carsand Guard Dogs',
  },
  {
    value: '7394',
    label:
      '7394, Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
  },
  {
    value: '7395',
    label: '7395, Photofinishing Laboratories, Photo Developing',
  },
  {
    value: '7399',
    label: '7399, Business Services, Not Elsewhere Classified',
  },
  {
    value: '7511',
    label: '7511, Truck Stop',
  },
  {
    value: '7512',
    label: '7512, Car Rental Companies (Not Listed Below)',
  },
  {
    value: '7513',
    label: '7513, Truck and Utility Trailer Rentals',
  },
  {
    value: '7519',
    label: '7519, Motor Home and Recreational Vehicle Rentals',
  },
  {
    value: '7523',
    label: '7523, Automobile Parking Lots and Garages',
  },
  {
    value: '7531',
    label: '7531, Automotive Body Repair Shops',
  },
  {
    value: '7534',
    label: '7534, Tire Re-treading and Repair Shops',
  },
  {
    value: '7535',
    label: '7535, Paint Shops – Automotive',
  },
  {
    value: '7538',
    label: '7538, Automotive Service Shops',
  },
  {
    value: '7542',
    label: '7542, Car Washes',
  },
  {
    value: '7549',
    label: '7549, Towing Services',
  },
  {
    value: '7622',
    label: '7622, Radio Repair Shops',
  },
  {
    value: '7623',
    label: '7623, Air Conditioning and Refrigeration Repair Shops',
  },
  {
    value: '7629',
    label: '7629, Electrical And Small Appliance Repair Shops',
  },
  {
    value: '7631',
    label: '7631, Watch, Clock, and Jewelry Repair',
  },
  {
    value: '7641',
    label: '7641, Furniture, Furniture Repair, and Furniture Refinishing',
  },
  {
    value: '7692',
    label: '7692, Welding Repair',
  },
  {
    value: '7699',
    label: '7699, Repair Shops and Related Services –Miscellaneous',
  },
  {
    value: '7800',
    label: '7800, Government-Owned Lotteries',
  },
  {
    value: '7801',
    label: '7801, Government-Licensed On-Line Casinos (On-Line Gambling)',
  },
  {
    value: '7802',
    label: '7802, Government-Licensed Horse/Dog Racing',
  },
  {
    value: '7829',
    label: '7829, Motion Pictures and Video Tape Production and Distribution',
  },
  {
    value: '7832',
    label: '7832, Motion Picture Theaters',
  },
  {
    value: '7841',
    label: '7841, Video Tape Rental Stores',
  },
  {
    value: '7911',
    label: '7911, Dance Halls, Studios and Schools',
  },
  {
    value: '7922',
    label: '7922, Theatrical Producers (Except Motion Pictures), Ticket Agencies',
  },
  {
    value: '7929',
    label: '7929, Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
  },
  {
    value: '7932',
    label: '7932, Billiard and Pool Establishments',
  },
  {
    value: '7933',
    label: '7933, Bowling Alleys',
  },
  {
    value: '7941',
    label:
      '7941, Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
  },
  {
    value: '7991',
    label: '7991, Tourist Attractions and Exhibits',
  },
  {
    value: '7992',
    label: '7992, Golf Courses – Public',
  },
  {
    value: '7993',
    label: '7993, Video Amusement Game Supplies',
  },
  {
    value: '7994',
    label: '7994, Video Game Arcades/Establishments',
  },
  {
    value: '7995',
    label:
      '7995, Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
  },
  {
    value: '7996',
    label: '7996, Amusement Parks, Carnivals, Circuses, Fortune Tellers',
  },
  {
    value: '7997',
    label:
      '7997, Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
  },
  {
    value: '7998',
    label: '7998, Aquariums, Sea-aquariums, Dolphinariums',
  },
  {
    value: '7999',
    label: '7999, Recreation Services (Not Elsewhere Classified)',
  },
  {
    value: '8011',
    label: '8011, Doctors and Physicians (Not Elsewhere Classified)',
  },
  {
    value: '8021',
    label: '8021, Dentists and Orthodontists',
  },
  {
    value: '8031',
    label: '8031, Osteopaths',
  },
  {
    value: '8041',
    label: '8041, Chiropractors',
  },
  {
    value: '8042',
    label: '8042, Optometrists and Ophthalmologists',
  },
  {
    value: '8043',
    label: '8043, Opticians, Opticians Goods and Eyeglasses',
  },
  {
    value: '8044',
    label: '8044, Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
  },
  {
    value: '8049',
    label: '8049, Podiatrists and Chiropodists',
  },
  {
    value: '8050',
    label: '8050, Nursing and Personal Care Facilities',
  },
  {
    value: '8062',
    label: '8062, Hospitals',
  },
  {
    value: '8071',
    label: '8071, Medical and Dental Laboratories',
  },
  {
    value: '8099',
    label: '8099, Medical Services and Health Practitioners (Not Elsewhere Classified)',
  },
  {
    value: '8111',
    label: '8111, Legal Services and Attorneys',
  },
  {
    value: '8211',
    label: '8211, Elementary and Secondary Schools',
  },
  {
    value: '8220',
    label: '8220, Colleges, Junior Colleges, Universities, and ProfessionalSchools',
  },
  {
    value: '8241',
    label: '8241, Correspondence Schools',
  },
  {
    value: '8244',
    label: '8244, Business and Secretarial Schools',
  },
  {
    value: '8249',
    label: '8249, Vocational Schools and Trade Schools',
  },
  {
    value: '8299',
    label: '8299, Schools and Educational Services (Not Elsewhere Classified)',
  },
  {
    value: '8351',
    label: '8351, Child Care Services',
  },
  {
    value: '8398',
    label: '8398, Charitable and Social Service Organizations',
  },
  {
    value: '8641',
    label: '8641, Civic, Fraternal, and Social Associations',
  },
  {
    value: '8651',
    label: '8651, Political Organizations',
  },
  {
    value: '8661',
    label: '8661, Religious Organizations',
  },
  {
    value: '8675',
    label: '8675, Automobile Associations',
  },
  {
    value: '8699',
    label: '8699, Membership Organizations (Not Elsewhere Classified)',
  },
  {
    value: '8734',
    label: '8734, Testing Laboratories (non-medical)',
  },
  {
    value: '8911',
    label: '8911, Architectural – Engineering and Surveying Services',
  },
  {
    value: '8931',
    label: '8931, Accounting, Auditing, and Bookkeeping Services',
  },
  {
    value: '8999',
    label: '8999, Professional Services (Not Elsewhere Defined)',
  },
  {
    value: '9211',
    label: '9211, Court Costs, including Alimony and Child Support',
  },
  {
    value: '9222',
    label: '9222, Fines',
  },
  {
    value: '9223',
    label: '9223, Bail and Bond Payments',
  },
  {
    value: '9311',
    label: '9311, Tax Payments',
  },
  {
    value: '9399',
    label: '9399, Government Services (Not Elsewhere Classified)',
  },
  {
    value: '9402',
    label: '9402, Postal Services – Government Only',
  },
  {
    value: '9405',
    label: '9405, Intra – Government Transactions',
  },
  {
    value: '9700',
    label: '9700, Automated Referral Service (For Visa Only)',
  },
  {
    value: '9701',
    label: '9701, Visa Credential Service (For Visa Only)',
  },
  {
    value: '9702',
    label: '9702, GCAS Emergency Services (For Visa Only)',
  },
  {
    value: '9950',
    label: '9950, Intra – Company Purchases (For Visa Only)',
  },
  {
    value: '0742',
    label: '0742, Veterinary Services',
  },
  {
    value: '0763',
    label: '0763, Agricultural Co-operatives',
  },
  {
    value: '0780',
    label: '0780, Horticultural Services, Landscaping Services',
  },
];
