import { Button } from '@components/button';
import { useRouter } from '@tanstack/react-router';
import { ArrowLeft } from 'lucide-react';
import { ReactNode } from 'react';

interface BaseLayoutProps {
  isChild?: boolean;
  title?: string | ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode[];
}

export const BaseLayout = ({ title, actions, children, isChild = false }: BaseLayoutProps) => {
  const router = useRouter();
  const handleBackAction = () => router.history.back();

  return (
    <div className="flex flex-col gap-2 md:gap-6">
      {isChild && (
        <Button
          variant="link"
          onClick={handleBackAction}
          className="me-auto transition-colors hover:text-primary">
          <ArrowLeft className="h-6" />
        </Button>
      )}
      <div className="flex w-full items-center justify-between">
        {title && <h1 className="grow text-2xl font-medium text-black">{title}</h1>}
        <div className="ms-auto flex shrink-0 items-center gap-2 empty:hidden md:gap-4">
          {actions}
        </div>
      </div>
      {children}
    </div>
  );
};
