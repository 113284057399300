import { AlertTriangle } from 'lucide-react';
import { ReactNode } from 'react';
import { type VariantProps } from 'class-variance-authority';
import { alertVariants } from './variants';
import { cn } from '@utils/cn';

export interface AlertProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof alertVariants> {
  children: ReactNode;
  icon?: ReactNode;
}

export const Alert = ({ title, children, className, variant, icon }: AlertProps) => {
  return (
    <div className={cn(alertVariants({ variant }), className)}>
      <div data-type="icon" className="mr-5 flex h-9 w-9 items-center justify-center rounded-lg">
        {icon || <AlertTriangle size={24} />}
      </div>
      <div className="w-full">
        {title && <h5 className="mb-3 text-lg font-semibold">{title}</h5>}
        <div className="leading-relaxed">{children}</div>
      </div>
    </div>
  );
};
