import { FiltersConfig } from '@ctx/filters/context';
import {
  sourceTypeOptions,
  transactionStatusOptions,
  transactionTypeOptions,
} from '@components/filters/selectOptions';

export const config: FiltersConfig = {
  items: [
    { id: 'id', filterType: 'text' },
    { id: 'status', filterType: 'select', options: transactionStatusOptions },
    { id: 'type', filterType: 'select', options: transactionTypeOptions },
    { id: 'source_type', filterType: 'select', options: sourceTypeOptions },
    { id: 'amount', filterType: 'text' },
    { id: 'retrieval_reference', filterType: 'text', format: 'number' },
    { id: 'stan', filterType: 'text', format: 'number' },
    { id: 'auth_code', filterType: 'text', format: 'number' },
    { id: 'response_code', filterType: 'text' },
    { id: 'created_at', filterType: 'date', format: 'date' },
    { id: 'updated_at', filterType: 'date', format: 'date' },
  ],
  default: ['id', 'status', 'type', 'source_type', 'retrieval_reference', 'created_at'],
};
