import { Card, CardContent } from '@components/card';
import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { TabLink, TabsContainer } from '@components/tabLink';
import { BaseLayout } from '@layouts/base';
import { CardAuth } from '@services/global/cardAuths/types';
import { Outlet, useMatches } from '@tanstack/react-router';
import { formatAmount } from '@utils/textHelpers';
import { formatDate } from '@utils/dateHelpers';
import { useTranslation } from 'react-i18next';
import { MATCH_LEVEL } from '@utils/consts';
import {
  Banknote,
  CalendarCog,
  CalendarPlus,
  CircleX,
  Cog,
  DollarSign,
  Fingerprint,
  Link,
  MessageSquare,
  TrendingUp,
} from 'lucide-react';

export function CardAuthShow({ cardAuth }: { cardAuth: CardAuth }) {
  const { t } = useTranslation('app');
  const matches = useMatches();

  return (
    <BaseLayout isChild>
      <DetailsCard>
        <DetailsCardItem
          icon={<Fingerprint />}
          data={cardAuth.id}
          label={t('table.cardAuthId')}
          column={1}
        />
        <DetailsCardItem
          icon={<MessageSquare />}
          data={cardAuth.message}
          label={t('table.message')}
          column={1}
        />
        <DetailsCardItem
          icon={<Banknote />}
          data={cardAuth.currency}
          label={t('table.currency')}
          column={1}
        />
        <DetailsCardItem
          icon={<TrendingUp />}
          data={cardAuth.status}
          label={t('table.status')}
          column={1}
        />
        <DetailsCardItem
          icon={<Cog />}
          data={cardAuth.challengePreference}
          label={t('table.challengePreference')}
          column={1}
        />
        <DetailsCardItem
          icon={<DollarSign />}
          data={formatAmount(cardAuth.amount, cardAuth.currency)}
          label={t('table.amount')}
          column={2}
        />
        <DetailsCardItem
          icon={<Link />}
          data={<a href={cardAuth.returnUrl}>{cardAuth.returnUrl}</a>}
          label={t('table.returnUrl')}
          column={2}
        />
        {cardAuth.failureReason && (
          <DetailsCardItem
            icon={<CircleX />}
            data={cardAuth.failureReason}
            label={t('table.failureReason')}
            column={2}
          />
        )}
        <DetailsCardItem
          icon={<CalendarPlus />}
          data={formatDate(new Date(cardAuth.createdAt))}
          label={t('table.createdAt')}
          column={2}
        />
        <DetailsCardItem
          icon={<CalendarCog />}
          data={formatDate(new Date(cardAuth.updatedAt))}
          label={t('table.updatedAt')}
          column={2}
        />
      </DetailsCard>
      <Card>
        <CardContent className="py-3 pb-10">
          <TabsContainer>
            <TabLink
              label={t(`pages.cardAuths.merchant`)}
              path={`/${matches[MATCH_LEVEL].pathname}/merchant`}
            />
            {cardAuth.auth && (
              <TabLink
                label={t(`pages.cardAuths.auth`)}
                path={`/${matches[MATCH_LEVEL].pathname}/auth`}
              />
            )}
            <TabLink
              label={t(`pages.cardAuths.apiResponse`)}
              path={`/${matches[MATCH_LEVEL].pathname}/apiResponse`}
            />
          </TabsContainer>
          <Outlet />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}
