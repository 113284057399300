import { Loader } from '@components/loader';
import { Page404 } from '@pages/404';
import { MerchantBriefDetails } from '@pages/merchants/brief';
import { useGetOrder } from '@services/global/orders/get';
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router';

const OrderMerchant = () => {
  const { id, localId } = useParams({
    from: '/u/$localId/_acquirer/acquirer/orders/$id/merchant',
  });
  const { data, loading } = useGetOrder(id);
  const navigate = useNavigate();

  if (loading) return <Loader />;
  if (!data) return <Page404 fallbackPath={`/u/${localId}/acquirer/orders`} />;

  return (
    <MerchantBriefDetails
      merchant={data.merchant}
      merchantRedirect={(merchantId: string) => {
        navigate({
          to: '/u/$localId/acquirer/merchants/$merchantId',
          params: { merchantId, localId },
        }).catch((err) => console.error(err));
      }}
    />
  );
};

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/orders/$id/merchant')({
  component: OrderMerchant,
});
