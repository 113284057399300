import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { StaffUser } from '@root/globalTypes';

export function useGetUserByToken({
  type,
  token,
}: {
  type: 'invitations' | 'password';
  token: string;
}) {
  const { query } = useApi();

  const { data, isPending } = useQuery<StaffUser>({
    queryKey: [...queryKeys.staffUsers, token],
    queryFn: (): Promise<StaffUser> =>
      query({
        path: `v1/staff/users/${type}/${token}`,
        useAuth: false,
      }),
    enabled: Boolean(token),
  });

  return {
    data: data,
    isPending,
  };
}
