import { Button } from '@components/button';
import { Dialog, DialogTrigger, DialogContent, DialogTitle } from '@components/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useOrderRefund } from '@hooks/forms/order/refund';
import { Order } from '@root/globalTypes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OrderRefund = ({ order }: { order: Order }) => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const [open, setOpen] = useState(false);
  const possibleAmount = order.capturedAmount - order.refundedAmount;

  const onSuccess = () => {
    setOpen(false);
  };

  const { form, onSubmit, isPending, error } = useOrderRefund(order.id, possibleAmount, onSuccess);

  if (!verifyUserPermission('orders_refund')) return null;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button disabled={possibleAmount <= 0} variant="outline" className="w-24">
          {t('pages.order.refund')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{t('pages.order.refundOrder', { id: order.id })}</DialogTitle>
        <p>{t('pages.order.possibleRefund', { amount: `${order.currency} ${possibleAmount}` })}</p>
        <Form {...form}>
          <form
            id="acquirerForm"
            className="flex w-full flex-col gap-2"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('table.amount')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {error && <p className="text-red">{error}</p>}
            <Button
              type="submit"
              variant="primary"
              size="lg"
              className="mt-8"
              disabled={isPending}
              loading={isPending}>
              {t('form.submit.refund')}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
