import { Loader } from '@components/loader';
import { useGetApiKey } from '@services/acquirer/apiKeys/get';
import { createFileRoute, redirect, useParams } from '@tanstack/react-router';
import { ApiKeyShow } from '@pages/apiKeys/id';
import { useDisableApiKey } from '@services/acquirer/apiKeys/disable';
import { useGetPermissionGroups } from '@services/acquirer/apiKeys/permissionGroups';

const ShowApiKey = () => {
  const { id } = useParams({ from: '/u/$localId/_acquirer/acquirer/api-keys/$id/' });
  const { data, isPending } = useGetApiKey(id);
  const { mutate, isPending: disabling } = useDisableApiKey();
  const { data: permissions } = useGetPermissionGroups();

  if (isPending) return <Loader />;

  return (
    <ApiKeyShow apiKey={data} onDisable={mutate} disabling={disabling} permissions={permissions} />
  );
};

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/api-keys/$id/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['api_keys_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: ShowApiKey,
});
