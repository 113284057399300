import { useListAcquirerLinks } from '@services/acquirer/links/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useAcquirerLinksTable } from '@components/tables/acquirerLinks';
import { BaseLayout } from '@layouts/base';
import { Loader } from '@components/loader';

const AcquirerLinks = () => {
  const { t } = useTranslation('app');
  const { localId } = useParams({ from: '/u/$localId/_acquirer/acquirer/links/' });
  const navigate = useNavigate();
  const { data, isPending } = useListAcquirerLinks();
  const { Table: AcquirerLinksTable } = useAcquirerLinksTable({
    data,
    onRowSelect: (id: string) => {
      navigate({ to: '/u/$localId/acquirer/links/$id', params: { id, localId } }).catch((err) =>
        console.error(err),
      );
    },
  });

  if (isPending) return <Loader />;

  return (
    <BaseLayout title={t('pages.links.title')}>
      <AcquirerLinksTable />
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/links/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirer_links_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: () => <AcquirerLinks />,
});
