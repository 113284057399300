import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Loader } from '@components/loader';
import { useListOrders } from '@services/global/orders/list';
import { Orders } from '@pages/orders';
import { config } from './-filterConfig';

const MerchantOrders = () => {
  const { meta, orders, loading } = useListOrders();
  const { localId } = useParams({ from: '/u/$localId/_merchant/merchant/orders/' });
  const navigate = useNavigate();

  const handleSelect = (id: string) => {
    navigate({ to: '/u/$localId/merchant/orders/$id/transactions', params: { id, localId } }).catch(
      (err) => console.error(err),
    );
  };

  if (loading) return <Loader />;

  return <Orders orders={orders} meta={meta} onRowSelect={handleSelect} filterConfig={config} />;
};

export const Route = createFileRoute('/u/$localId/_merchant/merchant/orders/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['orders_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantOrders,
});
