import { Card, CardContent } from '@components/card';
import { AcquirerLinkForm } from '@components/forms/acquirerLinks';
import { Loader } from '@components/loader';
import { useAcquirerLinkForm } from '@hooks/forms/acquirerLinks';
import { BaseLayout } from '@layouts/base';
import { useGetAcquirerLink } from '@services/staff/acquirer/links/get';
import { useUpdateAcquirerLink } from '@services/staff/acquirer/links/update';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function EditAcquirerLink() {
  const { t } = useTranslation('app');
  const { acquirerId, id, localId } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId/links/$id/edit',
  });
  const navigate = useNavigate();
  const { data, isPending } = useGetAcquirerLink({ acquirerId, id });

  const requestHandler = useUpdateAcquirerLink({
    id,
    acquirerId,
    successHandler: async () => {
      await navigate({
        to: '/u/$localId/staff/acquirers/$acquirerId/links',
        params: { acquirerId, localId },
        replace: true,
      });
      toast.success(t('toast.acquirerLink.createSuccess'));
    },
  });
  const formObj = useAcquirerLinkForm({ link: data, requestHandler });

  if (isPending) return <Loader />;
  if (!data) return null;

  return (
    <BaseLayout isChild title={t('pages.editAcquirerLink.title')}>
      <Card>
        <CardContent className="p-6">
          <AcquirerLinkForm formObj={formObj} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}

export const Route = createFileRoute(
  '/u/$localId/_staff/staff/acquirers/$acquirerId/links/$id/edit',
)({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirer_links_read', 'acquirer_links_update'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: EditAcquirerLink,
});
