import { Card, CardContent } from '@components/card';
import { Loader } from '@components/loader';
import { BaseLayout } from '@layouts/base';
import { Page404 } from '@pages/404';
import { MerchantForm } from '@components/forms/merchants';
import { useGetMerchant } from '@services/staff/merchants/get';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { useMerchantForm } from '@hooks/forms/merchants';
import { useUpdateMerchant } from '@services/staff/merchants/update';

const EditAcquirerMerchant = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { localId, merchantId } = useParams({
    from: '/u/$localId/_staff/staff/merchants/$merchantId/edit',
  });

  const { data, isPending } = useGetMerchant(merchantId);

  const requestHandler = useUpdateMerchant({
    id: data?.id || '',
    successHandler: (merchant) => {
      toast.success(t('toast.merchants.updateSuccess'));
      navigate({
        to: '/u/$localId/staff/merchants/$merchantId',
        params: { merchantId: merchant.merchantId, localId },
        replace: true,
      }).catch((err) => {
        console.error(err);
      });
    },
  });

  const form = useMerchantForm({
    merchant: data,
    requestHandler,
  });

  if (isPending) return <Loader />;
  if (!data) return <Page404 />;

  return (
    <BaseLayout isChild title={t('pages.merchants.update')}>
      <Card>
        <CardContent className="p-6">
          <MerchantForm formObj={form} submitText={t('form.submit.merchantUpdate')} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/merchants/$merchantId/edit')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchants_update'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: EditAcquirerMerchant,
});
