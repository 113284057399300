import { DataTable } from '@components/dataTable';
import { TableDateCell } from '@components/table/dateCell';
import { useTableSorting } from '@hooks/useTableSorting';
import { EodStatement } from '@services/acquirer/eodStatements/types';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

type EodStatementsTableProps = {
  data: EodStatement[];
  visibleColumns?: string[];
};

export function useEodStatementsTable({ data, visibleColumns }: EodStatementsTableProps) {
  const { t } = useTranslation('app');
  const { sorting, updateSorting } = useTableSorting();

  const columns: ColumnDef<EodStatement>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
      enableSorting: false,
    },
    {
      id: 'version',
      accessorKey: 'version',
      header: t('table.version'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'transactions',
      accessorKey: 'transactions',
      header: t('table.transactionCount'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'date',
      accessorKey: 'date',
      header: t('table.date'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.date} />,
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
    {
      id: 'updated_at',
      accessorKey: 'updatedAt',
      header: t('table.updatedAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.updatedAt} />,
    },
  ];

  return {
    columns,
    Table: () => {
      return (
        <DataTable
          data={data}
          columns={
            visibleColumns
              ? columns.filter(({ id }) =>
                  typeof id === 'string' ? visibleColumns.includes(id) : false,
                )
              : columns
          }
          sorting={sorting}
          onSortChange={updateSorting}
        />
      );
    },
  };
}
