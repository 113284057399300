import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { AuditLog } from '@services/global/auditLogs/types';
import { Fingerprint, Landmark, Store, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function AuditLogOrganization({ auditLog }: { auditLog: AuditLog }) {
  const { t } = useTranslation('app');
  if (!auditLog.organization) return null;

  function organizationTypeIcon() {
    if (!auditLog.organizationType) return null;
    if (auditLog.organizationType === 'Acquirer') return <Landmark />;
    return <Store />;
  }

  return (
    <DetailsCard>
      <DetailsCardItem
        alt
        icon={<Fingerprint />}
        label={t('table.id')}
        data={auditLog.organization.id}
        column={1}
      />
      <DetailsCardItem
        alt
        icon={<User />}
        label={t('table.name')}
        data={auditLog.organization.name}
        column={1}
      />
      <DetailsCardItem
        alt
        icon={organizationTypeIcon()}
        label={t('table.type')}
        data={auditLog.organizationType}
        column={2}
      />
    </DetailsCard>
  );
}
