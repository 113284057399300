import { Loader } from '@components/loader';
import { AuditLogPage } from '@pages/auditLogs/id';
import { useGetAuditLog } from '@services/global/auditLogs/get';
import { createFileRoute, useParams } from '@tanstack/react-router';

function MerchantAuditLog() {
  const { id } = useParams({ from: '/u/$localId/_merchant/merchant/audit-logs/$id' });
  const { data, isPending } = useGetAuditLog({ id });

  if (isPending) return <Loader />;
  if (!data) return null;

  return <AuditLogPage auditLog={data} />;
}

export const Route = createFileRoute('/u/$localId/_merchant/merchant/audit-logs/$id')({
  component: MerchantAuditLog,
});
