import { Avatar, AvatarImage } from '@components/avatar';
import { CircleX } from 'lucide-react';

type LogoPreviewProps = {
  file: File | null;
  logoUrl: string | null;
  onRemove?: () => void;
};
export function LogoPreview({ file, logoUrl, onRemove }: LogoPreviewProps) {
  return (
    <div className="relative m-2">
      {onRemove && (
        <CircleX
          className="hover absolute right-[-1.5em] top-[-5px] hover:opacity-40"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
        />
      )}
      <Avatar className="h-20 w-20">
        <AvatarImage src={file ? URL.createObjectURL(file) : logoUrl || ''} />
      </Avatar>
    </div>
  );
}
