import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { JSONTree } from 'react-json-tree';

export const RawPreview = ({ data, lang = 'json' }: { data: unknown; lang?: string }) => {
  if (lang === 'json' && typeof data === 'object') {
    // const theme = {
    //   base00: '', // BACKGROUND_COLOR
    //   base07: '', // TEXT_COLOR
    //   base0B: '',
    //   base0B: '', // DATE_COLOR
    //   base09: '', // NUMBER_COLOR
    //   base09: '', // BOOLEAN_COLOR
    //   base08: '', // NULL_COLOR
    //   base08: '', // UNDEFINED_COLOR
    //   base08: '', // FUNCTION_COLOR
    //   base08: '', // SYMBOL_COLOR
    //   base0D: '', // LABEL_COLOR
    //   base0D: '', // ARROW_COLOR
    //   base0B: '', // ITEM_STRING_COLOR
    //   base03: '', // ITEM_STRING_EXPANDED_COLOR
    // }

    const theme = {
      scheme: 'pg',
      author: 'Moyasar',
      base00: 'transparent', // BACKGROUND_COLOR
      base01: '#383830',
      base02: '#49483e',
      base03: '#75715e',
      base04: '#a59f85',
      base05: '#f8f8f2',
      base06: '#f5f4f1',
      base07: '#f9f8f5',
      base08: '#f92672',
      base09: '#fd971f',
      base0A: '#f4bf75',
      base0B: 'rgba(119, 124, 130, 1)', // STRING_COLOR
      base0C: '#a1efe4',
      base0D: 'rgba(43, 103, 255, 1)', // LABEL_COLOR && ARROW_COLOR
      base0E: '#ae81ff',
      base0F: '#cc6633',
    };

    return (
      <div className="rounded bg-[#D9D9D930] p-6">
        <JSONTree data={{ ...data }} theme={theme} />
      </div>
    );
  }
  return (
    <SyntaxHighlighter style={github} language={lang} showLineNumbers wrapLongLines>
      {JSON.stringify(data, null, 2)}
    </SyntaxHighlighter>
  );
};
