import { DataTable } from '@components/dataTable';
import { TableDateCell } from '@components/table/dateCell';
import { OrderDetails, TransactionBase } from '@root/globalTypes';
import { ColumnDef } from '@tanstack/react-table';
import { formatAmount } from '@utils/textHelpers';
import { useTranslation } from 'react-i18next';

export function useOrderTransactionsTable({
  order,
  visibleColumns,
}: {
  order: OrderDetails;
  visibleColumns: string[];
}) {
  const { t } = useTranslation('app');

  const columns: ColumnDef<TransactionBase>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: t('table.status'),
    },
    {
      id: 'type',
      accessorKey: 'type',
      header: t('table.type'),
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: t('table.amount'),
      cell: ({ row }) => (
        <div className="text-end">{formatAmount(row.original.amount, order.currency)}</div>
      ),
    },
    {
      id: 'refunded_amount',
      accessorKey: 'refundedAmount',
      header: t('table.refundedAmount'),
      cell: ({ row }) => (
        <div className="text-end">{formatAmount(row.original.refundedAmount, order.currency)}</div>
      ),
    },
    {
      id: 'message',
      accessorKey: 'message',
      header: t('table.message'),
    },
    {
      id: 'response_code',
      accessorKey: 'responseCode',
      header: t('table.responseCode'),
    },
    {
      id: 'stan',
      accessorKey: 'stan',
      header: t('table.stan'),
    },
    {
      id: 'source_type',
      accessorKey: 'source_type',
      header: t('table.sourceType'),
    },
    {
      id: 'reverse_reason',
      accessorKey: 'reverseReason',
      header: t('table.reverseReason'),
    },
    {
      id: 'retrieval_reference',
      accessorKey: 'retrievalReference',
      header: t('table.retrievalReference'),
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
  ];

  return {
    columns,
    Table: () => (
      <DataTable
        columns={columns.filter(({ id }) =>
          typeof id === 'string' ? visibleColumns.includes(id) : false,
        )}
        data={order.transactions}
        enableSorting={false}
      />
    ),
  };
}
