import { PasswordExpirationAlert } from '@components/alerts/passwordExpiration';
import { TwoFactorRequiredAlert } from '@components/alerts/twoFactorRequired';
import { SideNav } from './sideNav';
import { NavTopBar } from './topNav';
import { Outlet } from '@tanstack/react-router';

export function MainLayout() {
  return (
    <>
      <NavTopBar />
      <main className={`main mx-auto !flex w-full max-w-full grow bg-neutral-50`}>
        <SideNav />
        <div className="grow overflow-x-auto px-5 py-3">
          <TwoFactorRequiredAlert />
          <PasswordExpirationAlert />
          <Outlet />
        </div>
      </main>
    </>
  );
}
