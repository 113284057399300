import { Loader } from '@components/loader';
import { AgreementPage } from '@pages/agreements/id';
import { useGetAgreement } from '@services/global/agreements/get';
import { createFileRoute, redirect, useParams } from '@tanstack/react-router';

function StaffAgreement() {
  const { id } = useParams({ from: '/u/$localId/_staff/staff/agreements/$id' });
  const { data, isPending } = useGetAgreement({ id });

  if (isPending) return <Loader />;
  if (!data) return null;

  return <AgreementPage agreement={data} />;
}

export const Route = createFileRoute('/u/$localId/_staff/staff/agreements/$id')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['agreements_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: StaffAgreement,
});
