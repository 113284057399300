import { StaffLinks } from '@components/navigation/staff/links';
import { NavigationProvider } from '@ctx/navigation';
import { useAppContext } from '@hooks/useAppContext';
import { MainLayout } from '@layouts/main';
import { useStaffLogout } from '@services/staff/session/delete';
import { createFileRoute, redirect, useNavigate, useRouter } from '@tanstack/react-router';
import { getSlots, getUserSlot } from '@utils/apiHelpers';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function StaffLayout() {
  const { t } = useTranslation('app');
  const { clearData, sendBcPost, user } = useAppContext();
  const router = useRouter();
  const navigate = useNavigate();
  const { mutate: logout, isPending } = useStaffLogout({
    successHandler: async () => {
      clearData();
      await router.invalidate();
      await navigate({ to: '/staff/login' });
      if (sendBcPost) sendBcPost({ type: 'session', sender: getUserSlot(user?.id) });
      toast.success(t('toast.session.end'));
    },
  });

  return (
    <NavigationProvider logout={logout} links={<StaffLinks />} logoutPending={isPending}>
      <MainLayout />
    </NavigationProvider>
  );
}

export const Route = createFileRoute('/u/$localId/_staff')({
  beforeLoad: ({ context, location, params }) => {
    // check if logged in
    if (!context.isAuthenticated()) {
      throw redirect({ to: '/staff/login', search: { redirect: location.href } });
    }
    // check if same user type as logged in
    if (
      !context.isValidUserType('staff') &&
      location.pathname !== `/u/${params.localId}/staff/forbidden`
    ) {
      const idx = getSlots().findIndex(
        (el) => el && (JSON.parse(el as string) as Record<string, unknown>).userType === 'staff',
      );
      // if session for requested user type exist go to first found or redirect to login
      if (idx >= 0) {
        // eslint-disable-next-line immutable/no-mutation
        window.location.href = window.location.href.replace(`/u/${params.localId}`, `/u/${idx}`);
        return;
      }
      throw redirect({ to: '/staff/login', search: { redirect: location.href } });
    }
    // check if 2fa is required and enabled or password require update
    if (context.user) {
      const { twoFactorEnabled } = context.user;
      if (context.twoFactorRequired) {
        if (!twoFactorEnabled) throw redirect({ to: '/u/$localId/staff/settings', params });
      }
      if (
        context.user &&
        location.pathname !== `/u/${params.localId}/staff/settings` &&
        context.partialAccess
      ) {
        throw redirect({ to: '/u/$localId/staff/settings', params });
      }
    }
  },
  component: StaffLayout,
});
