import { Button } from '@components/button';
import { Popover, PopoverContent, PopoverTrigger } from '@components/popover';
import { SessionData } from '@ctx/appCtx';
import { useAppContext } from '@hooks/useAppContext';
import { getSlots } from '@utils/apiHelpers';
import { Shuffle } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SwitchUser = () => {
  const { t } = useTranslation('app');
  const { user } = useAppContext();
  const [open, setOpen] = useState(false);
  const otherUsers = getSlots().map((el) => {
    if (typeof el !== 'string') return null;
    return JSON.parse(el) as SessionData;
  });

  if (otherUsers.filter((el) => el && el.user?.id !== user?.id).length === 0) {
    return null;
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          title={t('switchUser')}
          className="h-auto self-center py-1"
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            setOpen(true);
          }}>
          <Shuffle size={18} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-2">
        <h2 className="mb-4 border-b px-4 py-2 text-center text-sm font-semibold">
          {t('switchUser')}
        </h2>
        <ul className="flex flex-col gap-4">
          {otherUsers.map((readUser, i) => {
            if (!readUser) return null;
            if (readUser.user?.id === user?.id) return null;
            return (
              <li key={readUser.user?.id || i}>
                <a
                  className="flex flex-col rounded px-3 py-1.5 text-xs hover:bg-neutral-100 hover:text-primary"
                  href={`/u/${i}/${readUser.userType}`}
                  target="_blank">
                  <strong className="text-sm font-semibold">
                    {readUser.user?.name} <small>({readUser.userType})</small>
                  </strong>
                  {readUser.user?.email}
                </a>
              </li>
            );
          })}
        </ul>
      </PopoverContent>
    </Popover>
  );
};
