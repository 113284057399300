import { useRef } from 'react';
import { FiltersPopover } from './filtersPopover';
import { Button } from '@components/button';
import { useTranslation } from 'react-i18next';
import { useFilters } from '@ctx/filters/context';
import { Filter } from './types';
import { useResetTable } from '@hooks/useResetTable';

export const Filters = () => {
  const filtersRef = useRef<HTMLDivElement | null>(null);
  const { configItems, visibleFilters, applyFilters } = useFilters();
  const { t } = useTranslation('app');
  const { reset } = useResetTable();

  return (
    <div className="hidden gap-4 rounded bg-white p-4 lg:flex">
      <FiltersPopover filters={configItems} />
      <div className="flex flex-wrap gap-x-4 gap-y-2 self-center" ref={filtersRef}>
        {visibleFilters.map((af) => {
          const configItem = configItems.find((item) => item.id == af);
          return configItem ? <Filter key={af} configItem={configItem} /> : null;
        })}
      </div>
      <div className="ms-auto flex gap-2">
        <Button variant="secondary" type="button" onClick={applyFilters}>
          {t('filters.apply')}
        </Button>
        <Button variant="ghost" type="button" onClick={reset}>
          {t('filters.reset')}
        </Button>
      </div>
    </div>
  );
};
