import { useApi } from '@hooks/useApi';
import { MetaData } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { AuditLog } from './types';

type AuditLogsReponse = {
  auditLogs: AuditLog[];
  meta: MetaData;
};

export function useAuditLogsList() {
  const { query } = useApi();

  const { data, isPending } = useQuery<AuditLogsReponse>({
    queryKey: [...queryKeys.auditLogs, window.location.search],
    queryFn: (): Promise<AuditLogsReponse> =>
      query({ path: `v1/audit_logs${window.location.search}` }),
    placeholderData: (previousValue) => previousValue,
  });

  return {
    data: data?.auditLogs || [],
    meta: data?.meta || undefined,
    isPending,
  };
}
