import { useAppContext } from './useAppContext';
import { RouteType } from '@utils/routes';

export const useCheckUserPermissions = () => {
  const { user, isMerchant, userType, token, twoFactorRequired } = useAppContext();

  const verifyUserPermission = (permission: string | string[]) => {
    if (!token || !user) return false;
    if (typeof permission === 'string') {
      return user.permissions.includes(permission);
    }
    return permission.every((perm) => user.permissions.includes(perm));
  };

  const verifyRouteAccess = (route: RouteType) => {
    if (!token || !user) return false;
    if (!userType) return false;
    if (route.allowedUsers !== '*' && !route.allowedUsers.includes(userType)) return false;

    return route.permissions.every((perm) => user.permissions.includes(perm));
  };

  const verifyPasswordExpired = () => {
    if (!user || !user.passwordExpiresAt) return false;

    return new Date(user.passwordExpiresAt).getTime() < new Date().getTime();
  };

  const verifyNeedsToUpdatePassword = () => {
    if (!user || !user.mustResetPasswordAt) return false;

    return new Date(user.mustResetPasswordAt).getTime() < new Date().getTime();
  };

  const verifyNeedsToEnable2FA = () => {
    if (!user) return false;

    return twoFactorRequired && !user.twoFactorEnabled;
  };

  return {
    isMerchant,
    isLogged: Boolean(token),
    verifyUserPermission,
    verifyRouteAccess,
    verifyPasswordExpired,
    verifyNeedsToUpdatePassword,
    verifyNeedsToEnable2FA,
  };
};
