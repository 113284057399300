import { useApi } from '@hooks/useApi';
import { ApiError } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { CardAuth } from './types';

export const useGetCardAuth = (id?: string) => {
  const { query } = useApi();
  const { data, isPending } = useQuery<CardAuth, ApiError>({
    queryKey: [...queryKeys.cardAuth, id],
    queryFn: (): Promise<CardAuth> =>
      query({
        path: `v1/3ds/${id}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: Boolean(id),
  });

  return {
    data,
    isPending,
  };
};
