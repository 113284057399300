import { Order } from '@root/globalTypes';
import { ColumnDef, Row } from '@tanstack/react-table';
import { DataTable } from '@components/dataTable';
import { mapSourceToAsset, mapSchemeToAsset } from '@utils/fileHelpers';
import { formatAmount, formatCardNumber } from '@utils/textHelpers';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '@hooks/useAppContext';
import { CreditCard } from 'lucide-react';
import { useTableSorting } from '@hooks/useTableSorting';
import { TableDateCell } from '@components/table/dateCell';

type OrderTableProps = {
  visibleColumns: string[];
  onRowSelect: (id: string) => void;
  data: Order[];
};

export function useOrdersTable({ visibleColumns, onRowSelect, data }: OrderTableProps) {
  const { userType } = useAppContext();
  const { t } = useTranslation('app');
  const { sorting, updateSorting } = useTableSorting();
  const optionalColumns: ColumnDef<Order>[] = [
    {
      id: 'merchant_id',
      accessorKey: 'merchant.merchantId',
      header: t('table.merchantId'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'merchantName',
      accessorKey: 'merchant.name',
      header: t('table.merchantName'),
      enableSorting: false,
    },
  ];

  const defaultColumns: ColumnDef<Order>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
      enableSorting: false,
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: t('table.description'),
      enableSorting: false,
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: t('table.amount'),
      cell: ({ row }) => (
        <div className="text-end">{formatAmount(row.original.amount, row.original.currency)}</div>
      ),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'captured_amount',
      accessorKey: 'captured_amount',
      header: t('table.capturedAmount'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => (
        <div className="text-end">
          {formatAmount(row.original.capturedAmount, row.original.currency)}
        </div>
      ),
    },
    {
      id: 'refunded_amount',
      accessorKey: 'refunded_amount',
      header: t('table.refundedAmount'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => (
        <div className="text-end">
          {formatAmount(row.original.refundedAmount, row.original.currency)}
        </div>
      ),
    },
    {
      id: 'currency',
      accessorKey: 'currency',
      header: t('table.currency'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'source',
      accessorKey: 'source',
      header: t('table.source'),
      enableSorting: false,
      cell: ({ row }) => {
        const s = row.original.source;
        return formatCardNumber(s.firstDigits, s.lastDigits);
      },
    },
    {
      id: 'scheme',
      accessorKey: 'scheme',
      header: t('table.scheme'),
      enableSorting: false,
      cell: ({ row }) => {
        const s = row.original.source;
        return (
          <img
            alt={s.type}
            src={mapSchemeToAsset(s.scheme)}
            className="h-6 w-12 object-contain object-center"
          />
        );
      },
    },
    {
      id: 'source_type',
      accessorKey: 'source.type',
      header: t('table.sourceType'),
      enableSorting: false,
      cell: ({ row }) => {
        const s = row.original.source;
        if (s.type === 'card')
          return (
            <div className="flex w-12 justify-center">
              <CreditCard size={27} />
            </div>
          );
        return (
          <img
            alt={s.type}
            src={mapSourceToAsset(s.type)}
            className="h-6 w-12 object-contain object-center"
          />
        );
      },
    },
    {
      id: 'source_issuer',
      accessorKey: 'source.issuer',
      header: t('table.sourceIssuer'),
      enableSorting: false,
    },
    {
      id: 'source_card_type',
      accessorKey: 'source.cardType',
      header: t('table.sourceCardType'),
      enableSorting: false,
    },
    {
      id: 'source_country',
      accessorKey: 'source.countryAlpha2',
      header: t('table.sourceCountry'),
      enableSorting: false,
    },
    {
      id: 'source_category',
      accessorKey: 'source.category',
      header: t('table.sourceCategory'),
      enableSorting: false,
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      enableSorting: true,
      sortDescFirst: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
    {
      id: 'updated_at',
      accessorKey: 'updatedAt',
      header: t('table.updatedAt'),
      enableSorting: true,
      sortDescFirst: true,
      cell: ({ row }) => <TableDateCell date={row.original.updatedAt} />,
    },
  ];

  const columns =
    userType === 'merchant'
      ? defaultColumns
      : [defaultColumns[0], ...optionalColumns, ...defaultColumns.slice(1)];

  return {
    columns,
    Table: () => (
      <DataTable
        columns={columns.filter(({ id }) =>
          typeof id === 'string' ? visibleColumns.includes(id) : false,
        )}
        sorting={sorting}
        onSortChange={updateSorting}
        data={data}
        selectionCallback={(row: Row<Order>) => onRowSelect(row.original.id)}
      />
    ),
  };
}
