import { Card, CardContent } from '@components/card';
import { usePaymentControlsTable } from '@components/tables/paymentControls';
import { BaseLayout } from '@layouts/base';
import { MutateObject, PaymentControl } from '@root/globalTypes';
import { PaymentControlsTypes } from '@utils/consts';
import { useTranslation } from 'react-i18next';
import { Row } from '@tanstack/react-table';
import { CreateDialog } from './createDialog';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { PaymentControlApiParams } from '@hooks/forms/paymentControls';
import { TabsContainer } from '@components/tabLink';

type PaymentControlsProps = {
  type: PaymentControlsTypes;
  paymentControls: PaymentControl[];
  tabs: React.ReactNode;
  createHandler: MutateObject<PaymentControl, PaymentControlApiParams>;
  onRowSelect: (row: Row<PaymentControl>) => void;
};

export function PaymentControls({
  paymentControls,
  type,
  onRowSelect,
  tabs,
  createHandler,
}: PaymentControlsProps) {
  const { verifyUserPermission } = useCheckUserPermissions();
  const { t } = useTranslation('app');
  const { Table: PaymentControlsTable } = usePaymentControlsTable({
    data: paymentControls,
    onRowSelect,
  });

  return (
    <BaseLayout
      title={t('pages.paymentControls.title', {
        type: type ? '- ' + t(`pages.paymentControls.${type}`) : '',
      })}
      actions={[
        verifyUserPermission('payment_controls_create') && (
          <CreateDialog
            key="createDialog"
            type={type as PaymentControlsTypes}
            requestHandler={createHandler}
          />
        ),
      ]}>
      <Card>
        <CardContent className="py-3">
          <TabsContainer>{tabs}</TabsContainer>
          <PaymentControlsTable />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}
