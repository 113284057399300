import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectItem,
} from '@components/form/select';
import { useAppContext } from '@hooks/useAppContext';
import { useMatch, useNavigate } from '@tanstack/react-router';
import { getCacheKeyFromRoute } from '@utils/apiHelpers';
import { Dispatch, useMemo, SetStateAction } from 'react';

const ITEMS_PER_PAGE = [10, 25, 50];

export function RowsPerPageSelect({
  rowsPerPage,
  setRowsPerPage,
}: {
  rowsPerPage: string;
  setRowsPerPage: Dispatch<SetStateAction<string>>;
}) {
  const navigate = useNavigate();
  const { updateRowsPerPageCache } = useAppContext();
  const match = useMatch({ strict: false });
  const key = useMemo(() => getCacheKeyFromRoute(match.routeId), [match]);

  function handleRowsPerPageChange(rows: string) {
    navigate({ to: '.', search: (s) => ({ ...s, items: rows }) }).catch((err) =>
      console.error(err),
    );
    updateRowsPerPageCache(key, rows);
    setRowsPerPage(rows);
  }

  return (
    <Select onValueChange={handleRowsPerPageChange} defaultValue={rowsPerPage}>
      <SelectTrigger className="w-16 p-2">
        <SelectValue />
      </SelectTrigger>
      <SelectContent className="w-16 min-w-0">
        {ITEMS_PER_PAGE.map((item) => (
          <SelectItem value={String(item)} key={item}>
            {item}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
