import { useApi } from '@hooks/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { queryKeys } from '@utils/consts';
import { isError } from '@utils/apiHelpers';
import { ApiKeyType } from '@root/globalTypes';

export const useDisableApiKey = (merchantId: string) => {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string>('');

  const { mutate, isPending } = useMutation({
    mutationFn: (id: string): Promise<ApiKeyType> => {
      return query({
        path: `v1/staff/merchants/${merchantId}/api_keys/${id}/disable`,
        opt: {
          method: 'PUT',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isError(data)) setApiError(data.message);
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: [...queryKeys.merchantApiKeys, merchantId] });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
};
