import { useApi } from '@hooks/useApi';
import { AcquirerUser } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

export function useListAcquirerUsers({ acquirerId }: { acquirerId: string }) {
  const { query } = useApi();

  const { data, isPending } = useQuery<AcquirerUser[]>({
    queryKey: [...queryKeys.acquirerUsers, acquirerId, window.location.search],
    queryFn: (): Promise<AcquirerUser[]> =>
      query({
        path: `v1/staff/acquirers/${acquirerId}/users${window.location.search}`,
      }),
  });

  return {
    data: data || [],
    isPending,
  };
}
