import { StatusBadge } from '@components/badge/status';
import { DataTable } from '@components/dataTable';
import { TableDateCell } from '@components/table/dateCell';
import { useTableSorting } from '@hooks/useTableSorting';
import { Agreement } from '@root/globalTypes';
import { ColumnDef, Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

type AgreementsTableProps = {
  visibleColumns: string[];
  data: Agreement[];
  onRowSelect: (id: string) => void;
};

export function useAgreementsTable({ visibleColumns, data, onRowSelect }: AgreementsTableProps) {
  const { t } = useTranslation('app');
  const { sorting, updateSorting } = useTableSorting();

  const columns: ColumnDef<Agreement>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
      enableSorting: false,
    },
    {
      id: 'agreement_id',
      accessorKey: 'agreementId',
      header: t('table.agreementId'),
      enableSorting: false,
    },
    {
      id: 'first_order_id',
      accessorKey: 'firstOrderId',
      header: t('table.firstOrderId'),
      enableSorting: false,
    },
    {
      id: 'type',
      accessorKey: 'type',
      header: t('table.type'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'max_use',
      accessorKey: 'maxUse',
      header: t('table.maxUse'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'variability',
      accessorKey: 'variability',
      header: t('table.variability'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'expiry',
      accessorKey: 'expiry',
      header: t('table.expiry'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.expiry} />,
    },
    {
      id: 'status',
      accessorKey: 'active',
      header: t('table.status'),
      enableSorting: false,
      cell: ({ row }) => <StatusBadge active={row.original.active} />,
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
    {
      id: 'updated_at',
      accessorKey: 'updatedAt',
      header: t('table.updatedAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.updatedAt} />,
    },
  ];

  return {
    columns,
    Table: () => (
      <DataTable
        columns={columns.filter(({ id }) =>
          typeof id === 'string' ? visibleColumns.includes(id) : false,
        )}
        sorting={sorting}
        onSortChange={updateSorting}
        data={data}
        selectionCallback={(row: Row<Agreement>) => onRowSelect(row.original.id)}
      />
    ),
  };
}
