import { useListTransactions } from '@services/global/transactions/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Loader } from '@components/loader';
import { Transactions } from '@pages/transactions';
import { Transaction } from '@root/globalTypes';
import { config } from './-filterConfig';

const StaffTransactions = () => {
  const navigate = useNavigate();
  const { localId } = useParams({ from: '/u/$localId/_staff/staff/transactions/' });
  const { meta, transactions, loading } = useListTransactions();

  const handleSelect = (transaction: Transaction) => {
    navigate({
      to: '/u/$localId/staff/orders/$id/transactions',
      params: { id: transaction.orderId, localId },
      hash: 'tabs',
    }).catch((err) => {
      console.error(err);
    });
  };

  if (loading) return <Loader />;

  return (
    <Transactions
      transactions={transactions}
      meta={meta}
      onRowSelect={handleSelect}
      filterConfig={config}
    />
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/transactions/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['orders_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: StaffTransactions,
});
