import { IconButton } from '@components/button/iconButton';
import { Loader } from '@components/loader';
import { useStaffAcquirersTable } from '@components/tables/staffAcquirers';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { useList } from '@services/staff/acquirers/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

function AcquirersUsers() {
  const { t } = useTranslation('app');
  const { localId } = useParams({ from: '/u/$localId/_staff/staff/acquirers/' });
  const { verifyUserPermission } = useCheckUserPermissions();
  const navigate = useNavigate();
  const { data, isPending } = useList();

  function handleSelect(id: string) {
    if (!verifyUserPermission('acquirers_read')) return;
    navigate({
      to: '/u/$localId/staff/acquirers/$acquirerId/orders',
      params: { acquirerId: id, localId },
    }).catch((err) => console.error(err));
  }

  function newRedirect() {
    navigate({ to: '/u/$localId/staff/acquirers/new', params: { localId } }).catch((err) =>
      console.error(err),
    );
  }

  const { Table: UsersTable } = useStaffAcquirersTable({
    data,
    onRowSelect: handleSelect,
  });

  if (isPending) return <Loader />;

  return (
    <BaseLayout
      title={t('pages.acquirers.title')}
      actions={[
        verifyUserPermission('acquirers_create') && (
          <IconButton icon={<Plus size={18} />} key="new" variant="secondary" onClick={newRedirect}>
            {t('actions.addNew')}
          </IconButton>
        ),
      ]}>
      <UsersTable />
    </BaseLayout>
  );
}

export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirers_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: AcquirersUsers,
});
