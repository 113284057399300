import { useApi } from '@hooks/useApi';
import { ApiError } from '@root/globalTypes';
import { AcquirerLink } from '@root/globalTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { queryKeys } from '@utils/consts';
import { AcquirerLinkData } from '@services/types';

export function isFailure(data: AcquirerLink | ApiError): data is ApiError {
  return (data as ApiError)?.message !== undefined;
}

type UpdateMerchantLinkParams = {
  id: string;
  acquirerId: string;
  successHandler: (data: AcquirerLink) => Promise<void>;
};

export function useUpdateAcquirerLink({
  id,
  acquirerId,
  successHandler,
}: UpdateMerchantLinkParams) {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { mutate, isPending } = useMutation({
    mutationFn: (data: AcquirerLinkData): Promise<AcquirerLink | ApiError> => {
      return query({
        path: `v1/staff/acquirers/${acquirerId}/links/${id}`,
        data: data,
        opt: {
          method: 'PUT',
        },
      });
    },
    onSettled: async (data) => {
      if (!data) return;
      if (isFailure(data)) {
        setApiError(data);
      } else {
        await successHandler(data);
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: [...queryKeys.acquirerLinks, acquirerId] });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
}
