import { createFileRoute, redirect } from '@tanstack/react-router';
import { paymentControlsParams } from '@utils/consts';

export const Route = createFileRoute('/u/$localId/_merchant/merchant/payment-controls/')({
  beforeLoad: ({ params }) => {
    if (Object.keys(params).length === 1) {
      throw redirect({
        to: `/u/$localId/merchant/payment-controls/$type`,
        params: { localId: params.localId, type: paymentControlsParams[0] },
      });
    }
  },
  component: () => null,
});
