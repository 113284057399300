import { Loader } from '@components/loader';
import { useGetOrder } from '@services/global/orders/get';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { outletNavScroll } from '@utils/apiHelpers';
import { useEffect } from 'react';
import { Page404 } from '@pages/404';
import { OrderSubMerchant } from '@pages/orders/subMerchant';

const SubMerchant = () => {
  const { id, localId } = useParams({
    from: '/u/$localId/_merchant/merchant/orders/$id/subMerchant',
  });
  const { data, loading } = useGetOrder(id);

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (loading) return <Loader />;
  if (!data) return <Page404 fallbackPath={`/u/${localId}/merchant/orders`} />;

  return <OrderSubMerchant order={data} />;
};

export const Route = createFileRoute('/u/$localId/_merchant/merchant/orders/$id/subMerchant')({
  component: SubMerchant,
});
