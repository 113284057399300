/* eslint-disable immutable/no-mutation */
import { formatBody, formatResponse } from '@utils/textHelpers';
import { useAppContext } from './useAppContext';
import { useTranslation } from 'react-i18next';
import { ApiError } from '@root/globalTypes';

export class CustomApiError extends Error {
  public code: number;

  constructor({ code, message }: { code: number; message: string }) {
    super(message);
    /* eslint-disable-next-line */
    this.code = code;
  }
}

export const useApi = () => {
  const { token } = useAppContext();
  const { t } = useTranslation('app');
  const query = async <T>({
    path,
    data,
    opt,
    useAuth = true,
    raw = false,
    formData = false,
  }: {
    path: string;
    data?: Record<string, unknown>;
    opt?: RequestInit;
    formData?: boolean;
    useAuth?: boolean;
    raw?: boolean;
  }) => {
    if (!navigator.onLine) {
      throw new Error(t('errors.offline'));
    }
    const createOptions = () => {
      const options: RequestInit = {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        cache: 'reload',
      };
      if (!formData)
        options.headers = {
          ...options.headers,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        };
      options.method = opt?.method || 'GET';
      if (data) options.body = JSON.stringify(formatBody(data));
      if (opt?.body) options.body = opt.body;
      if (useAuth && options?.headers && token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return options;
    };
    const resp = await fetch(`${import.meta.env.VITE_API_URL}/${path}`, createOptions());

    if (resp.status >= 500) {
      throw new Error(t('errors.serverError', { code: resp.status }));
    }
    if (resp.status === 204) {
      return {} as T;
    }
    const respData = (await resp.json()) as T;

    if (resp.status >= 400 && resp.status !== 422) {
      let message = (respData as ApiError).message;
      if (!message) {
        if (resp.status === 400) message = t('errors.400');
        if (resp.status === 401) message = t('errors.401');
        if (resp.status === 404) message = t('errors.404');
      }
      throw new CustomApiError({
        code: resp.status,
        message: t('errors.apiError', { code: resp.status, message: message }),
      });
    }

    return raw ? respData : formatResponse(respData);
  };

  return {
    query,
  };
};
