import { ComboBoxItemType } from '@components/combobox';
import { FilterConfigItem, FiltersConfig } from '@ctx/filters/context';
import { useAppContext } from '@hooks/useAppContext';
import { useGetAuditLogSubjects } from '@services/global/auditLogs/subjects';

const ACTION_TYPE_OPTIONS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Passive',
    value: 'passive',
  },
];

const ORGANIZATION_TYPE_OPTIONS = [
  {
    label: 'Merchant',
    value: 'Merchant',
  },
  {
    label: 'Acquirer',
    value: 'Acquirer',
  },
];

// TODO: Maybe move config for each user to separate file?
export function useAuditLogConfig(): FiltersConfig {
  const { userType } = useAppContext();
  const { data: subjects } = useGetAuditLogSubjects();

  const performerFactory = (opt: ComboBoxItemType[]): FilterConfigItem => ({
    id: 'performer_type',
    filterType: 'select',
    options: opt,
    groupe: [
      { id: 'performer_id', filterType: 'text', disabledBy: 'performer_type' },
      { id: 'performer_email', filterType: 'text', disabledBy: 'performer_type' },
    ],
  });

  const organizationFilter: FilterConfigItem = {
    id: 'organization_type',
    filterType: 'select',
    options: ORGANIZATION_TYPE_OPTIONS,
    groupe: [{ id: 'organization_name', filterType: 'text', disabledBy: 'organization_type' }],
  };

  const BASE_FILTERS: FilterConfigItem[] = [
    {
      id: 'subject_type',
      filterType: 'select',
      options: subjects.map((s) => ({ label: s, value: s })),
      groupe: [{ id: 'subject_id', filterType: 'text', disabledBy: 'subject_type' }],
    },
    { id: 'action_type', filterType: 'select', options: ACTION_TYPE_OPTIONS },
    { id: 'action_name', filterType: 'text' },
    { id: 'created_at', filterType: 'date', format: 'date' },
  ];

  const MERCHANT_FILTERS: FilterConfigItem[] = [
    performerFactory([
      { label: 'Merchant User', value: 'MerchantUser' },
      { label: 'Api Key', value: 'ApiKey' },
    ]),
    ...BASE_FILTERS,
  ];

  const ACQUIRER_FILTERS: FilterConfigItem[] = [
    performerFactory([
      { label: 'Acquirer User', value: 'AcquirerUser' },
      { label: 'Acquirer Api Key', value: 'AcquirerApiKey' },
      { label: 'Merchant User', value: 'MerchantUser' },
      { label: 'Api Key', value: 'ApiKey' },
    ]),
    organizationFilter,
    ...BASE_FILTERS,
  ];

  const STAFF_FILTERS: FilterConfigItem[] = [
    performerFactory([
      { label: 'Acquirer User', value: 'AcquirerUser' },
      { label: 'Merchant User', value: 'MerchantUser' },
      { label: 'Staff User', value: 'StaffUser' },
      { label: 'Acquirer Api Key', value: 'AcquirerApiKey' },
      { label: 'Api Key', value: 'ApiKey' },
    ]),
    organizationFilter,
    ...BASE_FILTERS,
  ];

  if (userType === 'staff') {
    return {
      items: STAFF_FILTERS,
      default: [
        'performer_type',
        'performer_email',
        'organization_type',
        'organization_name',
        'subject_type',
        'subject_id',
        'created_at',
      ],
    };
  } else if (userType === 'acquirer') {
    return {
      items: ACQUIRER_FILTERS,
      default: [
        'performer_type',
        'performer_email',
        'organization_type',
        'organization_name',
        'subject_type',
        'subject_id',
        'created_at',
      ],
    };
  } else {
    return {
      items: MERCHANT_FILTERS,
      default: ['performer_type', 'performer_email', 'subject_type', 'subject_id', 'created_at'],
    };
  }
}
