import { Loader } from '@components/loader';
import { ColumnFilterPopover } from '@components/popover/columnsFilterPopover';
import { useMerchantLinksTable } from '@components/tables/merchantLinks';
import { useToggleTableColumns } from '@hooks/useToggleTableColumns';
import { BaseLayout } from '@layouts/base';
import { useMerchantLinkList } from '@services/merchant/links/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function MerchantLinks() {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { localId } = useParams({ from: '/u/$localId/_merchant/merchant/links/' });
  const { data, isPending } = useMerchantLinkList();
  const predefinedCols = useMemo(
    () => [
      'id',
      'acquirer_link_id',
      'mcc',
      'schemes',
      'currencies',
      'priority',
      'created_at',
      'updated_at',
    ],
    [],
  );
  const { visibleColumns, setColumns } = useToggleTableColumns({ predefinedCols });
  const { columns, Table: MerchantLinksTable } = useMerchantLinksTable({
    data,
    visibleColumns,
    onRowSelect: (row) => {
      const { id } = row.original;
      navigate({
        to: `/u/$localId/merchant/links/$id`,
        params: { id, localId },
      }).catch((err) => console.error(err));
    },
  });

  if (isPending) return <Loader />;

  return (
    <BaseLayout
      title={t('pages.links.title')}
      actions={[
        <ColumnFilterPopover
          key="colFilter"
          activeCols={visibleColumns}
          setActiveCols={setColumns}
          columns={columns.map((col) => ({ id: col.id as string, label: col.header as string }))}
          defaultColumns={predefinedCols}
        />,
      ]}>
      <MerchantLinksTable />
    </BaseLayout>
  );
}
export const Route = createFileRoute('/u/$localId/_merchant/merchant/links/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_links_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantLinks,
});
