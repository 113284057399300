import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { Agreement } from '@root/globalTypes';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';

export function useGetAgreement({ id }: { id: string }) {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();

  const { data, isPending } = useQuery<Agreement>({
    queryKey: [...queryKeys.agreements, id],
    queryFn: (): Promise<Agreement> =>
      query({
        path: `v1/agreements/${id}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged,
  });

  return {
    data: data,
    isPending,
  };
}
