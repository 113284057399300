import { LoginLayout } from '@layouts/login';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { MerchantLoginForm } from '@components/forms/merchant/login';

function MerchantLogin() {
  const { t } = useTranslation('app');

  return (
    <LoginLayout title={t('pages.login.title')}>
      <MerchantLoginForm />
    </LoginLayout>
  );
}

export const Route = createFileRoute('/merchant/login')({
  component: MerchantLogin,
});
