import { Button } from '@components/button';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { MerchantUser } from '@root/globalTypes';
import { UseMutateFunction } from '@tanstack/react-query';
import { UserMinus } from 'lucide-react';

type DisableUserBtnProps = {
  user: MerchantUser;
  onDisable: UseMutateFunction<MerchantUser, Error, string, unknown>;
};

export function DisableMerchantUserButton({ user, onDisable }: DisableUserBtnProps) {
  const { verifyUserPermission } = useCheckUserPermissions();

  if (!verifyUserPermission(`merchant_users_disable`) || !user.active) {
    return null;
  }

  return (
    <Button
      size="sm"
      className="w-14"
      variant="destructive-outline"
      type="button"
      onClick={() => onDisable(user.id)}>
      <UserMinus className="h-5" />
    </Button>
  );
}
