import { Loader } from '@components/loader';
import { Page404 } from '@pages/404';
import { CardAuthShow } from '@pages/cardAuths/id';
import { useGetCardAuth } from '@services/global/cardAuths/get';
import { createFileRoute, useParams } from '@tanstack/react-router';

function CardAuthDetails() {
  const { id } = useParams({ from: '/u/$localId/_staff/staff/card-authentications/$id' });
  const { data, isPending } = useGetCardAuth(id);

  if (isPending) return <Loader />;
  if (!data) return <Page404 />;

  return <CardAuthShow cardAuth={data} />;
}

export const Route = createFileRoute('/u/$localId/_staff/staff/card-authentications/$id')({
  component: CardAuthDetails,
});
