import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useApi } from '@hooks/useApi';
import { ApiError } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { PaymentControlsTypes, queryKeys } from '@utils/consts';
import { PaymentControl } from '@root/globalTypes';
import { mapControlType } from '@utils/apiHelpers';

export const useGetControl = (type: PaymentControlsTypes, id?: string) => {
  const { isLogged } = useCheckUserPermissions();
  const url = `v1/payment_controls/${mapControlType(type)}/${id}`;
  const { query } = useApi();
  const { data, isPending } = useQuery<PaymentControl, ApiError>({
    queryKey: [...queryKeys.paymentControls, id],
    queryFn: () =>
      query({
        path: url,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged || Boolean(id),
  });

  return {
    data,
    loading: isPending,
  };
};
