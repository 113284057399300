import { useState } from 'react';
import { TwoFactorSetupContext } from './context';
import { AcquirerUser, MerchantUser, StaffUser } from '@root/globalTypes';

export function TwoFactorSetupContextProvider({
  user,
  children,
}: {
  user: MerchantUser | AcquirerUser | StaffUser;
  children: React.ReactNode;
}) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showBackupCodesDialog, setShowBackupCodesDialog] = useState(false);
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const [qrCodeUri, setQrCodeUri] = useState<string>('');

  return (
    <TwoFactorSetupContext.Provider
      value={{
        user,
        showConfirmDialog,
        setShowConfirmDialog,
        showBackupCodesDialog,
        setShowBackupCodesDialog,
        backupCodes,
        setBackupCodes,
        setQrCodeUri,
        qrCodeUri,
      }}>
      {children}
    </TwoFactorSetupContext.Provider>
  );
}
