import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { MutateObject } from '@root/globalTypes';
import { useTranslation } from 'react-i18next';

export const ACCEPTED_FILE_TYPES = {
  madaBinDb: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  binDb: ['text/csv', 'application/zip'],
};
export const IMPORT_TYPES = ['madaBinDb', 'binDb'] as const;

export function useImportBinsForm({
  requestHandler,
}: {
  requestHandler: MutateObject<undefined, FormData>;
}) {
  const { t } = useTranslation('app');

  const fileValidation = z.instanceof(File, { message: t('form.file.required') }).refine((file) => {
    if (!file) return;
    return Object.values(ACCEPTED_FILE_TYPES).flat().includes(file.type);
  }, t('form.file.invalidType'));

  const schema = z.object({
    madaBinDb: fileValidation,
    binDb: fileValidation,
  });

  const defaultValues = {
    madaBinDb: undefined,
    binDb: undefined,
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, error, isPending } = requestHandler;

  const onSubmit = async (values: z.infer<typeof schema>) => {
    const formData = new FormData();
    if (values.madaBinDb) formData.append('mada_bin_db', values.madaBinDb);
    if (values.binDb) formData.append('bin_db', values.binDb);
    mutate(formData);
  };

  return {
    form,
    onSubmit,
    isPending,
    error: error?.message,
  };
}
