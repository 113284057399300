import { useApi } from '@hooks/useApi';
import { ApiError } from '@root/globalTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useState } from 'react';
import { OrderDetails } from '@root/globalTypes';
import { isError } from '@utils/apiHelpers';
import { UseFormSetError } from 'react-hook-form';

export type AmountData = {
  amount: number;
};

export type OrderActionResp = OrderDetails | ApiError;

export const usePostOrderRefund = ({
  id,
  callback,
  setError,
}: {
  id: string;
  callback?: () => void;
  setError: UseFormSetError<{
    amount: number;
  }>;
}) => {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string>('');

  const { mutate, isPending } = useMutation({
    mutationFn: (amountData: AmountData): Promise<OrderActionResp> => {
      return query({
        path: `v1/orders/${id}/refund`,
        data: amountData,
        opt: {
          method: 'POST',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isError(data)) {
        setApiError(data.message);
        if (data.errors) {
          Object.keys(data.errors).forEach((err) => {
            if (!data.errors) return;
            setError(err as 'amount' | 'root', {
              type: 'manual',
              message: data.errors[err].join(', '),
            });
          });
        }
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: [...queryKeys.order, id] });
      if (callback) callback();
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
};
