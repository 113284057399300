import { localStore } from '@utils/localStore';
import { useState, useEffect } from 'react';
import { useAppContext } from './useAppContext';

export function useToggleTableColumns({ predefinedCols }: { predefinedCols: string[] }) {
  const { uid } = useAppContext();
  const key = `${location.pathname}__columns`;
  const [visibleColumns, setVisibleColumns] = useState<string[]>(predefinedCols);

  useEffect(() => {
    const defaultCols = localStore.getItemValue(uid, key) as string[];
    if (defaultCols) {
      setVisibleColumns(defaultCols);
    }
  }, [key, uid]);

  function setColumns(columns: string[]) {
    setVisibleColumns(columns);

    if (uid) {
      localStore.updateItem(
        uid,
        {
          [key]: columns,
        },
        1000 * 60 * 60 * 24 * 30,
      );
    }
  }

  return {
    visibleColumns,
    setColumns,
  };
}
