import { PASSWORD_REGEX } from '@utils/passwordsHelpers';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export function useSchema() {
  const { t } = useTranslation('app');

  return z.object({
    password: z
      .string()
      .trim()
      .min(1, {
        message: t('form.password.required'),
      })
      .regex(PASSWORD_REGEX, { message: t('form.password.complexity') }),
    confirmPassword: z.custom<string>((val) => {
      const { value } = document.querySelector('[name="password"]') as HTMLInputElement;
      return val === value;
    }, t('form.passwordConfirmation.required')),
  });
}
