import { Loader } from '@components/loader';
import { BaseLayout } from '@layouts/base';
import { useGetApiKey } from '@services/acquirer/apiKeys/get';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { Card, CardContent } from '@components/card';
import { useGetPermissionGroups } from '@services/acquirer/apiKeys/permissionGroups';
import { useUpdateApiKey } from '@services/acquirer/apiKeys/update';
import { useApiKeyForm } from '@hooks/forms/apiKeys';
import { ApiKeyForm } from '@components/forms/apiKeys';
import { Page404 } from '@pages/404';

const EditApiKey = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { id, localId } = useParams({ from: '/u/$localId/_acquirer/acquirer/api-keys/$id/edit' });
  const { data, isPending: loading } = useGetApiKey(id);
  const { data: permissions } = useGetPermissionGroups();

  const requestHandler = useUpdateApiKey({
    id,
    callback: () => {
      toast.success(t('toast.apiKeys.updateSuccess'));
      navigate({
        to: '/u/$localId/acquirer/api-keys/$id',
        params: { id, localId },
        replace: true,
      }).catch((err) => {
        console.error(err);
      });
    },
  });

  const form = useApiKeyForm({
    apiKey: data,
    requestHandler,
  });

  if (loading) return <Loader />;
  if (!data) return <Page404 />;

  return (
    <BaseLayout isChild title={t('pages.apiKeys.edit')}>
      <Card>
        <CardContent className="p-6">
          {permissions && (
            <ApiKeyForm
              formObj={form}
              permissions={permissions}
              buttonText={t('form.submit.apiKeyUpdate')}
            />
          )}
        </CardContent>
      </Card>
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/api-keys/$id/edit')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['api_keys_read', 'api_keys_update'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: EditApiKey,
});
