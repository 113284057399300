import { Button } from '@components/button';
import { ColumnFilterPopover } from '@components/popover/columnsFilterPopover';
import { useOrdersTable } from '@components/tables/orders';
import { useToggleTableColumns } from '@hooks/useToggleTableColumns';
import { BaseLayout } from '@layouts/base';
import { Order } from '@root/globalTypes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const OrdersPreview = ({
  orders,
  onSelect,
  onViewMore,
}: {
  orders: Order[];
  onSelect: (id: string) => void;
  onViewMore: () => void;
}) => {
  const { t } = useTranslation('app');
  const truncatedOrders = orders.slice(0, 5);
  const predefinedCols = useMemo(
    () => [
      'id',
      'amount',
      'currency',
      'source_type',
      'scheme',
      'source',
      'created_at',
      'updated_at',
    ],
    [],
  );
  const { visibleColumns, setColumns } = useToggleTableColumns({ predefinedCols });
  const { columns, Table: OrdersTable } = useOrdersTable({
    data: truncatedOrders,
    onRowSelect: onSelect,
    visibleColumns,
  });

  return (
    <BaseLayout
      title={t('pages.orders.title')}
      actions={[
        <ColumnFilterPopover
          key="colFilter"
          activeCols={visibleColumns}
          setActiveCols={setColumns}
          columns={columns.map((col) => ({ id: col.id as string, label: col.header as string }))}
          defaultColumns={predefinedCols}
        />,
      ]}>
      <OrdersTable />
      {truncatedOrders.length < orders.length && (
        <Button className="mx-4 mt-[-1em]" size="lg" variant="accent" onClick={onViewMore}>
          {t('showMore')}
        </Button>
      )}
    </BaseLayout>
  );
};
