import { Card, CardContent } from '@components/card';
import { BaseLayout } from '@layouts/base';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { useGetPermissionGroups } from '@services/merchant/apiKeys/permissionsGroups';
import { useCreateApiKey } from '@services/staff/merchant/apiKeys/create';
import { useApiKeyForm } from '@hooks/forms/apiKeys';
import { ApiKeyForm } from '@components/forms/apiKeys';

const NewApiKey = () => {
  const { t } = useTranslation('app');
  const { localId, merchantId } = useParams({
    from: '/u/$localId/_staff/staff/merchants/$merchantId/api-keys/new',
  });
  const navigate = useNavigate();
  const { data: permissions } = useGetPermissionGroups();

  const requestHandler = useCreateApiKey({
    merchantId,
    callback: () => {
      toast.success(t('toast.apiKeys.createSuccess'));
      navigate({
        to: '/u/$localId/staff/merchants/$merchantId/api-keys',
        params: { localId, merchantId },
        replace: true,
      }).catch((err) => {
        console.error(err);
      });
    },
  });

  const form = useApiKeyForm({ requestHandler });

  return (
    <BaseLayout isChild title={t('pages.apiKeys.create')}>
      <Card>
        <CardContent className="p-6">
          {permissions && (
            <ApiKeyForm
              formObj={form}
              permissions={permissions}
              buttonText={t('form.submit.apiKeyCreate')}
            />
          )}
        </CardContent>
      </Card>
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/merchants/$merchantId/api-keys/new')(
  {
    beforeLoad: ({ context }) => {
      if (!context.userHasPermissions(['api_keys_create'])) {
        throw redirect({
          to: `/u/$localId/${context.userType}/forbidden`,
        });
      }
    },
    component: NewApiKey,
  },
);
