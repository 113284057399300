import { PaymentControlsTypes, paymentControlsParams } from '@utils/consts';
import { Dialog, DialogTrigger, DialogContent, DialogTitle } from '@components/dialog';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@components/form/select';
import { Plus } from 'lucide-react';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { PaymentControlForm } from '@components/forms/paymentControls';
import { PaymentControlApiParams, usePaymentControlForm } from '@hooks/forms/paymentControls';
import { usePaymentControlFormContext } from '@hooks/usePaymentControlsContext';
import { MutateObject, PaymentControl } from '@root/globalTypes';
import { IconButton } from '@components/button/iconButton';

export const CreateDialog = ({
  type,
  requestHandler,
}: {
  type: PaymentControlsTypes;
  requestHandler: MutateObject<PaymentControl, PaymentControlApiParams>;
}) => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const { formOpen, setFormOpen, formType, setFormType } = usePaymentControlFormContext();

  const form = usePaymentControlForm({ requestHandler });

  const resetForm = useCallback(() => {
    form.form.reset();
  }, [form.form]);

  useEffect(() => {
    if (formOpen) setFormType(type);
  }, [formOpen, type, setFormType]);

  useEffect(() => {
    if (!formOpen.create) resetForm();
  }, [formOpen.create, resetForm]);

  const handleTypeChange = (v: PaymentControlsTypes) => {
    setFormType(v);
  };

  if (!verifyUserPermission('payment_controls_create')) return null;

  return (
    <Dialog
      open={formOpen.create}
      onOpenChange={(open) => setFormOpen({ ...formOpen, create: open })}>
      <DialogTrigger asChild>
        <IconButton key="new" icon={<Plus size={18} />} variant="secondary">
          {t('pages.paymentControls.add', { type: t(`pages.paymentControls.${type}`) })}
        </IconButton>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle className="flex items-center gap-2 whitespace-nowrap">
          {t('pages.paymentControls.addTitle')}
          <div className="w-fit">
            <Select value={formType} onValueChange={handleTypeChange}>
              <SelectTrigger className="w-full rounded bg-primary/10 py-2 text-sm font-normal text-primary">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {paymentControlsParams.map((p) => (
                  <SelectItem value={p} key={p}>
                    {t(`pages.paymentControls.${p}`)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </DialogTitle>
        <PaymentControlForm key={formType} type={formType} formObj={form} />
      </DialogContent>
    </Dialog>
  );
};
