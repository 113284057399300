import { IconButton } from '@components/button/iconButton';
import { useMerchantLinksTable } from '@components/tables/merchantLinks';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { MerchantLink } from '@root/globalTypes';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function MerchantLinksList({
  data,
  onRowSelect,
  newRedirect,
}: {
  data: MerchantLink[];
  onRowSelect: (id: string) => void;
  newRedirect: () => void;
}) {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();

  const { Table: MerchantLinksTable } = useMerchantLinksTable({
    data,
    onRowSelect: (row) => {
      const { id } = row.original;
      onRowSelect(id);
    },
  });

  return (
    <BaseLayout
      title={t('pages.links.title')}
      actions={[
        verifyUserPermission('merchant_links_create') && (
          <IconButton key="new" icon={<Plus size={18} />} variant="secondary" onClick={newRedirect}>
            {t('actions.addNew')}
          </IconButton>
        ),
      ]}>
      <MerchantLinksTable />
    </BaseLayout>
  );
}
