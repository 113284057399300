import { AcquirerLinks } from '@components/navigation/acquirer/links';
import { NavigationProvider } from '@ctx/navigation';
import { useAppContext } from '@hooks/useAppContext';
import { MainLayout } from '@layouts/main';
import { useAcquirerLogout } from '@services/acquirer/session/delete';
import { createFileRoute, redirect, useNavigate, useRouter } from '@tanstack/react-router';
import { getSlots, getUserSlot } from '@utils/apiHelpers';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function AcquirerLayout() {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const router = useRouter();

  const { clearData, sendBcPost, user } = useAppContext();
  const { mutate: logout, isPending } = useAcquirerLogout({
    successHandler: async () => {
      clearData();
      await router.invalidate();
      await navigate({ to: '/acquirer/login' });
      if (sendBcPost) sendBcPost({ type: 'session', sender: getUserSlot(user?.id) });
      toast.success(t('toast.session.end'));
    },
  });

  return (
    <NavigationProvider logout={logout} links={<AcquirerLinks />} logoutPending={isPending}>
      <MainLayout />
    </NavigationProvider>
  );
}

export const Route = createFileRoute('/u/$localId/_acquirer')({
  beforeLoad: async ({ context, location, params }) => {
    // check if logged in
    if (!context.isAuthenticated()) {
      throw redirect({ to: '/acquirer/login', search: { redirect: location.href } });
    }
    // check if same user type as logged in
    if (
      !context.isValidUserType('acquirer') &&
      location.pathname !== `/u/${params.localId}/acquirer/forbidden`
    ) {
      const idx = getSlots().findIndex(
        (el) => el && (JSON.parse(el as string) as Record<string, unknown>).userType === 'acquirer',
      );
      // if session for requested user type exist go to first found or redirect to login
      if (idx >= 0) {
        // eslint-disable-next-line immutable/no-mutation
        window.location.href = window.location.href.replace(`/u/${params.localId}`, `/u/${idx}`);
        return;
      }
      throw redirect({ to: '/acquirer/login', search: { redirect: location.href } });
    }
    // check if 2fa is required and enabled or password require update
    if (
      context.user &&
      location.pathname !== `/u/${params.localId}/acquirer/settings` &&
      context.partialAccess
    ) {
      throw redirect({ to: '/u/$localId/acquirer/settings', params });
    }
  },
  component: AcquirerLayout,
});
