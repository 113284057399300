import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useVerifyOtp } from '@services/merchant/session/verifyOtp';
import { AuthRespSuccess, MerchantUser } from '@root/globalTypes';

export const useSecondStepForm = ({
  handleSuccess,
}: {
  handleSuccess: (data: AuthRespSuccess<MerchantUser>) => Promise<void>;
}) => {
  const { t } = useTranslation('app');

  const schema = z.object({
    token: z.string(),
    otpCode: z
      .string()
      .trim()
      .min(1, { message: t('form.otpCode.required') })
      .min(6, { message: t('form.otpCode.length') }),
  });

  const defaultValues = {
    token: '',
    otpCode: '',
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, isPending, error } = useVerifyOtp({ handleSuccess });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    isPending,
    error,
  };
};
