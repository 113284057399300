import { Button } from '@components/button';
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '@components/dialog';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { AcquirerLink } from '@root/globalTypes';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { useDeleteAcquirerLink } from '@services/staff/acquirer/links/delete';

export function DeleteAcquirerLinkDialog({
  acquirerId,
  acquirerLink,
}: {
  acquirerId: string;
  acquirerLink: AcquirerLink;
}) {
  const navigate = useNavigate();
  const { localId } = useParams({ strict: false });
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const [open, setOpen] = useState(false);

  const onSuccess = async () => {
    await navigate({
      to: '/u/$localId/staff/acquirers/$acquirerId/links',
      params: { acquirerId, localId: localId || '0' },
    });
    toast.success(t('toast.acquirerLink.deleteSuccess'));
  };

  const { mutate, isPending } = useDeleteAcquirerLink({
    id: acquirerLink.id,
    acquirerId: acquirerId,
    callback: onSuccess,
  });

  if (!verifyUserPermission('acquirer_links_delete')) return null;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive">{t('pages.acquirerLink.remove.title')}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle className="flex items-center gap-2 whitespace-nowrap">
          {t('pages.acquirerLink.remove.title')}
        </DialogTitle>
        <p>{t('pages.acquirerLink.remove.info')}</p>
        <Button disabled={isPending} onClick={() => mutate()} variant="destructive">
          {t('actions.delete')}
        </Button>
        <Button disabled={isPending} onClick={() => setOpen(false)} variant="ghost">
          {t('actions.cancel')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
