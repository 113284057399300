import { Input } from '@components/form/input';
import { Label } from '@components/form/label';
import { useTranslation } from 'react-i18next';
import { validateKeyDown } from '@utils/textHelpers';
import { ChangeEvent, useMemo } from 'react';
import { useFilters } from '@ctx/filters/context';
import { FilterItem } from './filterItem';
import { FilterProps } from '.';

export const CardExpiryFilter = ({ configItem }: FilterProps) => {
  const { t } = useTranslation('app');
  const { activeFilters, setFilter } = useFilters();
  const mField = activeFilters.card_expiry?.month;
  const yField = activeFilters.card_expiry?.year;

  const formattedMonth = useMemo(() => {
    return mField ? (mField.length == 1 ? `0${mField}` : mField) : '';
  }, [mField]);

  const formattedYear = useMemo(() => {
    return yField?.substring(yField.length - 2) || '';
  }, [yField]);

  const formattedValue = useMemo(() => {
    if (!formattedMonth.length && !formattedYear.length) return '';

    return `: ${formattedMonth}${formattedYear ? `/${formattedYear}` : ''}`;
  }, [formattedYear, formattedMonth]);

  function handleMonthChange(ev: ChangeEvent<HTMLInputElement>) {
    let value = ev.target.value;

    if (value.length > 2) value = value.substring(1);
    if (Number(value) > 12) value = '12';
    if (Number(value) > 12) value = '1';

    setFilter('card_expiry', {
      ...activeFilters.card_expiry,
      month: value ? Number(value).toString() : '',
    });
  }

  function handleYearChange(ev: ChangeEvent<HTMLInputElement>) {
    let value = ev.target.value;

    if (value.length > 2) value = value.substring(1);

    setFilter('card_expiry', {
      ...activeFilters.card_expiry,
      year: value ? (Number(value) + 2000).toString() : '',
    });
  }

  return (
    <FilterItem displayedValue={formattedValue} configItem={configItem}>
      <div className="flex flex-col gap-4">
        <Label htmlFor="month_alt">{t(`filters.card_expiry.label`)}</Label>
        <div className="flex gap-4">
          <div className="relative space-y-2">
            <Label className="absolute left-3 bg-white px-2 text-xs" htmlFor="month_alt">
              {t(`filters.month.label`)}
            </Label>
            <Input
              id="month_alt"
              name="month_alt"
              placeholder="MM"
              onChange={handleMonthChange}
              value={formattedMonth}
              onKeyDown={(e) => {
                if (!validateKeyDown(e, /[0-9]|/)) e.preventDefault();
              }}
            />
          </div>
          <div className="relative space-y-2">
            <Label className="absolute left-3 bg-white px-2 text-xs" htmlFor="year_alt">
              {t(`filters.year.label`)}
            </Label>
            <Input
              id="year_alt"
              name="year_alt"
              placeholder="YY"
              onChange={handleYearChange}
              value={formattedYear}
              onKeyDown={(e) => {
                if (!validateKeyDown(e, /[0-9]|/)) e.preventDefault();
              }}
            />
          </div>
        </div>
      </div>
    </FilterItem>
  );
};
