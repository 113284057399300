import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { AuditLog } from '@services/global/auditLogs/types';
import { Fingerprint, KeySquare, Mail, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function AuditLogPerformer({ auditLog }: { auditLog: AuditLog }) {
  const { t } = useTranslation('app');

  function performerTypeIcon() {
    return auditLog.performer.email ? <User /> : <KeySquare />;
  }

  return (
    <DetailsCard>
      <DetailsCardItem
        alt
        icon={<Fingerprint />}
        label={t('table.id')}
        data={auditLog.performer.id}
        column={1}
      />
      {auditLog.performer.email && (
        <DetailsCardItem
          alt
          icon={<Mail />}
          label={t('table.email')}
          data={auditLog.performer.email}
          column={1}
        />
      )}
      <DetailsCardItem
        alt
        icon={performerTypeIcon()}
        label={t('table.type')}
        data={auditLog.performerType}
        column={2}
      />
    </DetailsCard>
  );
}
