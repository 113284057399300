import { Loader } from '@components/loader';
import { MerchantBriefDetails } from '@pages/merchants/brief';
import { useGetCardAuth } from '@services/global/cardAuths/get';
import { createFileRoute, useParams } from '@tanstack/react-router';

const Merchant = () => {
  const { id } = useParams({
    from: '/u/$localId/_merchant/merchant/card-authentications/$id/merchant',
  });
  const { data, isPending } = useGetCardAuth(id);

  if (isPending) return <Loader />;
  if (!data?.merchant) return null;

  return <MerchantBriefDetails merchant={data.merchant} />;
};

export const Route = createFileRoute(
  '/u/$localId/_merchant/merchant/card-authentications/$id/merchant',
)({
  component: Merchant,
});
