import { Button } from '@components/button';
import { MerchantUser } from '@root/globalTypes';
import { ColumnDef } from '@tanstack/react-table';
import { PencilIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { UserStatusBadge } from './statusBadge';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { DataTable } from '@components/dataTable';
import { DisableMerchantUserButton } from './disableUserButton';
import { EnableMerchantUserButton } from './enableUserButton';
import { useTableSorting } from '@hooks/useTableSorting';
import { TableDateCell } from '@components/table/dateCell';

type MerchantUsersTableProps = {
  data: MerchantUser[];
  visibleColumns?: string[];
  onRowSelect: (id: string) => void;
  onDisable: (id: string) => void;
  onEnable: (id: string) => void;
};

export function useMerchantUsersTable({
  data,
  visibleColumns,
  onRowSelect,
  onDisable,
  onEnable,
}: MerchantUsersTableProps) {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const { sorting, updateSorting } = useTableSorting();

  const columns: ColumnDef<MerchantUser>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: t('table.name'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'email',
      accessorKey: 'email',
      header: t('table.email'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: t('table.description'),
      enableSorting: false,
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: t('table.status'),
      enableSorting: false,
      cell: ({ row }) => <UserStatusBadge active={row.original.active} />,
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
    {
      id: 'updated_at',
      accessorKey: 'updatedAt',
      header: t('table.updatedAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.updatedAt} />,
    },
    {
      id: 'actions',
      accessorKey: 'actions',
      header: t('table.actions'),
      enableSorting: false,
      cell: ({ row }) => {
        const rowUser = row.original;
        return (
          <div className="flex items-center gap-2">
            {verifyUserPermission('merchant_users_read') && (
              <Button
                variant="primary"
                size="sm"
                className="w-14"
                onClick={() => onRowSelect(rowUser.id)}>
                <PencilIcon className="h-5" />
              </Button>
            )}
            <EnableMerchantUserButton user={rowUser} onEnable={onEnable} />
            <DisableMerchantUserButton user={rowUser} onDisable={onDisable} />
          </div>
        );
      },
    },
  ];

  return {
    columns,
    Table: () => {
      return (
        <DataTable
          columns={
            visibleColumns
              ? columns.filter(({ id }) =>
                  typeof id === 'string' ? visibleColumns.includes(id) : false,
                )
              : columns
          }
          data={data}
          sorting={sorting}
          onSortChange={updateSorting}
        />
      );
    },
  };
}
