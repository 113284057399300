import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { Agreement, AgreementBrief } from '@root/globalTypes';
import { formatDate } from '@utils/dateHelpers';
import {
  Blocks,
  Calculator,
  CalendarClock,
  CalendarCog,
  CalendarPlus,
  Fingerprint,
  TrendingUp,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function AgreementDetails({
  agreement,
  agreementRedirect,
}: {
  agreement: Agreement | AgreementBrief;
  agreementRedirect?: (id: string) => void;
}) {
  const { t } = useTranslation('app');

  return (
    <DetailsCard>
      <DetailsCardItem
        icon={<Fingerprint />}
        label={t('table.id')}
        data={
          agreementRedirect ? (
            <a
              onClick={() => agreementRedirect(agreement.id)}
              className="inline-flex items-center gap-1 hover:cursor-pointer hover:text-blue-700 hover:underline">
              {agreement.id}
            </a>
          ) : (
            agreement.id
          )
        }
        column={1}
      />
      <DetailsCardItem
        icon={<Fingerprint />}
        label={t('table.agreementId')}
        data={agreement.agreementId}
        column={1}
      />
      <DetailsCardItem
        icon={<Fingerprint />}
        label={t('table.firstOrderId')}
        data={agreement.firstOrderId}
        column={1}
      />
      <DetailsCardItem icon={<Blocks />} label={t('table.type')} data={agreement.type} column={1} />
      <DetailsCardItem
        icon={<TrendingUp />}
        label={t('table.variability')}
        data={agreement.variability}
        column={1}
      />
      <DetailsCardItem
        icon={<Calculator />}
        label={t('table.maxUse')}
        data={agreement.maxUse}
        column={2}
      />
      {agreement.expiry && (
        <DetailsCardItem
          icon={<CalendarClock />}
          label={t('table.expiry')}
          data={agreement.expiry ? formatDate(new Date(agreement.expiry)) : null}
          column={2}
        />
      )}
      <DetailsCardItem
        icon={<CalendarPlus />}
        label={t('table.createdAt')}
        data={formatDate(new Date(agreement.createdAt))}
        column={2}
      />
      <DetailsCardItem
        icon={<CalendarCog />}
        label={t('table.updatedAt')}
        data={formatDate(new Date(agreement.updatedAt))}
        column={2}
      />
    </DetailsCard>
  );
}
