import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useStaffLogin } from '@services/staff/session/create';
import { AuthRespSuccess, StaffUser } from '@root/globalTypes';

export const useStaffLoginForm = ({
  successHandler,
}: {
  successHandler: (data: AuthRespSuccess<StaffUser>) => Promise<void>;
}) => {
  const { t } = useTranslation('app');
  const schema = z.object({
    email: z
      .string()
      .trim()
      .email({
        message: t('form.email.required'),
      }),
    password: z
      .string()
      .trim()
      .min(1, {
        message: t('form.password.required'),
      }),
  });

  const defaultValues = {
    acquirer: '',
    merchantId: '',
    email: '',
    password: '',
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, isPending, error } = useStaffLogin({ successHandler });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    isPending,
    error,
  };
};
