import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ExportParameters, useCreateExportFile } from '@services/global/exports';
import { useParams } from 'react-router-dom';
import { ExportVariants } from '@components/dialog/exportDialog';
import { useTranslation } from 'react-i18next';

export const useExportData = ({
  successHandler,
  type,
}: {
  successHandler: () => void;
  type: ExportVariants;
}) => {
  const { merchantId } = useParams();
  const { t } = useTranslation('app');
  const schema = z.object({
    count: z.coerce
      .number()
      .int(t('form.number.int'))
      .positive(t('form.number.positive'))
      .optional()
      .or(z.literal('')),
    columns: z.string().array().optional(),
  });

  const defaultValues: ExportParameters = {
    count: '',
    columns: [],
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, isPending } = useCreateExportFile({
    type,
    merchantId,
    callback: successHandler,
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    const reqData: ExportParameters = {
      count: values.count || '',
      columns: values.columns || [],
    };
    mutate(reqData).catch((err) => {
      console.error(err);
    });
  };

  return {
    form,
    onSubmit,
    isPending,
  };
};
