import { Button } from '@components/button';
import { Loader } from '@components/loader';
import { useAcquirerUsersTable } from '@components/tables/acquirerUsers';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { useDisableUser } from '@services/acquirer/users/disable';
import { useEnableUser } from '@services/acquirer/users/enable';
import { useListAcquirerUsers } from '@services/acquirer/users/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const AcquirerUsers = () => {
  const { t } = useTranslation('app');
  const { localId } = useParams({ from: '/u/$localId/_acquirer/acquirer/users/' });
  const { verifyUserPermission } = useCheckUserPermissions();
  const navigate = useNavigate();
  const { data, isPending } = useListAcquirerUsers();
  const { mutate: disableUser } = useDisableUser();
  const { mutate: enableUser } = useEnableUser();

  function handleSelect(id: string) {
    navigate({ to: '/u/$localId/acquirer/users/$id', params: { id, localId } }).catch((err) =>
      console.error(err),
    );
  }

  function newRedirect() {
    navigate({ to: '/u/$localId/acquirer/users/new', params: { localId } }).catch((err) =>
      console.error(err),
    );
  }

  const { Table: UsersTable } = useAcquirerUsersTable({
    data,
    onRowSelect: handleSelect,
    onDisable: disableUser,
    onEnable: enableUser,
  });

  if (isPending) return <Loader />;

  return (
    <BaseLayout
      title={t('pages.users.acquirer.title')}
      actions={[
        verifyUserPermission('acquirer_users_create') && (
          <Button key="new" variant="secondary" onClick={newRedirect}>
            {t('actions.addNew')}
            <Plus size={18} />
          </Button>
        ),
      ]}>
      <UsersTable />
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/users/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirer_users_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: AcquirerUsers,
});
