import { useTranslation } from 'react-i18next';
import { Popover, PopoverContent, PopoverTrigger } from '@components/popover';
import { Label } from '@components/form/label';
import { useState } from 'react';
import { Checkbox } from '@components/form/checkbox';
import { Input } from '@components/form/input';
import { SearchIcon, SlidersHorizontal } from 'lucide-react';
import { AvailableFilters, FilterConfigItem, useFilters } from '@ctx/filters/context';
import { useResetTable } from '@hooks/useResetTable';
import { IconButton } from '@components/button/iconButton';
import { useMediaQuery } from '@hooks/useMediaQuery';

export const FiltersPopover = ({ filters }: { filters: FilterConfigItem[] }) => {
  const filtersList = filters.map((item) => item.id);
  const { t } = useTranslation('app');
  const [open, setOpen] = useState(false);
  const { visibleFilters, addFilter, removeFilter, addFilters } = useFilters();
  const { reset } = useResetTable();
  const [visible, setVisible] = useState(filtersList);
  const isDesktop = useMediaQuery('(min-width:1024px)');

  function handleSearch(value: string) {
    setVisible(filtersList.filter((f) => f.toLowerCase().includes(value.toLowerCase())));
  }

  function handleSelect(checked: boolean, filter: AvailableFilters) {
    if (checked) {
      addFilter(filter);
    } else {
      removeFilter([filter]);
    }
  }

  function handleSelectAll(checked: boolean) {
    if (checked) {
      addFilters(filtersList);
    } else {
      reset();
    }
  }

  function handleOpenChange(isOpen: boolean) {
    handleSearch('');

    return setOpen(isOpen);
  }

  return (
    <Popover open={open} onOpenChange={handleOpenChange} modal={!isDesktop}>
      <PopoverTrigger asChild>
        <IconButton icon={<SlidersHorizontal size={18} />} variant="primary">
          {t('filters.add')}
        </IconButton>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <h2 className="whitespace-nowrap p-4 font-semibold">{t('filters.title')}</h2>
        <div className="relative">
          <SearchIcon size={16} className="absolute left-3 top-1/2 -translate-y-1/2" />
          <Input
            placeholder={t('filters.search')}
            className="rounded-none py-1 ps-10"
            name="search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="mt-3 flex space-x-3 border-b-2 border-accent-100 px-4 pb-2 pr-7">
          <Label htmlFor="allColumns" className="me-auto text-primary">
            {t('checkAll')}
          </Label>
          <Checkbox
            id="allColumns"
            checked={visibleFilters.length === filters.length}
            onCheckedChange={handleSelectAll}
          />
        </div>
        <div className="max-h-72 overflow-y-auto">
          <ul className="space-y-3 p-4">
            {visible.map((filter) => {
              const filterConfig = filters.find((f) => f.id === filter);
              const groupeId = filterConfig?.groupe?.length
                ? filterConfig.id.split('_')[0]
                : filter;
              return (
                <li key={filter} className="flex items-center gap-4">
                  <Label htmlFor={filter} className="me-auto">
                    {t(`filters.${groupeId}.label`)}
                  </Label>
                  <Checkbox
                    id={filter}
                    checked={visibleFilters.includes(filter)}
                    onCheckedChange={(checked: boolean) => handleSelect(checked, filter)}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </PopoverContent>
    </Popover>
  );
};
