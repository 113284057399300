import { useApi } from '@hooks/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { queryKeys } from '@utils/consts';
import { ApiError, MerchantUser } from '@root/globalTypes';
import { isFailure } from '@utils/apiHelpers';
import { UpdateMerchantUserData } from '@services/types';

type UpdateUserParams = {
  id: string;
  merchantId: string;
  callback: (data: MerchantUser) => void;
};

export function useUpdateMerchantUser({ id, merchantId, callback }: UpdateUserParams) {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { mutate, isPending } = useMutation({
    mutationFn: ({ ...data }: UpdateMerchantUserData): Promise<MerchantUser | ApiError> => {
      return query({
        path: `v1/staff/merchants/${merchantId}/users/${id}`,
        data: data,
        opt: {
          method: 'PUT',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isFailure(data)) {
        setApiError(data);
      } else {
        callback(data);
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.merchantUsers });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
}
