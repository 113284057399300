import { useApi } from '@hooks/useApi';
import { MerchantData } from '@services/types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useState } from 'react';
import { ApiError, Merchant } from '@root/globalTypes';
import { isFailure } from '@utils/apiHelpers';

type CreateMerchantParams = {
  acquirerId: string;
  successHandler: () => void;
};

export function useCreateMerchant({ acquirerId, successHandler }: CreateMerchantParams) {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { mutate, isPending } = useMutation({
    mutationFn: (merchantData: MerchantData): Promise<Merchant | ApiError> => {
      return query({
        path: `v1/staff/acquirers/${acquirerId}/merchants`,
        data: merchantData,
        opt: {
          method: 'POST',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isFailure(data)) {
        setApiError(data);
      } else {
        successHandler();
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.merchants });
      await queryClient.refetchQueries({ queryKey: queryKeys.acquirerMerchants });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
}
