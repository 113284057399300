import { Loader } from '@components/loader';
import { AcquirerLinkPage } from '@pages/acquirerLinks/id';
import { useGetAcquirerLink } from '@services/acquirer/links/get';
import { createFileRoute, redirect, useParams } from '@tanstack/react-router';

function AcquirerLink() {
  const { id } = useParams({ from: '/u/$localId/_acquirer/acquirer/links/$id' });
  const { data, isPending } = useGetAcquirerLink({ id });

  if (isPending) return <Loader />;
  if (!data) return null;

  return <AcquirerLinkPage acquirerLink={data} />;
}

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/links/$id')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirer_links_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: AcquirerLink,
});
