import { useApi } from '@hooks/useApi';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { AcquirerLink } from '@root/globalTypes';

export function useDeleteAcquirerLink({
  id,
  acquirerId,
  callback,
}: {
  id: string;
  acquirerId: string;
  callback: () => Promise<void>;
}) {
  const { query } = useApi();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<AcquirerLink> => {
      return query({
        path: `v1/staff/acquirers/${acquirerId}/links/${id}`,
        opt: {
          method: 'DELETE',
        },
      });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: [...queryKeys.acquirerLinks, acquirerId] });
      await callback();
    },
  });

  return {
    mutate,
    isPending,
  };
}
