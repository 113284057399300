import { Agreements } from '@pages/agreements';
import { useGetAgreements } from '@services/global/agreements/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Loader } from '@components/loader';

const MerchantAgreements = () => {
  const navigate = useNavigate();
  const { localId } = useParams({ from: '/u/$localId/_merchant/merchant/agreements/' });
  const { meta, data, isPending } = useGetAgreements();

  if (isPending) return <Loader />;

  return (
    <Agreements
      meta={meta}
      agreements={data}
      onRowSelect={(id: string) => {
        navigate({
          to: '/u/$localId/merchant/agreements/$id/orders',
          params: { id, localId },
        }).catch((err) => console.error(err));
      }}
    />
  );
};

export const Route = createFileRoute('/u/$localId/_merchant/merchant/agreements/')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['agreements_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantAgreements,
});
