import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import './i18n';
import './index.css';
import AppSignal from '@appsignal/javascript';

const appSignal = new AppSignal({
  key: (import.meta.env.VITE_APPSIGNAL_API_KEY as string) || '',
});

declare global {
  interface Window {
    errorReporter: typeof appSignal;
  }
}
// eslint-disable-next-line immutable/no-mutation
window.errorReporter = appSignal;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
