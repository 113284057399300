import { Card, CardContent } from '@components/card';
import { EditMerchantUserForm } from '@components/forms/merchantUsers/edit';
import { Loader } from '@components/loader';
import { useEditMerchantUserForm } from '@hooks/forms/merchantUsers/editForm';
import { BaseLayout } from '@layouts/base';
import { useGetMerchantUser } from '@services/staff/merchant/users/get';
import { useUpdateMerchantUser } from '@services/staff/merchant/users/update';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export function EditMerchantUser() {
  const { t } = useTranslation('app');
  const { merchantId, localId, id } = useParams({
    from: '/u/$localId/_staff/staff/merchants/$merchantId/users/$id/edit',
  });
  const navigate = useNavigate();
  const { data, isPending } = useGetMerchantUser(merchantId, id);

  const requestHandler = useUpdateMerchantUser({
    id,
    merchantId,
    callback: () => {
      toast.success(t('toast.users.createSuccess'));
      navigate({
        to: '/u/$localId/staff/merchants/$merchantId/users/$id',
        params: { merchantId, localId: localId || '0', id },
        replace: true,
      }).catch((err) => {
        console.error(err);
      });
    },
  });

  const formObj = useEditMerchantUserForm({ user: data, requestHandler });

  if (isPending) return <Loader />;

  return (
    <BaseLayout isChild title={t('pages.newMerchantUser.title')}>
      <Card>
        <CardContent className="p-6">
          <EditMerchantUserForm formObj={formObj} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}

export const Route = createFileRoute(
  '/u/$localId/_staff/staff/merchants/$merchantId/users/$id/edit',
)({
  component: EditMerchantUser,
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_users_update'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
});
