import { getCacheKeyFromRoute } from '@utils/apiHelpers';
import { useMemo } from 'react';
import { useMatch, useNavigate } from '@tanstack/react-router';
import { useAppContext } from './useAppContext';
import { parseSortingToSearchObject, DEFAULT_SORTING } from './useTableSorting';
import { useFilters } from '@ctx/filters/context';

export function useResetTable() {
  const match = useMatch({ strict: false });
  const key = useMemo(() => getCacheKeyFromRoute(match.routeId), [match]);
  const navigate = useNavigate();
  const { updateFiltersCache, updateSortingCache } = useAppContext();
  const { setActiveFilters, defaultFilters } = useFilters();

  function reset() {
    setActiveFilters(defaultFilters);
    updateFiltersCache(key, defaultFilters);
    updateSortingCache(key, [DEFAULT_SORTING]);

    navigate({
      to: '.',
      search: {
        sort: parseSortingToSearchObject(DEFAULT_SORTING),
      },
    }).catch((err) => {
      console.error(err);
    });
  }

  return {
    reset,
  };
}
