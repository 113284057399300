import { Card, CardContent } from '@components/card';
import { EditAcquirerUserForm } from '@components/forms/acquirerUsers/edit';
import { Loader } from '@components/loader';
import { useEditAcquirerUserForm } from '@hooks/forms/acquirerUsers/editForm';
import { BaseLayout } from '@layouts/base';
import { Page404 } from '@pages/404';
import { useGetAcquirerUser } from '@services/acquirer/users/get';
import { useUpdateAcquirerUser } from '@services/acquirer/users/update';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const EditAcquirerUser = () => {
  const { t } = useTranslation('app');
  const { id, localId } = useParams({ from: '/u/$localId/_acquirer/acquirer/users/$id/edit' });
  const navigate = useNavigate();
  const { data: user, isPending: loading } = useGetAcquirerUser(id);

  const requestHandler = useUpdateAcquirerUser({
    id,
    callback: () => {
      toast.success(t('toast.users.updateSuccess'));
      navigate({
        to: '/u/$localId/acquirer/users/$id',
        params: { id, localId },
        replace: true,
      }).catch((err) => {
        console.error(err);
      });
    },
  });
  const formObj = useEditAcquirerUserForm({ user, requestHandler });

  if (loading) return <Loader />;
  if (!user) return <Page404 />;

  return (
    <BaseLayout isChild title={t('pages.editAcquirerUser.title')}>
      <Card>
        <CardContent className="p-6">
          <EditAcquirerUserForm formObj={formObj} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
};

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/users/$id/edit')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirer_users_read', 'acquirer_users_update'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: EditAcquirerUser,
});
