import { Loader } from '@components/loader';
import { RawPreview } from '@components/rawPreview';
import { useGetCardAuth } from '@services/global/cardAuths/get';
import { createFileRoute, useParams } from '@tanstack/react-router';

const Auth = () => {
  const { id } = useParams({
    from: '/u/$localId/_staff/staff/card-authentications/$id/auth',
  });
  const { data, isPending } = useGetCardAuth(id);

  if (isPending) return <Loader />;
  if (!data?.auth) return null;

  return <RawPreview data={data.auth} />;
};

export const Route = createFileRoute('/u/$localId/_staff/staff/card-authentications/$id/auth')({
  component: Auth,
});
