import { useApi } from '@hooks/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { queryKeys } from '@utils/consts';
import { isFailure } from '@utils/apiHelpers';
import { ApiError, MerchantUser } from '@root/globalTypes';

export function useEnableMerchantUser(merchantId: string) {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string>('');

  const { mutate, isPending } = useMutation({
    mutationFn: (id: string): Promise<MerchantUser | ApiError> => {
      return query({
        path: `v1/acquirer/merchants/${merchantId}/users/${id}/enable`,
        opt: {
          method: 'PUT',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isFailure(data)) setApiError(data.message);
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: [...queryKeys.merchantUsers, merchantId] });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
}
