import { Card, CardContent } from '@components/card';
import { CreateMerchantUserForm } from '@components/forms/merchantUsers/create';
import { useCreateMerchantUserForm } from '@hooks/forms/merchantUsers/createForm';
import { BaseLayout } from '@layouts/base';
import { useCreateUser } from '@services/merchant/users/create';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export function NewMerchantUser() {
  const { t } = useTranslation('app');
  const { localId } = useParams({ from: '/u/$localId/_merchant/merchant/users/new' });
  const navigate = useNavigate();

  const requestHandler = useCreateUser({
    callback: () => {
      toast.success(t('toast.users.createSuccess'));
      navigate({
        to: '/u/$localId/merchant/users',
        params: { localId: localId || '0' },
        replace: true,
      }).catch((err) => {
        console.error(err);
      });
    },
  });

  const formObj = useCreateMerchantUserForm({ requestHandler });

  return (
    <BaseLayout isChild title={t('pages.newMerchantUser.title')}>
      <Card>
        <CardContent className="p-6">
          <CreateMerchantUserForm formObj={formObj} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}

export const Route = createFileRoute('/u/$localId/_merchant/merchant/users/new')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_users_create'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: NewMerchantUser,
});
