import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { Merchant } from '@root/globalTypes';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';

export function useGetMerchant(id: string) {
  const { query } = useApi();
  const { isLogged } = useCheckUserPermissions();

  const { data, isPending } = useQuery<Merchant>({
    queryKey: [...queryKeys.merchant, id],
    queryFn: () =>
      query({
        path: `v1/acquirer/merchants/${id}`,
      }),
    enabled: isLogged && Boolean(id),
  });

  return {
    data: data,
    isPending,
  };
}
