import { CountriesCombobox } from '@components/comboboxes/countries';
import { ControllerRenderProps } from 'react-hook-form';
import { FormControl } from '@components/form/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@components/form/select';

export const PCSelect = ({
  internalType,
  field,
}: {
  internalType: string;
  field: ControllerRenderProps<
    {
      identifier: string;
      activity: 'allow' | 'deny';
    },
    'identifier'
  >;
}) => {
  const selectVariants: Record<string, string[]> = {
    eci: ['00', '01', '02', '05', '06', '07'],
    '3ds_status': ['Y', 'A', 'N', 'U', 'R'],
  };

  if (internalType.includes('country'))
    return <CountriesCombobox value={field.value} onSelect={field.onChange} />;

  if (selectVariants[internalType])
    return (
      <FormControl>
        <Select value={field.value} onValueChange={field.onChange}>
          <SelectTrigger className="w-full">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {selectVariants[internalType].map((opt) => (
              <SelectItem value={opt} key={opt}>
                {opt}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </FormControl>
    );

  return null;
};
