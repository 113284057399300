import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster, toast } from 'sonner';
import { pubSub } from '@utils/pubSub';
import { RouterProvider, createRouter } from '@tanstack/react-router';

// Import the generated route tree
import { routeTree } from './routeTree.gen';
import { AppContextProvider } from './ctx';
import { useAppContext } from '@hooks/useAppContext';
import { Page404 } from '@pages/404';
import { CustomApiError } from '@hooks/useApi';
import { useTranslation } from 'react-i18next';
import { fromQueryString, toQueryString } from '@utils/searchHelpers';

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  parseSearch: fromQueryString,
  stringifySearch: (search) => toQueryString(search),
  context: undefined!,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function InnerApp() {
  const { t } = useTranslation('app');
  const ctx = useAppContext();

  return (
    <RouterProvider
      router={router}
      context={{ ...ctx }}
      defaultNotFoundComponent={() => <Page404 message={t('pages.404.title')} />}
    />
  );
}

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        networkMode: 'always',
        retry: (_failureCount, error) => {
          if (error instanceof CustomApiError) {
            return false;
          }
          return true;
        },
      },
      mutations: {
        networkMode: 'always',
        onError: (err) => toast.error(err.message, { id: err.message }),
      },
    },
    queryCache: new QueryCache({
      onError: (err) => {
        toast.error(err.message, { id: err.message });
        if (err.message.includes('401')) {
          pubSub.publish('error:401');
        }
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <Toaster visibleToasts={3} richColors toastOptions={{ duration: 5 * 1000 }} closeButton />
        <InnerApp />
        <ReactQueryDevtools initialIsOpen={false} />
      </AppContextProvider>
    </QueryClientProvider>
  );
}

export default App;
