/* eslint-disable immutable/no-mutation */
import * as React from 'react';

import { cn } from '@utils/cn';
import { baseInputStyle } from './inputStyle';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, children, ...props }, ref) => {
    if (children && type !== 'file') {
      return (
        <div className={cn(baseInputStyle, 'flex items-center gap-3')}>
          <input
            type={type}
            className={cn(baseInputStyle, '-my-3 -ms-5 border-0', className)}
            ref={ref}
            {...props}
          />
          {children}
        </div>
      );
    }
    return (
      <input
        type={type}
        className={cn(
          baseInputStyle,
          type === 'file' &&
            ' file:border-stroke file:bg-whiter cursor-pointer p-0 outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-solid file:px-5 file:py-3 file:hover:bg-opacity-10',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
