import { DataTable } from '@components/dataTable';
import { TableDateCell } from '@components/table/dateCell';
import { useTableSorting } from '@hooks/useTableSorting';
import { Merchant } from '@root/globalTypes';
import { ColumnDef, Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

type MerchantsTableProps = {
  visibleColumns: string[];
  onRowSelect: (merchant: Merchant) => void;
  data: Merchant[];
};

export function useMerchantsTable({ data, visibleColumns, onRowSelect }: MerchantsTableProps) {
  const { t } = useTranslation('app');
  const { sorting, updateSorting } = useTableSorting();

  const columns: ColumnDef<Merchant>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
      enableSorting: false,
    },
    {
      id: 'merchant_id',
      accessorKey: 'merchantId',
      header: t('table.merchantId'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: t('table.name'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'arabic_name',
      accessorKey: 'arabicName',
      header: t('table.arabicName'),
      enableSorting: false,
    },
    {
      id: 'description',
      accessorKey: 'goodsDescription',
      header: t('table.description'),
      enableSorting: false,
    },
    {
      id: 'trading_name',
      accessorKey: 'tradingName',
      header: t('table.tradingName'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'mcc',
      accessorKey: 'mcc',
      header: t('table.mcc'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'cr_number',
      accessorKey: 'crNumber',
      header: t('table.crNumber'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
    {
      id: 'updated_at',
      accessorKey: 'updatedAt',
      header: t('table.updatedAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.updatedAt} />,
    },
  ];

  return {
    columns,
    Table: () => (
      <DataTable
        columns={columns.filter(({ id }) =>
          typeof id === 'string' ? visibleColumns.includes(id) : false,
        )}
        data={data}
        selectionCallback={(row: Row<Merchant>) => onRowSelect(row.original)}
        sorting={sorting}
        onSortChange={updateSorting}
      />
    ),
  };
}
