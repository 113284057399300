import { isRangeFilter, useFilters } from '@ctx/filters/context';
import { FilterItem } from './filterItem';
import { forwardRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { endOfToday, endOfYesterday, format, startOfToday, startOfYesterday } from 'date-fns';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@components/form/select';
import { Input } from '@components/form/input';
import { Label } from '@radix-ui/react-label';
import { Button } from '@components/button';
import { DateTimePicker } from '@components/form/dateTimePicker';
import { Accordion, AccordionTrigger } from '@radix-ui/react-accordion';
import { AccordionContent, AccordionItem } from '@components/accordion';
import { FilterProps } from '.';

const DateFilterOption = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ onClick, children }, ref) => {
  return (
    <button
      ref={ref}
      onClick={onClick}
      className="w-full rounded-md p-2 text-left hover:cursor-pointer hover:bg-neutral-100">
      {children}
    </button>
  );
});

const TIME_COMPONENTS = ['days', 'hours', 'minutes'] as const;
type TimeComponent = (typeof TIME_COMPONENTS)[number];

const TIME_COMPONENT_TO_MS: Record<TimeComponent, number> = {
  days: 24 * 60 * 60 * 1000,
  hours: 60 * 60 * 1000,
  minutes: 60 * 1000,
} as const;

export function DateFilter({ configItem }: FilterProps) {
  const { t } = useTranslation('app');
  const { activeFilters, setFilter } = useFilters();
  const filter = activeFilters[configItem.id];
  const [timeComponent, setTimeComponent] = useState<TimeComponent>('days');
  const [timeComponentValue, setTimeComponentValue] = useState<string>('7');

  function formatDate(dateStr: string) {
    const date = new Date(dateStr);
    const currentYear = date.getFullYear() === new Date().getFullYear();

    if (currentYear) return format(date, "MMM' 'do, HH:mm");

    return format(date, "yyyy,' 'MMM' 'do, HH:mm");
  }

  const formattedValue = useMemo(() => {
    if (!isRangeFilter(filter)) return '';
    const gte = filter.gte && formatDate(filter.gte);
    const lte = filter.lte && formatDate(filter.lte);

    if (gte && lte) return `: ${gte} - ${lte}`;
    if (gte && !lte) return ` > ${gte}`;
    if (!gte && lte) return ` < ${lte}`;
    return '';
  }, [filter]);

  function today() {
    const gte = startOfToday();
    const lte = endOfToday();

    setFilter(configItem.id, { gte: gte.toISOString(), lte: lte.toISOString() });
  }

  function yesterday() {
    const gte = startOfYesterday();
    const lte = endOfYesterday();

    setFilter(configItem.id, { gte: gte.toISOString(), lte: lte.toISOString() });
  }

  function setFilterFromTimeComponent() {
    const lte = new Date();
    const gte = new Date(
      lte.getTime() - TIME_COMPONENT_TO_MS[timeComponent] * Number(timeComponentValue),
    );

    setFilter(configItem.id, { gte: gte.toISOString(), lte: lte.toISOString() });
  }

  function setFilterFromRangeInput(field: 'gte' | 'lte', date: Date) {
    if (!isRangeFilter(filter)) return;

    setFilter(configItem.id, { ...filter, [field]: date.toISOString() });
  }

  if (!isRangeFilter(filter)) return;

  return (
    <FilterItem configItem={configItem} displayedValue={formattedValue}>
      <div className="flex flex-col gap-2">
        <DateFilterOption onClick={today}>{t('filters.date.options.today')}</DateFilterOption>
        <DateFilterOption onClick={yesterday}>
          {t('filters.date.options.yesterday')}
        </DateFilterOption>
        <Accordion type="multiple" defaultValue={['range']}>
          <AccordionItem value="timeComponent" className="border-0 shadow-none">
            <AccordionTrigger asChild>
              <DateFilterOption>{t('filters.date.options.timeComponent')}</DateFilterOption>
            </AccordionTrigger>
            <AccordionContent className="border-0 p-0 pt-2 shadow-none">
              <div className="flex flex-col gap-4">
                <div className="relative">
                  <Label
                    className="absolute left-3 bg-white px-2 text-xs"
                    htmlFor={`${configItem.id}[gte]_alt`}>
                    {t('filters.date.timeComponent.lastX')}
                  </Label>
                  <Select
                    defaultValue={timeComponent}
                    onValueChange={(value) => setTimeComponent(value as TimeComponent)}>
                    <SelectTrigger className="mt-1">
                      <SelectValue placeholder={t('filters.date.timeComponent.placeholder')} />
                    </SelectTrigger>
                    <SelectContent>
                      {TIME_COMPONENTS.map((component) => (
                        <SelectItem key={component} value={component}>
                          {component}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="relative">
                  <Label
                    className="absolute left-3 bg-white px-2 text-xs"
                    htmlFor={`${configItem.id}[gte]_alt`}>
                    {t('filters.date.timeComponent.value')}
                  </Label>
                  <Input
                    type="number"
                    className="mt-1"
                    value={timeComponentValue}
                    onChange={(e) => setTimeComponentValue(e.target.value)}
                  />
                </div>
                <Button variant="primary" onClick={setFilterFromTimeComponent}>
                  {t('filters.date.timeComponent.set')}
                </Button>
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="range" className="border-0 pt-2 shadow-none">
            <AccordionTrigger asChild>
              <DateFilterOption>{t('filters.date.options.range')}</DateFilterOption>
            </AccordionTrigger>
            <AccordionContent className="border-0 p-0 pt-2 shadow-none">
              <div className="relative">
                <Label
                  className="absolute left-3 top-[-0.5em] bg-white px-2 text-xs"
                  htmlFor={`${configItem.id}[gte]_alt`}>
                  {t(`filters.${configItem.id}.from`)}
                </Label>
                <DateTimePicker
                  displayFormat={{ hour24: "MMM' 'do', 'yyyy', 'HH:mm" }}
                  value={filter.gte ? new Date(filter.gte) : undefined}
                  granularity="minute"
                  onChange={(date) => {
                    if (!date) return;
                    setFilterFromRangeInput('gte', date);
                  }}
                />
              </div>
              <div className="relative space-y-2">
                <Label
                  className="absolute left-3 top-[-0.5em] bg-white px-2 text-xs"
                  htmlFor={`${configItem.id}[lte]_alt`}>
                  {t(`filters.${configItem.id}.to`)}
                </Label>
                <DateTimePicker
                  displayFormat={{ hour24: "MMM' 'do', 'yyyy', 'HH:mm" }}
                  value={filter.lte ? new Date(filter.lte) : undefined}
                  granularity="minute"
                  onChange={(date) => {
                    if (!date) return;
                    setFilterFromRangeInput('lte', date);
                  }}
                />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </FilterItem>
  );
}
