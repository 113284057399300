import { format } from 'date-fns';

const DEFAULT_FORMAT = 'yyyy-MM-dd h:mm a';

export const formatDate = (date?: Date, pattern?: string) =>
  format(date || new Date(), pattern || DEFAULT_FORMAT);

export const formatDateFromString = (dateString: string, pattern?: string) => {
  if (dateString.length !== 14) return dateString;

  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10) - 1;
  const day = parseInt(dateString.substring(6, 8), 10);
  const hour = parseInt(dateString.substring(8, 10), 10);
  const minute = parseInt(dateString.substring(10, 12), 10);
  const second = parseInt(dateString.substring(12, 14), 10);

  return format(new Date(year, month, day, hour, minute, second), pattern || DEFAULT_FORMAT);
};
