import { DataTable } from '@components/dataTable';
import { CardAuth } from '@services/global/cardAuths/types';
import { ColumnDef, Row } from '@tanstack/react-table';
import { formatAmount } from '@utils/textHelpers';
import { useTranslation } from 'react-i18next';
import { useTableSorting } from '@hooks/useTableSorting';
import { TableDateCell } from '@components/table/dateCell';
import { useAppContext } from '@hooks/useAppContext';

type CardAuthsTableProps = {
  visibleColumns: string[];
  onRowSelect: (row: Row<CardAuth>) => void;
  data: CardAuth[];
};

export function useCardAuthsTable({ visibleColumns, onRowSelect, data }: CardAuthsTableProps) {
  const { t } = useTranslation('app');
  const { sorting, updateSorting } = useTableSorting();
  const { userType } = useAppContext();

  const optionalColumns: ColumnDef<CardAuth>[] = [
    {
      id: 'merchant_id',
      accessorKey: 'merchant.merchantId',
      header: t('table.merchantId'),
      sortDescFirst: true,
      enableSorting: true,
    },
  ];

  const defaultColumns: ColumnDef<CardAuth>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
      enableSorting: false,
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: t('table.status'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: t('table.amount'),
      cell: ({ row }) => formatAmount(row.original.amount, row.original.currency),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'currency',
      accessorKey: 'currency',
      header: t('table.currency'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'message',
      accessorKey: 'message',
      header: t('table.message'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'created_at',
      accessorKey: 'created_at',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
    {
      id: 'updated_at',
      accessorKey: 'updated_at',
      header: t('table.updatedAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.updatedAt} />,
    },
  ];

  const columns =
    userType === 'merchant'
      ? defaultColumns
      : [defaultColumns[0], ...optionalColumns, ...defaultColumns.slice(1)];

  return {
    columns,
    Table: () => (
      <DataTable
        columns={columns.filter(({ id }) =>
          typeof id === 'string' ? visibleColumns.includes(id) : false,
        )}
        sorting={sorting}
        onSortChange={updateSorting}
        data={data}
        selectionCallback={onRowSelect}
      />
    ),
  };
}
