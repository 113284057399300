import { ComboBoxItemType } from '@components/combobox';
import { Label } from '@components/form/label';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ChevronsLeft, ChevronsRight, Equal } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@components/form/select';
import CurrencyInput from 'react-currency-input-field';
import { useFilters } from '@ctx/filters/context';
import { baseInputStyle } from '@components/form/inputStyle';
import { getCurrencyFractionSize } from '@utils/textHelpers';
import { FilterItem } from './filterItem';
import { CurrencyFilter } from './currency';
import { FilterProps } from '.';

export const AmountFilter = ({ configItem }: FilterProps) => {
  const { t } = useTranslation('app');
  const { activeFilters, setFilter } = useFilters();
  const amountGteField = activeFilters.amount?.gte?.toString() || '0';
  const amountLteField = activeFilters.amount?.lte?.toString() || '0';
  const currencyFractionSize = useMemo(
    () => getCurrencyFractionSize(activeFilters.currency as string),
    [activeFilters.currency],
  );

  const parseFilterAmount = useCallback(
    (value: string | number) => {
      const amount = typeof value === 'string' ? parseFloat(value) : value;

      return amount / 10 ** currencyFractionSize;
    },
    [currencyFractionSize],
  );

  const formattedValue = useMemo(() => {
    const cur = activeFilters.currency || '';
    const gte = activeFilters.amount?.gte;
    const lte = activeFilters.amount?.lte;
    const formattedGte = parseFilterAmount(gte || '');
    const formattedLte = parseFilterAmount(lte || '');
    if (gte && lte && gte !== lte) return `: ${formattedGte} - ${formattedLte} ${cur}`;
    if (gte && lte && gte === lte) return `: ${formattedGte} ${cur}`;
    if (gte && !lte) return ` > ${formattedGte} ${cur}`;
    if (!gte && lte) return ` < ${formattedLte} ${cur}`;
    return '';
  }, [
    activeFilters.amount?.gte,
    activeFilters.amount?.lte,
    activeFilters.currency,
    parseFilterAmount,
  ]);

  const amountOptions = [
    {
      label: t('filters.amount.eq'),
      value: 'eq',
    },
    {
      label: t('filters.amount.gte'),
      value: 'gte',
    },
    {
      label: t('filters.amount.lte'),
      value: 'lte',
    },
    {
      label: t('filters.amount.both'),
      value: 'both',
    },
  ];

  // TODO: Find better way to set default option
  const defaultAmountOpt = () => {
    return amountOptions.find((opt) => {
      if (activeFilters.amount?.gte && !activeFilters.amount.lte) {
        return opt.value === 'gte';
      }
      if (!activeFilters.amount?.gte && activeFilters.amount?.lte) {
        return opt.value === 'lte';
      }

      if (activeFilters.amount?.gte && activeFilters.amount?.lte) {
        return activeFilters.amount.gte === activeFilters.amount?.lte
          ? opt.value === 'eq'
          : opt.value === 'both';
      }
    });
  };

  const [amountOpt, setAmountOpt] = useState<ComboBoxItemType>(
    defaultAmountOpt() || amountOptions[0],
  );
  const handleSelect = useDebouncedCallback((el: string) => {
    if (el === 'gte') setFilter('amount', { lte: '', gte: activeFilters.amount?.gte });
    if (el === 'lte') setFilter('amount', { gte: '', lte: activeFilters.amount?.lte });
    const v: ComboBoxItemType = amountOptions.find((e) => e.value === el) || amountOptions[0];
    setAmountOpt(v);
  }, 200);

  function formatAmount(value: number) {
    return value.toFixed(currencyFractionSize).replace('.', '');
  }

  return (
    <FilterItem configItem={configItem} displayedValue={formattedValue}>
      <div className="space-y-4">
        <CurrencyFilter configItem={configItem} wrapped={true} />
        <div className="relative space-y-4">
          <div className="space-y-4">
            <div className="relative space-y-2">
              <Label className="absolute left-3 top-[-6px] bg-white px-2 text-xs" htmlFor="amount">
                {t(`filters.amount.label`)}
              </Label>
              <Select value={amountOpt.value} onValueChange={handleSelect}>
                <SelectTrigger className="w-full">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {amountOptions.map((o) => (
                    <SelectItem key={o.label} value={o.value}>
                      {o.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-[1fr_5fr] gap-2">
              {amountOpt.value === 'eq' && (
                <div className="contents">
                  <Label className="inline-flex items-center" htmlFor="amount_alt">
                    <Equal />
                  </Label>
                  <CurrencyInput
                    className={baseInputStyle}
                    decimalsLimit={currencyFractionSize}
                    defaultValue={parseFilterAmount(amountGteField)}
                    allowNegativeValue={false}
                    onValueChange={(_value, _name, values) => {
                      if (values?.float === undefined || values?.float === null) return;
                      setFilter('amount', {
                        gte: formatAmount(values?.float),
                        lte: formatAmount(values?.float),
                      });
                    }}
                  />
                </div>
              )}
              {(amountOpt.value === 'gte' || amountOpt.value === 'both') && (
                <div className="contents">
                  <Label className="inline-flex items-center" htmlFor="amount[gte]_alt">
                    <ChevronsRight />
                  </Label>
                  <CurrencyInput
                    className={baseInputStyle}
                    decimalsLimit={currencyFractionSize}
                    defaultValue={parseFilterAmount(amountGteField)}
                    onValueChange={(_value, _name, values) => {
                      if (!values?.float) return;
                      setFilter('amount', {
                        gte: formatAmount(values.float),
                        lte: activeFilters.amount?.lte,
                      });
                    }}
                  />
                </div>
              )}
              {(amountOpt.value === 'lte' || amountOpt.value === 'both') && (
                <div className="contents">
                  <Label className="inline-flex items-center" htmlFor="amount[lte]_alt">
                    <ChevronsLeft />
                  </Label>
                  <CurrencyInput
                    className={baseInputStyle}
                    name="amount[lte]_alt"
                    decimalsLimit={currencyFractionSize}
                    defaultValue={parseFilterAmount(amountLteField)}
                    onValueChange={(_value, _name, values) => {
                      if (!values?.float) return;
                      setFilter('amount', {
                        lte: formatAmount(values.float),
                        gte: activeFilters.amount?.gte,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </FilterItem>
  );
};
