import { Button } from '@components/button';
import { Loader } from '@components/loader';
import { ColumnFilterPopover } from '@components/popover/columnsFilterPopover';
import { useCardAuthsTable } from '@components/tables/cardAuths';
import { useToggleTableColumns } from '@hooks/useToggleTableColumns';
import { BaseLayout } from '@layouts/base';
import { useGetMerchantCardAuths } from '@services/acquirer/merchant/cardAuths/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { outletNavScroll } from '@utils/apiHelpers';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const MerchantCardAuths = () => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const { merchantId, localId } = useParams({
    from: '/u/$localId/_acquirer/acquirer/merchants/$merchantId/card-authentications',
  });
  const { data, isPending } = useGetMerchantCardAuths(merchantId);
  const truncatedCardAuths = data.slice(0, 5);
  const predefinedCols = useMemo(
    () => [
      'id',
      'merchant_id',
      'status',
      'amount',
      'currency',
      'message',
      'created_at',
      'updated_at',
    ],
    [],
  );
  const { visibleColumns, setColumns } = useToggleTableColumns({ predefinedCols });
  const { columns, Table: CardAuthsTable } = useCardAuthsTable({
    visibleColumns,
    onRowSelect: (row) => {
      const id: string = row.original.id;

      navigate({
        to: '/u/$localId/acquirer/card-authentications/$id',
        params: { id, localId },
      }).catch((err) => {
        console.error(err);
      });
    },
    data: truncatedCardAuths,
  });

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (isPending) return <Loader />;

  return (
    <BaseLayout
      title={t('pages.cardAuths.title')}
      actions={[
        <ColumnFilterPopover
          key="colFilter"
          activeCols={visibleColumns}
          setActiveCols={setColumns}
          columns={columns.map((col) => ({ id: col.id as string, label: col.header as string }))}
          defaultColumns={predefinedCols}
        />,
      ]}>
      <CardAuthsTable />
      {truncatedCardAuths.length < data.length && (
        <Button
          className="mx-4 mt-[-1em]"
          size="lg"
          variant="accent"
          onClick={() => {
            navigate({
              to: '/u/$localId/acquirer/card-authentications',
              params: { localId },
              /* eslint-disable camelcase */
              search: { merchant_id: merchantId },
            }).catch((err) => console.error(err));
          }}>
          {t('showMore')}
        </Button>
      )}
    </BaseLayout>
  );
};

export const Route = createFileRoute(
  '/u/$localId/_acquirer/acquirer/merchants/$merchantId/card-authentications',
)({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['3ds_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantCardAuths,
});
