import { cn } from '@utils/cn';
import { useTranslation } from 'react-i18next';
import { SideNavLink } from '../sideNavLink';
import { iconsClass, staffLinks } from '../const';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { LogOut, Settings } from 'lucide-react';
import { useNavigation } from '@ctx/navigation/context';
import { useAppContext } from '@hooks/useAppContext';

export const StaffLinks = () => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const { partialAccess } = useAppContext();
  const { navOpen: isOpen, logout, logoutPending } = useNavigation();

  return (
    <ul
      className={cn(
        'flex h-full w-full flex-col justify-between gap-4',
        isOpen ? 'lg:pl-8 lg:pr-14' : 'lg:pl-5 lg:pr-5',
      )}>
      <div className="flex flex-col gap-4">
        {staffLinks
          .filter(({ permission }) => (permission ? verifyUserPermission(permission) : true))
          .map((link) => (
            <li key={link.label}>
              <SideNavLink
                disabled={partialAccess}
                link={{
                  ...link,
                  label: t(`nav.${link.label}`),
                }}
                isOpen={isOpen}
              />
            </li>
          ))}
        <li>
          <SideNavLink
            isOpen={isOpen}
            link={{
              icon: <Settings className={iconsClass} />,
              to: `/u/$localId/staff/settings`,
              label: t('nav.settings'),
            }}
          />
        </li>
      </div>
      <li className="mb-4">
        <button
          id="logoutBtn"
          disabled={logoutPending}
          onClick={() => logout()}
          className={cn(
            'flex w-full items-center gap-4 px-4 py-3 text-neutral-500 transition-colors hover:bg-primary hover:text-white lg:py-2',
            isOpen ? 'rounded-full' : 'rounded',
          )}>
          <LogOut className={iconsClass} />
          {isOpen && t('nav.logout')}
        </button>
      </li>
    </ul>
  );
};
