import { buttonVariants } from '@components/button/variants';
import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { DeleteAcquirerLinkDialog } from '@components/dialog/deleteAcquirerLink';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { AcquirerLink } from '@root/globalTypes';
import { Link } from '@tanstack/react-router';
import { formatDate } from '@utils/dateHelpers';
import { useTranslation } from 'react-i18next';

export function AcquirerLinkPage({
  acquirerId,
  acquirerLink,
}: {
  acquirerId?: string;
  acquirerLink: AcquirerLink;
}) {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();

  return (
    <BaseLayout
      isChild
      title={t('pages.acquirerLink.title')}
      actions={[
        verifyUserPermission('acquirer_links_update') && (
          <Link
            key="edit"
            to={`${location.pathname}/edit`}
            replace={true}
            className={buttonVariants({ variant: 'primary' })}>
            {t('actions.edit')}
          </Link>
        ),
        verifyUserPermission('acquirer_links_delete') && acquirerId && (
          <DeleteAcquirerLinkDialog acquirerId={acquirerId} acquirerLink={acquirerLink} />
        ),
      ]}>
      <DetailsCard>
        <DetailsCardItem label={t('table.id')} data={acquirerLink.id} column={1} />
        <DetailsCardItem label={t('table.bin')} data={acquirerLink.bin} column={1} />
        <DetailsCardItem label={t('table.linkType')} data={acquirerLink.linkType} column={1} />
        <DetailsCardItem
          label={t('table.schemes')}
          data={acquirerLink.schemes.join(', ')}
          column={1}
        />
        <DetailsCardItem label={t('table.priority')} data={acquirerLink.priority} column={2} />
        <DetailsCardItem
          label={t('table.createdAt')}
          data={formatDate(new Date(acquirerLink.createdAt))}
          column={2}
        />
        <DetailsCardItem
          label={t('table.updatedAt')}
          data={formatDate(new Date(acquirerLink.updatedAt))}
          column={2}
        />
      </DetailsCard>
    </BaseLayout>
  );
}
