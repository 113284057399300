import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useApi } from '@hooks/useApi';
import { ApiError, PaymentControl } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { PaymentControlsTypes, queryKeys } from '@utils/consts';
import { mapControlType } from '@utils/apiHelpers';

export const useListControls = ({
  type,
  merchantId,
}: {
  merchantId: string;
  type: PaymentControlsTypes;
}) => {
  const { query } = useApi();

  const { isLogged } = useCheckUserPermissions();

  const { data, isPending } = useQuery<PaymentControl[], ApiError>({
    queryKey: [...queryKeys.paymentControls, merchantId, type, window.location.search],
    queryFn: (): Promise<PaymentControl[]> =>
      query({
        path: `v1/staff/merchants/${merchantId}/payment_controls/${mapControlType(type)}${
          window.location.search
        }`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged,
  });

  return {
    data: data || [],
    loading: isPending,
  };
};
