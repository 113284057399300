import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useAppContext } from '@hooks/useAppContext';

export function useSessionUser<T>() {
  const { query } = useApi();
  const { token } = useAppContext();

  const { data, isPending, refetch } = useQuery<T>({
    queryKey: queryKeys.loggedUser,
    queryFn: (): Promise<T> => query({ path: 'v1/session' }),
    enabled: Boolean(token),
  });

  return {
    data: data,
    isPending,
    refetch,
  };
}
