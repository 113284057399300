import { useApiKeysTable } from '@components/tables/apiKeys';
import { BaseLayout } from '@layouts/base';
import { ApiKeyType } from '@root/globalTypes';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Row } from '@tanstack/react-table';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { IconButton } from '@components/button/iconButton';

export const ApiKeys = ({
  data,
  onDisable,
  disabling,
  newRedirect,
  onRowSelect,
}: {
  data: ApiKeyType[];
  disabling: boolean;
  onDisable: (id: string) => void;
  newRedirect: () => void;
  onRowSelect: (row: Row<ApiKeyType>) => void;
}) => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const { Table: ApiKeysTable } = useApiKeysTable({
    data,
    disabling,
    onDisable,
    onRowSelect,
  });

  return (
    <BaseLayout
      title={t('pages.apiKeys.title')}
      actions={[
        verifyUserPermission('api_keys_create') && (
          <IconButton key="new" icon={<Plus size={18} />} variant="secondary" onClick={newRedirect}>
            {t('actions.addNew')}
          </IconButton>
        ),
      ]}>
      <ApiKeysTable />
    </BaseLayout>
  );
};
