import { Button } from '@components/button';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import { IpWhitelist } from './ipWhitelist';
import { useTranslation } from 'react-i18next';
import { PermissionGroups } from '@components/permissionGroups';
import { useApiKeyForm } from '@hooks/forms/apiKeys';

type ApiKeyFormProps = {
  formObj: ReturnType<typeof useApiKeyForm>;
  permissions: Record<string, string[]>;
  buttonText: string;
};

export function ApiKeyForm({ formObj, permissions, buttonText }: ApiKeyFormProps) {
  const { t } = useTranslation('app');

  const { form, error, isPending, onSubmit } = formObj;

  return (
    <Form {...form}>
      <form
        id="apiKeyForm"
        className="flex flex-col gap-4"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.description.label')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormLabel className="static ml-3 mt-2">{t('form.permissions.label')}</FormLabel>
        <FormField
          control={form.control}
          name="permissions"
          render={({ field }) => (
            <>
              <PermissionGroups
                permissions={permissions}
                selectedValues={field.value}
                onSelect={(perms: string[]) => form.setValue('permissions', perms)}
              />
              <FormMessage />
            </>
          )}
        />

        <FormField
          control={form.control}
          name="ipWhitelist"
          render={({ field }) => {
            return (
              <div className="flex flex-col">
                <FormLabel className="static ml-3 mt-2">{t('form.whitelist.label')}</FormLabel>
                <IpWhitelist value={field.value} setValue={form.setValue} />
                <FormMessage />
              </div>
            );
          }}
        />
        {error && <p className="text-red">{error}</p>}
        <Button type="submit" size="lg" variant="primary" disabled={isPending} loading={isPending}>
          {buttonText}
        </Button>
      </form>
    </Form>
  );
}
