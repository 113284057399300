import { useNavigation } from '@ctx/navigation/context';
import { useAppContext } from '@hooks/useAppContext';
import { MerchantUser } from '@root/globalTypes';
import { Link, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { SessionInfo } from '../sessionInfo';

export function MerchantUserInfo({ user }: { user: MerchantUser }) {
  const { t } = useTranslation('app');
  const { localId } = useParams({ strict: false });
  const { frontSession } = useAppContext();
  const { logout } = useNavigation();

  return (
    <Link
      to={`/u/$localId/merchant/settings`}
      params={{ localId: localId || '0' }}
      className="flex items-center gap-4">
      <div className="flex flex-col">
        <span>
          {t('merchant')}: {user?.merchantId}
        </span>
        {frontSession && <SessionInfo callback={logout} expiresAt={frontSession} />}
      </div>
    </Link>
  );
}
