import { useApi } from '@hooks/useApi';
import { UserType } from '@root/globalTypes';
import { useMutation } from '@tanstack/react-query';

export type AcceptInvitationData = {
  password: string;
};

type AcceptInvitationResponse = {
  message: string;
};

export type AcceptInvitationParams = {
  userType: UserType;
  token: string;
  callback: () => void;
};

export function useAcceptInvitation({ userType, token, callback }: AcceptInvitationParams) {
  const { query } = useApi();

  const url = `v1/${userType}/users/invitations/accept?invitation_token=${token}`;
  const { mutate, isPending } = useMutation({
    mutationFn: (data: AcceptInvitationData): Promise<AcceptInvitationResponse> => {
      return query({
        path: url,
        data,
        opt: {
          method: 'PUT',
        },
      });
    },
    onSuccess: () => {
      callback();
    },
  });

  return {
    mutate,
    isPending,
  };
}
