import { PermissionGroups } from '@components/permissionGroups';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@components/accordion';
import { useTranslation } from 'react-i18next';
import { PermissionGroupsType } from '@root/globalTypes';

export function PermissionsAccordion({
  selectedPermissions,
  permissions,
}: {
  selectedPermissions: string[];
  permissions: PermissionGroupsType;
}) {
  const { t } = useTranslation('app');

  return (
    <Accordion type="multiple">
      <AccordionItem value="permissions">
        <AccordionTrigger className="border-none" variant="default">
          <h3>{t('permissions')}</h3>
        </AccordionTrigger>
        <AccordionContent>
          <PermissionGroups
            permissions={permissions}
            selectedValues={selectedPermissions}
            disabled
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
