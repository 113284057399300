import { Card, CardContent } from '@components/card';
import { AcquirerForm } from '@components/forms/acquirers';
import { useAcquirerForm } from '@hooks/forms/acquirers';
import { BaseLayout } from '@layouts/base';
import { useCreateAcquirer } from '@services/staff/acquirers/create';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export function NewAcquirer() {
  const { t } = useTranslation('app');
  const { localId } = useParams({ from: '/u/$localId/_staff/staff/acquirers/new' });
  const navigate = useNavigate();

  const requestHandler = useCreateAcquirer({
    callback: () => {
      toast.success(t('toast.acquirers.createSuccess'));
      navigate({
        to: '/u/$localId/staff/acquirers',
        replace: true,
        params: { localId: localId || '0' },
      }).catch((err) => {
        console.error(err);
      });
    },
  });

  const formObj = useAcquirerForm({ requestHandler });

  return (
    <BaseLayout isChild title={t('pages.newAcquirer.title')}>
      <Card>
        <CardContent className="p-6">
          <AcquirerForm formObj={formObj} submitText={t('form.submit.acquirerCreate')} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}

export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/new')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirers_create'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: NewAcquirer,
});
