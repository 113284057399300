/* eslint-disable camelcase */
import { ErrorFallback } from '@pages/errorFallback';
import { appFiltersSchema } from '@ctx/filters/context';
import { Loader } from '@components/loader';
import { AppContextData } from '@ctx/appCtx';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useAppContext } from '@hooks/useAppContext';
import { useGetLoggedUser } from '@services/global/session/get';
import {
  Outlet,
  ScrollRestoration,
  createRootRouteWithContext,
  useLocation,
  useNavigate,
  useParams,
} from '@tanstack/react-router';
import { pubSub } from '@utils/pubSub';
import { transformUid } from '@utils/textHelpers';
import { Suspense, lazy, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { z } from 'zod';
import { ConnectionIndicator } from '@components/connectionIndicator';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : lazy(() =>
        // eslint-disable-next-line import/no-extraneous-dependencies
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

const RootPage = () => {
  const {
    token,
    expiresAt,
    user,
    setData,
    uid,
    userType,
    clearData,
    updateSessionTimer,
    lastLoggedUserType,
  } = useAppContext();
  const { localId } = useParams({ strict: false });
  const { isLogged } = useCheckUserPermissions();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isPending, refetch } = useGetLoggedUser();

  useEffect(() => {
    if (!uid && isLogged) {
      refetch().catch((err) => {
        console.error(err);
      });
    }
  }, [refetch, uid, isLogged]);

  useEffect(() => {
    if (!data || typeof setData !== 'function') return;
    if (JSON.stringify(data) === JSON.stringify(user) && uid === transformUid(data.id)) return;

    setData((ps) => ({
      ...ps,
      uid: transformUid(data.id),
      user: data,
    }));
  }, [data, expiresAt, setData, token, uid, user]);

  useEffect(() => {
    const sub401 = pubSub.subscribe('error:401', () => {
      const redirect = `${location.pathname}${window.location.search}`;
      navigate({
        to: `/${lastLoggedUserType()}/login`,
        replace: true,
        search: localId
          ? {
              redirect: redirect,
            }
          : {},
      })
        .finally(() => {
          clearData();
        })
        .catch((err) => {
          console.error(err);
        });
    });
    return () => {
      sub401.remove();
    };
  }, [userType, clearData, navigate, location.pathname, localId, lastLoggedUserType]);

  const timerUpdater = useDebouncedCallback((ev: Event) => {
    const target = ev.target as HTMLElement;
    if (target.closest('[aria-haspopup="dialog"]')) return;
    updateSessionTimer();
  }, 500);

  useEffect(() => {
    document.addEventListener('click', timerUpdater, true);
    document.addEventListener('keyup', timerUpdater, true);
    return () => {
      document.removeEventListener('click', timerUpdater, true);
      document.removeEventListener('keyup', timerUpdater, true);
    };
  }, [timerUpdater]);

  if (!user && isPending && token) return <Loader />;

  return (
    <div className={`flex min-h-screen w-full flex-col`}>
      <ScrollRestoration />
      <Outlet />
      <ConnectionIndicator />
      <Suspense>
        <TanStackRouterDevtools position="bottom-left" />
      </Suspense>
    </div>
  );
};

export const Route = createRootRouteWithContext<AppContextData>()({
  validateSearch: (search) =>
    appFiltersSchema
      .merge(
        z.object({
          redirect: z.string().optional(),
          invitation_token: z.string().optional(),
          password_reset_token: z.string().optional(),
          items: z.string().catch('50').optional(),
          sort: z
            .object({
              dir: z.string().optional(),
              field: z.string().optional(),
            })
            .optional(),
        }),
      )
      .parse(search),
  errorComponent: ({ error, reset }) => <ErrorFallback error={error} reset={reset} />,
  onCatch: (error) => {
    const span = window.errorReporter.createSpan();
    span.setError(error);
    void window.errorReporter.send(span);
  },
  component: RootPage,
});
