import { useApi } from '@hooks/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { queryKeys } from '@utils/consts';
import { isError } from '@utils/apiHelpers';
import { ApiError } from '@root/globalTypes';

type TwoFactorEnableResp = { otpProvisioningUri: string };

export const useEnableTwoFactor = ({
  successCallback,
}: {
  successCallback: (data: TwoFactorEnableResp) => void;
}) => {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string>('');

  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<TwoFactorEnableResp | ApiError> => {
      return query({
        path: 'v1/2fa',
        opt: {
          method: 'POST',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isError(data)) {
        setApiError(data.message);
      } else {
        successCallback(data);
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.loggedUser });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
};
