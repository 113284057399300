import { Agreement } from '@root/globalTypes';
import { BaseLayout } from '@layouts/base';
import { useTranslation } from 'react-i18next';
import { TabLink, TabsContainer } from '@components/tabLink';
import { Card, CardContent } from '@components/card';
import { Outlet, useMatches } from '@tanstack/react-router';
import { MATCH_LEVEL } from '@utils/consts';
import { AgreementDetails } from './details';
import { DisableDialog } from './disableDialog';
import { StatusBadge } from '@components/badge/status';

export function AgreementPage({ agreement }: { agreement: Agreement }) {
  const { t } = useTranslation('app');
  const matches = useMatches();

  return (
    <BaseLayout
      isChild
      title={
        <div className="flex items-center gap-2">
          {t('pages.agreement.title')}
          <StatusBadge active={agreement.active} />
        </div>
      }
      actions={[<DisableDialog agreement={agreement} />]}>
      <AgreementDetails agreement={agreement} />
      <Card>
        <CardContent className="py-3 pb-10">
          <TabsContainer>
            <TabLink
              label={t(`pages.agreement.orders`)}
              path={`/${matches[MATCH_LEVEL].pathname}/orders`}
            />
            <TabLink
              label={t(`pages.agreement.apiResponse`)}
              path={`/${matches[MATCH_LEVEL].pathname}/api-response`}
            />
          </TabsContainer>
          <Outlet />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}
