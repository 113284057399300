import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useApi } from '@hooks/useApi';
import { ApiError, MetaData } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { Transaction } from '@root/globalTypes';

interface TransactionsRespSuccess {
  transactions: Transaction[];
  meta: MetaData | undefined;
}

interface TransactionsReturn extends TransactionsRespSuccess {
  loading: boolean;
}

export const useListAcquirerTransactions = (acquirerId: string): TransactionsReturn => {
  const { isLogged } = useCheckUserPermissions();

  const { query } = useApi();
  const { data, isPending } = useQuery<TransactionsRespSuccess, ApiError>({
    queryKey: [...queryKeys.transactions, acquirerId, window.location.search],
    queryFn: () =>
      query({
        path: `v1/staff/acquirers/${acquirerId}/transactions${window.location.search}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged,
  });

  return {
    transactions: data?.transactions || [],
    meta: data?.meta || undefined,
    loading: isPending,
  };
};
