import { Badge } from '@components/badge';
import { useTranslation } from 'react-i18next';

export function UserStatusBadge({ active }: { active: boolean }) {
  const { t } = useTranslation('app');

  return (
    <Badge variant={active ? 'default' : 'danger'}>
      {active ? t('status.active') : t('status.inactive')}
    </Badge>
  );
}
