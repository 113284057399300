import { Card, CardContent } from '@components/card';
import { CreateAcquirerUserForm } from '@components/forms/acquirerUsers/create';
import { useCreateAcquirerUserForm } from '@hooks/forms/acquirerUsers/createForm';
import { BaseLayout } from '@layouts/base';
import { useCreateAcquirerUser } from '@services/staff/acquirer/users/create';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export function NewMerchantUser() {
  const { acquirerId, localId } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId/users/new',
  });
  const navigate = useNavigate();
  const { t } = useTranslation('app');

  const requestHandler = useCreateAcquirerUser({
    acquirerId,
    callback: () => {
      toast.success(t('toast.users.createSuccess'));
      navigate({
        to: '/u/$localId/staff/acquirers/$acquirerId/users',
        params: { acquirerId, localId },
        replace: true,
      }).catch((err) => {
        console.error(err);
      });
    },
  });
  const formObj = useCreateAcquirerUserForm({ requestHandler });

  return (
    <BaseLayout isChild title={t('pages.newAcquirerUser.title')}>
      <Card>
        <CardContent className="p-6">
          <CreateAcquirerUserForm formObj={formObj} />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}

export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/$acquirerId/users/new')({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['acquirer_users_create'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: NewMerchantUser,
});
