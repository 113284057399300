import { Combobox } from '@components/combobox';
import { MERCHANT_CATEGORIES } from '@utils/merchantCategories';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function MerchantCategoriesCombobox({
  value,
  onSelect,
  className,
}: {
  value: string;
  onSelect: (value: string | undefined) => void;
  className?: string;
}) {
  const { t } = useTranslation('app');
  const [filteredItems, setFilteredItems] = useState(MERCHANT_CATEGORIES);

  function handleSearch(input: string) {
    const trimmedInput = input.trim();
    if (trimmedInput.length) {
      const items = MERCHANT_CATEGORIES.filter((item) =>
        item.label.toLowerCase().includes(trimmedInput.toLowerCase()),
      );

      setFilteredItems(items);
    } else {
      setFilteredItems(MERCHANT_CATEGORIES);
    }
  }

  return (
    <Combobox
      items={filteredItems}
      defaultItems={MERCHANT_CATEGORIES}
      value={value}
      onSelect={onSelect}
      onSearch={handleSearch}
      className={className}
      noResultsMsg={t('form.mcc.noResultsMsg')}
      placeholder={t('form.mcc.placeholder')}
      searchPlaceholder={t('form.mcc.searchPlaceholder')}
    />
  );
}
