import { Loader } from '@components/loader';
import { AgreementPage } from '@pages/agreements/id';
import { useGetAgreement } from '@services/global/agreements/get';
import { createFileRoute, useParams } from '@tanstack/react-router';

function MerchantAgreement() {
  const { id } = useParams({ from: '/u/$localId/_merchant/merchant/agreements/$id' });
  const { data, isPending } = useGetAgreement({ id });

  if (isPending) return <Loader />;
  if (!data) return null;

  return <AgreementPage agreement={data} />;
}

export const Route = createFileRoute('/u/$localId/_merchant/merchant/agreements/$id')({
  component: MerchantAgreement,
});
