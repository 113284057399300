import { TablePagination } from '@components/pagination/tablePagination';
import { ColumnFilterPopover } from '@components/popover/columnsFilterPopover';
import { useAuditLogsTable } from '@components/tables/auditLogs';
import { useToggleTableColumns } from '@hooks/useToggleTableColumns';
import { BaseLayout } from '@layouts/base';
import { MetaData } from '@root/globalTypes';
import { AuditLog } from '@services/global/auditLogs/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersProvider } from '@ctx/filters';
import { Filters } from '@components/filters';
import { useAuditLogConfig } from '@hooks/filterConfigs/auditLogs';
import { MobileFilters } from '@components/filters/mobileFilters';

type AuditLogsListProps = {
  data: AuditLog[];
  meta?: MetaData;
  onRowSelect: (id: string) => void;
};

export function AuditLogsList({ data, meta, onRowSelect }: AuditLogsListProps) {
  const { t } = useTranslation('app');
  const config = useAuditLogConfig();
  const predefinedCols = useMemo(
    () => [
      'action',
      'action_type',
      'performer_id',
      'performer_email',
      'performer_type',
      'subject_id',
      'subject_type',
      'created_at',
    ],
    [],
  );
  const { visibleColumns, setColumns } = useToggleTableColumns({ predefinedCols });
  const { columns, Table } = useAuditLogsTable({ visibleColumns, data, onRowSelect });

  return (
    <FiltersProvider config={config}>
      <BaseLayout
        title={t('pages.auditLogs.title')}
        actions={[
          <MobileFilters key="filters" />,
          <ColumnFilterPopover
            key="colFilter"
            activeCols={visibleColumns}
            setActiveCols={setColumns}
            columns={columns.map((col) => ({ id: col.id as string, label: col.header as string }))}
            defaultColumns={predefinedCols}
          />,
        ]}>
        <Filters />
        <Table />
        {meta?.totalPages && (
          <TablePagination pages={meta.totalPages} total={meta.totalCount} items={data.length} />
        )}
      </BaseLayout>
    </FiltersProvider>
  );
}
