import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@components/alertDialog';
import { Button } from '@components/button';
import { useTranslation } from 'react-i18next';
import { useDisableTwoFactor } from '@services/global/twoFactor/disable';

export function DisableTwoFactorDialog() {
  const { t } = useTranslation('app');
  const { mutate, isPending } = useDisableTwoFactor();

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive" size="lg">
          {t('form.submit.disable')}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('pages.settings.twoFactor.disable.title')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('pages.settings.twoFactor.disable.description')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isPending}>{t('form.submit.cancel')}</AlertDialogCancel>
          <AlertDialogAction disabled={isPending} onClick={() => mutate()}>
            {t('form.submit.continue')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
