import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { useAppContext } from '@hooks/useAppContext';
import { MerchantBrief } from '@root/globalTypes';
import { BookA, Fingerprint, Store } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function MerchantBriefDetails({
  merchant,
  merchantRedirect,
  acquirerRedirect,
}: {
  merchant: MerchantBrief;
  merchantRedirect?: (id: string) => void;
  acquirerRedirect?: (id: string) => void;
}) {
  const { t } = useTranslation('app');
  const { userType } = useAppContext();

  return (
    <DetailsCard>
      <DetailsCardItem
        icon={<Fingerprint />}
        data={
          merchantRedirect ? (
            <a
              onClick={() => merchantRedirect(merchant.merchantId)}
              className="inline-flex items-center gap-1 hover:cursor-pointer hover:text-blue-700 hover:underline">
              {merchant.merchantId}
            </a>
          ) : (
            merchant.merchantId
          )
        }
        label={t('table.merchantId')}
        column={1}
      />
      {userType === 'staff' && (
        <DetailsCardItem
          icon={<Fingerprint />}
          data={
            <a
              onClick={() => acquirerRedirect?.(merchant.acquirer.id)}
              className="inline-flex items-center gap-1 hover:cursor-pointer hover:text-blue-700 hover:underline">
              {merchant.acquirer.id}
            </a>
          }
          label={t('table.acquirerId')}
          column={1}
        />
      )}
      <DetailsCardItem icon={<Store />} data={merchant.name} label={t('table.name')} column={1} />
      <DetailsCardItem
        icon={<BookA />}
        data={merchant.arabicName}
        label={t('table.arabicName')}
        column={2}
      />
    </DetailsCard>
  );
}
