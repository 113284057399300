import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Loader } from '@components/loader';
import { outletNavScroll } from '@utils/apiHelpers';
import { useEffect } from 'react';
import { useGetMerchantTransactions } from '@services/acquirer/merchant/transactions/list';
import { TransactionsPreview } from '@pages/transactions/preview';
import { Transaction } from '@root/globalTypes';

const MerchantTransactions = () => {
  const navigate = useNavigate();
  const { localId, merchantId } = useParams({
    from: '/u/$localId/_acquirer/acquirer/merchants/$merchantId/transactions',
  });
  const { transactions, loading } = useGetMerchantTransactions(merchantId);
  const handleSelect = (transaction: Transaction) => {
    navigate({
      to: '/u/$localId/acquirer/orders/$id/transactions',
      params: { id: transaction.orderId, localId },
    }).catch((err) => {
      console.error(err);
    });
  };

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (loading) return <Loader />;

  return (
    <TransactionsPreview
      onSelect={handleSelect}
      transactions={transactions}
      onViewMore={() => {
        navigate({
          to: '/u/$localId/acquirer/transactions',
          params: { localId },
          /* eslint-disable camelcase */
          search: { merchant_id: merchantId },
        }).catch((err) => console.error(err));
      }}
    />
  );
};

export const Route = createFileRoute(
  '/u/$localId/_acquirer/acquirer/merchants/$merchantId/transactions',
)({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['orders_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantTransactions,
});
