import { Button } from '@components/button';
import { Dialog, DialogTrigger, DialogContent, DialogTitle } from '@components/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import { useExportData } from '@hooks/forms/exports';
import { Checkbox } from '@components/form/checkbox';
import { useGetExportColumns } from '@services/global/exports/columns';
import { humanizeText } from '@utils/textHelpers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@components/switch';
import { Download, Info } from 'lucide-react';
import { IconButton } from '@components/button/iconButton';

export type ExportVariants = 'orders' | 'transactions';

export const ExportDialog = ({ type }: { type: ExportVariants }) => {
  const { t } = useTranslation('app');
  const [open, setOpen] = useState(false);
  const [limit, setLimit] = useState(false);
  const onSuccess = () => {
    setOpen(false);
  };

  const { columns } = useGetExportColumns(type, open);

  const { form, onSubmit, isPending } = useExportData({ successHandler: onSuccess, type });

  useEffect(() => {
    if (!open) form.reset();
  }, [open, form]);

  useEffect(() => {
    if (columns) form.setValue('columns', columns);
  }, [columns, form]);

  useEffect(() => {
    form.setValue('count', limit ? 1000 : '');
  }, [limit, form]);

  const handleFormReset = () => {
    form.reset();
    setLimit(false);
  };

  const handleSelectAll = (checked: boolean) => {
    form.setValue('columns', checked ? columns : []);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <IconButton variant="secondary" icon={<Download size={18} />}>
          {t(`pages.${type}.export`)}
        </IconButton>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle className="whitespace-nowrap text-3xl font-semibold">
          {t(`pages.${type}.export`)}
        </DialogTitle>
        <div className="flex items-center gap-1">
          <Info size={16} className="text-gray-400" />
          <h3 className="text-gray-400">{t('exportInfo')}</h3>
        </div>
        <Form {...form}>
          <form
            id="acquirerForm"
            className="flex w-full flex-col gap-2"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="columns"
              render={({ field }) => {
                return (
                  <div className="flex flex-col">
                    <div className="my-2 flex items-center gap-2">
                      <FormLabel className="static text-base">{t('table.columns')}</FormLabel>
                      <div className="flex items-center gap-2">
                        <Switch
                          light
                          id="allColumns"
                          checked={field.value?.length === columns.length}
                          onCheckedChange={handleSelectAll}
                        />
                        <FormLabel htmlFor="allColumns" className="static text-neutral-400">
                          {t('checkAll')}
                        </FormLabel>
                      </div>
                    </div>
                    <div className="my-3 rounded bg-primary-300/5 p-3">
                      <div className="grid max-h-72 grid-cols-2 gap-4 overflow-auto">
                        {columns.map((col) => (
                          <FormItem className="flex space-x-3 space-y-0 bg-transparent" key={col}>
                            <FormControl>
                              <Checkbox
                                light
                                checked={field.value?.includes(col)}
                                onCheckedChange={(checked) => {
                                  if (!field.value) return;
                                  if (checked) form.setValue('columns', [...field.value, col]);
                                  if (!checked)
                                    form.setValue('columns', [
                                      ...field.value.filter((el) => el !== col),
                                    ]);
                                }}
                              />
                            </FormControl>
                            <div className="space-y-1 leading-none">
                              <FormLabel className="static">{humanizeText(col)}</FormLabel>
                            </div>
                          </FormItem>
                        ))}
                      </div>
                    </div>
                    <FormMessage />
                  </div>
                );
              }}
            />
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <Switch id="exportLimit" checked={limit} onCheckedChange={setLimit} light />
                <FormLabel htmlFor="exportLimit" className="static text-neutral-400">
                  {t('form.limit.switch')}
                </FormLabel>
              </div>
              {limit && (
                <FormField
                  control={form.control}
                  name="count"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('form.limit.label')}</FormLabel>
                      <FormControl>
                        <Input type="number" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
            <div className="mt-8 grid grid-cols-3 gap-4">
              <Button
                variant="primary"
                size="lg"
                type="submit"
                className="col-span-2"
                disabled={isPending}
                loading={isPending}>
                {t('form.submit.export')}
              </Button>
              <Button
                disabled={isPending}
                variant="link"
                type="reset"
                size="lg"
                onClick={handleFormReset}>
                {t('form.reset')}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
