import { useEffect, useRef } from 'react';
import { PanelLeftClose, PanelLeftOpen } from 'lucide-react';
import { cn } from '@utils/cn';
import { useNavigation } from '@ctx/navigation/context';

export const SideNav = () => {
  const offset = useRef<number>(0);
  const { navOpen: open, setNavOpen: setOpen, links } = useNavigation();

  useEffect(() => {
    const topBar = document.getElementById('topBar');
    if (topBar) {
      const { height } = topBar.getBoundingClientRect();
      // eslint-disable-next-line immutable/no-mutation
      offset.current = height;
    }
  }, []);

  return (
    <aside
      className={cn(
        'group sticky top-0 z-40 hidden shrink-0 flex-col self-start overflow-hidden overflow-y-auto bg-white pb-3 pt-8 lg:flex lg:pt-0',
      )}
      style={{ height: window.innerHeight - offset.current, top: offset.current }}>
      <div className="-mt-2 flex justify-end">
        <button
          onClick={() => setOpen((ps) => !ps)}
          className="-mr-1 flex h-14 w-7 items-center justify-center rounded-bl-3xl bg-neutral-50 text-primary shadow-inner">
          {open ? <PanelLeftClose size={16} /> : <PanelLeftOpen size={16} />}
        </button>
      </div>
      {links}
    </aside>
  );
};
