import { useContext } from 'react';
import { TwoFactorSetupContext } from '@ctx/twoFactor/context';

export function useTwoFactorSetupContext() {
  const context = useContext(TwoFactorSetupContext);

  if (!context) {
    throw new Error('useTwoFactorSetupContext must be used within a TwoFactorSetupContextProvider');
  }

  return context;
}
