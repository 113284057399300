import { useApi } from '@hooks/useApi';
import { queryKeys } from '@utils/consts';
import { useQuery } from '@tanstack/react-query';

export function useGetAuditLogSubjects() {
  const { query } = useApi();

  const { data, isPending } = useQuery<string[]>({
    queryKey: [...queryKeys.auditLogsSubjects],
    queryFn: (): Promise<string[]> => query({ path: 'v1/audit_logs/subjects' }),
  });

  return {
    data: data || [],
    isPending,
  };
}
