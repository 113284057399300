import { Button } from '@components/button';
import { Dialog, DialogTrigger, DialogContent, DialogTitle } from '@components/dialog';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MutateObject, PaymentControl } from '@root/globalTypes';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { PaymentControlForm } from '@components/forms/paymentControls';
import { PaymentControlApiParams, usePaymentControlForm } from '@hooks/forms/paymentControls';
import { usePaymentControlFormContext } from '@hooks/usePaymentControlsContext';

export const EditDialog = ({
  paymentControl,
  requestHandler,
}: {
  paymentControl: PaymentControl;
  requestHandler: MutateObject<PaymentControl, PaymentControlApiParams>;
}) => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const { formOpen, setFormOpen, formType, setFormType } = usePaymentControlFormContext();
  const { identifierType: type } = paymentControl;

  useEffect(() => {
    if (formOpen) setFormType(type);
  }, [formOpen, type, setFormType]);

  const form = usePaymentControlForm({
    paymentControl,
    requestHandler,
  });

  if (!verifyUserPermission('payment_controls_update')) return null;

  return (
    <Dialog open={formOpen.edit} onOpenChange={(open) => setFormOpen({ ...formOpen, edit: open })}>
      <DialogTrigger asChild>
        <Button variant="secondary">
          {t('pages.paymentControls.edit', { type: t(`pages.paymentControls.${type}`) })}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle className="flex items-center gap-2 whitespace-nowrap">
          {t('pages.paymentControls.edit', { type: t(`pages.paymentControls.${type}`) })}
        </DialogTitle>
        <PaymentControlForm key={formType} formObj={form} type={formType} />
      </DialogContent>
    </Dialog>
  );
};
