import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useResetPassword } from '@services/global/passwords/reset';
import { ApiError, UserType } from '@root/globalTypes';
import { useSchema } from './schema';

export const useResetPasswordForm = ({
  userType,
  token,
  successHandler,
}: {
  userType: UserType;
  token: string;
  successHandler: () => void;
}) => {
  const schema = useSchema();

  const defaultValues = {
    password: '',
    confirmPassword: '',
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  function setFieldErrors(errorResp: ApiError) {
    const { errors } = errorResp;
    if (!errors) return;

    Object.entries(errors).forEach(([key, value]) => {
      const k = key as keyof z.infer<typeof schema>;

      form.setError(k, { type: 'manual', message: value[0] });
    });
  }

  const { mutate, isPending } = useResetPassword({
    handleSuccess: successHandler,
    handleFailure: setFieldErrors,
    userType,
    token,
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate({ password: values.password });
  };

  return {
    form,
    onSubmit,
    isPending,
  };
};
