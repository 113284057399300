import { FiltersConfig } from '@ctx/filters/context';

export const config: FiltersConfig = {
  items: [
    { id: 'agreement_id', filterType: 'text' },
    { id: 'max_use', filterType: 'range', format: 'number' },
    { id: 'order_id', filterType: 'text' },
    { id: 'expiry', filterType: 'date', format: 'date' },
    {
      id: 'type',
      filterType: 'select',
      options: [
        { label: 'Unscheduled', value: 'unscheduled' },
        { label: 'Recurring', value: 'recurring' },
        { label: 'Registered', value: 'registered' },
        { label: 'Installment', value: 'installment' },
      ],
    },
    {
      id: 'variability',
      filterType: 'select',
      options: [
        { label: 'Fixed', value: 'fixed' },
        { label: 'Variable', value: 'variable' },
      ],
    },
    { id: 'created_at', filterType: 'date', format: 'date' },
    { id: 'updated_at', filterType: 'date', format: 'date' },
  ],
  default: ['agreement_id', 'order_id', 'type', 'variability', 'max_use', 'expiry', 'created_at'],
};
