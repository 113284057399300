import { LoginLayout } from '@layouts/login';
import { AcquirerLoginForm } from '@components/forms/acquirer/login';
import { useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';

function AcquirerLogin() {
  const { t } = useTranslation('app');

  return (
    <LoginLayout title={t('pages.login.title')}>
      <AcquirerLoginForm />
    </LoginLayout>
  );
}

export const Route = createFileRoute('/acquirer/login')({
  component: AcquirerLogin,
});
