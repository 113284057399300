import { Button } from '@components/button';
import { Dialog, DialogTrigger, DialogContent, DialogTitle } from '@components/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/form/form';
import { Input } from '@components/form/input';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useOrderVoid } from '@hooks/forms/order/void';
import { Order } from '@root/globalTypes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OrderVoid = ({ order }: { order: Order }) => {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();
  const [open, setOpen] = useState(false);
  const { amount, capturedAmount, voidedAmount } = order;
  const disabled = capturedAmount + voidedAmount >= amount;
  const limit = amount - capturedAmount - voidedAmount;

  const onSuccess = () => {
    setOpen(false);
  };

  const { form, onSubmit, isPending, error } = useOrderVoid(order.id, limit, onSuccess);

  if (!verifyUserPermission('orders_void')) return null;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button disabled={disabled} variant="destructive" className="w-24">
          {t('pages.order.void')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{t('pages.order.voidOrder', { id: order.id })}</DialogTitle>
        <Form {...form}>
          <form
            id="acquirerForm"
            className="flex w-full flex-col gap-2"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('table.amount')}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {error && <p className="text-red">{error}</p>}
            <Button
              type="submit"
              variant="destructive"
              size="lg"
              className="mt-8"
              disabled={isPending}
              loading={isPending}>
              {t('form.submit.void')}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
