import { cva } from 'class-variance-authority';

export const alertVariants = cva(
  'flex w-full items-center border-l-6 px-7 py-8 shadow-md md:p-9 ',
  {
    variants: {
      variant: {
        default:
          'border-blue-400 text-body bg-blue-400/10 [&>div:first-child]:bg-blue-400/20 [&>div:first-child]:text-blue-400 [&_h5]:text-black',
        danger:
          'border-red-400 bg-red-400/10 text-red-600 [&>div:first-child]:bg-red-400/20 [&>div:first-child]:text-red-400 [&_h5]:text-red-600',
        warning:
          'border-yellow-400 bg-yellow-400/10 text-yellow-600 [&>div:first-child]:bg-yellow-400/20 [&>div:first-child]:text-yellow-400 [&_h5]:text-yellow-600',
        success:
          'border-green-400 text-body bg-green-400/10 [&>div:first-child]:bg-green-400/20 [&>div:first-child]:text-green-400 [&_h5]:text-black',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
