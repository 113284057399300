import { Card, CardContent } from '@components/card';
import { MerchantLinkForm } from '@components/forms/merchantLinks';
import { useMerchantLinkForm } from '@hooks/forms/merchantLinks';
import { BaseLayout } from '@layouts/base';
import { Page404 } from '@pages/404';
import { useListAcquirerLinks } from '@services/acquirer/links/list';
import { useGetMerchantLink } from '@services/acquirer/merchant/links/get';
import { useUpdateMerchantLink } from '@services/acquirer/merchant/links/update';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Loader } from '@components/loader';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function EditMerchantLink() {
  const { t } = useTranslation('app');
  const { id, merchantId, localId } = useParams({
    from: '/u/$localId/_acquirer/acquirer/merchants/$merchantId/links/$id/edit',
  });
  const navigate = useNavigate();

  const { data: merchantLink, isPending: fetchingMerchantLink } = useGetMerchantLink({
    id,
    merchantId,
  });
  const { data: acquirerLinks, isPending: fetchingAcquirerLinks } = useListAcquirerLinks();
  const requestHandler = useUpdateMerchantLink({
    id,
    merchantId,
    successHandler: async (link) => {
      await navigate({
        to: '/u/$localId/acquirer/merchants/$merchantId/links/$id',
        params: {
          merchantId: link.merchant.merchantId,
          id: link.id,
          localId: localId || '0',
        },
        replace: true,
      });
      toast.success(t('toast.merchantLink.updateSuccess'));
    },
  });

  const form = useMerchantLinkForm({ merchantLink, requestHandler });

  if (fetchingMerchantLink || fetchingAcquirerLinks) return <Loader />;
  if (!merchantLink) return <Page404 />;

  return (
    <BaseLayout isChild title={t('pages.editMerchantLink.title')}>
      <Card>
        <CardContent className="p-6">
          <MerchantLinkForm
            formObj={form}
            acquirerLinks={acquirerLinks}
            buttonLabel={t('form.submit.updateMerchantLink')}
          />
        </CardContent>
      </Card>
    </BaseLayout>
  );
}

export const Route = createFileRoute(
  '/u/$localId/_acquirer/acquirer/merchants/$merchantId/links/$id/edit',
)({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_links_read', 'merchant_links_update'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: EditMerchantLink,
});
