import { useState } from 'react';
import { LoginContext } from './context';

export function LoginContextProvider({ children }: { children: React.ReactNode }) {
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [showRecovery, setShowRecovery] = useState(false);
  const [token, setToken] = useState('');

  return (
    <LoginContext.Provider
      value={{
        showSecondStep,
        setShowSecondStep,
        showRecovery,
        setShowRecovery,
        token,
        setToken,
      }}>
      {children}
    </LoginContext.Provider>
  );
}
