import { Button } from '@components/button';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogHeader,
  DialogDescription,
} from '@components/dialog';
import { useTwoFactorSetupContext } from '@hooks/useTwoFactorSetupContext';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '@utils/fileHelpers';

export function BackupCodesDialog() {
  const { t } = useTranslation('app');
  const { backupCodes, showBackupCodesDialog, setShowBackupCodesDialog } =
    useTwoFactorSetupContext();

  function downloadBackupCodes() {
    const fileString =
      'data:plain/plain;charset=utf-8,' + encodeURIComponent(backupCodes.join('\n'));
    const filename = 'backup-codes.txt';

    downloadFile({ filename, fileString });
    setShowBackupCodesDialog(false);
  }

  return (
    <Dialog open={showBackupCodesDialog} onOpenChange={setShowBackupCodesDialog}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('pages.settings.twoFactor.backupCodes.title')}</DialogTitle>
          <DialogDescription>
            {t('pages.settings.twoFactor.backupCodes.description')}
          </DialogDescription>
        </DialogHeader>

        <ul className="ml-4 grid grid-cols-2">
          {backupCodes.map((code) => (
            <li key={code} className="list-disc">
              {code}
            </li>
          ))}
        </ul>
        <Button variant="primary" onClick={downloadBackupCodes}>
          Download
        </Button>
      </DialogContent>
    </Dialog>
  );
}
