import { Card, CardHeader, CardTitle } from '@components/card';
import { ConfirmDialog } from './confirmDialog';
import { BackupCodesDialog } from './backupCodesDialog';
import { Button } from '@components/button';
import { useEnableTwoFactor } from '@services/global/twoFactor/enable';
import { DisableTwoFactorDialog } from './disableTwoFactorDialog';
import { useTwoFactorSetupContext } from '@hooks/useTwoFactorSetupContext';
import { useTranslation } from 'react-i18next';

export function TwoFactorSection() {
  const { t } = useTranslation('app');
  const { user, setQrCodeUri, setShowConfirmDialog } = useTwoFactorSetupContext();
  const { mutate: enable } = useEnableTwoFactor({
    successCallback: (data) => {
      setQrCodeUri(data.otpProvisioningUri);
      setShowConfirmDialog(true);
    },
  });

  return (
    <div>
      <ConfirmDialog />
      <BackupCodesDialog />
      <Card>
        <CardHeader className="flex flex-row justify-between">
          <CardTitle className="flex flex-col gap-2">
            {t('pages.settings.twoFactor.title')}
            {user.twoFactorEnabled ? (
              <div className="text-base font-medium text-green-500">
                {t('pages.settings.twoFactor.enabled')}
              </div>
            ) : (
              <div className="text-base font-medium text-red-500">
                {t('pages.settings.twoFactor.disabled')}
              </div>
            )}
          </CardTitle>

          {user.twoFactorEnabled ? (
            <DisableTwoFactorDialog />
          ) : (
            <Button variant="secondary" size="lg" onClick={() => enable()}>
              {t('form.submit.enable')}
            </Button>
          )}
        </CardHeader>
      </Card>
    </div>
  );
}
