/* eslint-disable camelcase, immutable/no-mutation */
import { ComboBoxItemType } from '@components/combobox';
import { createContext, useContext } from 'react';
import { z } from 'zod';

export const appFiltersSchema = z.object({
  page: z.string().catch('1').optional(),
  agreement_id: z.string().optional(),
  type: z.string().optional(),
  max_use: z
    .object({
      gte: z.string().optional(),
      lte: z.string().optional(),
    })
    .optional(),
  order_id: z.string().optional(),
  id: z.string().optional(),
  merchant_id: z.string().optional(),
  source_type: z.string().optional(),
  terminal_id: z.string().optional(),
  acquirer_id: z.string().optional(),
  forwarder_id: z.string().optional(),
  issuer_id: z.string().optional(),
  currency: z.string().optional(),
  amount: z
    .object({
      gte: z.string().optional(),
      lte: z.string().optional(),
    })
    .optional(),
  device_ip: z.string().optional(),
  sub_merchant_id: z.string().optional(),
  retrieval_reference: z.string().optional(),
  stan: z.string().optional(),
  auth_code: z.string().optional(),
  response_code: z.string().optional(),
  first_digits: z.string().optional(),
  last_digits: z.string().optional(),
  scheme: z.string().optional(),
  fingerprint: z.string().optional(),
  created_at: z
    .object({
      gte: z.string().optional(),
      lte: z.string().optional(),
    })
    .optional(),
  card_expiry: z
    .object({
      month: z.string().optional(),
      year: z.string().optional(),
    })
    .optional(),
  expiry: z
    .object({
      gte: z.string().optional(),
      lte: z.string().optional(),
    })
    .optional(),
  updated_at: z
    .object({
      gte: z.string().optional(),
      lte: z.string().optional(),
    })
    .optional(),
  tds_ref: z.string().optional(),
  ds_ref: z.string().optional(),
  acs_ref: z.string().optional(),
  tds_id: z.string().optional(),
  ds_id: z.string().optional(),
  acs_id: z.string().optional(),
  status: z.string().optional(),
  name: z.string().optional(),
  mcc: z.string().optional(),
  cr_number: z.string().optional(),
  performer_type: z.string().optional(),
  performer_email: z.string().optional(),
  subject_type: z.string().optional(),
  subject_id: z.string().optional(),
  organization_type: z.string().optional(),
  organization_name: z.string().optional(),
  action_type: z.string().optional(),
  action_name: z.string().optional(),
  performer_id: z.string().optional(),
  description: z.string().optional(),
  card_type: z.string().optional(),
  category: z.string().optional(),
  issuer: z.string().optional(),
  country: z.string().optional(),
  variability: z.string().optional(),
  acquirer_name: z.string().optional(),
});

const rangeFilters = ['created_at', 'updated_at', 'expiry', 'amount', 'max_use'];

export type FiltersSearch = z.infer<typeof appFiltersSchema>;
export type AvailableFilters = keyof FiltersSearch;
export type FiltersValues = FiltersSearch[AvailableFilters];

export function isRangeFilter(filter: FiltersValues): filter is { gte?: string; lte?: string } {
  return (
    typeof filter === 'object' &&
    filter !== null &&
    ('gte' in filter ? typeof filter.gte === 'string' || filter.gte === undefined : true) &&
    ('lte' in filter ? typeof filter.lte === 'string' || filter.lte === undefined : true)
  );
}

export function getDefaultFilterValue(filter: AvailableFilters): FiltersValues {
  if (filter === 'card_expiry') return { month: '', year: '' };
  if (rangeFilters.includes(filter)) return { gte: '', lte: '' };
  return '';
}

export function isFilterKey(key: string): boolean {
  const keysSchema = appFiltersSchema.keyof();
  const result = keysSchema.safeParse(key);
  return result.success;
}

export function removeEmptyKeys(obj: FiltersSearch): FiltersSearch {
  const cleanedObject: Record<string, unknown> = {};

  Object.keys(obj).forEach((k) => {
    const value = obj[k as keyof FiltersSearch];

    if (typeof value === 'object' && value !== null) {
      const cleanedValue = removeEmptyKeys(value as FiltersSearch);
      if (Object.keys(cleanedValue).length > 0) {
        cleanedObject[k as keyof FiltersSearch] = cleanedValue;
      }
    } else if (value !== '' && value !== undefined && value !== null) {
      cleanedObject[k as keyof FiltersSearch] = value;
    }
  });

  return cleanedObject as FiltersSearch;
}

type FilterTypes =
  | 'text'
  | 'select'
  | 'range'
  | 'amount'
  | 'card_expiry'
  | 'currency'
  | 'date'
  | 'group';

export type FilterConfigItem = {
  id: AvailableFilters;
  filterType: FilterTypes;
  options?: ComboBoxItemType[];
  format?: 'text' | 'number' | 'date';
  disabledBy?: AvailableFilters;
  groupe?: FilterConfigItem[];
};

export type FiltersConfig = {
  items: FilterConfigItem[];
  default: AvailableFilters[];
};

export type FiltersCtx = {
  activeFilters: FiltersSearch;
  visibleFilters: AvailableFilters[];
  setFilter: (filter: AvailableFilters, value: FiltersValues) => void;
  setActiveFilters: (filters: FiltersSearch) => void;
  removeFilter: (filter: AvailableFilters[]) => void;
  addFilter: (filter: AvailableFilters) => void;
  addFilters: (filters: AvailableFilters[]) => void;
  applyFilters: () => void;
  configItems: FilterConfigItem[];
  defaultFilters: FiltersSearch;
};

export const FiltersContext = createContext<FiltersCtx | null>(null);

export function useFilters() {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error('useFitlters must be used within FiltersProvider');
  }

  return context;
}
