import { FiltersConfig } from '@ctx/filters/context';

export const config: FiltersConfig = {
  items: [
    { id: 'merchant_id', filterType: 'text' },
    { id: 'name', filterType: 'text' },
    { id: 'mcc', filterType: 'text' },
    { id: 'cr_number', filterType: 'text', format: 'number' },
    { id: 'acquirer_id', filterType: 'text', format: 'text' },
    { id: 'acquirer_name', filterType: 'text', format: 'text' },
    { id: 'created_at', filterType: 'date', format: 'date' },
    { id: 'updated_at', filterType: 'date', format: 'date' },
  ],
  default: ['merchant_id', 'name', 'mcc', 'cr_number', 'created_at'],
};
