import { Alert } from '@components/alert';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/button';
import { useAppContext } from '@hooks/useAppContext';
import { useLocation, useNavigate, useParams } from '@tanstack/react-router';
import { useState } from 'react';
import { X } from 'lucide-react';

export function ResetPasswordAlert({ daysToExpire }: { daysToExpire: number }) {
  const { localId } = useParams({ strict: false });
  const { t } = useTranslation('app');
  const location = useLocation();
  const [closed, setClosed] = useState(false);
  const { userType } = useAppContext();
  const navigate = useNavigate();

  if (closed) return null;

  return (
    <Alert variant="warning" className="mb-4 lg:px-12">
      <div className="flex flex-row items-center justify-between">
        {t('alerts.passwordReset', { days: daysToExpire })}
        <div className="flex gap-2">
          {location.pathname !== `/u/${localId}/${userType}/settings` ? (
            <Button
              variant="warning"
              onClick={() => {
                navigate({ to: `/u/$localId/${userType}/settings` }).catch((err) =>
                  console.error(err),
                );
              }}>
              {t('nav.settings')}
            </Button>
          ) : null}
          <Button variant="warning" onClick={() => setClosed(true)}>
            <X size={18} />
          </Button>
        </div>
      </div>
    </Alert>
  );
}
