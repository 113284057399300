import { useTranslation } from 'react-i18next';
import { TablePagination } from '@components/pagination/tablePagination';
import { ColumnFilterPopover } from '@components/popover/columnsFilterPopover';
import { useToggleTableColumns } from '@hooks/useToggleTableColumns';
import { BaseLayout } from '@layouts/base';
import { useCardAuthsTable } from '@components/tables/cardAuths';
import { CardAuth } from '@services/global/cardAuths/types';
import { Row } from '@tanstack/react-table';
import { MetaData } from '@root/globalTypes';
import { useMemo } from 'react';
import { FiltersProvider } from '@ctx/filters';
import { Filters } from '@components/filters';
import { FiltersConfig } from '@ctx/filters/context';
import { MobileFilters } from '@components/filters/mobileFilters';

type CardAuthsProps = {
  cardAuths: CardAuth[];
  onRowSelect: (row: Row<CardAuth>) => void;
  meta?: MetaData;
  filterConfig: FiltersConfig;
};

export const CardAuths = ({ cardAuths, onRowSelect, meta, filterConfig }: CardAuthsProps) => {
  const { t } = useTranslation('app');
  const predefinedCols = useMemo(
    () => [
      'id',
      'merchant_id',
      'status',
      'amount',
      'currency',
      'message',
      'created_at',
      'updated_at',
    ],
    [],
  );
  const { visibleColumns, setColumns } = useToggleTableColumns({ predefinedCols });
  const { columns, Table: CardAuthsTable } = useCardAuthsTable({
    visibleColumns,
    onRowSelect,
    data: cardAuths,
  });

  return (
    <FiltersProvider config={filterConfig}>
      <BaseLayout
        title={t('pages.cardAuths.title')}
        actions={[
          <MobileFilters key="filters" />,
          <ColumnFilterPopover
            key="colFilter"
            activeCols={visibleColumns}
            setActiveCols={setColumns}
            columns={columns.map((col) => ({ id: col.id as string, label: col.header as string }))}
            defaultColumns={predefinedCols}
          />,
        ]}>
        <Filters />
        <CardAuthsTable />
        {meta?.totalPages && (
          <TablePagination
            pages={meta.totalPages}
            total={meta.totalCount}
            items={cardAuths.length}
          />
        )}
      </BaseLayout>
    </FiltersProvider>
  );
};
