import { RawPreview } from '@components/rawPreview';
import { useGetAgreement } from '@services/global/agreements/get';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { outletNavScroll } from '@utils/apiHelpers';
import { Loader } from '@components/loader';
import { useEffect } from 'react';

function AgreementApiResponse() {
  const { id } = useParams({ from: '/u/$localId/_merchant/merchant/agreements/$id/api-response' });
  const { data, isPending } = useGetAgreement({ id });

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (isPending) return <Loader />;

  return <RawPreview data={data} />;
}

export const Route = createFileRoute('/u/$localId/_merchant/merchant/agreements/$id/api-response')({
  component: AgreementApiResponse,
});
