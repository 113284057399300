import { buttonVariants } from '@components/button/variants';
import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { DeleteMerchantLinkDialog } from '@components/dialog/deleteMerchantLink';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { BaseLayout } from '@layouts/base';
import { MerchantLink } from '@root/globalTypes';
import { formatDate } from '@utils/dateHelpers';
import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';

export function MerchantLinkPage({
  merchantLink,
  onDelete,
}: {
  merchantLink: MerchantLink;
  onDelete?: () => void;
}) {
  const { t } = useTranslation('app');
  const { verifyUserPermission } = useCheckUserPermissions();

  return (
    <BaseLayout
      isChild
      title={t('pages.merchantLink.title')}
      actions={[
        verifyUserPermission('merchant_links_update') && (
          <Link
            key="edit"
            to={`${location.pathname}/edit`}
            className={buttonVariants({ variant: 'primary' })}>
            {t('actions.edit')}
          </Link>
        ),
        onDelete && <DeleteMerchantLinkDialog key="delete" onDelete={onDelete} />,
      ]}>
      <DetailsCard>
        <DetailsCardItem label={t('table.id')} data={merchantLink.id} column={1} />
        <DetailsCardItem label={t('table.mcc')} data={merchantLink.mcc} column={1} />
        <DetailsCardItem
          label={t('table.merchantSchemeId')}
          data={merchantLink.merchantSchemeId}
          column={1}
        />
        <DetailsCardItem label={t('table.terminalId')} data={merchantLink.terminalId} column={1} />
        <DetailsCardItem
          label={t('table.schemes')}
          data={merchantLink.schemes.join(', ')}
          column={1}
        />
        <DetailsCardItem label={t('table.priority')} data={merchantLink.priority} column={2} />
        <DetailsCardItem
          label={t('table.currencies')}
          data={merchantLink.currencies.join(', ')}
          column={2}
        />
        <DetailsCardItem
          label={t('table.createdAt')}
          data={formatDate(new Date(merchantLink.createdAt))}
          column={2}
        />
        <DetailsCardItem
          label={t('table.updatedAt')}
          data={formatDate(new Date(merchantLink.updatedAt))}
          column={2}
        />
      </DetailsCard>
    </BaseLayout>
  );
}
