import { Button } from '@components/button';
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '@components/dialog';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { Agreement } from '@root/globalTypes';
import { useDisableAgreement } from '@services/global/agreements/disable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export function DisableDialog({ agreement }: { agreement: Agreement }) {
  const [open, setOpen] = useState(false);
  const { verifyUserPermission } = useCheckUserPermissions();
  const { t } = useTranslation('app');
  const { isPending, mutate } = useDisableAgreement({
    id: agreement.id,
    callback: () => {
      setOpen(false);
      toast.success(t('toast.agreements.disableSuccess'));
    },
  });

  if (!agreement.active) return null;
  if (!verifyUserPermission('agreements_disable')) return null;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive">{t('pages.agreements.disable.title')}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle className="flex items-center gap-2 whitespace-nowrap">
          {t('pages.agreements.disable.title')}
        </DialogTitle>
        <p>{t('pages.agreements.disable.info')}</p>
        <Button disabled={isPending} onClick={() => mutate()} variant="destructive">
          {t('actions.disable')}
        </Button>
        <Button disabled={isPending} onClick={() => setOpen(false)} variant="ghost">
          {t('actions.cancel')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
