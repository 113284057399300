/* eslint-disable immutable/no-mutation */
import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { cn } from '@utils/cn';
import { Check } from 'lucide-react';

interface ExtraProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  light?: boolean;
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, ExtraProps>(
  ({ className, light, ...props }, ref) => (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        'border-stroke peer relative h-5 w-5 shrink-0 rounded-[4px] border bg-white focus:border-primary active:border-primary',
        props.checked && 'border-primary bg-primary',
        props.checked && light && 'border-primary-300 bg-primary-300',
        className,
      )}
      {...props}>
      <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
        <div className={`absolute inset-0 flex items-center justify-center`}>
          <span className={`opacity-0 ${props.checked && 'text-white !opacity-100'}`}>
            <Check size={16} />
          </span>
        </div>
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  ),
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
