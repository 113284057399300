import { Button } from '@components/button';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { StaffUser } from '@root/globalTypes';
import { UseMutateFunction } from '@tanstack/react-query';
import { UserMinus } from 'lucide-react';

type DisableUserBtnProps = {
  user: StaffUser;
  onDisable: UseMutateFunction<StaffUser, Error, string, unknown>;
};

export function DisableStaffUserButton({ user, onDisable }: DisableUserBtnProps) {
  const { verifyUserPermission } = useCheckUserPermissions();

  if (!verifyUserPermission(`staff_users_disable`) || !user.active) {
    return null;
  }

  return (
    <Button
      size="sm"
      className="w-14"
      variant="destructive-outline"
      type="button"
      onClick={() => onDisable(user.id)}>
      <UserMinus className="h-5" />
    </Button>
  );
}
