import visa from '@assets/payments/visa.png';
import mada from '@assets/payments/mada.svg';
import master from '@assets/payments/mc.svg';
import amex from '@assets/payments/amex.png';
import appple from '@assets/payments/apple-pay.svg';
import samsung from '@assets/payments/samsung-pay.png';
import google from '@assets/payments/google-pay.svg';
import { ValidSchemes } from '@root/globalTypes';

export function downloadFile({ fileString, filename }: { fileString: string; filename: string }) {
  const element = document.createElement('a');
  element.setAttribute('href', fileString);
  element.setAttribute('download', filename);
  // eslint-disable-next-line immutable/no-mutation
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function mapSchemeToAsset(scheme: ValidSchemes) {
  if (scheme === 'mada') return mada;
  if (scheme === 'mastercard') return master;
  if (scheme === 'amex') return amex;
  return visa;
}

export function mapSourceToAsset(source: string) {
  if (source === 'apple_pay') return appple;
  if (source === 'samsung_pay') return samsung;
  if (source === 'google_pay') return google;
  return source;
}

export type DetailsIcons =
  | 'orderId'
  | 'sourceType'
  | 'sourceNumber'
  | 'sourceExpiry'
  | 'currency'
  | 'description'
  | 'airline'
  | 'authorizedAmount'
  | 'capturedAmount'
  | 'voidedAmount'
  | 'refundedAmount'
  | 'createdAt'
  | 'updatedAt'
  | 'id'
  | 'user'
  | 'suitcase'
  | 'emails'
  | 'acquirer'
  | 'merchant';
