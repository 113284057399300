import { useContext } from 'react';
import { PaymentControlFormContext } from '@ctx/paymentControlForm/context';

export function usePaymentControlFormContext() {
  const context = useContext(PaymentControlFormContext);

  if (!context) {
    throw new Error(
      'usePaymentControlsContext must be used within a TwoFactorSetupContextProvider',
    );
  }

  return context;
}
