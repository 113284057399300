import { useApi } from '@hooks/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useState } from 'react';
import { ApiError } from '@root/globalTypes';

type ConfirmTwoFactorData = {
  otpCode: string;
};

export type ConfirmTwoFactorResp = { backupCodes: string[] };

export function isFailure(data: ConfirmTwoFactorResp | ApiError): data is ApiError {
  return (data as ApiError).message !== undefined;
}

export const useConfirmTwoFactor = ({
  handleSuccess,
}: {
  handleSuccess: (data: ConfirmTwoFactorResp) => void;
}) => {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string>('');

  const { mutate, isPending } = useMutation({
    mutationFn: (confirmData: ConfirmTwoFactorData): Promise<ConfirmTwoFactorResp | ApiError> => {
      return query({
        path: 'v1/2fa/confirm',
        data: confirmData,
        opt: {
          method: 'POST',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isFailure(data)) {
        setApiError(data.message);
      } else {
        handleSuccess(data);
      }
    },
    onError: (error) => {
      setApiError(error.message);
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.loggedUser });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
};
