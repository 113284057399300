import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { MerchantUser } from '@root/globalTypes';

export function useGetMerchantUser(merchantId: string, id: string) {
  const { query } = useApi();

  const { data, isPending } = useQuery<MerchantUser>({
    queryKey: [...queryKeys.merchantUsers, merchantId, id],
    queryFn: (): Promise<MerchantUser> =>
      query({
        path: `v1/staff/merchants/${merchantId}/users/${id}`,
      }),
    enabled: Boolean(id),
  });

  return {
    data: data,
    isPending,
  };
}
