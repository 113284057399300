import { createFileRoute, redirect } from '@tanstack/react-router';
import { getUserSlot } from '@utils/apiHelpers';

export const Route = createFileRoute('/u/')({
  beforeLoad: ({ context }) => {
    if (context.isAuthenticated()) {
      throw redirect({
        to: `/u/$localId`,
        params: { localId: getUserSlot() },
        replace: true,
      });
    } else {
      throw redirect({ to: `/${context.lastLoggedUserType()}/login`, replace: true });
    }
  },
  component: () => null,
});
