import { Card, CardContent, CardHeader, CardSeparator, CardTitle } from '@components/card';
import { cn } from '@utils/cn';
import { ReactNode } from 'react';

type DetailsItemProps = {
  data: string | number | ReactNode;
  label: string;
  column?: number;
  icon?: ReactNode;
  alt?: boolean;
  className?: string;
};

export function DetailsCardItem({ data, label, column, icon, className, alt }: DetailsItemProps) {
  return (
    <div
      className={cn('flex gap-2 font-alt md:gap-4', alt && 'grow', className)}
      style={{ gridColumnStart: column }}>
      {icon && (
        <div
          className={cn(
            'flex aspect-square h-10 items-center justify-center rounded-full bg-accent-100 text-accent md:h-12',
            alt && 'bg-filter-400 text-filter-700',
          )}>
          {icon}
        </div>
      )}
      <div className="flex flex-col">
        <span className="text-sm text-neutral-400 md:text-base">{label}</span>
        <strong className="text-wrap text-sm md:text-base">{data}</strong>
      </div>
    </div>
  );
}

export function DetailsCard({
  className,
  children,
  title,
}: {
  className?: string;
  children: ReactNode;
  title?: string;
}) {
  return (
    <Card>
      {title && (
        <>
          <CardHeader>
            <CardTitle>{title}</CardTitle>
          </CardHeader>
          <CardSeparator />
        </>
      )}
      <CardContent className={cn('overflow-auto p-4 md:p-6', className)}>
        <div className="flex grid-flow-col grid-cols-2 flex-col gap-x-12 gap-y-4 lg:grid">
          {children}
        </div>
      </CardContent>
    </Card>
  );
}
