import { Alert } from '@components/alert';
import { useTranslation } from 'react-i18next';

export function MustResetPasswordAlert({ daysToExpire }: { daysToExpire: number }) {
  const { t } = useTranslation('app');

  return (
    <Alert variant="danger" className="mb-2 lg:px-12">
      <div className="flex flex-row items-center justify-between">
        {t('alerts.mustResetPassword', { days: daysToExpire })}
      </div>
    </Alert>
  );
}
