/* eslint-disable camelcase */
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { MutateObject, PaymentControl } from '@root/globalTypes';
import { useEffect } from 'react';

export type PaymentControlApiParams = {
  identifier: string;
  activity: 'allow' | 'deny';
};

export const usePaymentControlForm = ({
  paymentControl,
  requestHandler,
}: {
  paymentControl?: PaymentControl;
  requestHandler: MutateObject<PaymentControl, PaymentControlApiParams>;
}) => {
  const { t } = useTranslation('app');
  const actions = ['allow', 'deny'] as const;

  const schema = z.object({
    identifier: z
      .string()
      .trim()
      .min(1, {
        message: t('form.identifier.required'),
      }),
    activity: z.enum(actions, {
      required_error: t('form.action.required'),
    }),
  });

  const defaultValues: PaymentControlApiParams = {
    identifier: paymentControl?.identifier || '',
    activity: paymentControl?.activity || 'allow',
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, isPending, error } = requestHandler;

  useEffect(() => {
    if (!error || !error.errors) return;

    Object.entries(error.errors).forEach(([key, value]) => {
      const k = key as keyof z.infer<typeof schema>;

      form.setError(k, { type: 'manual', message: value[0] });
    });
  }, [error, form]);

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    isPending: isPending,
    error: error?.message,
  };
};
