import { useApi } from '@hooks/useApi';
import { ApiError } from '@root/globalTypes';
import { useMutation } from '@tanstack/react-query';
import { isFailure } from '@utils/apiHelpers';
import { useState } from 'react';

export function useImportBins({ callback }: { callback: () => void }) {
  const { query } = useApi();
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { mutate, isPending } = useMutation({
    mutationFn: (binsData: FormData): Promise<undefined | ApiError> => {
      return query({
        path: 'v1/staff/bins/import',
        formData: true,
        opt: {
          method: 'POST',
          body: binsData,
        },
      });
    },
    onSettled: (data) => {
      if (isFailure(data)) {
        setApiError(data);
      }
    },
    onSuccess: () => {
      callback();
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
}
