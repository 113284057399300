import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useUpdate } from '@services/staff/users/update';
import { StaffUser } from '@root/globalTypes';

export function useStaffUserUpdateForm({
  user,
  successHandler,
}: {
  user: StaffUser;
  successHandler: () => void;
}) {
  const { t } = useTranslation();

  const schema = z.object({
    name: z
      .string()
      .trim()
      .min(1, {
        message: t('form.name.required'),
      }),
    permissions: z.string().array(),
  });

  const defaultValues = {
    name: user.name,
    permissions: user.permissions,
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, isPending, error } = useUpdate({
    callback: successHandler,
  });

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate({ ...values, id: user.id });
  };

  return {
    form,
    onSubmit,
    isPending,
    error,
  };
}
