import { Button } from '@components/button';
import { UserMinus } from 'lucide-react';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { AcquirerUser } from '@root/globalTypes';
import { UseMutateFunction } from '@tanstack/react-query';

type DisableUserBtnProps = {
  user: AcquirerUser;
  onDisable: UseMutateFunction<AcquirerUser, Error, string, unknown>;
};

export function DisableAcquirerUserButton({ user, onDisable }: DisableUserBtnProps) {
  const { verifyUserPermission } = useCheckUserPermissions();

  if (!verifyUserPermission(`acquirer_users_disable`) || !user.active) {
    return null;
  }

  return (
    <Button
      size="sm"
      className="w-14"
      variant="destructive-outline"
      type="button"
      onClick={() => onDisable(user.id)}>
      <UserMinus className="h-5" />
    </Button>
  );
}
