/* eslint-disable immutable/no-mutation */
import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@utils/cn';
import { FieldError } from 'react-hook-form';

const labelVariants = cva(
  'font-medium text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants> & { required?: boolean; error?: FieldError }
>(({ className, error, required, ...props }, ref) => {
  return (
    <LabelPrimitive.Root
      ref={ref}
      className={cn(
        labelVariants(),
        className,
        required && 'after:ml-0.5 after:content-["*"]',
        error && 'after:text-red-500',
      )}
      {...props}
    />
  );
});
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
