import { Button } from '@components/button';
import { Card, CardHeader, CardContent } from '@components/card';
import { staffLinks, acquirerLinks, merchantLinks } from '@components/navigation/const';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useAppContext } from '@hooks/useAppContext';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { Link, useRouter } from '@tanstack/react-router';
import { Bug } from 'lucide-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ErrorFallback = ({ error, reset }: { error: Error; reset: () => void }) => {
  const { t } = useTranslation('app', { keyPrefix: 'errorFallback' });
  const router = useRouter();
  const { verifyUserPermission } = useCheckUserPermissions();
  const { userType } = useAppContext();

  const mapUserTypeLinks = () => {
    if (userType === 'staff') return staffLinks;
    if (userType === 'acquirer') return acquirerLinks;
    if (userType === 'merchant') return merchantLinks;
    return [];
  };

  const navUrl =
    mapUserTypeLinks().filter(({ permission }) =>
      permission ? verifyUserPermission(permission) : true,
    )[0]?.to || '/';
  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  useEffect(() => {
    // Reset the query error boundary
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  const isModuleError = error.message.includes('Failed to fetch dynamically imported module');

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center p-4">
      <Card className="border-red-500 bg-red-500/10">
        <CardHeader>
          <h2 className="flex items-center gap-1 text-red-500">
            <Bug size={16} /> {t('title')}
          </h2>
        </CardHeader>
        <CardContent>
          <div className="mx-auto flex justify-center gap-2">
            {isModuleError ? (
              <Button
                variant="ghost"
                onClick={() => {
                  location.reload();
                }}>
                {t('reload')}
              </Button>
            ) : (
              <>
                <Button
                  variant="outline-dark"
                  onClick={() => {
                    router.invalidate().catch((err) => {
                      console.error(err);
                    });
                    reset();
                  }}>
                  {t('retry')}
                </Button>
                <Link className="ms-6" to={navUrl}>
                  <Button variant="primary">{t('action')}</Button>
                </Link>
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
