import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserData, useCreate } from '@services/staff/users/create';
import { useTranslation } from 'react-i18next';

export function useCreateStaffUserForm({ successHandler }: { successHandler: () => void }) {
  const { t } = useTranslation('app');

  const schema = z.object({
    name: z
      .string()
      .trim()
      .min(1, {
        message: t('form.name.required'),
      }),
    email: z
      .string()
      .trim()
      .min(1, {
        message: t('form.email.required'),
      }),
    permissions: z.string().array(),
  });

  const defaultValues: UserData = {
    name: '',
    email: '',
    permissions: [],
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, error, isPending } = useCreate({
    callback: successHandler,
  });
  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    isPending,
    error,
  };
}
