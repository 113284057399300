import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { AcquirerLink } from '@root/globalTypes';

export function useListAcquirerLinks() {
  const { isLogged, isMerchant } = useCheckUserPermissions();
  const { query } = useApi();
  const url = `v1/acquirer/links${window.location.search}`;

  const { data, isPending } = useQuery<AcquirerLink[]>({
    queryKey: [...queryKeys.acquirerLinks, window.location.search],
    queryFn: (): Promise<AcquirerLink[]> =>
      query({
        path: url,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged && !isMerchant,
  });

  return {
    data: data || [],
    isPending,
  };
}
