import { useApi } from '@hooks/useApi';
import { ApiError } from '@root/globalTypes';
import { AcquirerUser } from '@root/globalTypes';
import { useMutation } from '@tanstack/react-query';

export type UserResponse = AcquirerUser | ApiError;

type RequestResetPasswordParams = {
  id: string;
  callback: () => void;
};

export function useAcquirerUserResetPassword({ id, callback }: RequestResetPasswordParams) {
  const { query } = useApi();
  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<UserResponse> => {
      return query({
        path: `v1/acquirer/users/${id}/password`,
        opt: {
          method: 'POST',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      callback();
    },
  });

  return {
    mutate,
    isPending,
  };
}
