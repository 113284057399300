import { Card, CardContent, CardHeader, CardSeparator, CardTitle } from '@components/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/form/form';
import { useTranslation } from 'react-i18next';
import { useUpdatePassword } from '@hooks/forms/passwords/update';
import { Input } from '@components/form/input';
import { Button } from '@components/button';
import { toast } from 'sonner';
import { UserType } from '@root/globalTypes';
import { useState } from 'react';
import { Eye } from 'lucide-react';

export function UpdatePasswordSection({ userType }: { userType: UserType }) {
  const { t } = useTranslation('app');
  const [inputType, setInputType] = useState({
    password: 'password',
    confirmPassword: 'password',
  });

  const toggleInputType = (field: 'password' | 'confirmPassword') => {
    setInputType((ps) =>
      ps[field] == 'password' ? { ...ps, [field]: 'text' } : { ...ps, [field]: 'password' },
    );
  };

  const { form, onSubmit, isPending } = useUpdatePassword({
    successHandler: () => {
      form.reset();
      toast.success(t('toast.password.updateSuccess'));
    },
    userType,
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('pages.settings.updatePassword.title')}</CardTitle>
      </CardHeader>
      <CardSeparator className="mb-6" />
      <CardContent>
        <Form {...form}>
          <form
            className="flex flex-col items-end justify-between gap-8 md:flex-row"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-8 lg:w-[50%]">
              <div className="flex flex-col justify-between gap-4 md:flex-row">
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem className="md:w-[calc(50%-1rem)]">
                      <FormLabel>{t('form.password.label')}</FormLabel>
                      <FormControl>
                        <Input type={inputType.password} {...field}>
                          <button
                            type="button"
                            onClick={() => toggleInputType('password')}
                            className="text-black/30 transition-colors hover:text-black">
                            <Eye className="w-4" />
                          </button>
                        </Input>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="confirmPassword"
                  render={({ field }) => (
                    <FormItem className="md:w-[calc(50%-1rem)]">
                      <FormLabel>{t('form.passwordConfirmation.label')}</FormLabel>
                      <FormControl>
                        <Input type={inputType.confirmPassword} {...field}>
                          <button
                            type="button"
                            onClick={() => toggleInputType('confirmPassword')}
                            className="text-black/30 transition-colors hover:text-black">
                            <Eye className="w-4" />
                          </button>
                        </Input>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  className="w-full md:hidden"
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={isPending}
                  loading={isPending}>
                  {t('form.submit.updatePassword')}
                </Button>
              </div>
              <div className="rounded bg-[#DADDDD29] p-4 text-sm leading-normal">
                <h3 className="mb-2 text-gray-400">
                  {t('pages.settings.updatePassword.requirements.info')}
                </h3>
                <ul>
                  <li>{t('pages.settings.updatePassword.requirements.rule1')}</li>
                  <li>{t('pages.settings.updatePassword.requirements.rule2')}</li>
                </ul>
              </div>
            </div>
            <Button
              className="mt-2 hidden self-start md:flex"
              type="submit"
              variant="primary"
              size="lg"
              disabled={isPending}
              loading={isPending}>
              {t('form.submit.updatePassword')}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
