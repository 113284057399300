import { Loader } from '@components/loader';
import { ApiKeys } from '@pages/apiKeys';
import { ApiKeyType } from '@root/globalTypes';
import { useDisableApiKey } from '@services/staff/acquirer/apiKeys/disable';
import { useListApiKeys } from '@services/staff/acquirer/apiKeys/list';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { Row } from '@tanstack/react-table';

const AcquirerApiKeys = () => {
  const navigate = useNavigate();
  const { localId, acquirerId } = useParams({
    from: '/u/$localId/_staff/staff/acquirers/$acquirerId/api-keys',
  });
  const { data, isPending } = useListApiKeys(acquirerId);
  const { mutate: disableApiKey, isPending: disabling } = useDisableApiKey(acquirerId);

  function handleSelect(row: Row<ApiKeyType>) {
    const id: string = row.original.id;

    navigate({
      to: '/u/$localId/staff/acquirers/$acquirerId/api-keys/$id',
      params: { id, localId, acquirerId },
    }).catch((err) => console.error(err));
  }

  function newRedirect() {
    navigate({
      to: '/u/$localId/staff/acquirers/$acquirerId/api-keys/new',
      params: { localId, acquirerId },
    }).catch((err) => console.error(err));
  }

  if (isPending) return <Loader />;

  return (
    <ApiKeys
      data={data}
      onDisable={disableApiKey}
      disabling={disabling}
      newRedirect={newRedirect}
      onRowSelect={handleSelect}
    />
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/acquirers/$acquirerId/api-keys')({
  component: AcquirerApiKeys,
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['api_keys_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
});
