import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { Acquirer } from '@root/globalTypes';

type GetUsersReturn = {
  data: Acquirer[];
  isPending: boolean;
};

export function useList(): GetUsersReturn {
  const { query } = useApi();

  const { data, isPending } = useQuery<Acquirer[]>({
    queryKey: [...queryKeys.staffAcquirers, window.location.search],
    queryFn: (): Promise<Acquirer[]> =>
      query({
        path: `v1/staff/acquirers${window.location.search}`,
      }),
  });

  return {
    data: data || [],
    isPending,
  };
}
