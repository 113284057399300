import { PaymentControl } from '@root/globalTypes';
import { ColumnDef, Row } from '@tanstack/react-table';
import { DataTable } from '@components/dataTable';
import { cn } from '@utils/cn';
import { useTranslation } from 'react-i18next';
import { useTableSorting } from '@hooks/useTableSorting';
import { TableDateCell } from '@components/table/dateCell';

type PaymentControlsTableProps = {
  data: PaymentControl[];
  onRowSelect: (row: Row<PaymentControl>) => void;
  visibleColumns?: string[];
};

export function usePaymentControlsTable({
  data,
  onRowSelect,
  visibleColumns,
}: PaymentControlsTableProps) {
  const { t } = useTranslation('app');
  const { sorting, updateSorting } = useTableSorting();

  const columns: ColumnDef<PaymentControl>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
      enableSorting: false,
    },
    {
      id: 'identifier',
      accessorKey: 'identifier',
      header: t('table.identifier'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'identifier_type',
      accessorKey: 'identifierType',
      header: t('table.identifierType'),
      enableSorting: false,
      cell: ({ row }) => {
        return <>{t(`pages.paymentControls.${row.original.identifierType}`)}</>;
      },
    },
    {
      id: 'activity',
      accessorKey: 'activity',
      header: t('table.action'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => {
        const { activity } = row.original;
        return (
          <span
            className={cn(
              'font-semibold',
              activity === 'allow' && 'text-green-500',
              activity === 'deny' && 'text-red-400',
            )}>
            {t(activity)}
          </span>
        );
      },
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
    {
      id: 'updated_at',
      accessorKey: 'updatedAt',
      header: t('table.updatedAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.updatedAt} />,
    },
  ];

  return {
    columns,
    Table: () => (
      <DataTable
        columns={
          visibleColumns
            ? columns.filter(({ id }) =>
                typeof id === 'string' ? visibleColumns.includes(id) : false,
              )
            : columns
        }
        selectionCallback={onRowSelect}
        data={data}
        sorting={sorting}
        onSortChange={updateSorting}
      />
    ),
  };
}
