import { createFileRoute, redirect } from '@tanstack/react-router';
import { paymentControlsParams } from '@utils/consts';

export const Route = createFileRoute('/u/$localId/_acquirer/acquirer/payment-controls/')({
  beforeLoad: ({ params }) => {
    if (Object.keys(params).length === 1) {
      throw redirect({
        to: `/u/$localId/acquirer/payment-controls/$type`,
        params: { type: paymentControlsParams[0], localId: params.localId },
      });
    }
  },
  component: () => null,
});
