import { useApi } from '@hooks/useApi';
import { ApiError, UserType } from '@root/globalTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

export type PasswordData = {
  password: string;
};

type PasswordUpdateResponse = {
  message: string;
};

export type UpdatePasswordParams = {
  userType: UserType;
  handleSuccess: () => void;
  handleFailure: (data: ApiError) => void;
};

export function isFailure(data: PasswordUpdateResponse | ApiError): data is ApiError {
  return (data as ApiError)?.errors !== undefined;
}

export function useUpdateUserPassword({
  userType,
  handleSuccess,
  handleFailure,
}: UpdatePasswordParams) {
  const { query } = useApi();
  const queryClient = useQueryClient();

  const url = `v1/${userType}/users/password`;
  const { mutate, isPending } = useMutation({
    mutationFn: (data: PasswordData): Promise<PasswordUpdateResponse | ApiError> => {
      return query({
        path: url,
        data,
        opt: {
          method: 'PUT',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isFailure(data)) {
        handleFailure(data);
      } else {
        handleSuccess();
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.loggedUser });
    },
  });

  return {
    mutate,
    isPending,
  };
}
