import { cva } from 'class-variance-authority';

export const triggerVariants = cva('flex items-center justify-center h-10 w-full w-10', {
  variants: {
    variant: {
      default: 'order-2 ml-[-0.6em]',
      accent: 'bg-accent-100 text-accent rounded-md bg-accent-100',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});
