import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { AuditLog } from '@services/global/auditLogs/types';
import { Fingerprint, SquareMousePointer } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function AuditLogSubject({ auditLog }: { auditLog: AuditLog }) {
  const { t } = useTranslation('app');
  if (!auditLog.subjectId) return null;

  return (
    <DetailsCard>
      <DetailsCardItem
        alt
        icon={<Fingerprint />}
        label={t('table.id')}
        data={auditLog.subjectId}
        column={1}
      />
      <DetailsCardItem
        alt
        icon={<SquareMousePointer />}
        label={t('table.type')}
        data={auditLog.subjectType}
        column={2}
      />
    </DetailsCard>
  );
}
