import { useApi } from '@hooks/useApi';
import { AuditLog } from './types';
import { queryKeys } from '@utils/consts';
import { useQuery } from '@tanstack/react-query';

export function useGetAuditLog({ id }: { id: string }) {
  const { query } = useApi();

  const { data, isPending } = useQuery<AuditLog>({
    queryKey: [...queryKeys.auditLogs, id],
    queryFn: (): Promise<AuditLog> => query({ path: `v1/audit_logs/${id}` }),
  });

  return {
    data: data,
    isPending,
  };
}
