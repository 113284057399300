import { AppContext, FiltersCache, RowsPerPageCache, SessionData, SortingCache } from './appCtx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { calcTimeOffset, localStore } from '@utils/localStore';
import {
  AcquirerUser,
  AuthRespSuccess,
  BCMessage,
  MerchantUser,
  StaffUser,
  UserType,
} from '@root/globalTypes';
import { getUserSlot } from '@utils/apiHelpers';
import { transformUid } from '@utils/textHelpers';
import { isPast } from 'date-fns';
import { FiltersSearch } from './filters/context';
import { SortingState } from '@tanstack/react-table';

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const bc = useRef<BroadcastChannel | null>(null);
  const localIdKey = `app-cache-${getUserSlot()}`;
  const getInitialData = () => {
    const storedData = localStore.getItem(localIdKey);
    if (!storedData || typeof storedData !== 'string') return {};
    const parsedData = JSON.parse(storedData) as SessionData;
    return parsedData;
  };

  const [data, setData] = useState<SessionData>(getInitialData());
  const [filtersCache, setFiltersCache] = useState<FiltersCache>(
    (localStore.getItemValue(data.uid, 'filters') as FiltersCache) || {},
  );
  const [sortingCache, setSortingCache] = useState<SortingCache>(
    (localStore.getItemValue(data.uid, 'sorting') as SortingCache) || {},
  );
  const [rowsPerPageCache, setRowsPerPageCache] = useState<RowsPerPageCache>(
    (localStore.getItemValue(data.uid, 'rowsPerPage') as RowsPerPageCache) || {},
  );

  const updateFiltersCache = useCallback(
    (key: string, filters: FiltersSearch) => {
      if (!data.uid) return;
      setFiltersCache((pv) => ({ ...pv, [key]: filters }));
      localStore.updateItem(
        data.uid,
        {
          filters: {
            ...filtersCache,
            [key]: filters,
          },
        },
        1000 * 60 * 60 * 24 * 30,
      );
    },
    [data.uid, filtersCache],
  );

  const updateSortingCache = useCallback(
    (key: string, sort: SortingState) => {
      if (!data.uid) return;
      setSortingCache((pv) => ({ ...pv, [key]: sort }));
      localStore.updateItem(
        data.uid,
        {
          sorting: {
            ...sortingCache,
            [key]: sort,
          },
        },
        1000 * 60 * 60 * 24 * 30,
      );
    },
    [data.uid, sortingCache],
  );

  const updateRowsPerPageCache = useCallback(
    (key: string, rowsPerPage: string) => {
      if (!data.uid) return;
      setRowsPerPageCache((pv) => ({ ...pv, [key]: rowsPerPage }));
      localStore.updateItem(
        data.uid,
        {
          rowsPerPageCache: {
            ...rowsPerPageCache,
            [key]: rowsPerPage,
          },
        },
        1000 * 60 * 60 * 24 * 30,
      );
    },
    [data.uid, rowsPerPageCache],
  );

  const clearData = () => {
    setData({});
    localStore.removeItem(localIdKey);
  };

  const offset = 20 * 60 * 1000;
  const sessionEndDate = () => new Date(new Date().getTime() + offset).toUTCString();

  const handleLogin = ({
    loginData,
    userType,
  }: {
    loginData: AuthRespSuccess<AcquirerUser | MerchantUser | StaffUser>;
    userType: UserType;
  }) => {
    const isMerchant = userType === 'merchant';
    const newData = {
      sessionId: loginData.user.id,
      user: { ...loginData.user },
      token: loginData.token,
      expiresAt: loginData.expiresAt,
      twoFactorRequired: loginData.twoFactorRequired,
      isMerchant,
      userType,
      frontSession: sessionEndDate(),
    };
    setData((ps) => ({
      ...ps,
      ...newData,
    }));
    localStore.setItem(
      localIdKey,
      JSON.stringify({
        sessionId: loginData.user.id,
        uid: transformUid(loginData.user.id),
        token: loginData.token,
        expiresAt: loginData.expiresAt,
        twoFactorRequired: loginData.twoFactorRequired,
        user: { ...loginData.user },
        isMerchant,
        userType,
        frontSession: sessionEndDate(),
      }),
      calcTimeOffset(sessionEndDate()),
    );
    localStore.setItem('lastLoggedUserType', userType);
  };

  const isAuthenticated = () => {
    return !!getInitialData()?.token;
  };

  const lastLoggedUserType = (): UserType => {
    return (localStore.getItem('lastLoggedUserType') as UserType) || 'merchant';
  };

  const isValidUserType = (type: UserType): boolean => {
    const storedType = getInitialData()?.userType;
    if (!storedType) return false;
    return storedType === type;
  };

  const userHasPermissions = (permissions: string[]) => {
    if (!data?.user?.permissions) return false;

    return permissions.every((perm) => data?.user?.permissions.includes(perm));
  };

  const updateSessionTimer = () => {
    if (location.pathname.endsWith('/login')) return;
    const { expiresAt, frontSession } = data;
    if (!expiresAt || !frontSession || typeof frontSession !== 'string') return;
    const sessionsOffset =
      calcTimeOffset(expiresAt) - calcTimeOffset(frontSession) >= 20 * 60 * 1000;
    setData((ps) => ({
      ...ps,
      frontSession: sessionsOffset ? sessionEndDate() : expiresAt,
    }));

    localStore.setItem(
      localIdKey,
      JSON.stringify({
        ...data,
        frontSession: sessionEndDate(),
      }),
      calcTimeOffset(sessionsOffset ? sessionEndDate() : expiresAt),
    );
  };

  const sendBcPost = (msg: BCMessage) => {
    if (!bc.current) return;
    bc.current.postMessage(msg);
  };

  useEffect(() => {
    // eslint-disable-next-line immutable/no-mutation
    bc.current = new BroadcastChannel('internal_notification');
    bc.current.addEventListener('message', (msg) => {
      const { type, message, sender } = msg.data as BCMessage;
      const current = getUserSlot(data.user?.id);
      if (sender === current) return;
      if (type === 'session') location.reload();
      // eslint-disable-next-line no-console
      if (type === 'info' && message) console.info(message);
    });
  }, [data.user?.id]);

  const partialAccess =
    (data?.twoFactorRequired && !data?.user?.twoFactorEnabled) ||
    isPast(new Date(data?.user?.mustResetPasswordAt as string));

  return (
    <AppContext.Provider
      value={{
        ...data,
        setData,
        clearData,
        handleLogin,
        isAuthenticated,
        isValidUserType,
        userHasPermissions,
        updateSessionTimer,
        bc: bc.current,
        sendBcPost,
        partialAccess,
        lastLoggedUserType,
        filtersCache,
        updateFiltersCache,
        sortingCache,
        updateSortingCache,
        rowsPerPageCache,
        updateRowsPerPageCache,
      }}>
      {children}
    </AppContext.Provider>
  );
};
