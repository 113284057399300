/* eslint-disable immutable/no-mutation */
import { DEFAULT_ROWS_PER_PAGE } from '@utils/consts';
import { removeEmptyKeys } from '@ctx/filters/context';
import { useNavigation } from '@ctx/navigation/context';
import { useAppContext } from '@hooks/useAppContext';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { DEFAULT_SORTING, parseSortingToSearchObject } from '@hooks/useTableSorting';
import { useLinkProps, useMatches, useNavigate, useParams } from '@tanstack/react-router';
import { getCacheKeyFromRoute } from '@utils/apiHelpers';
import { cn } from '@utils/cn';
import { useMemo } from 'react';

export interface LinkProps {
  icon: JSX.Element;
  to: string;
  label: string;
  permission?: string;
  skipSearch?: boolean;
}

export interface SideNavLinkProps {
  link: LinkProps;
  isOpen: boolean;
  disabled?: boolean;
}

export const SideNavLink = ({ isOpen, link, disabled = false }: SideNavLinkProps) => {
  const { icon, to, label, skipSearch } = link;
  const { filtersCache, sortingCache, rowsPerPageCache } = useAppContext();
  const navigate = useNavigate();
  const { localId } = useParams({ strict: false });
  const matches = useMatches();
  const active =
    matches.findIndex((el) =>
      el.pathname.includes(localId ? to.replace('$localId', localId) : to),
    ) >= 0;
  const { setNavOpen } = useNavigation();
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const cacheKey = useMemo(() => getCacheKeyFromRoute(to), [to]);

  const searchValue = useMemo(() => {
    const filters = filtersCache[cacheKey] || {};
    const sorting = sortingCache[cacheKey];
    const rows = rowsPerPageCache[cacheKey] || DEFAULT_ROWS_PER_PAGE;

    const search = removeEmptyKeys(filters) as Record<string, unknown>;
    if (!sorting) {
      search.sort = parseSortingToSearchObject(DEFAULT_SORTING);
    } else if (sorting.length) {
      search.sort = parseSortingToSearchObject(sorting[0]);
    }
    search.items = rows;

    return search;
  }, [cacheKey, filtersCache, sortingCache, rowsPerPageCache]);

  const { href } = useLinkProps({
    to: to,
    search: skipSearch ? '' : searchValue,
  });

  if (disabled) {
    return (
      <span
        className={cn(
          'flex w-full items-center gap-4 px-4 py-3 text-neutral-500 transition-colors hover:bg-primary hover:text-white lg:py-2',
          active && 'bg-primary text-white',
          isOpen ? 'rounded-full' : 'rounded',
          disabled && 'cursor-not-allowed opacity-50 hover:bg-white hover:text-neutral-500',
        )}>
        {icon}
        {isOpen && label}
      </span>
    );
  }

  return (
    <a
      href={disabled ? '' : href}
      onClick={(ev) => {
        ev.preventDefault();
        if (disabled) return;
        navigate({
          to: to,
          search: skipSearch ? '' : searchValue,
        }).catch((err) => console.error(err));
        if (!isDesktop) setNavOpen(false);
      }}
      className={cn(
        'flex w-full items-center gap-4 px-4 py-3 text-neutral-500 transition-colors hover:bg-primary hover:text-white lg:py-2',
        active && 'bg-primary text-white',
        isOpen ? 'rounded-full' : 'rounded',
        disabled && 'cursor-not-allowed opacity-50 hover:bg-white hover:text-neutral-500',
      )}>
      {icon}
      {isOpen && label}
    </a>
  );
};
