import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ApiKeyData } from '@services/types';
import { useTranslation } from 'react-i18next';
import { ApiIpWhitelistItem, ApiKeyType, MutateObject } from '@root/globalTypes';
import { useEffect } from 'react';

export const useApiKeyForm = ({
  apiKey,
  requestHandler,
}: {
  apiKey?: ApiKeyType;
  requestHandler: MutateObject<ApiKeyType, ApiKeyData>;
}) => {
  const { t } = useTranslation('app');

  const schema = z.object({
    description: z.string().trim().optional(),
    permissions: z.custom<string[]>((v) => {
      if (!Array.isArray(v)) return false;
      if (v.length === 0) return false;
      return true;
    }, t('form.permissions.required')),
    ipWhitelist: z.custom<ApiIpWhitelistItem[]>((v) => {
      if (!Array.isArray(v)) return false;
      return true;
    }, t('form.whitelist.required')),
  });

  const defaultValues: ApiKeyData = {
    description: apiKey?.description || '',
    permissions: apiKey?.permissions || [],
    ipWhitelist: apiKey?.ipWhitelist || [],
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, isPending, error } = requestHandler;

  useEffect(() => {
    if (!error || !error.errors) return;

    Object.entries(error.errors).forEach(([key, value]) => {
      const k = key as keyof z.infer<typeof schema>;

      form.setError(k, { type: 'manual', message: value[0] });
    });
  }, [form, error]);

  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate({
      description: values.description || '',
      permissions: values.permissions || [],
      ipWhitelist: values.ipWhitelist || [],
    });
  };

  return {
    form,
    onSubmit,
    isPending,
    error: error?.message,
  };
};
