import { Card, CardContent } from '@components/card';
import { BaseLayout } from '@layouts/base';
import { OrderCapture } from '@components/forms/global/orders/capture';
import { OrderExtend } from '@components/forms/global/orders/extend';
import { OrderRefund } from '@components/forms/global/orders/refund';
import { OrderVoid } from '@components/forms/global/orders/void';
import { OrderDetails } from '@root/globalTypes';
import { Outlet, useMatches } from '@tanstack/react-router';
import { mapSchemeToAsset } from '@utils/fileHelpers';
import { formatAmount } from '@utils/textHelpers';
import { useTranslation } from 'react-i18next';
import { TabLink, TabsContainer } from '@components/tabLink';
import { DetailsCard, DetailsCardItem } from '@components/detailsCard';
import { formatDate } from '@utils/dateHelpers';
import {
  Fingerprint,
  Banknote,
  FileText,
  ShieldCheck,
  Focus,
  FileX2,
  RotateCcw,
  CalendarPlus,
  CalendarCog,
} from 'lucide-react';
import { MATCH_LEVEL } from '@utils/consts';
import { useMediaQuery } from '@hooks/useMediaQuery';

export const Order = ({ order }: { order: OrderDetails }) => {
  const { t } = useTranslation('app');
  const matches = useMatches();
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <BaseLayout
      isChild
      title={
        <span className="ml-6 flex items-center gap-2 font-semibold">
          {order.source.scheme && (
            <img
              src={mapSchemeToAsset(order.source.scheme)}
              alt={order.source.scheme}
              style={{ height: '1em' }}
            />
          )}
          {order.currency} {order.amount && formatAmount(order.amount, order.currency)}
        </span>
      }
      actions={
        isMobile
          ? []
          : [
              <OrderExtend key="extend" order={order} />,
              <OrderCapture key="capture" order={order} />,
              <OrderVoid key="void" order={order} />,
              <OrderRefund key="refund" order={order} />,
            ]
      }>
      {isMobile && (
        <div className="wrap mx-0 flex justify-between gap-2 sm:mx-10">
          <OrderExtend key="extend" order={order} />
          <OrderCapture key="capture" order={order} />
          <OrderVoid key="void" order={order} />
          <OrderRefund key="refund" order={order} />
        </div>
      )}
      <DetailsCard>
        <DetailsCardItem
          icon={<Fingerprint size={26} />}
          data={order.id}
          label={t('table.orderId')}
          column={1}
        />
        <DetailsCardItem
          icon={<Banknote size={26} />}
          data={order.currency}
          label={t('table.currency')}
          column={1}
        />
        <DetailsCardItem
          icon={<FileText size={26} />}
          data={order.description}
          label={t('table.description')}
          column={1}
        />
        {order.airline && (
          <DetailsCardItem
            icon={<FileText size={26} />}
            data={
              <>
                <span className="font-normal text-accent">{t('table.reference')}: </span>
                {order?.airline.reference}
              </>
            }
            label={t('table.airline')}
            column={1}
          />
        )}
        <DetailsCardItem
          icon={<ShieldCheck size={26} />}
          data={formatAmount(order.amount, order.currency)}
          label={t('table.authorizedAmount')}
          column={2}
        />
        <DetailsCardItem
          icon={<Focus size={26} />}
          data={formatAmount(order.capturedAmount, order.currency)}
          label={t('table.capturedAmount')}
          column={2}
        />
        <DetailsCardItem
          icon={<FileX2 size={26} />}
          data={formatAmount(order.voidedAmount, order.currency)}
          label={t('table.voidedAmount')}
          column={2}
        />
        <DetailsCardItem
          icon={<RotateCcw size={26} />}
          data={formatAmount(order.refundedAmount, order.currency)}
          label={t('table.refundedAmount')}
          column={2}
        />
        <DetailsCardItem
          icon={<CalendarPlus size={26} />}
          data={formatDate(new Date(order.createdAt))}
          label={t('table.createdAt')}
          column={1}
        />
        <DetailsCardItem
          icon={<CalendarCog size={26} />}
          data={formatDate(new Date(order.updatedAt))}
          label={t('table.updatedAt')}
          column={2}
        />
      </DetailsCard>
      <Card>
        <CardContent className="py-3 pb-10">
          <TabsContainer>
            <TabLink
              label={t('pages.order.transactions')}
              path={`/${matches[MATCH_LEVEL].pathname}/transactions`}
            />
            {order.merchant && (
              <TabLink
                label={t('pages.order.merchant')}
                path={`/${matches[MATCH_LEVEL].pathname}/merchant`}
              />
            )}
            {order.source && (
              <TabLink
                label={t('pages.order.source')}
                path={`/${matches[MATCH_LEVEL].pathname}/source`}
              />
            )}
            {order.agreement && (
              <TabLink
                label={t('pages.order.agreement')}
                path={`/${matches[MATCH_LEVEL].pathname}/agreement`}
              />
            )}
            {order.subMerchant && (
              <TabLink
                label={t('pages.order.subMerchant')}
                path={`/${matches[MATCH_LEVEL].pathname}/subMerchant`}
              />
            )}
            <TabLink
              label={t('pages.order.apiResponse')}
              path={`/${matches[MATCH_LEVEL].pathname}/apiResponse`}
            />
          </TabsContainer>
          <Outlet />
        </CardContent>
      </Card>
    </BaseLayout>
  );
};
