import { DataTable } from '@components/dataTable';
import { DetailsCardItem } from '@components/detailsCard';
import { RawPreview } from '@components/rawPreview';
import { AuditLog } from '@services/global/auditLogs/types';
import { ColumnDef } from '@tanstack/react-table';
import { humanizeText } from '@utils/textHelpers';
import { Braces, CloudUpload, Radar, SquarePen, Waypoints } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type TransformedSubject = {
  key: string;
  oldValue: null | string | number | object;
  newValue: null | string | number | object;
};

export function AuditLogMetadata({ auditLog }: { auditLog: AuditLog }) {
  const { t } = useTranslation('app', { keyPrefix: 'metadata' });
  if (!auditLog.metadata) return null;

  const { ipInfo, userAgent, requestPath, requestPayload, subjectChanges } = auditLog.metadata;

  const subjectCols: ColumnDef<TransformedSubject>[] = [
    {
      id: 'key',
      accessorKey: 'key',
      header: t('key'),
    },
    {
      id: 'oldValue',
      accessorKey: 'oldValue',
      header: t('oldValue'),
      cell: ({ row }) => {
        const { oldValue } = row.original;
        if (!oldValue) return '';
        if (typeof oldValue === 'string' || typeof oldValue === 'number')
          return <div className="text-red-700 line-through">{oldValue}</div>;
        return (
          <div className="line-through">
            <RawPreview data={oldValue} />
          </div>
        );
      },
    },
    {
      id: 'newValue',
      accessorKey: 'newValue',
      header: t('newValue'),
      cell: ({ row }) => {
        const { newValue } = row.original;
        if (!newValue) return '';
        if (typeof newValue === 'string' || typeof newValue === 'number') return newValue;
        return <RawPreview data={newValue} />;
      },
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      {ipInfo && (
        <DetailsCardItem
          alt
          icon={<Radar />}
          label={t('ip')}
          data={
            <ul>
              {Object.entries(ipInfo).map(([k, v]) => (
                <li key={k}>
                  <span className="font-thin text-neutral-400">
                    {k === 'ip' ? 'IP' : humanizeText(k)}:{' '}
                  </span>
                  {v}
                </li>
              ))}
            </ul>
          }
        />
      )}
      <DetailsCardItem alt icon={<Braces />} label={t('agent')} data={userAgent} />
      <DetailsCardItem alt icon={<Waypoints />} label={t('path')} data={requestPath} />
      {requestPayload && (
        <DetailsCardItem
          alt
          icon={<CloudUpload />}
          label={t('payload')}
          data={<RawPreview data={requestPayload} />}
        />
      )}
      {subjectChanges && (
        <DetailsCardItem
          alt
          icon={<SquarePen />}
          label={t('changes')}
          data={
            <DataTable
              columns={subjectCols}
              data={Object.entries(subjectChanges).map(([k, v]) => {
                if (typeof v === 'string') {
                  v = [v, v];
                }
                return {
                  key: k,
                  oldValue: v[0],
                  newValue: v[1],
                };
              })}
            />
          }
        />
      )}
    </div>
  );
}
