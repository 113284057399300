import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useApi } from '@hooks/useApi';
import { MerchantLink } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

export function useMerchantLinkList() {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();

  const { data, isPending } = useQuery<MerchantLink[]>({
    queryKey: [...queryKeys.merchantLinks, window.location.search],
    queryFn: (): Promise<MerchantLink[]> =>
      query({
        path: `v1/merchant/links${window.location.search}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged,
  });

  return {
    data: data || [],
    isPending,
  };
}
