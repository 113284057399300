import { useApi } from '@hooks/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { queryKeys } from '@utils/consts';
import { StaffUser } from '@root/globalTypes';
import { isError } from '@utils/apiHelpers';
import { useAppContext } from '@hooks/useAppContext';

export type UserData = {
  id: string;
  name?: string;
  permissions?: string[];
};

type UpdateUserParams = {
  callback: (data: StaffUser) => void;
};

export function useUpdate({ callback }: UpdateUserParams) {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const { user } = useAppContext();
  const [apiError, setApiError] = useState<string>('');

  const { mutate, isPending } = useMutation({
    mutationFn: ({ id, ...data }: UserData): Promise<StaffUser> => {
      return query({
        path: `v1/staff/users/${id}`,
        data: data,
        opt: {
          method: 'PUT',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isError(data)) {
        setApiError(data.message);
      } else {
        callback(data);
      }
    },
    onSuccess: async (data) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.staffUsers });
      if (user?.id === data.id) {
        await queryClient.refetchQueries({ queryKey: queryKeys.loggedUser });
      }
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
}
