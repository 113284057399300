import { VALID_SCHEMES } from '@utils/consts';
import { useTranslation } from 'react-i18next';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@components/form/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@components/form/select';
import { Input } from '@components/form/input';
import { Checkbox } from '@components/form/checkbox';
import { humanizeText } from '@utils/textHelpers';
import { Button } from '@components/button';
import { useAcquirerLinkForm } from '@hooks/forms/acquirerLinks';

export function AcquirerLinkForm({ formObj }: { formObj: ReturnType<typeof useAcquirerLinkForm> }) {
  const { t } = useTranslation('app');
  const { form, error, onSubmit, isPending } = formObj;

  const LINK_TYPES = ['spg', 'test'];

  return (
    <Form {...form}>
      <form
        id="userForm"
        className="flex flex-col gap-4"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="linkType"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('form.linkType.label')}</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t('form.linkType.placeholder')} />
                  </SelectTrigger>
                </FormControl>
                <FormMessage />
                <SelectContent>
                  {LINK_TYPES.map((link) => (
                    <SelectItem key={link} value={link}>
                      {link}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="priority"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('form.priority.label')}</FormLabel>
              <FormControl>
                <Input {...field} type="number" min={0} max={40} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="bin"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t('form.bin.label')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormLabel className="static">{t('form.schemes.label')}</FormLabel>
        <div className="flex flex-col gap-4 md:flex-row md:gap-6">
          {VALID_SCHEMES.map((scheme) => (
            <FormField
              key={scheme}
              control={form.control}
              name="schemes"
              render={({ field }) => {
                return (
                  <FormItem className="flex space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(scheme)}
                        onCheckedChange={(checked) => {
                          if (!field.value) return;
                          if (checked) form.setValue('schemes', [...field.value, scheme]);
                          if (!checked)
                            form.setValue('schemes', [
                              ...field.value.filter((el) => el !== scheme),
                            ]);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                    <div className="space-y-1 leading-none">
                      <FormLabel className="static">{humanizeText(scheme)}</FormLabel>
                    </div>
                  </FormItem>
                );
              }}
            />
          ))}
        </div>
        {error && <p className="text-red-500">{error}</p>}

        <Button type="submit" variant="primary" disabled={isPending} loading={isPending}>
          {t('form.submit.createAcquirerLink')}
        </Button>
      </form>
    </Form>
  );
}
