import { Dialog, DialogContent, DialogTitle, DialogHeader } from '@components/dialog';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  InputOTPSeparator,
} from '@components/form/inputOtp';
import { Button } from '@components/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@components/form/form';
import { useTranslation } from 'react-i18next';
import { useLoginContext } from '@hooks/useLoginContext';
import { useEffect } from 'react';
import { useSecondStepForm } from '@hooks/acquirer/login/secondStep';
import { useAppContext } from '@hooks/useAppContext';
import { toast } from 'sonner';
import { useNavigate } from '@tanstack/react-router';
import { useRouter } from '@tanstack/react-router';
import { getUserSlot } from '@utils/apiHelpers';

export function SecondStep() {
  const { t } = useTranslation('app');
  const { handleLogin, sendBcPost } = useAppContext();
  const { token, showSecondStep, setShowSecondStep, setShowRecovery } = useLoginContext();
  const navigate = useNavigate();
  const router = useRouter();

  const { form, onSubmit, isPending, error } = useSecondStepForm({
    handleSuccess: async (data) => {
      handleLogin({ loginData: data, userType: 'acquirer' });
      await router.invalidate();
      await navigate({
        to: '/u/$localId/acquirer',
        params: { localId: getUserSlot(data.user.id) },
      });
      if (sendBcPost) sendBcPost({ type: 'session', sender: getUserSlot(data.user.id) });
      toast.dismiss();
      toast.success(t('toast.session.success'));
    },
  });

  useEffect(() => {
    form.setValue('token', token);
  }, [form, token]);

  return (
    <Dialog open={showSecondStep} onOpenChange={setShowSecondStep}>
      <DialogContent onInteractOutside={(e) => e.preventDefault()}>
        <DialogHeader>
          <DialogTitle className="text-center text-2xl font-medium">
            {t('pages.login.otp.title')}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            id="verifyOtpForm"
            className="flex flex-col items-center gap-6"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="otpCode"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputOTP maxLength={6} {...field}>
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={3} />
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {error && <p className="text-red">{error}</p>}
            <div className="flex gap-4">
              <Button
                className="w-full"
                type="button"
                onClick={() => setShowSecondStep(false)}
                variant="outline"
                disabled={isPending}
                loading={isPending}>
                {t('form.submit.cancel')}
              </Button>
              <Button
                className="w-full"
                type="submit"
                variant="primary"
                disabled={isPending}
                loading={isPending}>
                {t('form.submit.verify')}
              </Button>
            </div>
          </form>
        </Form>
        <ul className="ml-4 mt-5 text-center text-primary">
          <li
            className="cursor-pointer hover:underline"
            onClick={() => {
              setShowSecondStep(false);
              setShowRecovery(true);
            }}>
            {t('pages.login.otp.recovery')}
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  );
}
