import { useMatch, useNavigate, useSearch } from '@tanstack/react-router';
import { ColumnSort, SortingState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useAppContext } from './useAppContext';
import { getCacheKeyFromRoute } from '@utils/apiHelpers';

export const DEFAULT_SORTING: ColumnSort = { desc: true, id: 'created_at' } as const;
export function parseSortingToSearchObject(sorting: ColumnSort) {
  return {
    dir: sorting.desc ? 'desc' : 'asc',
    field: sorting.id,
  };
}

export function useTableSorting(defaultSorting = DEFAULT_SORTING) {
  const { sortingCache, updateSortingCache } = useAppContext();
  const navigate = useNavigate();
  const search = useSearch({ from: '__root__' });
  const match = useMatch({ strict: false });
  const key = useMemo(() => getCacheKeyFromRoute(match.routeId), [match]);
  const savedSorting = sortingCache[key];

  const searchSorting = useMemo(() => {
    const sort = search.sort;
    if (!sort?.field && !sort?.dir) return null;

    return [
      {
        id: sort.field,
        desc: sort.dir === 'desc',
      },
    ] as SortingState;
  }, [search.sort]);

  function initializeSorting() {
    if (searchSorting) return searchSorting;
    if (savedSorting) return savedSorting;

    return defaultSorting ? [defaultSorting] : [];
  }

  const [sorting, setSorting] = useState<SortingState>(initializeSorting());

  useEffect(() => {
    if (searchSorting) setSorting(searchSorting);
  }, [searchSorting]);

  useEffect(() => {
    if (sorting.length) {
      navigate({
        to: '.',
        search: (pv) => ({
          ...pv,
          sort: parseSortingToSearchObject(sorting[0]),
        }),
        hash: (prev) => prev || '',
        replace: true,
      }).catch((err) => console.error(err));
    } else {
      navigate({
        to: '.',
        search: (pv) => {
          delete pv.sort;
          return pv;
        },
        hash: (prev) => prev || '',
        replace: true,
      }).catch((err) => console.error(err));
    }
  }, [navigate, sorting]);

  function updateSorting(sort: SortingState) {
    updateSortingCache(key, sort);
    setSorting(sort);
  }

  return {
    sorting,
    updateSorting,
  };
}
