import { useApi } from '@hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { CardAuth } from './types';
import { MetaData } from '@root/globalTypes';
import { useCheckUserPermissions } from '@hooks/checkUserPermisions';

interface CardAuthsResponse {
  cardAuths: CardAuth[];
  meta: MetaData | undefined;
}

export function useListCardAuths() {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();

  const { data, isPending } = useQuery<CardAuthsResponse>({
    queryKey: [...queryKeys.cardAuths, window.location.search],
    queryFn: (): Promise<CardAuthsResponse> =>
      query({
        path: `v1/3ds${window.location.search}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged,
  });

  return {
    data: data?.cardAuths || [],
    meta: data?.meta || undefined,
    isPending,
  };
}
