import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useApi } from '@hooks/useApi';
import { ApiError } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';

export const useGetExportColumns = (type: 'orders' | 'transactions', open: boolean) => {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();
  const { data, isPending } = useQuery<{ columns: string[] }, ApiError>({
    queryKey: [type, 'columns'],
    queryFn: () =>
      query({
        path: `v1/${type}_exports/columns`,
      }),
    enabled: isLogged && Boolean(type) && open,
  });

  return {
    columns: data?.columns || [],
    loading: isPending,
  };
};
