import { FormItem, FormLabel, FormControl } from '@components/form/form';
import { Input } from '@components/form/input';
import { AcceptInvitationForm } from '@components/forms/global/invitations/accept';
import { Loader } from '@components/loader';
import { useAcceptInvitationForm } from '@hooks/forms/invitations/accept';
import { LoginLayout } from '@layouts/login';
import { Page404 } from '@pages/404';
import { useGetUserByToken } from '@services/acquirer/users/getByToken';
import { createFileRoute, useNavigate, useSearch } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function InvalidToken() {
  const { t } = useTranslation('app');

  return <Page404 message={t('pages.invitation.invalidToken')} fallbackPath="/acquirer/login" />;
}

function AcceptInvitation() {
  const { t } = useTranslation('app');
  const search = useSearch({ from: '/acquirer/invitation' });
  const navigate = useNavigate();
  const token = search.invitation_token || '';

  const { data, isPending } = useGetUserByToken({
    type: 'invitations',
    token,
  });
  const form = useAcceptInvitationForm({
    userType: 'acquirer',
    token,
    successHandler: () => {
      navigate({ to: '/acquirer/login' }).catch((err) => console.error(err));
      toast.success(t('toast.invitation.success'));
    },
  });

  if (!token) return <InvalidToken />;
  if (isPending) return <Loader />;
  if (!data) return <InvalidToken />;

  return (
    <LoginLayout title={t('pages.invitation.title')}>
      <AcceptInvitationForm
        formObj={form}
        loginFields={
          <>
            <FormItem>
              <FormLabel>{t('form.acquirerName.label')}</FormLabel>
              <FormControl>
                <Input value={data.acquirer.name} disabled />
              </FormControl>
            </FormItem>
            <FormItem>
              <FormLabel>{t('form.email.label')}</FormLabel>
              <FormControl>
                <Input value={data.email} disabled />
              </FormControl>
            </FormItem>
          </>
        }
      />
    </LoginLayout>
  );
}

export const Route = createFileRoute('/acquirer/invitation')({
  component: AcceptInvitation,
});
