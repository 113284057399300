import { useCheckUserPermissions } from '@hooks/checkUserPermisions';
import { useApi } from '@hooks/useApi';
import { MerchantLink } from '@root/globalTypes';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

export function useGetMerchantLink({
  merchantId,
  id,
  enabled = true,
}: {
  merchantId: string;
  id: string;
  enabled?: boolean;
}) {
  const { isLogged } = useCheckUserPermissions();
  const { query } = useApi();

  const { data, isPending } = useQuery<MerchantLink>({
    queryKey: [...queryKeys.merchantLinks, id],
    queryFn: (): Promise<MerchantLink> =>
      query({
        path: `v1/staff/merchants/${merchantId}/links/${id}`,
      }),
    placeholderData: (previousValue) => previousValue,
    enabled: isLogged && enabled,
  });

  return {
    data: data,
    isPending,
  };
}
