import { useApi } from '@hooks/useApi';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { MerchantLink } from '@root/globalTypes';

export function useDeleteMerchantLink({
  id,
  merchantId,
  callback,
}: {
  id: string;
  merchantId: string;
  callback: () => Promise<void>;
}) {
  const { query } = useApi();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<MerchantLink> => {
      return query({
        path: `v1/acquirer/merchants/${merchantId}/links/${id}`,
        opt: {
          method: 'DELETE',
        },
      });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.merchantLinks });
      await callback();
    },
  });

  return {
    mutate,
    isPending,
  };
}
