import { WifiOff } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ConnectionIndicator = () => {
  const { t } = useTranslation('app');
  const [connected, setConnected] = useState(true);

  useEffect(() => {
    const check = setInterval(() => {
      if (connected !== navigator.onLine) setConnected(navigator.onLine);
    }, 500);
    return () => {
      clearInterval(check);
    };
  }, [connected]);

  if (connected) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 flex items-center justify-center gap-2 border-t-4 border-error bg-white p-4 font-semibold text-error">
      <WifiOff />
      {t('errors.offline')}
    </div>
  );
};
