import { Loader } from '@components/loader';
import { Page404 } from '@pages/404';
import { MerchantUserShow } from '@pages/merchantUsers/id';
import { useGetMerchantUser } from '@services/staff/merchant/users/get';
import { useMerchantUserResetPassword } from '@services/staff/merchant/users/resetPassword';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export function MerchantUserDetails() {
  const { t } = useTranslation('app');
  const { merchantId, id } = useParams({
    from: '/u/$localId/_staff/staff/merchants/$merchantId/users/$id/',
  });
  const { data, isPending } = useGetMerchantUser(merchantId, id);

  const { mutate: resetPassword, isPending: requestPending } = useMerchantUserResetPassword({
    id,
    merchantId,
    callback: () => {
      toast.success(t('toast.password.requestSuccessful'));
    },
  });

  if (isPending) return <Loader />;
  if (!data) return <Page404 />;

  return (
    <MerchantUserShow
      user={data}
      onResetPassword={() => {
        resetPassword();
      }}
      resetPending={requestPending}
    />
  );
}

export const Route = createFileRoute('/u/$localId/_staff/staff/merchants/$merchantId/users/$id/')({
  component: MerchantUserDetails,
});
