import { useContext } from 'react';
import { LoginContext } from '@ctx/login/context';

export function useLoginContext() {
  const context = useContext(LoginContext);

  if (!context) {
    throw new Error('useLoginContext must be used within a LoginCotnextProvider');
  }

  return context;
}
