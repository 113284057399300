import { Loader } from '@components/loader';
import { Page404 } from '@pages/404';
import { MerchantLinkPage } from '@pages/merchantLinks/id';
import { useDeleteMerchantLink } from '@services/acquirer/merchant/links/delete';
import { useGetMerchantLink } from '@services/acquirer/merchant/links/get';
import { createFileRoute, redirect, useNavigate, useParams } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function MerchantLink() {
  const { merchantId, id, localId } = useParams({
    from: '/u/$localId/_acquirer/acquirer/merchants/$merchantId/links/$id/',
  });
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const [fetchingEnabled, setFetchingEnabled] = useState(true);
  const { data: merchantLink, isPending } = useGetMerchantLink({
    merchantId,
    id,
    enabled: fetchingEnabled,
  });

  const { mutate } = useDeleteMerchantLink({
    id: merchantLink?.id || '',
    merchantId: merchantLink?.merchant?.id || '',
    callback: async () => {
      await navigate({
        to: `/u/$localId/acquirer/merchants/$merchantId/links`,
        params: { merchantId, localId: localId || '0' },
      });
      toast.success(t('toast.merchantLink.deleteSuccess'));
    },
  });

  if (isPending) return <Loader />;
  if (!merchantLink) return <Page404 />;

  return (
    <MerchantLinkPage
      merchantLink={merchantLink}
      onDelete={() => {
        setFetchingEnabled(false);
        mutate();
      }}
    />
  );
}

export const Route = createFileRoute(
  '/u/$localId/_acquirer/acquirer/merchants/$merchantId/links/$id/',
)({
  beforeLoad: ({ context }) => {
    if (!context.userHasPermissions(['merchant_links_read'])) {
      throw redirect({
        to: `/u/$localId/${context.userType}/forbidden`,
      });
    }
  },
  component: MerchantLink,
});
