import { Loader } from '@components/loader';
import { Page404 } from '@pages/404';
import { AgreementDetails } from '@pages/agreements/details';
import { useGetOrder } from '@services/global/orders/get';
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router';
import { outletNavScroll } from '@utils/apiHelpers';
import { useEffect } from 'react';

const OrderAgreement = () => {
  const { id, localId } = useParams({ from: '/u/$localId/_staff/staff/orders/$id/agreement' });
  const { data, loading } = useGetOrder(id);
  const navigate = useNavigate();

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (loading) return <Loader />;
  if (!data) return <Page404 fallbackPath={`/u/${localId}/staff/orders`} />;
  if (!data.agreement) return null;

  function handleRedirectToAgreement(agreementId: string) {
    navigate({
      to: '/u/$localId/staff/agreements/$id',
      params: { localId, id: agreementId },
    }).catch((err) => console.error(err));
  }

  return (
    <AgreementDetails agreement={data.agreement} agreementRedirect={handleRedirectToAgreement} />
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/orders/$id/agreement')({
  component: OrderAgreement,
});
