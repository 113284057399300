import { MerchantLink } from '@root/globalTypes';
import { ColumnDef, Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@components/dataTable';
import { useTableSorting } from '@hooks/useTableSorting';
import { TableDateCell } from '@components/table/dateCell';

type MerchantLinksTableProps = {
  data: MerchantLink[];
  visibleColumns?: string[];
  onRowSelect?: (row: Row<MerchantLink>) => void;
};

export function useMerchantLinksTable({
  data,
  visibleColumns,
  onRowSelect,
}: MerchantLinksTableProps) {
  const { t } = useTranslation('app');
  const { sorting, updateSorting } = useTableSorting();

  const columns: ColumnDef<MerchantLink>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('table.id'),
      enableSorting: false,
    },
    {
      id: 'acquirer_link_id',
      accessorKey: 'acquirerLink.id',
      header: t('table.acquirerLinkId'),
      enableSorting: false,
    },
    {
      id: 'merchant_scheme_id',
      accessorKey: 'merchantSchemeId',
      header: t('table.merchantSchemeId'),
      enableSorting: false,
    },
    {
      id: 'mcc',
      accessorKey: 'mcc',
      header: t('table.mcc'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'schemes',
      accessorKey: 'schemes',
      header: t('table.schemes'),
      enableSorting: false,
      cell: ({ row }) => row.original.schemes.join(', '),
    },
    {
      id: 'priority',
      accessorKey: 'priority',
      header: t('table.priority'),
      sortDescFirst: true,
      enableSorting: true,
    },
    {
      id: 'currencies',
      accessorKey: 'currencies',
      header: t('table.currencies'),
      enableSorting: false,
      cell: ({ row }) => row.original.currencies.join(', '),
    },
    {
      id: 'created_at',
      accessorKey: 'createdAt',
      header: t('table.createdAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.createdAt} />,
    },
    {
      id: 'updated_at',
      accessorKey: 'updatedAt',
      header: t('table.updatedAt'),
      sortDescFirst: true,
      enableSorting: true,
      cell: ({ row }) => <TableDateCell date={row.original.updatedAt} />,
    },
  ];

  return {
    columns,
    Table: () => {
      return (
        <DataTable
          columns={
            visibleColumns
              ? columns.filter(({ id }) =>
                  typeof id === 'string' ? visibleColumns.includes(id) : false,
                )
              : columns
          }
          data={data}
          selectionCallback={onRowSelect}
          sorting={sorting}
          onSortChange={updateSorting}
        />
      );
    },
  };
}
