import { Page404 } from '@pages/404';
import { OrderSource } from '@pages/orders/source';
import { useGetOrder } from '@services/global/orders/get';
import { createFileRoute, useParams } from '@tanstack/react-router';
import { outletNavScroll } from '@utils/apiHelpers';
import { Loader } from '@components/loader';
import { useEffect } from 'react';

function Source() {
  const { id, localId } = useParams({
    from: '/u/$localId/_staff/staff/orders/$id/source',
  });
  const { data, loading } = useGetOrder(id);

  useEffect(() => {
    outletNavScroll();
  }, []);

  if (loading) return <Loader />;
  if (!data) return <Page404 fallbackPath={`/u/${localId}/acquirer/orders`} />;

  return <OrderSource order={data} />;
}

export const Route = createFileRoute('/u/$localId/_staff/staff/orders/$id/source')({
  component: Source,
});
