import { useTranslation } from 'react-i18next';
import { Popover, PopoverContent, PopoverTrigger } from '.';
import { Button } from '@components/button';
import { Label } from '@components/form/label';
import { Checkbox } from '@components/form/checkbox';
import { Grid2x2X } from 'lucide-react';
import { IconButton } from '@components/button/iconButton';

type FilterColumn = {
  id: string;
  label: string;
};

interface ColumnFiltersProps {
  activeCols: string[];
  setActiveCols: (columns: string[]) => void;
  columns: FilterColumn[];
  defaultColumns: string[];
}

export const ColumnFilterPopover = ({
  columns,
  activeCols,
  setActiveCols,
  defaultColumns,
}: ColumnFiltersProps) => {
  const { t } = useTranslation('app');

  const handleSelectAll = (checked: boolean) => {
    setActiveCols(checked ? columns.map((col) => col.id) : []);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <IconButton variant="primary" icon={<Grid2x2X size={18} />}>
          {t('toggleColumns')}
        </IconButton>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <h2 className="whitespace-nowrap p-4 font-semibold">{t('toggleColumnsInfo')}</h2>
        <div className="mt-3 flex items-center justify-between space-x-3 border-b-2 border-accent-100 px-4 pb-2">
          <div className="flex items-center gap-4">
            <Label htmlFor="allColumns" className="me-auto text-primary">
              {t('checkAll')}
            </Label>
            <Checkbox
              id="allColumns"
              checked={activeCols.length === columns.length}
              onCheckedChange={handleSelectAll}
            />
          </div>
          <Button variant="ghost" onClick={() => setActiveCols(defaultColumns)}>
            {t('filters.reset')}
          </Button>
        </div>
        <div className="max-h-72 overflow-y-auto">
          <ul className="space-y-3 p-4">
            {columns.map((col) => (
              <li className="flex items-center space-x-3" key={col.id}>
                <Label htmlFor={col.id} className="static me-auto">
                  {col.label}
                </Label>
                <Checkbox
                  id={col.id}
                  checked={activeCols.includes(col.id)}
                  onCheckedChange={(checked) => {
                    const newCols = checked
                      ? [...activeCols, col.id]
                      : activeCols.filter((el) => el !== col.id);
                    setActiveCols(newCols);
                    // if (checked) return [...activeCols, col];
                    // return activeCols.filter((el) => el !== col);
                    // setActiveCols((ps) => {
                    //   if (checked) return [...ps, col];
                    //   return ps.filter((el) => el !== col);
                    // });
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </PopoverContent>
    </Popover>
  );
};
