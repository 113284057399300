import { Loader } from '@components/loader';
import { MerchantBriefDetails } from '@pages/merchants/brief';
import { useGetCardAuth } from '@services/global/cardAuths/get';
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router';

const Auth = () => {
  const { id, localId } = useParams({
    from: '/u/$localId/_staff/staff/card-authentications/$id/merchant',
  });
  const { data, isPending } = useGetCardAuth(id);
  const navigate = useNavigate();

  if (isPending) return <Loader />;
  if (!data) return null;

  return (
    <MerchantBriefDetails
      merchant={data.merchant}
      merchantRedirect={(merchantId: string) => {
        navigate({
          to: '/u/$localId/staff/merchants/$merchantId',
          params: { merchantId, localId },
        }).catch((err) => console.error(err));
      }}
      acquirerRedirect={(acquirerId: string) => {
        navigate({
          to: '/u/$localId/staff/acquirers/$acquirerId',
          params: { acquirerId, localId },
        }).catch((err) => console.error(err));
      }}
    />
  );
};

export const Route = createFileRoute('/u/$localId/_staff/staff/card-authentications/$id/merchant')({
  component: Auth,
});
