import { useApi } from '@hooks/useApi';
import { ApiError, Merchant } from '@root/globalTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { queryKeys } from '@utils/consts';
import { isFailure } from '@utils/apiHelpers';
import { MerchantData } from '@services/types';

export function useCreateMerchant({ successHandler }: { successHandler: () => void }) {
  const { query } = useApi();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<ApiError | null>(null);

  const { mutate, isPending } = useMutation({
    mutationFn: (merchantData: MerchantData): Promise<Merchant | ApiError> => {
      return query({
        path: `v1/acquirer/merchants`,
        data: merchantData,
        opt: {
          method: 'POST',
        },
      });
    },
    onSettled: (data) => {
      if (!data) return;
      if (isFailure(data)) {
        setApiError(data);
      } else {
        successHandler();
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queryKeys.merchants });
    },
  });

  return {
    mutate,
    isPending,
    error: apiError,
  };
}
