import { zodResolver } from '@hookform/resolvers/zod';
import { AcquirerLink, MutateObject } from '@root/globalTypes';
import { AcquirerLinkData } from '@services/types';
import { VALID_SCHEMES } from '@utils/consts';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export function useAcquirerLinkForm({
  link,
  requestHandler,
}: {
  link?: AcquirerLink;
  requestHandler: MutateObject<AcquirerLink, AcquirerLinkData>;
}) {
  const { t } = useTranslation('app');

  const schema = z.object({
    linkType: z
      .string()
      .trim()
      .min(1, { message: t('form.linkType.required') }),
    bin: z
      .string()
      .trim()
      .min(1, { message: t('form.bin.required') }),
    priority: z.preprocess(
      (v) => (typeof v === 'string' ? parseInt(v, 10) : v),
      z
        .number()
        .int()
        .min(0, { message: t('form.priority.min') })
        .max(40, { message: t('form.priority.max') }),
    ),
    schemes: z.array(z.enum(VALID_SCHEMES)),
  });

  const defaultValues = {
    linkType: link?.linkType || '',
    bin: link?.bin || '',
    priority: link?.priority || 0,
    schemes: link?.schemes || [],
  };

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  const { mutate, error, isPending } = requestHandler;

  useEffect(() => {
    if (!error || !error.errors) return;

    Object.entries(error.errors).forEach(([key, value]) => {
      const k = key as keyof z.infer<typeof schema>;

      form.setError(k, { type: 'manual', message: value[0] });
    });
  }, [form, error]);
  const onSubmit = async (values: z.infer<typeof schema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    isPending,
    error: error?.message,
  };
}
