import { Alert } from '@components/alert';
import { useAppContext } from '@hooks/useAppContext';
import { useTranslation } from 'react-i18next';

export function TwoFactorRequiredAlert() {
  const { t } = useTranslation('app');
  const { twoFactorRequired, user } = useAppContext();

  if (!twoFactorRequired || (twoFactorRequired && user?.twoFactorEnabled)) {
    return null;
  }

  return (
    <Alert variant="danger" className="mb-2 lg:px-12">
      <div className="flex flex-row items-center justify-between">
        {t('alerts.twoFactorRequired')}
      </div>
    </Alert>
  );
}
