import { FormControl, FormItem, FormLabel } from '@components/form/form';
import { Input } from '@components/form/input';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@components/form/select';
import { validateKeyDown } from '@utils/textHelpers';
import { useState } from 'react';
import { ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const BinRange = ({
  form,
  field,
}: {
  form: UseFormReturn<
    {
      identifier: string;
      activity: 'allow' | 'deny';
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    undefined
  >;
  field: ControllerRenderProps<
    {
      identifier: string;
      activity: 'allow' | 'deny';
    },
    'identifier'
  >;
}) => {
  const { t } = useTranslation('app');
  const [binType, setBinType] = useState<'exact' | 'range'>('exact');

  const handleBinRangeUpdate = () => {
    const { value: rangeStart } = document.getElementById('range[0]') as HTMLInputElement;
    const { value: rangeEnd } = document.getElementById('range[1]') as HTMLInputElement;
    let localVal = rangeStart || rangeEnd || '';
    if (rangeStart && rangeEnd) localVal = `${rangeStart},${rangeEnd}`;
    form.setValue('identifier', localVal);
  };

  return (
    <div className="flex gap-8">
      <div>
        <Select value={binType} onValueChange={(v: 'exact' | 'range') => setBinType(v)}>
          <SelectTrigger className="w-full">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="exact">{t('exact')}</SelectItem>
            <SelectItem value="range">{t('range')}</SelectItem>
          </SelectContent>
        </Select>
      </div>
      {binType === 'exact' ? (
        <FormControl>
          <Input
            type="text"
            onKeyDown={(e) => {
              if (!validateKeyDown(e, /[0-9]|,/)) e.preventDefault();
            }}
            {...field}
          />
        </FormControl>
      ) : (
        <div className="flex grow flex-col gap-4">
          <FormItem>
            <FormLabel className="inline-flex items-center" htmlFor="range[0]">
              {t(`form.range.from`)}
            </FormLabel>
            <Input
              id="range[0]"
              type="text"
              onKeyDown={(e) => {
                if (!validateKeyDown(e, /[0-9]|,/)) e.preventDefault();
              }}
              onChange={handleBinRangeUpdate}
            />
          </FormItem>
          <FormItem>
            <FormLabel className="inline-flex items-center" htmlFor="range[1]">
              {t(`form.range.to`)}
            </FormLabel>
            <Input
              id="range[1]"
              type="text"
              onKeyDown={(e) => {
                if (!validateKeyDown(e, /[0-9]|,/)) e.preventDefault();
              }}
              onChange={handleBinRangeUpdate}
            />
          </FormItem>
        </div>
      )}
    </div>
  );
};
