import {
  Column,
  ColumnDef,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@components/table';
import { cn } from '@utils/cn';
import { useTranslation } from 'react-i18next';
import { ReactNode, MouseEvent, useEffect, useRef } from 'react';
import { ChevronDown, ChevronsUpDown, ChevronUp } from 'lucide-react';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  className?: string;
  hasPagination?: boolean;
  disableRowSelection?: boolean;
  styleRowAction?: boolean;
  selectionCallback?: (x: Row<TData>) => void;
}

const CellContent = ({ children }: { children: ReactNode }) => {
  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!divRef.current) return;
    const row = divRef.current.closest('tr');
    if (!row) return;
    const h = [...row.querySelectorAll('td')].map((el) => el.getBoundingClientRect().height);
    // eslint-disable-next-line immutable/no-mutation
    divRef.current.style.height = `${Math.floor(Math.max(...h))}px`;
  }, []);

  return (
    <div ref={divRef} className={cn('flex items-center px-5 py-4')}>
      {children}
    </div>
  );
};

interface DataTableExtendedProps<TData, TValue> extends DataTableProps<TData, TValue> {
  sorting?: SortingState;
  onSortChange?: (sort: SortingState) => void;
  enableSorting?: boolean;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  className = '',
  disableRowSelection = false,
  selectionCallback,
  enableSorting = true,
  sorting,
  onSortChange,
}: DataTableExtendedProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    manualSorting: true,
    enableSorting: enableSorting,
    state: { sorting },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: (updater) => {
      if (!sorting || !onSortChange) return;
      const newSortingValue = updater instanceof Function ? updater(sorting) : updater;
      onSortChange(newSortingValue);
    },
  });
  const { t } = useTranslation('app');

  const handleClick = (row: Row<TData>, ev: MouseEvent) => {
    if (disableRowSelection) return;
    const tar = ev.target as HTMLElement;
    if (tar.closest('button') || tar.closest('[data-target="overlay"]')) return;
    if (selectionCallback) selectionCallback(row);
  };

  const fixedCols = ['action', 'actions', 'permissions', 'status'];

  function getSortingIcon(column: Column<TData, unknown>) {
    if (!column.getCanSort()) return null;

    switch (column.getIsSorted()) {
      case 'asc':
        return <ChevronUp size={14} />;
      case 'desc':
        return <ChevronDown size={14} />;
      default:
        return <ChevronsUpDown size={14} />;
    }
  }

  return (
    <div className={cn('rounded bg-white p-4', className)}>
      <Table>
        <TableHeader className="border-b-0">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const { id, column } = header;
                const { size } = column.columnDef;
                return (
                  <TableHead
                    key={header.id}
                    className={cn('px-5 pb-6 pt-4', header.column.getCanSort() && 'cursor-pointer')}
                    style={fixedCols.includes(id) ? { width: size } : {}}>
                    {header.column.getCanSort() ? (
                      <button
                        className="flex items-center gap-2"
                        onClick={header.column.getToggleSortingHandler()}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        {getSortingIcon(header.column)}
                      </button>
                    ) : (
                      <div className="flex items-center gap-2">
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="border-0">
          <TableRow className="h-5" />
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, i) => {
              return (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={(ev) => handleClick(row, ev)}
                  className={cn(
                    '',
                    i % 2 === 1 && 'bg-accent-100',
                    disableRowSelection
                      ? ''
                      : 'cursor-pointer transition-colors hover:bg-accent/30',
                  )}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className="group/row p-0 first:rounded-l last:rounded-r">
                      <CellContent>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </CellContent>
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="p-0">
                <div className='className="h-24 px-5 py-4 text-center font-medium text-red-500'>
                  {t('noData')}
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
